import { Box } from "@mui/material";
import Iconify from "../iconify/Iconify";

export const CalendarIcon = () => {
	return (
		<>
			<Box
				sx={{
					backgroundColor: "secondary.main",
					color: "common.white",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					height: { xs: 34, lg: 40 },
					height: "100%",
					width: { xs: 40, sm: 50, md: 50, lg: 50 },
					borderTopRightRadius: 6,
					borderBottomRightRadius: 6,
				}}
			>
				<Iconify
					sx={{ width: 18, height: "auto" }}
					icon="mdi:calendar-blank"
				/>
			</Box>
		</>
	);
};
