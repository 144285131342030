/*
  RULES:
  Always use dots at the end
*/

export const ErrorMessagesSchema = {
	newPassword: {
		required: "New Password is required.",
	},
	confirmPassword: {
		required: "To confirm your new password is required.",
	},
	passwordValidation: {
		requirements:
			"Password should meet these requirements: minimum length of 8 characters, 1 capital letter, 1 lower-case letter, 1 number and 1 special character.",
		dontMatch: "Passwords don't match. Please try again.",
		attemptLimit: "Attempt limit exceeded. Try again later.",
	},
	otpCode: {
		invalid: "OTP code is invalid.",
		wrong: "Incorrect verification code provided, please try again.",
	},
};
