import { decryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";
import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";

const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;

const get_tenant_url = baseUrl + "tenant/frontend/verify/tenant/company";
const create_order_url = baseUrl + "order";
const get_countries_url = baseUrl + "subscription/country_list";
const get_plans_url = baseUrl + "subscription/admin/subscription/plan";
const test_conection_url = baseUrl + "validate-integration";

export const extractData = (result) => {
	if (encConfig.encryption.decrypt) {
	  if (result?.data) return decryptData(result);
	}
	return result;
  };

export async function testConection(headers, token) {
	return await getData(test_conection_url, null, headers, token);
}

export async function createOnboardingUser(data) {
	return await postData(`${baseUrl}frontend/user/signup`, null, data, null);
}

export async function validateOtpCode(data) {
	return await postData(
		`${baseUrl}frontend/user/verify_email`,
		null,
		data,
		null
	);
}

export async function createOrderOnBoarding(data, user_id) {
	const headers = {
		user_id,
		action: 'placeOrder',
	};

	const res = await postData(create_order_url, headers, data, null);
	return JSON.parse(extractData(res));
}

export async function getCountries() {
	return await getData(get_countries_url);
}

export async function signupUser(data, userInfo) {
	const token = userInfo?.accessToken;
	const headers = {
		action: "createTenantByAdminCMS",
	};
	const res = await postData(`${baseUrl}account`, headers, data, token);
	return JSON.parse(extractData(res));
}

export async function getExistantTenant(tenant, token) {
	const params = {
		company_name: tenant,
	};
	const res = await getData(
		`${baseUrl}tenant/admin/verify/tenant/company`,
		params,
		{},
		token
	);
	return res;
}

export async function getPlans(token) {
	return await getData(get_plans_url,{},{},token);
}
