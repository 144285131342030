import {
	Box,
	Button,
	FormControl,
	InputAdornment,
	InputLabel,
	MenuItem,
	Select,
	Stack,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { retrieveLogoUrl } from "src/features/config/configApi";
import { getPlans } from "src/features/onborading/onboardingApi";
import { getAllTenants } from "src/features/tenants/tenantsApi";
import { setTenant } from "src/features/tenants/tenantsState";
import { useAppSelector } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";
import Iconify from "src/components/iconify/Iconify";
import AccountPopover from "src/layouts/dashboard/header/AccountPopover";
import palette from "src/theme/palette";
import {
	decrementAwaitingApiCounter,
	incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const inputStyle = {
	flex: { xs: 1, md: "unset" },
	width: { md: "30%", lg: "25%" },
	boxShadow: "none",
	backgroundColor: "common.white",
	fontSize: { xs: 14, sm: 14, md: 14, lg: 16 },
	lineHeight: "24px",
	marginTop: { xs: "10px", md: "20px" },
	"& :nth-of-type(1)": {
		fontSize: { xs: 14, sm: 14, md: 14, lg: 16 },
	},
	"& > div > input": {
		boxSizing: "border-box",
		color: "primary.main",
		height: { xs: 36, lg: 40 },
	},
	"& > div": {
		padding: 0,
	},
	"& .MuiOutlinedInput-root": {
		"&:hover fieldset": {
			borderColor: "primary.dark",
		},
		"&.Mui-focused fieldset": {
			borderColor: "primary.dark",
		},
	},
};

const FilterStyle = {
	input: { color: palette.common.black },
	color: "black",
	height: { xs: 36, lg: 40 },
	// width: { xs: 120, md: 240 },
	border: "1px solid transparent",
	"&.MuiInputBase-root": {
		"&:hover": {
			border: "1px solid #143F67",
		},
	},
};

const Tenants = () => {
	const userInfo = useAppSelector(getUserInfo);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [tenants, setTenants] = useState([]);
	const [filteredTentants, setFilteredTenants] = useState(tenants);
	const [isStateChange, setIsStateChange] = useState();
	const [searchTenant, setSearchTenant] = useState("");
	const [plans, setPlans] = useState([]);
	const [isFilter, setFilter] = useState("all");
	const handleSearchTenants = (e) => {
		e.preventDefault();

		const value = e.target.value;

		setSearchTenant(value);

		if (value === "") {
			setIsStateChange(!isStateChange);
		}

		const filteredRows = tenants.filter((row) =>
			row.tenant_name?.toLowerCase().includes(value.toLowerCase())
		);

		setFilteredTenants(filteredRows);
	};

	const handleTenantDetails = (tenant) => {
		const tenantObj = {
			tenantID: tenant._id,
			tenantName: tenant.tenant_name,
			userID: tenant.user_id,
			email: tenant.contact_info.email,
			phoneNo: tenant.contact_info.phone_no,
			is_demo_user: tenant.isMarketingUser,
		};
		dispatch(setTenant(tenantObj));
		navigate("details");
	};

	const getDate = (billingDate) => {
		let date = new Date(billingDate * 1000);
		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
	};

	useEffect(() => {
		dispatch(incrementAwaitingApiCounter());
		getAllTenants(userInfo?.accessToken)
			.then((r) => {
				const { statusCode, data } = r;
				if (statusCode === 200) {
					setTenants(data);
					setFilteredTenants(data);
				}
				dispatch(decrementAwaitingApiCounter());
			})
			.catch((err) => {
				dispatch(decrementAwaitingApiCounter());
				console.log(err);
			});
		getPlans(userInfo?.accessToken).then((res) => setPlans(res.data));
	}, [isStateChange]);

	return (
		<Box>
			<Helmet>
				<title> Tenants | BeyondCarts CMS </title>
			</Helmet>
			<Box
				sx={{
					paddingBottom: 2,
					borderBottom: "1px solid #c4c4c4",
				}}
			>
				<Box
					sx={{
						display: "flex",
						marginTop: "20px",
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					<Stack>
						<Typography variant="h4" sx={{ color: "#000" }}>
							Tenants
						</Typography>
						<Typography variant="body1" sx={{ color: "#000" }}>
							Manage all your tenants from one place
						</Typography>
					</Stack>
					<AccountPopover />
				</Box>
				<Box
					sx={{
						display: "flex",
						alignItems: "baseline",
						gap: { xs: 1, md: 1.5 },
					}}
				>
					<TextField
						sx={inputStyle}
						variant="outlined"
						onChange={handleSearchTenants}
						value={searchTenant}
						placeholder="Search"
						type="text"
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<Button
										variant="contained"
										type="submit"
										sx={{
											boxShadow: "none",
											backgroundColor: "secondary.main",
											alignContent: "center",
											justifyContent: "center",
											borderTopLeftRadius: 0,
											borderBottomLeftRadius: 0,
											height: { xs: 36, lg: 40 },
											width: { sm: 50, lg: 60 },
											minWidth: 30,
											"& .MuiButton-startIcon": {
												margin: "0",
											},
										}}
										startIcon={
											<Iconify
												sx={{
													width: {
														lg: 20,
														md: 20,
														sm: 16,
														xs: 16,
													},
													height: "auto",
													margin: 0,
													color: "text.active",
												}}
												icon="eva:search-fill"
											/>
										}
									/>
								</InputAdornment>
							),
						}}
					/>
					<FormControl
						sx={{
							position: "relative",
							flex: { xs: 1, md: "unset" },
							width: { md: "30%", lg: "25%" },
						}}
					>
						<InputLabel
							id="tenant-select-label"
							sx={{
								backgroundColor: "#fafafa",
								padding: "0 5px",
								textAlign: "center",
								position: "absolute",
								// left: "-5px",
							}}
						>
							Filter by
						</InputLabel>
						<Select
							labelId="tenant-select-label"
							value={isFilter}
							onChange={(e) => setFilter(e.target.value)}
							sx={{ ...FilterStyle, color: palette.common.black }}
						>
							<MenuItem
								value={"all"}
								sx={{ color: palette.common.black }}
							>
								All
							</MenuItem>
							<MenuItem
								value={"Inactive"}
								sx={{ color: palette.common.black }}
							>
								Inactive
							</MenuItem>
							<MenuItem
								value={"active"}
								sx={{ color: palette.common.black }}
							>
								Active
							</MenuItem>
						</Select>
					</FormControl>
				</Box>
			</Box>

			<Box
				sx={{
					display: "flex",
					flexWrap: "wrap",
					gap: 2,
					padding: "20px 0 14px 0",
					width: "100%",
				}}
			>
				{filteredTentants?.map((tenant) => {
					const matchedPlan = plans.find(
						(plan) => plan._id === tenant.plan_id
					);
					if (isFilter === "all" || tenant?.status === isFilter) {
						return (
							<Box
								key={tenant._id}
								onClick={() => handleTenantDetails(tenant)}
								sx={{
									border: "1px solid #c4c4c4",
									borderRadius: "8px",
									padding: "20px",
									flex: { xs: 1, lg: "unset" },
									width: {
										lg: "32%",
									},
									textAlign: {
										xs: "center",
										sm: "unset",
										cursor: "pointer",
									},
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										justifyContent: "space-between",
										marginBottom: "20px",
									}}
								>
									<img
										maxWidth="100px"
										style={{ objectFit: "contain" }}
										height="50px"
										src={
											tenant.logo_path
												? retrieveLogoUrl +
												  "/" +
												  tenant.logo_path
												: "/assets/no-image.png"
										}
									/>

									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											columnGap: "5px",
										}}
									>
										<Box
											sx={{
												padding: "8px",
												borderRadius: "50px",
												backgroundColor: `${
													tenant?.status !== "active"
														? palette.error.main
														: "green"
												}`,
											}}
										/>
									</Box>
								</Box>
								<Box
									sx={{
										display: "flex",
										alignItems: "center",
										justifyContent: "space-between",
									}}
								>
									<Typography
										variant="body1"
										sx={{
											color: "#000",
											fontSize: 18,
											fontWeight: "bold",
											textTransform: "capitalize",
											inlineSize: { xs: 170, md: 180 },
											overflowWrap: "break-word",
										}}
									>
										{tenant.tenant_name}
									</Typography>

									<Typography
										variant="caption"
										sx={{
											color: "#000",
											fontWeight: "bold",
											textAlign: "right",
										}}
									>
										Enrolled at{" "}
										{getDate(tenant.enrolled_at)}
									</Typography>
								</Box>

								<Box
									sx={{
										display: "flex",
										flexDirection: {
											xs: "row",
										},
										alignItems: "center",
										marginTop: "15px",
										justifyContent: "space-between",
									}}
								>
									<Typography
										variant="h6"
										sx={{
											color: "#000",
											fontWeight: "bold",
											textTransform: "capitalize",
										}}
									>{`${matchedPlan?.name} Subscriber`}</Typography>
									{matchedPlan?.name
										.toLowerCase()
										.includes("premium") ? (
										<img
											width="30px"
											src="/assets/icons/dashboardIcons/status.svg"
										/>
									) : (
										<img
											width="30px"
											src="/assets/icons/dashboardIcons/greyCrown.svg"
										/>
									)}
								</Box>
							</Box>
						);
					}
				})}
			</Box>
		</Box>
	);
};

export default Tenants;
