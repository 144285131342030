import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Box } from "@mui/material";
import { testConection } from "src/features/onborading/onboardingApi";
import { setSigninData } from "src/features/signin/signin.action";
import palette from "src/theme/palette";
import InfoLogo from "src/components/info-logo/info-logo";
import "./shipstationForm.scss";
import { LoadingButton } from "@mui/lab";
import { getUserInfo } from "src/features/user/userState";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import ModalComponent from "src/components/modal/ModalComponent";
import {
	decrementAwaitingApiCounter,
	incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const styleInput = {
	input: { color: palette.common.black },
	width: { lg: 515, md: 446, sm: 446 },
	marginTop: "10px",
	"& .MuiOutlinedInput-root": {
		"&:hover fieldset": {
			borderColor: "primary.dark",
		},
		"&.Mui-focused fieldset": {
			borderColor: "primary.dark",
		},
	},
	"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "secondary.light",
	},
	"& .MuiInputLabel-formControl": {
		color: "#00000099",
	},
	"& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
		color: "#00000099",
	},
	"& .MuiFormHelperText-root": {
		textAlign: "left",
		fontSize: "14px",
	},
};

const ShipstationForm = () => {
	const dispatch = useDispatch();
	const dispatchApp = useAppDispatch();
	const signinData = useSelector((state) => state.signin.signinData);
	const {
		managerSignShipStationApiKey,
		managerSignShipStationApiKeyErrors,
		managerSignShipStationApiSecret,
		managerSignShipStationApiSecretErrors,
		isShipStationValid,
		isShipStationTested,
		isFromConfig,
	} = signinData;

	const [openModal, setOpenModal] = useState(false);
	const [modalText, setModalText] = useState("");
	const [testCon, setTestCon] = useState(false);
	const userInfo = useAppSelector(getUserInfo);

	const form = [
		{ text: "Log in to your Shipstation account.", inp: false },
		{ text: "Click Account Settings in the top right corner.", inp: false },
		{ text: "Click Account > API Settings", inp: false },
		{
			text: "Click “Generate new API Keys” if you don’t already have one",
			inp: false,
		},
		{
			text: "Copy the API key, Paste it here:",
			inp: true,
			placehold: "API Key *",
			infoTxt:
				"Enter your ShipStation API Key. This key identifies your account to ShipStation's API, allowing for data exchange between our platform and ShipStation. You can find your API Key in ShipStation under Account Settings > API Settings.",
		},
		{
			text: "Copy the API Secret. Paste it here:",
			inp: true,
			placehold: "API Secret *",
			infoTxt:
				"Enter your ShipStation API Secret. This secret works alongside your API Key to authenticate access to ShipStation's services securely. You can locate your API Secret in the same section as your API Key within ShipStation's settings.",
		},
	];

	const handleTestConnection = async () => {
		if (!signinData.managerSignShipStationApiKey) {
			dispatch(
				setSigninData({
					...signinData,
					managerSignShipStationApiKeyErrors: true,
				})
			);
			return;
		}

		if (!signinData.managerSignShipStationApiSecret) {
			dispatch(
				setSigninData({
					...signinData,
					managerSignShipStationApiSecretErrors: true,
				})
			);
			return;
		}
		dispatchApp(incrementAwaitingApiCounter());

		testConection(
			{
				action: "shipstation",
				api_key: managerSignShipStationApiKey,
				api_secret: managerSignShipStationApiSecret,
			},
			userInfo.accessToken
		)
			.then((r) => {
				const { statusCode } = r;

				if (statusCode === 200) {
					dispatch(
						setSigninData({
							...signinData,
							isShipStationValid: true,
							isShipStationTested: true,
						})
					);

					setTestCon("SHIPSTATION");
				} else {
					dispatch(
						setSigninData({
							...signinData,
							isShipStationValid: false,
							isShipStationTested: true,
						})
					);

					setTestCon("SHIPSTATION_NOT");
				}

				dispatchApp(decrementAwaitingApiCounter());
			})
			.catch(() => {
				dispatch(
					setSigninData({
						...signinData,
						isShipStationValid: false,
						isShipStationTested: true,
					})
				);

				setTestCon("NOT_SHIPSTATION");
				dispatchApp(decrementAwaitingApiCounter());
			});
	};

	const onValue = (index) => {
		if (index === 4) return managerSignShipStationApiKey;
		if (index === 5) return managerSignShipStationApiSecret;
	};

	const onInputChange = (value, index) => {
		if (index === 4)
			dispatch(
				setSigninData({
					...signinData,
					managerSignShipStationApiKey: value,
					isShipStationValid: false,
					isShipStationTested: false,
					continueBtn: false,
				})
			);
		if (index === 5)
			dispatch(
				setSigninData({
					...signinData,
					managerSignShipStationApiSecret: value,
					isShipStationValid: false,
					isShipStationTested: false,
					continueBtn: false,
				})
			);
	};

	const onInputError = (index) => {
		if (index === 4 && managerSignShipStationApiKeyErrors) return true;
		if (index === 5 && managerSignShipStationApiSecretErrors) return true;
		return false;
	};

	const onFocus = (index) => {
		if (index === 4 && managerSignShipStationApiKeyErrors)
			dispatch(
				setSigninData({
					...signinData,
					managerSignShipStationApiKeyErrors: false,
				})
			);
		if (index === 5 && managerSignShipStationApiSecretErrors)
			dispatch(
				setSigninData({
					...signinData,
					managerSignShipStationApiSecretErrors: false,
				})
			);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		dispatch(setSigninData({ ...signinData, continueBtn: true }));
	};

	useEffect(() => {
		if (!testCon) return;

		if (isShipStationTested) {
			if (isShipStationValid) {
				dispatch(setSigninData({ ...signinData, continueBtn: true }));
			} else {
				setOpenModal(true);
				setTestCon(true);
				setModalText(
					"Your test connection did not succeed with ShipStation, affecting our ability to retrieve your order details at this moment."
				);
			}
		}
	}, [testCon]);

	useEffect(() => {
		if (isFromConfig) return;
		if (isShipStationTested)
			dispatch(setSigninData({ ...signinData, continueBtn: true }));
		else dispatch(setSigninData({ ...signinData, continueBtn: false }));
	}, []);

	return (
		<>
			<div className="shipstation-form-container">
				<img className="manage-img" src="/assets/shipStation.png"></img>

				<div className="shipstation-form-step-container">
					{form.map((f, index) => (
						<Fragment>
							<div className="shipstation-form-step">
								<p className="shipstation-form-index">
									{index + 1}
								</p>
								<p>{f.text}</p>
							</div>
							{f.inp && (
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										fullWidth
										sx={styleInput}
										label={f.placehold}
										value={onValue(index)}
										error={onInputError(index)}
										helperText={
											onInputError(index) &&
											`${f.placehold} is required.`
										}
										onFocus={() => onFocus(index)}
										onChange={(e) => {
											const trimmedValue =
												e.target.value.trim();
											onInputChange(trimmedValue, index);
										}}
										inputProps={{
											maxLength: 50,
										}}
									></TextField>
									<InfoLogo infoTxt={f.infoTxt}></InfoLogo>
								</Box>
							)}
						</Fragment>
					))}
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<LoadingButton
							variant="contained"
							type="submit"
							onClick={() => handleTestConnection()}
							sx={{
								fontSize: "16px !important",
								boxShadow: "none",
								width: "50%",
								margin: "20px auto",
								backgroundColor: isShipStationTested
									? isShipStationValid
										? palette.success.dark
										: palette.error.dark
									: palette.secondary.light,
							}}
						>
							{isShipStationTested
								? isShipStationValid
									? "Connection Successful"
									: "Unable to Connect"
								: "Test Ship Station Connection"}
						</LoadingButton>
					</Box>
				</div>
			</div>
			<ModalComponent
				openModal={openModal}
				handleCloseModal={handleCloseModal}
				handleBtnClick={handleCloseModal}
				title={`Connection status`}
				description={modalText}
				btnText={"Continue"}
			></ModalComponent>
		</>
	);
};

export default ShipstationForm;
