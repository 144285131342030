import { Backdrop, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ResetPassword from "src/components/reset-password/ResetPassword";
import CircularLoader from "src/loader/circular/CircularLoader";
import timer from "src/utils/timer";
import { useAppDispatch } from "src/hooks/hooks";
import { login, logoutUser } from "src/features/user/userState";
import { storeCredentialsInCookies } from "src/utils/cookiesTracker";
import { getToken } from "src/utils/extractUserToken";

const ResetPasswordDone = () => {
	const {
		handleSubmit,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [fetchedToken, setFetchedToken] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const {
		state: { obj },
	} = useLocation();
	const credentials = {
		email: obj?.email,
		password: obj?.new_password,
	};

	const onSubmit = () => {
		setIsLoading(true);
		dispatch(login(credentials))
			.then((action) => {
				// console.log("action", action.meta);
				const { email, password } = action.meta.arg;
				// const rememberMe = data.remember;
				// if (rememberMe) {
				storeCredentialsInCookies(email, password);
				// }
				setFetchedToken(getToken());
				// console.log(fetchedToken);
			})
			.catch((error) => console.log(error));
	};

	useEffect(() => {
		// console.log(fetchedToken);
		if (fetchedToken) {
			// setIsLoading(false);
			navigate("/dashboard");
		}
	}, [fetchedToken]);

	return (
		<>
			<Helmet>
				<title> Reset Password | BeyondCarts CMS </title>
			</Helmet>

			{isLoading && <CircularLoader />}

			<ResetPassword
				title={"Your password has been changed!"}
				style={{
					title: { color: "$primary-color", textAlign: "center" },
				}}
				btnText={"Continue"}
				handleSubmit={handleSubmit}
				onSubmit={onSubmit}
			/>
		</>
	);
};

export default ResetPasswordDone;
