/* eslint-disable */
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Box,
	Divider,
	MenuItem,
	Avatar,
	IconButton,
	Popover,
	Typography,
} from "@mui/material";
import Iconify from "src/components/iconify";
import account from "src/_mock/account";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { getUserInfo, logoutUser } from "src/features/user/userState";
import ProfileModal from "src/components/modal/ProfileModal";

export default function AccountPopover() {
	const [open, setOpen] = useState(null);
	const [userName, setUserName] = useState("");
	const [openProfileModal, setOpenProfileModal] = useState(false);
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const userInfo = useAppSelector(getUserInfo);
	useEffect(() => {
		setUserName(userInfo?.first_name);
	}, []);

	const handleOpen = (event) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	const handleLogout = () => {
		dispatch(logoutUser());
	};
	const first_name = localStorage.getItem("first_name");
	return (
		<>
			<IconButton
				onClick={handleOpen}
				sx={{
					p: 0,
					display: "flex",
					// alignItems: "flex-start",
				}}
			>
				<Avatar src={account.photoURL} alt="photoURL" />
			</IconButton>

			{openProfileModal && (
				<ProfileModal
					openModal={openProfileModal}
					setOpenModal={setOpenProfileModal}
				/>
			)}

			<Popover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
				transformOrigin={{ vertical: "top", horizontal: "right" }}
				PaperProps={{
					sx: {
						p: 0,
						mt: 1.5,
						ml: 0.75,
						width: 200,
						"& .MuiMenuItem-root": {
							typography: "body2",
							borderRadius: 0.75,
						},
					},
				}}
			>
				<Box
					sx={{
						my: 1.5,
						px: 2.5,
						display: "flex",
						alignItems: "center",
						cursor: "pointer",
					}}
					onClick={() => setOpenProfileModal(true)}
				>
					<Avatar />
					<Typography
						variant="body2"
						sx={{ color: "common.black", px: 2 }}
						noWrap
					>
						{first_name || userInfo?.first_name}
					</Typography>
				</Box>

				<Divider sx={{ borderStyle: "line" }} />
				<Box
					sx={{
						my: 1.5,
						px: 2.5,
						display: "flex",
						alignItems: "center",
					}}
				>
					<Iconify
						icon="mdi:logout"
						sx={{
							color: "text.disabled",
							width: 30,
							height: 26,
						}}
					/>
					<MenuItem
						onClick={handleLogout}
						sx={{ m: 1, color: "common.black" }}
					>
						Logout
					</MenuItem>
				</Box>
			</Popover>
		</>
	);
}
