/* eslint-disable camelcase */
/* eslint-disable no-loss-of-precision */
import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
// @mui
import { Container } from "@mui/material";
import getMailScans from "src/features/mailScans/mailScans";
import {
	unixToDate,
	unixToTime,
	dateToUnix,
	removeTitleCase,
} from "src/utils/core";
import SmartTable from "../components/smart-table/SmartTable";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import { logoutUser, updateUserPreferences } from "src/features/user/userState";
import { updateColumnPreferences } from "src/features/tableActions/userColPreferenceApi";
import { getUserInfo } from "src/features/user/userState";
import {
	incrementAwaitingApiCounter,
	decrementAwaitingApiCounter,
} from "src/features/ui/uiState";

function MailScans() {
	const mailScan = useAppSelector((state) => state.user.mailScan);
	const trackingInfo = useAppSelector((state) => state.user.trackingInfo);
	const [mainTrackingList, setMainTrackingList] = useState([]);
	const [totalItemsCount, setTotalItemCount] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [showLoader, setShowLoader] = useState(false);
	const [startDate, setStartDate] = useState(
		dayjs().subtract(7, "day").format("MM/DD/YYYY")
	);
	const [endDate, setEndDate] = useState(dayjs().format("MM/DD/YYYY"));
	const userInfo = useAppSelector(getUserInfo);
	const [advancedFormData, setAdvacnedFromData] = useState({
		report_type: "label_created_date",
	});
	const [userSelectedColumns, setUserSelectedColumns] = useState([]);
	const dispatch = useAppDispatch();
	const columnsToRemove = [
		"scanned_with_estimated_delivery_date",
		"scanned_without_estimated_delivery_date",
	];
	useEffect(() => {
		if (typeof mailScan !== "undefined" && mailScan.length > 0) {
			setUserSelectedColumns(mailScan);
		}
	}, [mailScan]);

	const dateTypeList = [
		{
			label: "Label Created Date",
			value: "label_created_date",
		},
		{
			label: "Order Created Date",
			value: "order_created_date",
		},
		{
			label: "Tracking Date",
			value: "db_created_date",
		},
	];

	// get Tracking number list
	const getmailScansList = () => {
		dispatch(incrementAwaitingApiCounter());
		setShowLoader(true);
		const res = getMailScans(advancedFormData, userInfo?.accessToken);
		res.then((result) => {
			if (startDate && endDate) {
				const startDateUnix = dateToUnix(startDate);
				const endDateUnix = dateToUnix(endDate);
				const dateFilterdScanList = result.data.items.filter(
					(obj) =>
						startDateUnix <= obj.created_date &&
						endDateUnix >= obj.created_date
				);
				setMainTrackingList(dateFilterdScanList);
				setTotalItemCount(dateFilterdScanList.length);
				dispatch(decrementAwaitingApiCounter());
				setShowLoader(false);
			} else {
				setMainTrackingList(result.data.items);
				setTotalItemCount(result.data.total_count);
				dispatch(decrementAwaitingApiCounter());
				setShowLoader(false);
			}
		}).catch((error) => {
			if (
				error?.response?.status === 401 ||
				error?.response?.status === 403
			) {
				dispatch(logoutUser());
			}
			setShowLoader(false);
		});
	};

	useEffect(() => {
		getmailScansList();
	}, [startDate, endDate, advancedFormData]);

	const estimatedTimeFormatted = mainTrackingList?.map((item) =>
		item.created_date
			? { ...item, created_date: `${unixToDate(item.created_date)}` }
			: item
	);

	// formate key names
	const originalRows1 = estimatedTimeFormatted?.map(
		({
			created_date: created_date,
			db_count: total_in_database,
			scanned_count: successful_scans,
			unscanned_count: no_scans,
			estimated_date_count: scanned_with_estimated_delivery_date,
			no_estimated_date_count: scanned_without_estimated_delivery_date,
			delivered_count: delivered,
			scanned_per: successful_scans_per,
			unscanned_per: no_scans_per,
			delivered_per,
		}) => ({
			created_date,
			total_in_database,
			successful_scans,
			no_scans,
			scanned_with_estimated_delivery_date,
			scanned_without_estimated_delivery_date,
			delivered,
			successful_scans_per,
			no_scans_per,
			delivered_per,
		})
	);

	// add % to percentage value

	const defaultColumn = "created_date";

	const getDateRange = (dates) => {
		if (dates != null && dates.length > 0) {
			const formattedStartDate = dayjs(dates[0]).format("MM/DD/YYYY");
			const formattedEndDate = dayjs(dates[1]).format("MM/DD/YYYY");
			setStartDate(formattedStartDate);
			setEndDate(formattedEndDate);
			const startDateUnix = dateToUnix(formattedStartDate);
			const endDateUnix = dateToUnix(formattedEndDate);
			const dateFilterdScanList = mainTrackingList.filter(
				(obj) =>
					startDateUnix <= obj.created_date &&
					endDateUnix >= obj.created_date
			);
			setMainTrackingList(dateFilterdScanList);
			setTotalItemCount(dateFilterdScanList.length);
		} else {
			setStartDate(false);
			setEndDate(false);
		}
	};

	// pagination
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setPage(0);
		setRowsPerPage(parseInt(event.target.value, 10));
	};

	const onChangeAdvancedForm = (e) => {
		const { name, value } = e.target;
		setAdvacnedFromData({ ...advancedFormData, [name]: value });
	};
	const handleColumnSubmit = (columnList) => {
		setUserSelectedColumns(columnList.map((item) => removeTitleCase(item)));
		dispatch(
			updateUserPreferences({
				mailScan: columnList.map((item) => removeTitleCase(item)),
				trackingInfo: trackingInfo,
			})
		);
		updateColumnPreferences(
			{
				table_name: "mail_scan",
				table_columns: columnList.map((item) => removeTitleCase(item)),
			},
			userInfo?.accessToken
		);
		// useAppDispatch(updateColumnPreferences({table_name:'tracking_info',table_columns:columnList.map(item => removeTitleCase(item))}))
	};
	const onExpandhandler = () => {
		console.log("");
	};

	return (
		<>
			<Helmet>
				<title> Mail Scans | BeyondCarts CMS </title>
			</Helmet>

			<Container>
				<SmartTable
					isSearch={false}
					isAdvancedFilter={false}
					isStatusMenu={false}
					isRowSelectable={false}
					isAction={false}
					isGridSelection={true}
					originalRows={
						typeof originalRows1 !== "undefined"
							? originalRows1
							: [{}]
					}
					defaultColumn={defaultColumn}
					getDateRange={getDateRange}
					totelItemCount={totalItemsCount}
					onExpandhandler={onExpandhandler}
					page={page}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					rowsPerPage={rowsPerPage}
					showLoader={showLoader}
					startDate={startDate}
					endDate={endDate}
					onChangeAdvancedForm={onChangeAdvancedForm}
					advancedFormData={advancedFormData}
					dateTypeList={dateTypeList}
					userSelectedColumns={userSelectedColumns}
					handleColumnSubmit={handleColumnSubmit}
					columnsToRemove={columnsToRemove}
				/>
			</Container>
		</>
	);
}

export default MailScans;
