import { BrowserRouter } from 'react-router-dom';
import { ScrollRestoration } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { SocketContext, socket } from './components/socket-handler/sockethandler';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import CircularLoader from './loader/circular/CircularLoader';
import { getUI } from './features/ui/uiState';
import { useAppSelector } from './hooks/hooks';

// ----------------------------------------------------------------------

export default function App() {
  const ui = useAppSelector(getUI);
  const [isConnected, setIsConnected] = useState(false);
  useEffect(() => {
    function onConnect() {
      console.log('onConnect');
      setIsConnected(true);
    }

    function onDisconnect() {
      setIsConnected(false);
    }

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, []);
  return (
    // <div>
    <SocketContext.Provider value={socket}>
      <HelmetProvider>
        {ui?.awaitingApis ? <CircularLoader /> : <></>}
        <ThemeProvider>
          <Router>
            <ScrollRestoration />
          </Router>
        </ThemeProvider>
      </HelmetProvider>
    </SocketContext.Provider>
    // </div>
  );
}
