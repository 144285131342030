import axios from "axios";
import { decryptData } from "src/dataFetcher/chiper";
import { encConfig } from "src/dataFetcher/config";
import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import { backendUserUrl } from "src/utils/backendUrls";

export const extractData = (result, flag) => {
	if (encConfig.encryption.decrypt) {
		if (result?.data) return decryptData(result);
	}
	return result;
};

export async function getUpcomingPayments(userInfo, user_id, tenant_id) {
	const headers = {
		tenant_id,
		user_id: user_id,
	};
	const res = await postData(
		`${backendUserUrl}upcoming-payment`,
		headers,
		{}
	);
	return JSON.parse(extractData(res));
}

export async function getInvoices(params, headers, userInfo, tenant_name) {
	const tenant = userInfo?.tenant_name;
	const token = userInfo.accessToken;

	const formatToTwoDecimals = (number) => {
		// Check if the number has decimal places
		if (number % 1 !== 0) {
			// If it has decimal places, round it to two decimal places
			return parseFloat(number.toFixed(2));
		} else {
			// If it's a whole number, add '.00' to the end
			return parseFloat(number + ".00");
		}
	};

	const res = await getData(
		`${backendUserUrl}user/${tenant_name}/billing/invoices`,
		params,
		headers,
		token
	);

	if (res.data?.records.length) {
		res.data.records = res.data.records.map((invoice) => ({
			...invoice,
			price: formatToTwoDecimals(invoice.price),
			gross_total: formatToTwoDecimals(invoice.gross_total),
		}));
	}

	return res;
}

export async function fetchTenants(token) {
	const get_tenants_url =
		process.env.REACT_APP_BACKEND_BASE_URL +
		process.env.REACT_APP_TENANT_ROUTES;
	return await getData(get_tenants_url, {}, {}, token);
}

export async function verifyEmail(data) {
	const headers = null;
	const result = await postData(
		`${backendUserUrl}frontend/user/verify_email`,
		headers,
		data
	);
	return JSON.parse(extractData(result));
}

export async function forceUpdatePassword(data, userInfo) {
	const headers = null;
	const token = userInfo.accessToken;
	const result = await putData(
		`${backendUserUrl}user/admin/user/password`,
		headers,
		data,
		token
	);
	return JSON.parse(extractData(result));
}

export async function updatePassword(headers, data, userInfo) {
	const tenant = userInfo?.tenant_name;
	const token = userInfo?.accessToken;
	const result = await putData(
		`${backendUserUrl}user/admin/profile/password`,
		headers,
		data,
		token
	);
	return JSON.parse(extractData(result.data));
}

export async function resetPassword(data) {
	const headers = { tenant: "admin", action: "resetPassword" };
	const result = await postData(`${backendUserUrl}account`, headers, data);
	return JSON.parse(extractData(result));
}

export async function forgotPassword(data) {
	const headers = null;
	const result = await postData(
		`${backendUserUrl}user/admin/user/forgot/password`,
		headers,
		data
	);
	return JSON.parse(extractData(result));
}

export async function loginCall(data) {
	const headers = null;
	const result = await postData(
		`${backendUserUrl}user/admin/user/login`,
		headers,
		data
	);
	return JSON.parse(extractData(result));
}

export async function resendCode(data) {
	const headers = null;
	const result = await postData(
		`${backendUserUrl}user/frontend/user/signup/resend_code`,
		headers,
		data
	);
	return JSON.parse(extractData(result));
}

export async function decryptResponse(data) {
	// random number of 9 digits, not starting by zero
	const randomInt = Math.floor(1e7 + Math.random() * 9e7);
	const result = await axios.post(
		`${backendUserUrl}decrypt`,
		{ data },
		{
			headers: {
				"Content-Type": "application/json",
				request_id: randomInt,
			},
		}
	);
	return result.data.data;
}

export async function encryptResponse(data) {
	// random number of 9 digits, not starting by zero
	const randomInt = Math.floor(1e7 + Math.random() * 9e7);
	const result = await axios.post(
		`${backendUserUrl}encrypt`,
		{ data },
		{
			headers: {
				"Content-Type": "application/json",
				request_id: randomInt,
			},
		}
	);
	return result.data.data;
}
