import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Box } from "@mui/material";
import { setSigninData } from "src/features/signin/signin.action";
import palette from "src/theme/palette";
import "./carrier.scss";

const selectedStyle = {
	backgroundColor: "#dee2e6",
	border: "1px solid #256fa5",
	transition: "all 0.5s ease",
};

const checkedLogoStyle = {
	position: "absolute",
	top: "-15px",
	right: "-15px",
	zIndex: "2",
};

const Carrier = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const signinData = useSelector((state) => state.signin.signinData);
	const { carrierError, carrier, isFromConfig } = signinData || {};

	const [isUSPSSelected, setIsUSPSSelected] = useState(false);
	const [isUPSSelected, setIsUPSSelected] = useState(false);
	const [isFedexSelected, setIsFedexSelected] = useState(false);
	const [isDHLSelected, setIsDHLSelected] = useState(false);

	const handleSelectCarrier = (carrierSelected) => {
		switch (carrierSelected) {
			case "usps":
				if (isUSPSSelected) {
					setIsUSPSSelected(false);
					dispatch(
						setSigninData({
							...signinData,
							carrier: { ...carrier, usps: null },
							carrierError: false,
						})
					);
				} else {
					setIsUSPSSelected(true);
					dispatch(
						setSigninData({
							...signinData,
							carrier: { ...carrier, usps: true },
							carrierError: false,
						})
					);
				}
				break;

			case "ups":
				if (isUPSSelected) {
					setIsUPSSelected(false);
					dispatch(
						setSigninData({
							...signinData,
							carrier: { ...carrier, ups: null },
							carrierError: false,
						})
					);
				} else {
					setIsUPSSelected(true);
					dispatch(
						setSigninData({
							...signinData,
							carrier: { ...carrier, ups: true },
							carrierError: false,
						})
					);
				}
				break;

			case "fedex":
				if (isFedexSelected) {
					setIsFedexSelected(false);
					dispatch(
						setSigninData({
							...signinData,
							carrier: { ...carrier, fedex: null },
							carrierError: false,
						})
					);
				} else {
					setIsFedexSelected(true);
					dispatch(
						setSigninData({
							...signinData,
							carrier: { ...carrier, fedex: true },
							carrierError: false,
						})
					);
				}
				break;

			case "dhl":
				if (isDHLSelected) {
					setIsDHLSelected(false);
					dispatch(
						setSigninData({
							...signinData,
							carrier: { ...carrier, dhl: null },
							carrierError: false,
						})
					);
				} else {
					setIsDHLSelected(true);
					dispatch(
						setSigninData({
							...signinData,
							carrier: { ...carrier, dhl: true },
							carrierError: false,
						})
					);
				}
				break;

			default:
				break;
		}
	};

	useEffect(() => {
		if (!signinData?.user_id) {
			navigate("/login");
			return;
		}

		if (carrier?.ups) setIsUPSSelected(true);
		if (carrier?.usps) setIsUSPSSelected(true);
		if (carrier?.fedex) setIsFedexSelected(true);
		if (carrier?.dhl) setIsDHLSelected(true);

		if (isFromConfig) return;

		dispatch(
			setSigninData({
				...signinData,
				continueBtn: true,
			})
		);
	}, []);

	useEffect(() => {
		if (!carrier) return;
		else if (
			Object.keys(carrier).filter((key) => !!carrier[key]).length === 0
		) {
			dispatch(
				setSigninData({
					...signinData,
					carrierError: true,
					continueBtn: false,
				})
			);
		} else
			dispatch(
				setSigninData({
					...signinData,
					continueBtn: true,
				})
			);
	}, [carrier]);

	return (
		<div className="carrier-container">
			<Typography variant="h3" sx={{ textAlign: "center" }}>
				Which carriers do you ship with?
			</Typography>
			<Typography
				sx={{
					color: palette.common.black,
					fontSize: 18,
					textAlign: "center",
				}}
			>
				We understand how important it is to stay informed about your
				shipments. Please select your preferred carrier from the list
				below for real-time tracking and timely notifications in case of
				any delays.
			</Typography>

			<Box className="carriers-img-container">
				<Box style={{ position: "relative" }}>
					<img
						className="carrier-img"
						src="/assets/usps.png"
						style={isUSPSSelected ? selectedStyle : null}
						onClick={() => handleSelectCarrier("usps")}
					/>
					{isUSPSSelected && (
						<img
							src="/assets/checkLogo.svg"
							style={checkedLogoStyle}
						/>
					)}
				</Box>

				<Box style={{ position: "relative" }}>
					<img
						className="carrier-img"
						src="/assets/ups.png"
						style={isUPSSelected ? selectedStyle : null}
						onClick={() => handleSelectCarrier("ups")}
					/>
					{isUPSSelected && (
						<img
							src="/assets/checkLogo.svg"
							style={checkedLogoStyle}
						/>
					)}
				</Box>
				{/* TODO: comment this Box when moving code to production, to hide fedex */}
				<Box>
					<img
						className="fedex-img"
						src="/assets/fedex.png"
						style={isFedexSelected ? selectedStyle : null}
						onClick={() => handleSelectCarrier("fedex")}
					/>
					{isFedexSelected && (
						<img
							src="/assets/checkLogo.svg"
							style={checkedLogoStyle}
						/>
					)}
				</Box>

				{/* TODO: comment this Box when moving code to production, to hide dhl */}
				<Box>
					<img
						className="dhl-img"
						src="/assets/dhl.png"
						style={isDHLSelected ? selectedStyle : null}
						onClick={() => handleSelectCarrier("dhl")}
					/>
					{isDHLSelected && (
						<img
							src="/assets/checkLogo.svg"
							style={checkedLogoStyle}
						/>
					)}
				</Box>
			</Box>

			<button
				onClick={() =>
					window.open(
						"https://calendly.com/kaushikmouli-c/konnect-working-hours",
						"_blank"
					)
				}
			>
				Need Additional help? →
			</button>

			{carrierError && (
				<Typography
					sx={{
						color: palette.error.main,
						textAlign: "center",
						fontSize: "18px",
					}}
				>
					You need to select 1 carrier.
				</Typography>
			)}
		</div>
	);
};

export default Carrier;
