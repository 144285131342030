import getData from "src/dataFetcher/getDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import { backendUserUrl as backendUrl } from "src/utils/backendUrls";

export async function updateAppConfig(data, userInfo) {
	const headers = null;
	const token = userInfo?.accessToken;
	return await putData(
		`${backendUrl}user/configuration/global_config`,
		headers,
		data,
		token,
	);
}

export async function getAppConfig(token) {
	const result = await getData(
		`${backendUrl}user/configuration/global_config`,{},{},token
	);
    return result;
}

export async function updateStripeConfig(data, userInfo) {
	const headers = null;
	const token = userInfo?.accessToken;
	return await putData(
		`${backendUrl}user/configuration/payment_config`,
		headers,
		data,
		token
	);
}