/**
 * Asynchronous timer function that resolves after a specified time.
 * @param {number} ms - The time to wait in milliseconds before resolving.
 * @returns {Promise<void>} A promise that resolves after the specified time.
 */
const timer = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export default timer;
