import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import palette from "src/theme/palette";

import "./welcome.scss";

const pStyle = {
	width: "800px",
	color: palette.common.black,
	fontSize: 18,
	textAlign: "center",
	margin: "auto",
	marginTop: "10px",
};

const WelcomeTenantView = () => {
	const navigate = useNavigate();

	return (
		<div className="welcome-view-container">
			<img
				src="/assets/beyond-carts-logo.svg"
				alt="logo"
				style={{
					height: "65px",
					display: "block",
					margin: "30px 0 0 30px",
				}}
			/>

			<Typography
				variant="h3"
				sx={{ marginTop: "100px", textAlign: "center" }}
			>
				Tenant Onboarded
			</Typography>

			<Typography sx={pStyle}>
				The tenant has successfully onboarded.
			</Typography>

			<Box textAlign="center">
				<LoadingButton
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					onClick={() => navigate("/login")}
					sx={{
						fontSize: "18px !important",
						margin: "30px auto",
						width: { lg: 513, sm: 444, md: 444 },
						boxShadow: "none",
						backgroundColor: palette.secondary.main,
					}}
				>
					Get Started
				</LoadingButton>
			</Box>
		</div>
	);
};

export default WelcomeTenantView;
