import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	TenantFullInfo: {},
	tenantName: "",
};

export const chosenTenantSlice = createSlice({
	name: "tenant/chosenTenant",
	initialState,
	reducers: {
		updateTenantUser: (state, action) => {
			const { TenantFullInfo, tenantName } = action.payload;
			(state.TenantFullInfo = TenantFullInfo),
				(state.tenantName = tenantName);
		},
	},
});
export const { updateTenantUser } = chosenTenantSlice.actions;
export default chosenTenantSlice.reducer;
