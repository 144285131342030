/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import {
	Table,
	TableBody,
	TableCell,
	Card,
	Box,
	Grid,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	TableSortLabel,
	Toolbar,
	Divider,
	Paper,
	Checkbox,
	Button,
	Stack,
	IconButton,
	Collapse,
	Typography,
	CircularProgress,
} from "@mui/material";

// eslint-disable-next-line import/no-extraneous-dependencies
import { CSVLink } from "react-csv";

import { titleCase, removeTitleCase, removeItemOnce } from "src/utils/core";

import Search from "../search/Search";
import Iconify from "../iconify/Iconify";
import DateRangePicker from "../date-picker/DateRangePicker";
import MultipleSelect from "../select/MultipleSelect";
import InputForm from "../input-field/InputField";
import DatePickerComp from "../date-picker/DatePicker";
import SelectInput from "../select/SelectInput";
import { useLocation } from "react-router-dom";
import typography from "src/theme/typography";
import UsersTableRowDetails from "./UsersTableRowDetails";
import TrackingTableRowDetails from "./TrackingTableRowDetails";
import AdvancedFilter from "./AdvancedFilter";
import AddUserModal from "../modal/AddUserModal";
import palette from "src/theme/palette";
import TenantMenuList from "../menu/TenantMenuList";
import { fetchTenants } from "src/features/user/userApi";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";

const tableBgColor = palette.primary.lighter;

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc"
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator, isSortable) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	if (isSortable) {
		stabilizedThis.sort((a, b) => {
			const order = comparator(a[0], b[0]);
			if (order !== 0) {
				return order;
			}
			return a[1] - b[1];
		});
	}
	return stabilizedThis.map((el) => el[0]);
}

// Table head
function SmartTabledHead(props) {
	const {
		isUsersPage,
		isCMSUsers,
		isTenantUsers,
		isBillingPage,
		isAdminUser,
		onSelectAllClick,
		order,
		orderBy,
		isRowSelectable,
		isAction,
		visibleRows,
		numSelected,
		selected,
		rowCount,
		onRequestSort,
		columnHeader,
		defaultColumn,
		onRefreshClicked,
		sortOrder,
	} = props;
	const { pathname } = useLocation();

	//function for refresh all button
	const handleRefreshAll = (e) => {
		if (selected.length > 0) {
			const filteredSelected = selected.filter((trackingNumber) => {
				const selectedItem = visibleRows.find(
					(item) => item.tracking_number === trackingNumber
				);
				return (
					selectedItem &&
					!(
						selectedItem.scanned_status === "Delivered" ||
						selectedItem.scanned_status === "Un Scanned Exit met"
					)
				);
			});
			onRefreshClicked(e, filteredSelected, true);
		} else {
			const filteredRows = visibleRows.filter(
				(item) =>
					!(
						item.scanned_status === "Delivered" ||
						item.scanned_status === "Un Scanned Exit met"
					)
			);
			const filteredTrackingNumbers = filteredRows.map(
				(item) => item.tracking_number
			);
			onRefreshClicked(e, filteredTrackingNumbers, true);
		}
	};

	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
		// if (
		// 	pathname.startsWith("/dashboard/tracking") ||
		// 	pathname.startsWith("/dashboard/tracking/") ||
		// 	pathname.startsWith("/dashboard/reports")
		// ) {
		// 	sortOrder(property, order);
		// }
	};
	const StyledTableHead = styled(TableHead)(({ theme }) => ({
		"& .MuiTableRow-head > .MuiTableCell-head": {
			color: theme.palette.common.black,
		},
		"& .MuiTableRow-head > .MuiTableCell-head> .MuiTableSortLabel-root:hover":
			{
				color: theme.palette.common.black,
			},
	}));

	// styles for the head table cells
	const StyledTableCell = styled(TableCell)((rest) => {
		const defaultStyle = {
			fontFamily: typography.fontFamilySemiBold,
			fontSize: { xs: 14, sm: 14, lg: 16 },
			textAlign: "center",
			textWrap: "nowrap",
		};
		return {
			...(rest.minWidth === "false" ? {} : { minWidth: 150 }),
			...defaultStyle,
		};
	});
	return (
		<StyledTableHead>
			<TableRow>
				{isRowSelectable ? (
					<>
						{!isUsersPage &&
							!isCMSUsers &&
							!isTenantUsers &&
							!isBillingPage && (
								<StyledTableCell
									padding="checkbox"
									minWidth="false"
								>
									<Checkbox
										color="primary"
										indeterminate={
											numSelected > 0 &&
											numSelected < rowCount
										}
										checked={
											rowCount > 0 &&
											numSelected === rowCount
										}
										onChange={onSelectAllClick}
										inputProps={{
											"aria-label": "select all desserts",
										}}
									/>
								</StyledTableCell>
							)}
						<StyledTableCell
							key={`${defaultColumn}1`}
							// align="left"
							// padding="normal"
							sortDirection={
								orderBy == { defaultColumn } ? order : false
							}
						>
							<TableSortLabel
								active={false}
								direction={
									orderBy == { defaultColumn } ? order : "asc"
								}
								onClick={createSortHandler(defaultColumn)}
							>
								{titleCase(defaultColumn)}
							</TableSortLabel>
						</StyledTableCell>
					</>
				) : (
					<StyledTableCell
						key={`${defaultColumn}1`}
						// align="left"
						padding="none"
						sx={{ paddingLeft: 5 }}
						// sx={{ paddingLeft: 5, width: 200 }}
						sortDirection={
							orderBy == { defaultColumn } ? order : false
						}
					>
						<TableSortLabel
							active={false}
							direction={
								orderBy == { defaultColumn } ? order : "asc"
							}
							onClick={createSortHandler(defaultColumn)}
							// sx={{ width: 160 }}
						>
							{titleCase(defaultColumn)}
						</TableSortLabel>
					</StyledTableCell>
				)}
				{columnHeader.map((headCell) => (
					<StyledTableCell
						key={headCell.id}
						// align="left"
						// padding="normal"
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={false}
							// sx={{ width: '200px' }}
							direction={orderBy === headCell.id ? order : "asc"}
							onClick={createSortHandler(headCell.id)}
						>
							{headCell.label === "Price" ||
							headCell.label === "Tax" ||
							headCell.label === "Gross Total"
								? headCell.label + " ($)"
								: headCell.label === "No Orders"
								? "Order Count"
								: headCell.label.replace("Per", "%")}
						</TableSortLabel>
					</StyledTableCell>
				))}
				{isAction &&
					!isUsersPage &&
					!isCMSUsers &&
					!isTenantUsers &&
					!isBillingPage && (
						<StyledTableCell
							key={defaultColumn}
							// align="right"
							padding="10px"
							sx={{
								fontFamily: typography.fontFamilySemiBold,
								fontSize: { xs: 14, lg: 16 },
								paddingRight: { lg: 3 },
							}}
						>
							<Button
								variant="outlined"
								onClick={(e) => handleRefreshAll(e)}
								sx={{
									width: "129px",
									height: "45px",
									// color: 'primary.dark',
									display: "inline-flex",
									alignItems: "center",
									gap: "10px",
									borderRadius: "12px",
									// border: '1px solid primary.main',
								}}
							>
								Refresh All
								<img
									width="18px"
									src="/assets/icons/table/refresh_green.svg"
									alt=""
								/>
							</Button>
						</StyledTableCell>
					)}
			</TableRow>
		</StyledTableHead>
	);
}

SmartTabledHead.propTypes = {
	isUsersPage: PropTypes.bool,
	isCMSUsers: PropTypes.bool,
	isTenantUsers: PropTypes.bool,
	isBillingPage: PropTypes.bool,
	isAdminUser: PropTypes.bool,
	numSelected: PropTypes.number.isRequired,
	selected: PropTypes.array,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(["asc", "desc"]).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired,
	columnHeader: PropTypes.array.isRequired,
	defaultColumn: PropTypes.string.isRequired,
	isRowSelectable: PropTypes.bool,
	isAction: PropTypes.bool,
	onRefreshClicked: PropTypes.func,
	sortOrder: PropTypes.func,
};

// Table Toolbar
function SmartTabledToolbar(props) {
	const {
		placeholder,
		numSelected,
		setIsRefresh,
		selected,
		onhandeSubmit,
		searchKey,
		exportCSV,
		onDateChange,
		columnsDetails,
		selectedColumns,
		handleColumnSubmit,
		isSearch,
		isAdvancedFilter,
		isTenantMenu,
		isUsersPage,
		isCMSUsers,
		isTenantUsers,
		isBillingPage,
		isAdminUser,
		onSearchClick,
		startDate,
		endDate,
		onClickAdvancedFilter,
		showAdvancedFilter,
		isGridSelection,
		dateTypeList,
		onChangeAdvancedForm,
		advancedFormData,
		exportButtonClicked,
		trackingPage,
		userSelectedColumns,
		type,
		userType,
	} = props;

	const StyledToolbar = styled(Toolbar)(({ theme }) => ({
		paddingLeft: "0 !important",
		paddingRight: "0 !important",
		marginBottom: 20,
	}));

	function generateFileName() {
		const currentTime = new Date();
		const year = currentTime.getFullYear();
		const month = String(currentTime.getMonth() + 1).padStart(2, "0"); // Add 1 to the month since it's zero-based
		const day = String(currentTime.getDate()).padStart(2, "0");
		const hours = String(currentTime.getHours()).padStart(2, "0");
		const minutes = String(currentTime.getMinutes()).padStart(2, "0");
		const seconds = String(currentTime.getSeconds()).padStart(2, "0");

		return `Beyond_carts_${year}-${month}-${day}_${hours}_${minutes}_${seconds}.csv`;
	}
	const filename = generateFileName();

	const [isOpenAddUserModal, setIsOpenAddUserModal] = useState(false);
	const [isOpenRowModal, setIsOpenRowModal] = useState(false);
	const [tenantsList, setTenantsList] = useState(null);
	const userInfo = useAppSelector(getUserInfo);

	useEffect(() => {
		const tenantList = fetchTenants(userInfo.accessToken);
		tenantList.then((res) => {
			setTenantsList(res.data);
		});
	}, []);

	return (
		<StyledToolbar>
			<Grid
				container
				marginTop={{ xs: 2, md: 3 }}
				gap={{ xs: 1, sm: 1, md: 2 }}
			>
				{isTenantMenu && <TenantMenuList tenantsList={tenantsList} />}

				{isSearch && (
					<Grid item xs="auto">
						<Search
							trackingPage={trackingPage}
							placeholder={placeholder}
							size="small"
							onSubmitClicked={onhandeSubmit}
							searchValue={searchKey}
							onSearchClick={onSearchClick}
						/>
					</Grid>
				)}

				{isAdvancedFilter && (
					<Grid item xs="auto">
						{/* <Grid item xs="auto" sx={{ marginRight: { xl: '120px', lg: '10px' } }}> */}
						<Button
							variant="contained"
							className={showAdvancedFilter ? "btn-active" : ""}
							onClick={onClickAdvancedFilter}
							type="button"
							sx={{
								boxShadow: "none",
								color: "secondary.main",
								border: "1px rgba(128, 128, 128, 0.32) solid",
								height: { xs: 36, sm: 36, md: 36, lg: 40 },
								width: { lg: 190, sm: 160, xs: 160, md: 170 },
								fontFamily: typography.fontFamilyRegular,
								letterSpacing: "0.15px",
								lineHeight: "24px",
								fontSize: { sm: 14, lg: 16 },
								backgroundColor: "common.white",
								"&.btn-active, :hover": {
									color: "common.white",
									backgroundColor: "secondary.main",
								},
							}}
							startIcon={
								<Iconify icon="material-symbols:instant-mix" />
							}
						>
							Advanced Filter
						</Button>
					</Grid>
				)}

				{/* {((isUsersPage && type !== "2") || isCMSUsers) && ( */}
				{!isTenantUsers && (isUsersPage || isCMSUsers) && (
					<Grid item xs="auto">
						<Button
							variant="contained"
							sx={{
								boxShadow: "none",
								backgroundColor: "secondary.main",
								width: {
									lg: 140,
									sm: 120,
									xs: 120,
									md: 140,
								},
								height: { xs: 36, sm: 36, md: 36, lg: 40 },
								fontSize: {
									md: 14,
									lg: 16,
									sm: 14,
									xs: 14,
								},
								letterSpacing: "0.1px",
							}}
							onClick={() => {
								// adds a new user
								setIsOpenAddUserModal(true);
							}}
							endIcon={<Iconify icon="ri:add-fill" />}
						>
							Add User
						</Button>
						{isOpenAddUserModal && (
							<AddUserModal
								isCMSUsers={isCMSUsers}
								openModal={isOpenAddUserModal}
								setOpenModal={setIsOpenAddUserModal}
								userType={userType}
								setIsRefresh={setIsRefresh}
							/>
						)}
					</Grid>
				)}

				{trackingPage && (
					<Grid item xs="auto">
						{/* <Grid item sx={{ width: '200px', marginLeft: { md: '-14px', lg: '100px', xl: '20px' } }}> */}
						<MultipleSelect
							columnsDetails={columnsDetails}
							selectedColumns={userSelectedColumns}
							handleColumnSubmit={handleColumnSubmit}
							isUsersPage={isUsersPage}
							isCMSUsers={isCMSUsers}
						/>
					</Grid>
				)}

				{isGridSelection && (
					<SelectInput
						sx={{
							marginTop: 0,
							height: { xs: 36, sm: 36, md: 36, lg: 40 },
							"&  .MuiFormControl-root": {
								width: { lg: 256, md: 200, sm: 150 },
							},
							"&  .MuiFormControl-root > .MuiInputBase-root": {
								height: { lg: 40, md: 33, sm: 33 },
							},
						}}
						name="report_type"
						onChangeAdvancedForm={onChangeAdvancedForm}
						advancedFormData={advancedFormData}
						listItem={dateTypeList}
					/>
				)}

				{!trackingPage && (
					<Grid item xs="auto">
						<MultipleSelect
							columnsDetails={columnsDetails}
							selectedColumns={userSelectedColumns}
							handleColumnSubmit={handleColumnSubmit}
							isUsersPage={isUsersPage}
							isCMSUsers={isCMSUsers}
						/>
					</Grid>
				)}

				{isUsersPage ||
				isCMSUsers ||
				isTenantUsers ||
				isBillingPage ? null : (
					<Grid item xs="auto">
						<DateRangePicker
							onDateChange={onDateChange}
							startDate={startDate}
							endDate={endDate}
						/>
					</Grid>
				)}

				<Grid item xs="auto">
					<Stack
						direction="row"
						spacing={2}
						justifyContent="space-between"
					>
						<CSVLink
							style={{
								color: "#FFF",
								textDecoration: "none",
								display: "flex",
							}}
							data={exportCSV}
							filename={filename}
							onClick={() => {
								if (columnsDetails.length === 0) return false;
								else return true;
							}}
						>
							<Button
								variant="contained"
								disabled={columnsDetails.length === 0}
								sx={{
									boxShadow: "none",
									backgroundColor: "secondary.main",
									width: {
										lg: 130,
										sm: 110,
										xs: 110,
										md: 120,
									},
									height: {
										xs: 36,
										sm: 36,
										md: 36,
										lg: 40,
									},
									fontSize: {
										md: 14,
										lg: 16,
										sm: 14,
										xs: 14,
									},
									letterSpacing: "0.1px",
								}}
								startIcon={
									<Iconify icon="ic:outline-sim-card-download" />
								}
							>
								Export
							</Button>
						</CSVLink>
					</Stack>
				</Grid>
			</Grid>
		</StyledToolbar>
	);
}

SmartTabledToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired,
	selected: PropTypes.array,
	onhandeSubmit: PropTypes.func,
	placeholder: PropTypes.string,
	searchKey: PropTypes.string,
	setIsRefresh: PropTypes.func,
	exportCSV: PropTypes.array,
	onDateChange: PropTypes.func,
	columnsDetails: PropTypes.array,
	selectedColumns: PropTypes.array,
	handleColumnChange: PropTypes.func,
	isSearch: PropTypes.bool,
	isAdvancedFilter: PropTypes.bool,
	isTenantMenu: PropTypes.bool,
	isUsersPage: PropTypes.bool,
	isCMSUsers: PropTypes.bool,
	isTenantUsers: PropTypes.bool,
	isBillingPage: PropTypes.bool,
	isAdminUser: PropTypes.bool,
	onSearchClick: PropTypes.func,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	onClickAdvancedFilter: PropTypes.func,
	showAdvancedFilter: PropTypes.bool,
	isGridSelection: PropTypes.bool,
	dateTypeList: PropTypes.array,
	onChangeAdvancedForm: PropTypes.func,
	advancedFormData: PropTypes.object,
	trackingPage: PropTypes.bool,
	userSelectedColumns: PropTypes.array,
	type: PropTypes.string,
	userType: PropTypes.string,
};

SmartTabledToolbar.propTypes = {};

// status filter
function StatusFilterMenu({
	active,
	onStatusChange,
	originalRows,
	statusList,
	statusColumn,
	statusAllData,
}) {
	const StyledButton = styled(Button)(({ theme }) => ({
		borderRadius: 0,
		color: theme.palette.text.light,
		fontWeight: 500,
	}));
	const rawStatusList =
		statusList.length > 0
			? statusList
			: originalRows.map((el) => el[statusColumn]);

	if (statusAllData != "") {
		rawStatusList.unshift(statusAllData);
	}

	const finalStatusList = [...new Set(rawStatusList)];
	const buttonList = [];

	finalStatusList.forEach((item) =>
		buttonList.push(
			<StyledButton
				key={item}
				name={item}
				className={active === item && "active"}
				onClick={onStatusChange}
				sx={{
					"&.active": {
						borderBottom: "3px solid",
						borderColor: "primary.dark",
						color: "primary.dark",
						lineHeight: "24px",
					},
					fontSize: { lg: 16, md: 16, sm: 14 },
					fontFamily: typography.fontFamilyRegular,
				}}
			>
				{item}
			</StyledButton>
		)
	);

	return (
		<Stack
			direction="row"
			spacing={3}
			sx={{
				borderBottom: 1,
				borderStyle: "line",
				borderColor: "secondary.gray",
				marginBottom: 2,
			}}
		>
			{buttonList}
		</Stack>
	);
}

StatusFilterMenu.propTypes = {
	active: PropTypes.string,
	onStatusChange: PropTypes.func,
	originalRows: PropTypes.array,
	statusList: PropTypes.array,
	statusColumn: PropTypes.string,
	statusAllData: PropTypes.string,
};

// main table
export default function SmartTable({
	columnsToAvoid,
	placeholexpandedRowarrayder,
	originalRows,
	placeholder,
	isSearch,
	defaultColumn,
	statusColumn,
	isStatusMenu,
	isRowSelectable,
	isAction,
	statusList,
	statusAllData,
	getDateRange,
	onRefreshClicked,
	onExpandhandler,
	setIsRefresh,
	expandedRow,
	isAdvancedFilter,
	isTenantMenu,
	isUsersPage,
	isCMSUsers,
	isTenantUsers,
	isBillingPage,
	isAdminUser,
	totelItemCount,
	page,
	handleChangePage,
	handleChangeRowsPerPage,
	rowsPerPage,
	onhandeSeachSubmit,
	onBulkOrderSearch,
	searchKey,
	onSearchClick,
	showLoader,
	startDate,
	endDate,
	onChangeAdvancedForm,
	advancedFilterUsersSearchData,
	advancedFilterInvoicesSearchData,
	advancedFormData,
	carrierSelect,
	statusSelect,
	scannedStatus,
	invoicesStatus,
	firstScanEmailStatistics,
	onSubmitAdvancedFilter,
	exapndRowIntputData,
	setExpandedUserInfo,
	trackingInfoRef,
	showAdvancedFilter,
	onClickAdvancedFilter,
	onStatusChange,
	statusToShow,
	isGridSelection,
	dateTypeList,
	exportButtonClicked,
	trackingPage,
	userSelectedColumns,
	handleColumnSubmit,
	modalLoader,
	setModalLoader,
	columnsToRemove,
	sortOrder,
	type,
	userType,
}) {
	//----------------------------------------------------------------------------

	const [rows, setRows] = useState([]);
	const [columnsToShow, setColumsToShow] = useState([]);
	// const [selectedColumns, setSelectedColumns] = useState(userSelectedColumns);
	const [order, setOrder] = useState("asc");
	const [isSortable, setIsSortable] = useState(false);
	const [orderBy, setOrderBy] = useState(defaultColumn);
	const [selected, setSelected] = useState([]);
	const [reportToExport, setReportToExport] = useState(rows);
	const [rawColumnList, setRawCloumnList] = useState([]);

	const [userUpdated, setUserUpdated] = useState(false);
	const [openChangePassword, setOpenChangePassword] = useState(false);
	const [openPasswordHasChanged, setOpenPasswordHasChanged] = useState(false);

	useEffect(() => {
		setRows(originalRows);
		const newdata = originalRows.map((data) => {
			const newRow = { ...data };
			delete newRow?.updateAt;
			delete newRow?._id;
			return newRow;
		});
		setReportToExport(newdata);
		if (userSelectedColumns.length > 0) {
			setColumsToShow(userSelectedColumns);
		} else if (originalRows.length > 0) {
			const filteredCloumns = Object.keys(originalRows[0]).filter(
				(name) => !columnsToRemove?.includes(name)
			);
			setColumsToShow(filteredCloumns);
		} else {
			setColumsToShow([]);
		}
		setRawCloumnList(
			originalRows.length > 0 ? Object.keys(originalRows[0]) : []
		);
		setIsSortable(false);
	}, [originalRows, userSelectedColumns]);

	// Styles
	const StyledTableBody = styled(TableBody)(({ theme }) => ({
		color: theme.palette.common.black,
		backgroundColor: tableBgColor,
		// fontSize: { xs: 12, sm: 14, lg: 16 },
		fontFamily: typography.fontFamilyRegular,
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		color: theme.palette.common.black,
		textWrap: "nowrap",
		// fontSize: { xs: 12, sm: 14, lg: 16 },
		fontFamily: typography.fontFamilyRegular,
	}));

	const StyledTablePagination = styled(TablePagination)(({ theme }) => ({
		backgroundColor: tableBgColor,
		color: theme.palette.common.black,
		"& .MuiInputBase-root": {
			border: "1px solid #757575",
			borderRadius: 4,
			width: "58px",
		},
		"& .MuiPaper-root": {
			color: theme.palette.common.black,
		},
	}));

	// Create columns from rows
	const columns = [];
	columnsToShow.forEach((element) => {
		if (!columnsToAvoid.includes(element)) {
			if (element !== defaultColumn) {
				const obj = {};
				obj.id = element;
				obj.label = titleCase(element);
				columns.push(obj);
			}
		}
	});

	// columns for toolbar
	const toolbarColumns = [];
	rawColumnList.sort().forEach((element) => {
		if (element !== defaultColumn) {
			const obj = {};
			obj.id = element;
			obj.label = titleCase(element);
			toolbarColumns.push(obj);
		}
	});

	const handleRequestSort = (event, property) => {
		setIsSortable(true);
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	};

	// Select single row
	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n[defaultColumn]);
			const recordsToExport = [];
			newSelected.forEach((item) => {
				const result = originalRows.filter(
					(obj) => obj[defaultColumn] === item
				);
				recordsToExport.push(result[0]);
			});
			setSelected(newSelected);
			setReportToExport(recordsToExport);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}
		const recordsToExport = [];
		newSelected.forEach((item) => {
			const result = originalRows.filter(
				(obj) => obj[defaultColumn] === item
			);
			recordsToExport.push(result[0]);
		});
		setSelected(newSelected);
		setReportToExport(recordsToExport);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Rows to show on table
	const visibleRows = useMemo(
		() =>
			stableSort(rows, getComparator(order, orderBy), isSortable).slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			),
		[order, orderBy, page, rowsPerPage, rows]
	);

	return (
		<>
			<SmartTabledToolbar
				key="TableToolBar"
				setIsRefresh={setIsRefresh}
				numSelected={selected.length}
				placeholder={placeholder}
				selected={selected}
				originalRows={originalRows}
				onhandeSubmit={onhandeSeachSubmit}
				searchKey={searchKey}
				exportCSV={reportToExport}
				onDateChange={getDateRange}
				columnsDetails={removeItemOnce(rawColumnList, defaultColumn)}
				handleColumnSubmit={handleColumnSubmit}
				isSearch={isSearch}
				isAdvancedFilter={isAdvancedFilter}
				isTenantMenu={isTenantMenu}
				isUsersPage={isUsersPage}
				isCMSUsers={isCMSUsers}
				isTenantUsers={isTenantUsers}
				isBillingPage={isBillingPage}
				isAdminUser={isAdminUser}
				onSearchClick={onSearchClick}
				startDate={startDate}
				endDate={endDate}
				onClickAdvancedFilter={onClickAdvancedFilter}
				showAdvancedFilter={showAdvancedFilter}
				isGridSelection={isGridSelection}
				dateTypeList={dateTypeList}
				onChangeAdvancedForm={onChangeAdvancedForm}
				advancedFormData={advancedFormData}
				exportButtonClicked={exportButtonClicked}
				trackingPage={trackingPage}
				userSelectedColumns={userSelectedColumns}
				type={type}
				userType={userType}
			/>
			{showAdvancedFilter && (
				<AdvancedFilter
					advancedFilterUsersSearchData={
						advancedFilterUsersSearchData
					}
					advancedFilterInvoicesSearchData={
						advancedFilterInvoicesSearchData
					}
					onChangeAdvancedForm={onChangeAdvancedForm}
					advancedFormData={advancedFormData}
					carrierSelect={carrierSelect}
					statusSelect={statusSelect}
					scannedStatus={scannedStatus}
					invoicesStatus={invoicesStatus}
					firstScanEmailStatistics={firstScanEmailStatistics}
					onSubmitAdvancedFilter={onSubmitAdvancedFilter}
				/>
			)}

			{statusColumn && isStatusMenu && (
				<StatusFilterMenu
					key="StatusFilterMenu"
					active={statusToShow}
					onStatusChange={onStatusChange}
					originalRows={originalRows}
					statusList={statusList}
					statusColumn={statusColumn}
					statusAllData={statusAllData}
				/>
			)}

			{showLoader ? (
				<Stack
					direction="row"
					alignItems="center"
					justifyContent="center"
				>
					<Typography variant="h5" sx={{ color: "primary.main" }}>
						Loading...
					</Typography>
				</Stack>
			) : rows.length > 0 ? (
				<Card
					sx={{
						width: "100%",
						borderRadius: 2,
					}}
				>
					<Paper sx={{ width: "100%", boxShadow: "none" }}>
						<TableContainer sx={{ width: "auto" }}>
							<Table
								sx={{ minWidth: 750 }}
								aria-labelledby="tableTitle"
								size="medium"
							>
								<SmartTabledHead
									isUsersPage={isUsersPage}
									isCMSUsers={isCMSUsers}
									isTenantUsers={isTenantUsers}
									isBillingPage={isBillingPage}
									isAdminUser={isAdminUser}
									numSelected={selected.length}
									selected={selected}
									order={order}
									visibleRows={visibleRows}
									orderBy={orderBy}
									onSelectAllClick={handleSelectAllClick}
									onRequestSort={handleRequestSort}
									rowCount={rows.length}
									columnHeader={columns}
									defaultColumn={defaultColumn}
									isRowSelectable={isRowSelectable}
									isAction={isAction}
									onRefreshClicked={onRefreshClicked}
									sortOrder={sortOrder}
								/>
								<StyledTableBody className="main">
									{visibleRows.map((row, index) => {
										const isItemSelected = isSelected(
											row[defaultColumn]
										);
										const labelId = `enhanced-table-checkbox-${index}`;

										return (
											<>
												<TableRow
													hover
													role="checkbox"
													aria-checked={
														isItemSelected
													}
													tabIndex={-1}
													key={index}
													selected={isItemSelected}
													sx={{ cursor: "pointer" }}
												>
													{isRowSelectable ? (
														<>
															{!isUsersPage &&
																!isCMSUsers &&
																!isTenantUsers &&
																!isBillingPage && (
																	<StyledTableCell
																		padding="checkbox"
																		onClick={(
																			event
																		) =>
																			handleClick(
																				event,
																				row[
																					defaultColumn
																				]
																			)
																		}
																	>
																		<Checkbox
																			color="primary"
																			checked={
																				isItemSelected
																			}
																			inputProps={{
																				"aria-labelledby":
																					labelId,
																			}}
																		/>
																	</StyledTableCell>
																)}

															<StyledTableCell
																onClick={(
																	event
																) =>
																	onExpandhandler(
																		event,
																		isUsersPage ||
																			isCMSUsers ||
																			isTenantUsers
																			? row[
																					"_id"
																			  ]
																			: row[
																					defaultColumn
																			  ],
																		index
																	)
																}
																component="th"
																id={labelId}
																scope="row"
																padding={
																	!isUsersPage ||
																	!isCMSUsers ||
																	!isTenantUsers
																		? "checkbox"
																		: "unset"
																}
																// align="left"
																sx={{
																	textAlign:
																		"left",
																	color: "secondary.main",
																	fontSize: {
																		xs: 14,
																		lg: 16,
																	},
																}}
															>
																{
																	row[
																		defaultColumn
																	]
																}
															</StyledTableCell>
														</>
													) : (
														<StyledTableCell
															component="th"
															id={labelId}
															scope="row"
															padding={
																!isUsersPage ||
																!isCMSUsers ||
																!isTenantUsers
																	? "checkbox"
																	: "unset"
															}
															// align="left"
															sx={{
																color: "secondary.main",
																paddingLeft: 5,
																fontSize: {
																	xs: 14,
																	lg: 16,
																},
															}}
														>
															{row[defaultColumn]}
														</StyledTableCell>
													)}

													{columnsToShow.map((item) =>
														item !==
															defaultColumn &&
														!columnsToAvoid.includes(
															item
														) ? (
															<StyledTableCell
																onClick={(
																	event
																) =>
																	onExpandhandler(
																		event,
																		isUsersPage ||
																			isCMSUsers ||
																			isTenantUsers
																			? row[
																					"_id"
																			  ]
																			: row[
																					defaultColumn
																			  ],
																		index
																	)
																}
																ref={(e) =>
																	trackingInfoRef
																		? (trackingInfoRef.current[
																				index
																		  ] = e)
																		: null
																}
																key={item}
																sx={
																	item ===
																	statusColumn
																		? {
																				color: `status.${
																					row[
																						item
																					] &&
																					removeTitleCase(
																						row[
																							item
																						]
																					)
																				}`,
																				fontWeight: 600,
																				textAlign:
																					"center",
																				width: 300,
																				fontSize:
																					{
																						xs: 14,
																						lg: 16,
																					},
																		  }
																		: {
																				textAlign:
																					"center",
																				fontSize:
																					{
																						xs: 14,
																						lg: 16,
																					},
																		  }
																}
																// align="left"
															>
																{row[item]}{" "}
															</StyledTableCell>
														) : null
													)}

													{isAction &&
														!isUsersPage &&
														!isCMSUsers &&
														!isTenantUsers &&
														!isBillingPage && (
															<StyledTableCell
																hover
																align="center"
																sx={{
																	width: "169px",
																	height: "62px",
																	// backgroundColor:
																	// 	"#fff",
																}}
															>
																<IconButton
																	size="small"
																	color="secondary"
																	disabled={
																		row[
																			statusColumn
																		] ===
																			"Delivered" ||
																		row[
																			"scanned_status"
																		] ===
																			"Delivered" ||
																		row[
																			"scanned_status"
																		] ===
																			"Un Scanned Exit met"
																	}
																	sx={{
																		opacity:
																			row[
																				statusColumn
																			] ==
																				"Delivered" ||
																			row[
																				"scanned_status"
																			] ==
																				"Delivered" ||
																			row[
																				"scanned_status"
																			] ===
																				"Un Scanned Exit met"
																				? 0.3
																				: 1,
																	}}
																	onClick={(
																		event
																	) =>
																		onRefreshClicked(
																			event,
																			row[
																				defaultColumn
																			],
																			false
																		)
																	}
																	aria-label="refresh"
																>
																	<img
																		width="18px"
																		src="/assets/icons/table/refresh.svg"
																		alt=""
																	/>
																</IconButton>
															</StyledTableCell>
														)}
												</TableRow>

												{isUsersPage ||
												isCMSUsers ||
												isTenantUsers ? (
													isAdminUser && (
														<TableRow
															key={`${expandedRow}-expand-column`}
															// sx={{
															// 	bgcolor:
															// 		"#F6FAFC",
															// }}
														>
															<TableCell
																xs="auto"
																colSpan={24}
																sx={{
																	padding: 0,
																	border: "0px",
																}}
															>
																<Collapse
																	in={
																		expandedRow ===
																		row[
																			isUsersPage ||
																			isCMSUsers ||
																			isTenantUsers
																				? "_id"
																				: defaultColumn
																		]
																	}
																	timeout="auto"
																	unmountOnExit
																>
																	{isUsersPage ||
																	isCMSUsers ||
																	isTenantUsers ? (
																		<UsersTableRowDetails
																			showLoader={
																				modalLoader
																			}
																			setShowLoader={
																				setModalLoader
																			}
																			inputData={
																				exapndRowIntputData
																			}
																			setExpandedUserInfo={
																				setExpandedUserInfo
																			}
																			onExpandhandler={
																				onExpandhandler
																			}
																			type={
																				type
																			}
																			setIsRefresh={
																				setIsRefresh
																			}
																			successfulUpdate={
																				userUpdated
																			}
																			setSuccessfulUpdate={
																				setUserUpdated
																			}
																			openChangePassword={
																				openChangePassword
																			}
																			setOpenChangePassword={
																				setOpenChangePassword
																			}
																			openPasswordHasChanged={
																				openPasswordHasChanged
																			}
																			setOpenPasswordHasChanged={
																				setOpenPasswordHasChanged
																			}
																		/>
																	) : (
																		<TrackingTableRowDetails
																			showLoader={
																				modalLoader
																			}
																			inputData={
																				exapndRowIntputData
																			}
																			onExpandhandler={
																				onExpandhandler
																			}
																			type={
																				type
																			}
																		/>
																	)}
																</Collapse>
															</TableCell>
														</TableRow>
													)
												) : (
													<TableRow
														key={`${expandedRow}-expand-column`}
														// sx={{
														// 	bgcolor: "#F6FAFC",
														// }}
													>
														<TableCell
															xs="auto"
															colSpan={24}
															sx={{
																padding: 0,
																border: "0px",
															}}
														>
															<Collapse
																in={
																	expandedRow ===
																	row[
																		isUsersPage ||
																		isCMSUsers ||
																		isTenantUsers
																			? "_id"
																			: defaultColumn
																	]
																}
																timeout="auto"
																unmountOnExit
															>
																{isUsersPage ||
																isCMSUsers ||
																isTenantUsers ? (
																	<UsersTableRowDetails
																		showLoader={
																			modalLoader
																		}
																		setShowLoader={
																			setModalLoader
																		}
																		inputData={
																			exapndRowIntputData
																		}
																		setExpandedUserInfo={
																			setExpandedUserInfo
																		}
																		onExpandhandler={
																			onExpandhandler
																		}
																		type={
																			type
																		}
																		setIsRefresh={
																			setIsRefresh
																		}
																		successfulUpdate={
																			userUpdated
																		}
																		setSuccessfulUpdate={
																			setUserUpdated
																		}
																		openChangePassword={
																			openChangePassword
																		}
																		setOpenChangePassword={
																			setOpenChangePassword
																		}
																		openPasswordHasChanged={
																			openPasswordHasChanged
																		}
																		setOpenPasswordHasChanged={
																			setOpenPasswordHasChanged
																		}
																	/>
																) : (
																	<TrackingTableRowDetails
																		showLoader={
																			modalLoader
																		}
																		inputData={
																			exapndRowIntputData
																		}
																		onExpandhandler={
																			onExpandhandler
																		}
																	/>
																)}
															</Collapse>
														</TableCell>
													</TableRow>
												)}
											</>
										);
									})}
								</StyledTableBody>
							</Table>
						</TableContainer>
						<StyledTablePagination
							rowsPerPageOptions={[10, 50, 100]}
							component="div"
							count={totelItemCount}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Paper>
				</Card>
			) : (
				<Card
					sx={{
						width: "100%",
						boxShadow: "none",
						minHeight: 500,
						display: "flex",
						borderRadius: 1,
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Stack
						direction="row"
						alignItems="center"
						justifyContent="center"
					>
						<Typography variant="h5" sx={{ color: "primary.main" }}>
							No data found
						</Typography>
					</Stack>
				</Card>
			)}
		</>
	);
}

SmartTable.propTypes = {
	columnsToAvoid: PropTypes.object,
	placeholder: PropTypes.string,
	originalRows: PropTypes.array,
	isSearch: PropTypes.bool,
	defaultColumn: PropTypes.string,
	statusColumn: PropTypes.string,
	statusList: PropTypes.array,
	statusAllData: PropTypes.string,
	getDateRange: PropTypes.func,
	onRefreshClicked: PropTypes.func,
	onExpandhandler: PropTypes.func,
	setIsRefresh: PropTypes.func,
	expandedRow: PropTypes.string,
	isAdvancedFilter: PropTypes.bool,
	isTenantMenu: PropTypes.bool,
	isUsersPage: PropTypes.bool,
	isCMSUsers: PropTypes.bool,
	isBillingPage: PropTypes.bool,
	isAdminUser: PropTypes.bool,
	totelItemCount: PropTypes.number,
	page: PropTypes.number,
	handleChangePage: PropTypes.func,
	handleChangeRowsPerPage: PropTypes.func,
	rowsPerPage: PropTypes.number,
	onhandeSeachSubmit: PropTypes.func,
	searchKey: PropTypes.string,
	onSearchClick: PropTypes.func,
	showLoader: PropTypes.bool,
	startDate: PropTypes.string,
	endDate: PropTypes.string,
	onChangeAdvancedForm: PropTypes.func,
	advancedFormData: PropTypes.object,
	carrierSelect: PropTypes.array,
	statusSelect: PropTypes.array,
	scannedStatus: PropTypes.array,
	invoicesStatus: PropTypes.array,
	firstScanEmailStatistics: PropTypes.array,
	onSubmitAdvancedFilter: PropTypes.func,
	exapndRowIntputData: PropTypes.object,
	trackingInfoRef: PropTypes.object,
	isStatusMenu: PropTypes.bool,
	isRowSelectable: PropTypes.bool,
	isAction: PropTypes.bool,
	showAdvancedFilter: PropTypes.bool,
	advancedFilterUsersSearchData: PropTypes.object,
	advancedFilterInvoicesSearchData: PropTypes.object,
	onClickAdvancedFilter: PropTypes.func,
	onStatusChange: PropTypes.func,
	statusToShow: PropTypes.string,
	isGridSelection: PropTypes.bool,
	dateTypeList: PropTypes.array,
	exportButtonClicked: PropTypes.func,
	trackingPage: PropTypes.bool,
	userSelectedColumns: PropTypes.array,
	handleColumnSubmit: PropTypes.func,
	modalLoader: PropTypes.bool,
	setModalLoader: PropTypes.func,
	columnsToRemove: PropTypes.array,
	sortOrder: PropTypes.func,
	type: PropTypes.string,
	userType: PropTypes.string,
};

SmartTable.defaultProps = {
	placeholder: "Enter your tracking number",
	columnsToAvoid: [],
	userType: "",
};
