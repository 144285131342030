import PropTypes from 'prop-types';
import { Box, List } from '@mui/material';
import NavItem from './NavItem';

NavSection.propTypes = {
  data: PropTypes.array,
};

export default function NavSection({ onCbNavMenuClick, data = [], ...other }) {
  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {data.map((item) => (
          <NavItem key={item.title} item={item} onClick={onCbNavMenuClick} />
        ))}
      </List>
    </Box>
  );
}
