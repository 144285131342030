import { DatePicker } from "antd";
import { Grid, FormLabel, Box, Stack } from "@mui/material";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import moment from "moment";
import Iconify from "../iconify/Iconify";
import typography from "src/theme/typography";
import { CalendarIcon } from "./CalendarIcon";
import styled from "@emotion/styled";
import palette from "src/theme/palette";

// const { DataPicker } = antDatePicker;

function DatePickerComp({
	name,
	label,
	onChangeAdvancedForm,
	advancedFormData,
}) {
	const StyledDatePicker = styled(DatePicker)(({ theme }) => {
		return {
			width: "100%",
			borderColor: "#E0E0E0",
			color: "primary.main",
			paddingRight: "0",
			paddingTop: "0",
			paddingBottom: "0",
			cursor: "pointer",
			fontFamily: typography.fontFamilyRegular,

			".ant-picker-clear": {
				paddingRight: "55px !important",
			},
			"& .ant-picker-suffix:not(:last-child)": {
				opacity: "1!important",
			},
			"& .ant-picker-suffix:not(:last-child)": {
				opacity: "1!important",
			},
			".ant-picker-suffix": {
				height: "100%",
			},
			[theme.breakpoints.down("lg")]: {
				height: "41px",
				".ant-picker-input": {
					height: "41px",
				},
			},
			[theme.breakpoints.down("md")]: {
				height: "35px",
				".ant-picker-input": {
					height: "35px",
				},
			},
			[theme.breakpoints.up("lg")]: {
				height: "47px",
				".ant-picker-input": {
					height: "47px",
				},
			},
		};
	});

	return (
		<Grid item lg={3} sm={4} width={{ xs: "48%", sm: "50%", md: "60%" }}>
			<FormLabel
				htmlFor="input1"
				labelPlacement="top"
				variant="formLabel"
				sx={{
					color: "secondary.light",
					marginBottom: 2,
					fontSize: 14,
					fontWeight: 600,
				}}
			>
				{label}
			</FormLabel>
			{/* <Stack direction="row" sx={{ marginTop: { lg: 1, sm: 0.3 }, height: { lg: 48, md: 41, sm: 35 } }}> */}
			<Stack
				direction="row"
				sx={{ marginTop: { lg: 1, sm: 0.5, xs: 0.5 } }}
			>
				<StyledDatePicker
					format="MM-DD-YYYY"
					name={name}
					value={
						advancedFormData[name] &&
						advancedFormData[name] !== "Invalid Date"
							? dayjs(advancedFormData[name])
							: false
					}
					controlHeight={16}
					disabledDate={(current) =>
						current &&
						(current.isBefore("2023-06-19") ||
							(name !== "estimated_date" &&
								current.isAfter(dayjs())))
					}
					onChange={(date) =>
						onChangeAdvancedForm(
							name,
							dayjs(date).format("MM/DD/YYYY")
						)
					}
					suffixIcon={<CalendarIcon />}
				/>
			</Stack>
		</Grid>
	);
}

DatePickerComp.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	advancedFormData: PropTypes.object,
	onChangeAdvancedForm: PropTypes.func,
};

export default DatePickerComp;
