import { useEffect, useState, Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch, useSelector } from "react-redux";
import {
	Link,
	IconButton,
	InputAdornment,
	TextField,
	Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { setSigninData } from "src/features/signin/signin.action";
import { MuiTelInput } from "mui-tel-input";

import Iconify from "src/components/iconify/Iconify";
import palette from "src/theme/palette";
import OtpForms from "./formsContainer/otpForm/otpForm";
import {
	createOnboardingUser,
	getExistantTenant,
} from "src/features/onborading/onboardingApi";

import CircularLoader from "src/loader/circular/CircularLoader";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";

const inputStyle = {
	input: { color: palette.common.black },
	width: "100%",
	marginTop: "10px",

	"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "secondary.light",
	},
	"& .MuiInputLabel-formControl": {
		color: "#00000099",
	},
	"& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
		color: "#00000099",
	},
	"& .MuiFormHelperText-root": {
		textAlign: "left",
		fontSize: "14px",
	},
};

const phoneStyle = {
	input: { color: palette.common.black },
	width: "100%",
	marginTop: "10px",

	"& .MuiMenu-paper": {
		color: palette.common.black,
		backgroundColor: palette.common.black,
	},
};

const SigninForm = ({ setSignUp, isOtpView, setIsOtpView }) => {
	const dispatch = useDispatch();

	const signinData = useSelector((state) => state.signin.signinData);
	const {
		emailError,
		emailInvalidError,
		emailExistantError,
		passwordError,
		weakPasswordError,
		rePasswordError,
		nameError,
		lastNameError,
		phoneError,
		invalidPhoneError,
		companyError,
		existantCompanyError,
		shippingVolError,
	} = signinData || {};

	const [showPassword, setShowPassword] = useState(false);
	const [pwsError, setPwsError] = useState("");
	const [phoneValue, setPhoneValue] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const userInfo = useAppSelector(getUserInfo);

	const handlePhoneChange = (newValue) => {
		let newPhone = newValue.split(" ");
		let newPhoneStr = "";

		for (let index = 0; index < newPhone.length; index++) {
			if (index === 0) newPhoneStr += newPhone[index] + " ";
			else newPhoneStr += newPhone[index];
		}

		newPhone = newPhoneStr.split(" ");

		if (newPhone[1]?.length > 10) return;

		setPhoneValue(newValue);
		dispatch(setSigninData({ ...signinData, phone: newPhoneStr }));
	};

	const validateEmail = (email) => {
		return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
	};

	const validatePhone = (phone) => {
		return /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g.test(
			phone
		);
	};

	const checkNamePhoneData = () => {
		if (!signinData.email) {
			dispatch(setSigninData({ ...signinData, emailError: true }));
			return true;
		}

		if (!validateEmail(signinData.email)) {
			dispatch(setSigninData({ ...signinData, emailInvalidError: true }));
			return true;
		}

		if (!signinData.password) {
			dispatch(setSigninData({ ...signinData, passwordError: true }));
			return true;
		}

		if (!signinData.repassword) {
			dispatch(setSigninData({ ...signinData, rePasswordError: true }));
			return true;
		}

		if (signinData.password !== signinData.repassword) {
			dispatch(setSigninData({ ...signinData, rePasswordError: true }));
			return true;
		}

		if (!signinData.name) {
			dispatch(setSigninData({ ...signinData, nameError: true }));
			return true;
		}

		if (!signinData.lastName) {
			dispatch(setSigninData({ ...signinData, lastNameError: true }));
			return true;
		}

		if (!signinData.phone) {
			dispatch(setSigninData({ ...signinData, phoneError: true }));
			return true;
		}

		if (!validatePhone(signinData.phone)) {
			dispatch(setSigninData({ ...signinData, invalidPhoneError: true }));
			return true;
		}

		if (!signinData.company) {
			dispatch(setSigninData({ ...signinData, companyError: true }));
			return true;
		}

		if (!signinData.shippingvolume) {
			dispatch(setSigninData({ ...signinData, shippingVolError: true }));
			return true;
		}
	};

	const handleSigup = (e) => {
		e.preventDefault();
		if (checkNamePhoneData()) return;

		getExistantTenant(signinData.company, userInfo.accessToken)
			.then((r) => {
				const { statusCode } = r;

				if (statusCode === 200) {
					const data = {
						email: signinData.email,
						password: signinData.password,
						first_name: signinData.name,
						last_name: signinData.lastName,
						phone_number: signinData.phone,
						company_name: signinData.company,
						shipping_volume: signinData.shippingvolume,
					};

					createOnboardingUser(data)
						.then((r) => {
							const { statusCode } = r;
							if (statusCode === 200) setIsOtpView(true);
							setIsLoading(false);
						})
						.catch(({ message }) => {
							setIsLoading(false);
							if (message.includes("User name already exists"))
								dispatch(
									setSigninData({
										...signinData,
										emailExistantError: true,
									})
								);

							if (
								message.includes(
									"Password did not conform with policy"
								)
							) {
								dispatch(
									setSigninData({
										...signinData,
										weakPasswordError: true,
									})
								);
								let newError = message.substring(
									message.indexOf("policy") + 6
								);
								newError = newError.replace(": ", "");
								setPwsError(newError);
							}
						});

					setIsLoading(true);
				}
			})
			.catch((e) => {
				const {
					response: {
						data: { statusCode, statusMessage },
					},
				} = e;

				if (
					statusCode === 400 ||
					statusMessage.includes("already exists")
				) {
					dispatch(
						setSigninData({
							...signinData,
							existantCompanyError: true,
						})
					);
				}
			});
	};

	useEffect(() => {
		dispatch(setSigninData({}));
	}, []);

	return (
		<Fragment>
			<Helmet>
				<title> Onboarding | BeyondCarts CMS </title>
			</Helmet>

			{isLoading && <CircularLoader />}
			{isOtpView ? (
				<OtpForms setIsLoading={setIsLoading} />
			) : (
				<Box
					component="form"
					sx={{
						width: "100%",
					}}
				>
					<TextField
						sx={inputStyle}
						label="Email *"
						error={
							emailError ||
							emailInvalidError ||
							emailExistantError
						}
						helperText={
							(emailError && "Email address is required.") ||
							(emailInvalidError && "Invalid email address.") ||
							(emailExistantError && "User already exists.")
						}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									emailError: false,
									emailInvalidError: false,
									emailExistantError: false,
								})
							)
						}
						onChange={(e) =>
							dispatch(
								setSigninData({
									...signinData,
									email: e.target.value,
								})
							)
						}
					></TextField>

					<TextField
						sx={inputStyle}
						label="Password"
						required
						type={showPassword ? "text" : "password"}
						error={passwordError || weakPasswordError}
						helperText={
							(passwordError && "Password is required.") ||
							(weakPasswordError && pwsError)
						}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									passwordError: false,
									weakPasswordError: false,
								})
							)
						}
						onChange={(e) =>
							dispatch(
								setSigninData({
									...signinData,
									password: e.target.value,
								})
							)
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() =>
											setShowPassword(!showPassword)
										}
										edge="end"
									>
										<Iconify
											icon={
												showPassword
													? "eva:eye-fill"
													: "eva:eye-off-fill"
											}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
					></TextField>

					<TextField
						sx={inputStyle}
						label="Retype password"
						required
						type={showPassword ? "text" : "password"}
						error={rePasswordError}
						helperText={
							rePasswordError && "Passwords doesn't match."
						}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									rePasswordError: false,
								})
							)
						}
						onChange={(e) =>
							dispatch(
								setSigninData({
									...signinData,
									repassword: e.target.value,
								})
							)
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() =>
											setShowPassword(!showPassword)
										}
										edge="end"
									>
										<Iconify
											icon={
												showPassword
													? "eva:eye-fill"
													: "eva:eye-off-fill"
											}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
					></TextField>

					<TextField
						sx={inputStyle}
						label="First Name"
						required
						error={nameError}
						helperText={nameError && "First Name is required."}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									nameError: false,
								})
							)
						}
						onChange={(e) =>
							dispatch(
								setSigninData({
									...signinData,
									name: e.target.value,
								})
							)
						}
					></TextField>

					<TextField
						sx={inputStyle}
						label="Last Name"
						required
						error={lastNameError}
						helperText={lastNameError && "Last Name is required."}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									lastNameError: false,
								})
							)
						}
						onChange={(e) =>
							dispatch(
								setSigninData({
									...signinData,
									lastName: e.target.value,
								})
							)
						}
					></TextField>

					<MuiTelInput
						sx={phoneStyle}
						defaultCountry="US"
						forceCallingCode={true}
						value={phoneValue}
						error={phoneError || invalidPhoneError}
						helperText={
							(phoneError || invalidPhoneError) &&
							"Invalid phone number."
						}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									phoneError: false,
									invalidPhoneError: false,
								})
							)
						}
						onChange={handlePhoneChange}
					></MuiTelInput>

					<TextField
						sx={inputStyle}
						label="Company Name"
						required
						error={companyError || existantCompanyError}
						helperText={
							(companyError && "Company Name is required.") ||
							(existantCompanyError &&
								"Company already onboarded, please contact company admin to signup!")
						}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									companyError: false,
									existantCompanyError: false,
								})
							)
						}
						onChange={(e) => {
							dispatch(
								setSigninData({
									...signinData,
									company: e.target.value,
								})
							);
						}}
					></TextField>

					<TextField
						sx={inputStyle}
						type="number"
						label="Shipping Volume per Month"
						required
						error={shippingVolError}
						helperText={
							shippingVolError && "Shipping Volume is required."
						}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									shippingVolError: false,
								})
							)
						}
						onChange={(e) => {
							dispatch(
								setSigninData({
									...signinData,
									shippingvolume: e.target.value,
								})
							);
						}}
					></TextField>

					<LoadingButton
						fullWidth
						size="large"
						type="submit"
						variant="contained"
						onClick={handleSigup}
						sx={{
							marginTop: 3,

							boxShadow: "none",
							backgroundColor: palette.secondary.main,
						}}
					>
						Sign up
					</LoadingButton>

					<Box
						sx={{
							marginTop: 3,
							height: 22.8,
							cursor: "pointer",
							textAlign: "center",
						}}
					>
						<Link
							variant="h6"
							underline="none"
							sx={{ fontWeight: 500 }}
							onClick={() => setSignUp(false)}
						>
							<span style={{ color: "#000" }}>
								Already have an account?
							</span>{" "}
							Log in
						</Link>
					</Box>
				</Box>
			)}
		</Fragment>
	);
};

export default SigninForm;
