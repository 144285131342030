import { useEffect, useRef, useState } from "react";
import { Stack, Snackbar, Alert, Typography, Box } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import { logoutUser } from "src/features/user/userState";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SmartTable from "src/components/smart-table/SmartTable";
import { useDispatch, useSelector } from "react-redux";
import {
	dateDiff,
	removeTitleCase,
	titleCase,
	unixToDate,
} from "src/utils/core";
import {
	incrementAwaitingApiCounter,
	decrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { getUserInfo } from "src/features/user/userState";
import typography from "src/theme/typography";

import { getInvoices, getUpcomingPayments } from "src/features/user/userApi";
import { fDate } from "src/utils/formatTime";
import palette from "src/theme/palette";
import CardsComponent from "./cardsComponent/cardsComponent";
import moment from "moment-timezone";
import "./billing.scss";

const textStyle = {
	color: palette.common.black,
	fontSize: { xs: "16px !important", md: "18px !important" },
	marginTop: { xs: "5px", md: "10px" },
};

const BillingInvoice = () => {
	const userInfo = useAppSelector(getUserInfo);
	const searchPlaceholder = "Enter invoice ID";
	const { invoiceId } = useParams();
	const { pathname } = useLocation();
	const billingPathname = "/dashboard/tenants/details";
	const billingPathname2 = "/dashboard/tenants/details/";

	// TODO: change this an invoice one
	const usersInfo = useSelector((state) => state.user.usersInfo);
	const [invoicesList, setInvoicesList] = useState([]);
	const [totalItemsCount, setTotalItemCount] = useState(0);
	const [showExportInfo, setShowExportInfo] = useState(false);
	const defaultColumn = "invoice_id";
	const [expandedInvoiceInfo, setExpandedInvoiceInfo] = useState({});
	const [searchKey, setSearchKey] = useState(null);
	const [modalLoader, setModalLoader] = useState(false);
	const [expandedRow, setExpandedRow] = useState(null);
	const dispatch = useAppDispatch();
	const [userSelectedColumns, setUserSelectedColumns] = useState([]);

	// current page of the table
	const [page, setPage] = useState(0);

	// number of table rows per page
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [statusList, setStatusList] = useState([]);
	const [statusAllData, setStatusAllData] = useState("");
	const [showLoader, setShowLoader] = useState(false);
	const [advancedFormData, setAdvancedFormData] = useState({});
	const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
	const [order, setOrder] = useState("");
	const [product, setProduct] = useState("");
	const { tenant_id, tenant_name, user_id } = useSelector(
		(state) => state.tenants
	);

	const [upcomingPayments, setUpcomingPayments] = useState({
		plan_name: "",
		start_date: "",
		expiration_date: "",
		orders_tracked: "",
		price: "",
		billing_cycle: {
			start: "",
			end: "",
		},
	});

	const navigate = useNavigate();
	const trackingInfoRef = useRef({});

	const advancedFilterInvoicesSearchData = {
		purchase_date: { name: "purchase_date", label: "Purchase Date" },
	};

	const getCurrentPageParams = (current_page = 1) => {
		let tempFormData = { ...advancedFormData };
		let queryParams = {
			page_size: rowsPerPage,
			current_page,
			// [sortDataString]: order,
			...tempFormData,
		};

		return queryParams;
	};

	// fetches the users list
	const fetchData = async () => {
		setInvoicesList([]);
		let tempFormData = getCurrentPageParams()?.status
			? {
					...getCurrentPageParams(),
					status: getCurrentPageParams()?.status.toLowerCase(),
			  }
			: getCurrentPageParams();

		if (pathname !== billingPathname || pathname !== billingPathname2) {
			navigate(billingPathname);
			getInvoicesList(tempFormData);
			setExpandedRow(null);
			setPage(0);
		} else {
			if (!advancedFormData) {
				getInvoicesList({ ...tempFormData });
			} else {
				getInvoicesList({ ...tempFormData });
			}
			setExpandedRow(null);
			setPage(0);
		}
	};

	// function to expand the user info when its row is clicked
	const onExpandhandler = (event, invoiceId, index) => {
		event.preventDefault();
		// if (expandedRow !== invoiceId) {
		// 	getSingleInvoiceInfo(invoiceId);
		// 	// navigate(`/dashboard/users/${invoiceId}`);
		// 	setExpandedRow(invoiceId);
		// 	setSearchKey(invoiceId);
		// } else {
		// 	// navigate('/dashboard/users');
		// 	setExpandedRow(null);
		// 	setSearchKey(null);
		// }
	};

	// analogous to getSingleTrackNumberInfo on TrackingPage.jsx
	const getSingleInvoiceInfo = (invoiceId) => {
		// setModalLoader(false);
		// let parmas = null;
		// TODO: change this to expanded invoice info
		// const res =getUserExpandedInfo (
		// 	invoiceId,
		// 	parmas,
		// 	userInfo?.accessToken
		// );
		// res.then((result) => {
		// 	setExpandedInvoiceInfo(result.data);
		// 	setModalLoader(false);
		// }).catch((error) => {
		// 	console.log(error);
		// 	if (error?.response?.status == "401") {
		// 		dispatch(logoutUser());
		// 	}
		// 	setModalLoader(false);
		// });
	};

	const onCloseExportInfo = () => {
		setShowExportInfo(false);
	};

	const checkInvoiceId = (arr, iId) => {
		const found = arr.some((el) => el._id === iId);
		return found;
	};

	// Function that gets the users list from the database
	const getInvoicesList = (parms) => {
		dispatch(incrementAwaitingApiCounter());
		setShowLoader(true);

		getInvoices(
			parms,
			{
				tenant_id: tenant_id,
				user_id: userInfo.user_id,
			},
			userInfo,
			tenant_name
		)
			.then((result) => {
				const dataFiltered = result.data.records?.map(
					({
						_id,
						plan_name,
						tenant_id,
						payment_id,
						plan_id,
						currency,
						purchase_date,
						status,
						createdAt,
						...rest
					}) => {
						return {
							invoice_id: payment_id,
							// purchase_date: moment
							// 	.unix(purchase_date)
							// 	.tz("America/Los_Angeles"),
							...rest,
							status: titleCase(status),
							// created_at: moment
							// 	.unix(createdAt)
							// 	.tz("America/Los_Angeles"),
						};
					}
				);

				setInvoicesList(dataFiltered);
				setTotalItemCount(result.data.total_count);

				dispatch(decrementAwaitingApiCounter());
				setShowLoader(false);
			})
			.catch((error) => {
				dispatch(decrementAwaitingApiCounter());
				setShowLoader(false);
			});
	};

	const onSearchClick = () => {
		setSearchKey(null);
	};

	// Function that manage the pagination. The event parameter is needed for TablePagination API of MUI
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		setExpandedRow(null);

		let paginationParams = {
			page_size: rowsPerPage,
			current_page: newPage + 1,
			// [sortDataString]: order,
		};
		// if (startDate && endDate) {
		// 	paginationParams.date_range = `${startDate}-${endDate}`;
		// }
		// if (first_scan) {
		// 	paginationParams.first_scan = first_scan;
		// } else if (statusToShow !== "All Orders") {
		// 	paginationParams.status = statusToShow;
		// }
		if (Object.keys(advancedFormData).length > 0) {
			Object.assign(paginationParams, advancedFormData);
		}
	};

	// Function that changes the number of rows per page
	const handleChangeRowsPerPage = (event) => {
		const newRowsPerPage = event.target.value;
		setRowsPerPage(newRowsPerPage);
		setPage(0);
		if (totalItemsCount > 1) {
			const queryParams = getCurrentPageParams();
			if (
				pathname === billingPathname ||
				pathname === billingPathname2 ||
				advancedFormData.hasOwnProperty("status")
			) {
				if (advancedFormData.hasOwnProperty("status")) {
					getTrackingNumerList(
						{
							...queryParams,
							...advancedFormData,
							page_size: selected_page,
							current_page: 1,
							// [sortDataString]: order,
						},
						false
					);
				} else {
					getInvoicesList({
						...queryParams,
						page_size: newRowsPerPage,
						current_page: 1,
						// status: statusToShow,
						// [sortDataString]: order,
					});
					//setCurrentStatusData(statusToShow);
				}
			} else {
				// const queryParams = getCurrentPageParams();
				if (first_scan) {
					getTrackingNumerList(
						{
							...queryParams,
							page_size: selected_page,
							current_page: 1,
							first_scan: first_scan,
							// [sortDataString]: order,
						},
						false
					);
				} else if (statusToShow) {
					getTrackingNumerList(
						{
							...queryParams,
							page_size: selected_page,
							current_page: 1,
							status: statusToShow,
							// [sortDataString]: order,
						},
						false
					);
					setPage(0);
				}
			}
		}
	};

	// Search by default column
	const handleSearchBtn = async (e) => {
		e.preventDefault();
		if (e.target.trackingNumber.value === "") setSearchError(true);
		const searchedVal = e.target.trackingNumber.value.trim();
		setSearchKey(searchedVal);
		setPage(0);
		setRowsPerPage(10);
		setTotalItemCount(0);
		setAdvancedFormData({});
		setShowAdvancedFilter(false);
		if (pathname !== billingPathname || pathname !== billingPathname2) {
			navigate("/dashboard/billing");
		}
		// internal search
		const filteredRows = invoicesList.filter((row) =>
			row[defaultColumn].toString().includes(searchedVal)
		);
		if (filteredRows.length > 0) {
			setInvoicesList(filteredRows);
			setTotalItemCount(filteredRows.length);
		} else {
			// external search
			getInvoicesList({
				page_size: rowsPerPage,
				current_page: 1,
				tracking_no: searchedVal,
			});
		}
	};

	// Function to manage what happens when the advanced filter btn is clicked
	const onClickAdvancedFilterBtn = () => {
		setSearchKey(null);
		setAdvancedFormData({});
		setShowAdvancedFilter(!showAdvancedFilter && !showAdvancedFilter);
		setPage(0);

		fetchData();
	};

	const onChangeAdvancedForm = (e = null, date = null) => {
		if (date == null) {
			const { name, value } = e.target;
			let tempData = { ...advancedFormData };
			if (
				(value == null || value === "") &&
				tempData.hasOwnProperty(name)
			) {
				delete tempData[name];
			} else {
				tempData[name] = value;
			}
			setAdvancedFormData({ ...tempData });
		} else {
			setAdvancedFormData({
				...advancedFormData,
				[e]: date !== "Invalid Date" ? date : null,
			});
		}
	};

	const onSubmitAdvancedFilter = () => {
		fetchData();
	};

	// Function to handle the column display
	const handleColumnSubmit = (columnList) => {
		// console.log(columnList);
		setUserSelectedColumns(columnList.map((item) => removeTitleCase(item)));
	};

	// TODO: make this functional. connect to the endpoint to export the data
	const exportInvoicesList = (parms, appendRow = false) => {
		const res = usersData;
		// TODO: connect this with the correct endpoint funct, similar as below
		// const res = getTrackingList(cleanObject(parms), userInfo?.accessToken);

		// res
		//   .then((result) => {
		//     console.log(result.statusCode);
		//   })
		//   .catch((error) => {
		//     if (error?.response?.status == '401') {
		//       dispatch(logoutUser());
		//     }
		//     console.log(error);
		//   });
	};

	const handleExportBtn = () => {
		setShowExportInfo(true);
		let parmas = {
			export_data: true,
			export_data_to: userInfo?.user?.user_mail,
			...advancedFormData,
		};

		if (searchKey) {
			parmas.user_id = searchKey;
		}

		exportInvoicesList(parmas);
	};

	// useEffect(() => {
	// 	if (typeof usersInfo !== "undefined" && usersInfo.length > 0) {
	// 		setUserSelectedColumns(usersInfo);
	// 	}
	// }, [usersInfo]);

	//from dashboard track num search
	// useEffect(() => {
	// 	if (invoiceId) {
	// 		if (!checkInvoiceId(invoicesList, invoiceId)) {
	// 			getInvoicesList(
	// 				{
	// 					page_size: rowsPerPage,
	// 					current_page: 1,
	// 					user_id: invoiceId,
	// 				},
	// 				false,
	// 				true
	// 			);
	// 			getSingleInvoiceInfo(invoiceId);
	// 		} else {
	// 			getSingleInvoiceInfo(invoiceId);
	// 		}
	// 		setExpandedRow(invoiceId);
	// 		setSearchKey(invoiceId);
	// 	}
	// }, [invoiceId]);

	useEffect(() => {
		if (!showAdvancedFilter && (searchKey == "" || searchKey == null)) {
			dispatch(incrementAwaitingApiCounter());
			setShowLoader(true);
			setPage(0);

			let queryParams = getCurrentPageParams();
			if (pathname === billingPathname || pathname === billingPathname2) {
				getInvoicesList({
					...queryParams,
					page_size: rowsPerPage,
					current_page: 1,
					// [sortDataString]: order,
				});
			}

			// fetches the upcoming payments
			getUpcomingPayments(userInfo, user_id, tenant_id)
				.then((upcomingPaymentsInfo) => {
					setUpcomingPayments(upcomingPaymentsInfo?.data || {});
					dispatch(decrementAwaitingApiCounter());
				})
				.catch((error) => {
					dispatch(decrementAwaitingApiCounter());
				});

			setShowLoader(false);
		}
	}, [!advancedFormData, pathname]);

	const dataConverted = invoicesList;

	const formatPrice = (str) => {
		if (!str) return "0";
		return str.toFixed(2);
	};

	const invoicesStatus = [
		{
			label: "Completed",
			value: "Completed",
		},
		{
			label: "Pending",
			value: "Pending",
		},
	];

	return (
		<Box className="billing-invoices-container">
			<Helmet>
				<title> Billing | BeyondCarts CMS </title>
			</Helmet>

			<CardsComponent />

			<Box className="billing-invoices-payments-container">
				<h3 style={typography.h3}>Upcoming Payment</h3>

				<Box className="billing-invoices-upcoming-payments-container">
					<Typography
						sx={{
							...textStyle,
							fontWeight: "bold",
							marginTop: 2,
							marginBottom: 1,
						}}
					>
						{upcomingPayments?.plan_name} Details:
					</Typography>
					<Typography sx={textStyle}>
						Billing Cycle:{" "}
						{unixToDate(upcomingPayments?.billing_cycle.start)} -{" "}
						{unixToDate(upcomingPayments?.billing_cycle.end)}
					</Typography>
					<Typography sx={textStyle}>
						Onboarded Date:{" "}
						{unixToDate(upcomingPayments?.start_date)}
					</Typography>
					<Typography sx={textStyle}>
						Expiration Date:{" "}
						{unixToDate(upcomingPayments?.expiration_date)}
					</Typography>
					<Typography sx={textStyle}>
						Orders tracked: {upcomingPayments.orders_tracked}
					</Typography>
					<Typography sx={{ ...textStyle, fontWeight: "bold" }}>
						Price: ${formatPrice(upcomingPayments?.price)}
					</Typography>
				</Box>
			</Box>

			<Snackbar
				open={showExportInfo}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				autoHideDuration={6000}
				onClose={onCloseExportInfo}
			>
				<Alert
					onClose={onCloseExportInfo}
					severity="success"
					sx={{ width: "100%" }}
				>
					Export in progress
				</Alert>
			</Snackbar>

			<Box className="billing-invoices-payments-container">
				<h3 style={typography.h3}>Invoices</h3>

				<Box className="billing-invoices-table-container">
					<Stack>
						<SmartTable
							isSearch
							isAdvancedFilter
							isBillingPage
							placeholder={searchPlaceholder}
							advancedFilterInvoicesSearchData={
								advancedFilterInvoicesSearchData
							}
							isStatusMenu
							isRowSelectable
							isAction
							originalRows={
								typeof dataConverted !== "undefined"
									? dataConverted
									: [{}]
							}
							defaultColumn={defaultColumn}
							statusList={statusList}
							statusAllData={statusAllData}
							onExpandhandler={onExpandhandler}
							expandedRow={expandedRow}
							totelItemCount={totalItemsCount}
							page={page}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
							rowsPerPage={rowsPerPage}
							onhandeSeachSubmit={handleSearchBtn}
							searchKey={searchKey}
							onSearchClick={onSearchClick}
							showLoader={showLoader}
							onChangeAdvancedForm={onChangeAdvancedForm}
							advancedFormData={advancedFormData}
							onSubmitAdvancedFilter={onSubmitAdvancedFilter}
							exapndRowIntputData={expandedInvoiceInfo}
							trackingInfoRef={trackingInfoRef}
							showAdvancedFilter={showAdvancedFilter}
							onClickAdvancedFilter={onClickAdvancedFilterBtn}
							exportButtonClicked={handleExportBtn}
							// trackingPage={true}
							userSelectedColumns={userSelectedColumns}
							handleColumnSubmit={handleColumnSubmit}
							modalLoader={modalLoader}
							invoicesStatus={invoicesStatus}
						/>
					</Stack>
				</Box>
			</Box>
		</Box>
	);
};

export default BillingInvoice;
