import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";

import { cleanObject, removeTitleCase, unixToDate } from "src/utils/core";
// @mui
import { Stack, Snackbar, Alert, Box, Tab, Typography } from "@mui/material";
import SmartTable from "src/components/smart-table/SmartTable";
import { useSelector } from "react-redux";
import { logoutUser } from "src/features/user/userState";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";
import {
	getAllAdminUsers,
	getAllTenantUsers,
	getAllTenantUsersList,
	getSingleTenant,
} from "src/features/usersPage/usersApi";
import { getTenantUser } from "src/features/usersPage/usersApi";
import {
	incrementAwaitingApiCounter,
	decrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { clearTenant } from "src/features/filteredTenant/filteredTenant";
import { updateTenantUser } from "src/features/user/userSelect";
import AccountPopover from "src/layouts/dashboard/header/AccountPopover";
import { capitalCase } from "capital-case";

const UsersPage = () => {
	const { pathname } = useLocation();
	const usersInfo = useSelector((state) => state.user.usersInfo);
	const filteredTenant = useSelector(
		(state) => state.selectedTenant.SelectedTenant
	);
	const userInfo = useAppSelector(getUserInfo);
	const dispatch = useAppDispatch();

	const [expandedRow, setExpandedRow] = useState(null);
	const [usersList, setUsersList] = useState([]);
	const [totalItemsCount, setTotalItemCount] = useState(0);

	// current page of the table
	const [page, setPage] = useState(0);

	// number of table rows per page
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [searchKey, setSearchKey] = useState(null);
	const [showLoader, setShowLoader] = useState(false);
	const [advancedFormData, setAdvancedFormData] = useState({});
	const [expandedUserInfo, setExpandedUserInfo] = useState({});
	const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
	const [statusList, setStatusList] = useState([]);
	const [statusAllData, setStatusAllData] = useState("");
	const [showExportInfo, setShowExportInfo] = useState(false);
	const [userSelectedColumns, setUserSelectedColumns] = useState([]);
	const [order, setOrder] = useState("");
	const [product, setProduct] = useState("");
	const [modalLoader, setModalLoader] = useState(false);
	const sortDataString = `sort_data[${product}]`;
	const [allTenantInfo, setAllTenantInfo] = useState([]);
	const [isRefresh, setIsRefresh] = useState(false);

	// TODO: uncomment when the adminUser attribute is well setted.
	const isAdminUser = ["administrator", "admin"].includes(userInfo?.role);

	const [value, setValue] = useState("1");
	const trackingInfoRef = useRef({});

	const searchPlaceholder = "Search email";
	const defaultColumn = "email";
	const dataConverted = [];

	// formate key names and column order
	const originalRowsFormatted = usersList?.map(
		({
			user_name,
			last_loggedin_at,
			// last_loggedout_at,
			role,
			updatedAt,
			login_failed_attempts,
			// last_password_reset_at,
			last_forgot_password_trigerred_at,
			status,
			company_name,
			createdAt,
			createdSource,
			createdBy,
			updatedBy,
			updatedSource,
			first_name,
			is_onBoarded,
			isMarketingUser,
			_id,
			...rest
		}) => ({
			email: user_name,
			first_name,
			created_at: createdAt,
			updated_source: updatedSource,
			updated_by: updatedBy,
			created_source: createdSource,
			created_by: createdBy,
			...rest,
			role: capitalCase(role),
			updated_at: updatedAt,
			last_logged_in: last_loggedin_at,
			// last_logged_out: last_loggedout_at,
			failed_attempts: login_failed_attempts,
			_id,
			// password_reset: last_password_reset_at,
		})
	);

	originalRowsFormatted?.forEach((item) => {
		dataConverted.push({
			...item,
		});
	});

	const advancedFilterUsersSearchData = {
		id: { name: "user_id", label: "User id" },
		first_name: { name: "first_name", label: "User First Name" },
		last_name: { name: "last_name", label: "User Last Name" },
		phone_number: { name: "phone_number", label: "User Phone Number" },
		email: { name: "user_name", label: "User Email" },
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
		dispatch(clearTenant());
	};

	const getCurrentPageParams = (current_page = 1) => {
		let tempFormData = { ...advancedFormData };
		let queryParams = {
			page_size: rowsPerPage,
			current_page,
			[sortDataString]: order,
			...tempFormData,
		};

		return queryParams;
	};
	// fetches the users list
	const fetchData = async (isLoadAll = false, selected_page = null) => {
		try {
			let queryParams = getCurrentPageParams();

			if (selected_page != null) {
				queryParams["page_size"] = selected_page;
			}

			if (isLoadAll) {
				dispatch(incrementAwaitingApiCounter());
				let params = null;
				const headers = {
					tenant_id: filteredTenant?._id,
					user_id: userInfo.user_id,
				};

				let data = null;

				if (value === "1") {
					data = await getAllAdminUsers(params, headers, userInfo);
				}

				if (value === "2" && !filteredTenant?._id) {
					data = await getAllTenantUsersList(
						params,
						headers,
						userInfo
					);
					setAllTenantInfo(data?.data);
				}

				if (value === "2" && filteredTenant?._id) {
					let tenant_name = filteredTenant?.tenant_name;
					data = await getAllTenantUsers(
						params,
						headers,
						userInfo,
						tenant_name
					);
				}

				const dataFiltered = data?.data.map(
					({
						tenant_id,
						updatedAt,
						createdAt,
						createdSource,
						last_loggedin_at,
						last_loggedout_at,
						last_password_reset_at,
						...rest
					}) => {
						return {
							...rest,
							updatedAt: updatedAt ? unixToDate(updatedAt) : "--",
							last_loggedout_at: last_loggedout_at
								? unixToDate(last_loggedout_at)
								: "--",
							createdAt: createdAt ? unixToDate(createdAt) : "--",
							last_loggedin_at: last_loggedin_at
								? unixToDate(last_loggedin_at)
								: "--",
							createdSource: createdSource,
						};
					}
				);

				setUsersList(dataFiltered || []);
				setTotalItemCount(dataFiltered.length || 0);
				dispatch(decrementAwaitingApiCounter());
				// setShowLoader(false);
			}
		} catch (error) {
			dispatch(decrementAwaitingApiCounter());
		}
	};

	// Function that gets the users list from the database
	const getUsersList = (parms, appendRow = false, isOnlyTrackNo = false) => {
		dispatch(incrementAwaitingApiCounter());
		setShowLoader(true);
		const res = getAllAdminUsers(cleanObject(parms), userInfo);
		res.then((result) => {
			const responseData = result.data;
			dispatch(decrementAwaitingApiCounter());
			setShowLoader(false);
		}).catch((error) => {
			dispatch(decrementAwaitingApiCounter());
			setShowLoader(false);
		});
	};

	// function to expand the user info when its row is clicked
	const onExpandhandler = (event, userId, record) => {
		event.preventDefault();
		// let expandedRecord = usersList[record];

		const expandedRecord = usersList.filter(
			(user) => user._id === userId
		)[0];

		if (expandedRow !== userId) {
			getSingleUserInfo(userId, expandedRecord);
			setExpandedRow(userId);
		} else {
			setExpandedRow(null);
			setSearchKey(null);
		}
	};

	// analogous to getSingleTrackNumberInfo on TrackingPage.jsx
	const getSingleUserInfo = async (userId, expandedRecord) => {
		setModalLoader(true);
		const params = null;
		let headers = null;

		if (!userId) return;

		let response = null;

		if (value === "1") {
			headers = {
				tenant_id: userInfo.tenant_id,
				user_id: userId,
			};

			response = await getTenantUser(params, headers, userId, userInfo);
		}

		if (value === "2" && filteredTenant?._id) {
			headers = {
				tenant_id: filteredTenant?._id,
				user_id: userId,
			};

			let tenant_name = filteredTenant?.tenant_name;

			response = await getSingleTenant(
				params,
				headers,
				userId,
				userInfo,
				tenant_name
			);
		}

		if (value === "2" && !filteredTenant?._id) {
			let tenant_name = expandedRecord.user_name;

			const chosenTenant = allTenantInfo.find((data) => {
				if (data._id === expandedRecord._id) {
					const payload = {
						TenantFullInfo: data,
						tenantName: tenant_name,
					};

					dispatch(updateTenantUser(payload));
					return data.tenant_id;
				}
			});

			headers = {
				tenant_id: chosenTenant.tenant_id,
				user_id: expandedRecord._id,
			};

			response = await getSingleTenant(
				params,
				headers,
				userId,
				userInfo,
				tenant_name
			);
		}

		const data = response?.data;

		if (data) {
			const filtered_data = {
				first_name: data?.first_name || "",
				last_name: data?.last_name || "",
				phone_number: data?.phone_number || "",
				email: data?.user_name || "",
				id: data?._id || "",
				role: data?.role || "",
			};
			setExpandedUserInfo(filtered_data);
			setModalLoader(false);
		}
	};

	// Search by default column
	const handleSearchBtn = (e) => {
		const searchedVal = e.target.trackingNumber.value.trim();
		setSearchKey(searchedVal);
		setPage(0);
		setRowsPerPage(10);
		setTotalItemCount(0);
		setAdvancedFormData({});
		setShowAdvancedFilter(false);

		if (searchedVal === "") {
			fetchData(true);
			return;
		}

		const filteredRows = usersList.filter((row) => {
			const name1 = row["user_name"].toLowerCase();
			const name2 = searchedVal.toLowerCase();
			return name1.includes(name2);
		});

		setUsersList(filteredRows);
		setTotalItemCount(filteredRows.length);
	};

	// function to clean the search text
	const onSearchClick = () => {
		setSearchKey(null);
	};

	// Function that manage the pagination. The event parameter is needed for TablePagination API of MUI
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		setExpandedRow(null);

		if (newPage <= page || usersList.length > rowsPerPage * (page + 1))
			return;

		let paginationParams = {
			page_size: rowsPerPage,
			current_page: newPage + 1,
			[sortDataString]: order,
		};

		if (Object.keys(advancedFormData).length > 0)
			Object.assign(paginationParams, advancedFormData);

		getUsersList(paginationParams, true);
	};

	// Function that changes the number of rows per page
	const handleChangeRowsPerPage = (event) => {
		const newRowsPerPage = event.target.value;
		setRowsPerPage(newRowsPerPage);
		setPage(0);
		if (totalItemsCount > 1) {
			if (
				pathname === "/dashboard/tracking" ||
				advancedFormData.hasOwnProperty("status")
			) {
				fetchData(false, newRowsPerPage);
			}
		}
	};

	// Function to manage what happens when the advanced filter btn is clicked
	const onClickAdvancedFilterBtn = () => {
		setSearchKey(null);
		setAdvancedFormData({});
		if (showAdvancedFilter) {
			setIsRefresh(true);
		}
		setShowAdvancedFilter(!showAdvancedFilter && !showAdvancedFilter);
		setPage(0);
	};

	// TODO: review this
	const onChangeAdvancedForm = (e = null, type) => {
		const { name, value } = e.target;
		let tempData = { ...advancedFormData };
		tempData[name] = value;
		setAdvancedFormData({ ...tempData });
		// if (date == null) {

		// if ((value == null || value === '') && tempData.hasOwnProperty(name)) {
		//   delete tempData[name];
		// } else {
		// }

		// } else {
		// setAdvancedFormData({ ...advancedFormData});
		// }
	};

	// TODO: review this
	const onSubmitAdvancedFilter = async () => {
		let tempFormData = getCurrentPageParams(false);
		try {
			setShowLoader(true);
			let params = null;
			const headers = {
				tenant_id: filteredTenant?._id,
				user_id: userInfo.user_id,
			};
			let res = null;
			if (value === "1") {
				res = await getAllAdminUsers(params, headers, userInfo);
			}
			if (value === "2" && !filteredTenant?._id) {
				res = await getAllTenantUsersList(params, headers, userInfo);
				setAllTenantInfo(res?.data);
			}
			if (value === "2" && filteredTenant?._id) {
				let tenant_name = filteredTenant?.tenant_name;
				res = await getAllTenantUsers(
					params,
					headers,
					userInfo,
					tenant_name
				);
			}
			const data = res.data;
			if (res.error) console.log(res.error?.message);

			const dataFiltered = data?.map(
				({
					tenant_id,
					last_loggedin_at,
					updatedAt,
					createdAt,
					is_demo_user,
					isMarketingUser,
					last_loggedout_at,
					last_password_reset_at,
					shipping_volume,
					createdSource,
					...rest
				}) => {
					return {
						...rest,
						updatedAt: updatedAt ? unixToDate(updatedAt) : "--",
						last_loggedin_at: last_loggedin_at
							? unixToDate(last_loggedin_at)
							: "--",
						createdAt: createdAt ? unixToDate(createdAt) : "--",
						createdSource: createdSource,
					};
				}
			);

			const newUserData = dataFiltered.filter((df) => {
				return (
					(!tempFormData.first_name ||
						(df.first_name &&
							df.first_name
								.toLowerCase()
								.includes(
									tempFormData.first_name.toLowerCase()
								))) &&
					(!tempFormData.last_name ||
						(df.last_name &&
							df.last_name
								.toLowerCase()
								.includes(
									tempFormData.last_name.toLowerCase()
								))) &&
					(!tempFormData.phone_number ||
						(df.phone_number &&
							df.phone_number.includes(
								tempFormData.phone_number
							))) &&
					(!tempFormData.user_name ||
						(df.user_name &&
							df.user_name
								.toLowerCase()
								.includes(
									tempFormData.user_name.toLowerCase()
								)))
				);
			});

			setUsersList(newUserData || []);
			setTotalItemCount(newUserData?.length || 0);
			setShowLoader(false);
		} catch (err) {}

		setExpandedRow(null);
		setPage(0);
	};

	// Function to handle the column display
	const handleColumnSubmit = (columnList) => {
		// console.log(columnList);
		setUserSelectedColumns(columnList.map((item) => removeTitleCase(item)));

		// TODO: review this

		// dispatch(updateUserPreferences({ trackingInfo: columnList.map((item) => removeTitleCase(item)) }));
		// updateColumnPreferences(
		//   { table_name: 'tracking_info', table_columns: columnList.map((item) => removeTitleCase(item)) },
		//   userInfo?.accessToken
		// );
		// useAppDispatch(updateColumnPreferences({table_name:'tracking_info',table_columns:columnList.map(item => removeTitleCase(item))}))
	};

	const onCloseExportInfo = () => {
		setShowExportInfo(false);
	};

	// Fetches the data on mount
	useEffect(() => {
		fetchData(true);
		return () => {
			setIsRefresh(false);
			setSearchKey(null);
		};
	}, [value, filteredTenant, isRefresh]);

	useEffect(() => {
		if (typeof usersInfo !== "undefined" && usersInfo.length > 0) {
			setUserSelectedColumns(usersInfo);
		}
	}, [usersInfo]);

	return (
		<>
			<Helmet>
				<title> Users | BeyondCarts CMS </title>
			</Helmet>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					borderBottom: "1px solid #c4c4c4",
					marginTop: "20px",
					paddingBottom: 2,
				}}
			>
				<Typography variant="h4" sx={{ color: "#000" }}>
					Users
				</Typography>
				<AccountPopover />
			</Box>
			<Snackbar
				open={showExportInfo}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				autoHideDuration={6000}
				onClose={onCloseExportInfo}
			>
				<Alert
					onClose={onCloseExportInfo}
					severity="success"
					sx={{ width: "100%" }}
				>
					Export in progress
				</Alert>
			</Snackbar>

			<TabContext value={value}>
				<Box sx={{ borderBottom: 1, borderColor: "divider" }}>
					<TabList
						onChange={handleChange}
						aria-label="lab API tabs example"
					>
						<Tab label="CMS Users" value="1" />
						<Tab label="Tenant Users" value="2" />
					</TabList>
				</Box>
				<TabPanel value="1" sx={{ padding: "0 10px" }}>
					<Stack>
						<SmartTable
							columnsToAvoid={["_id"]}
							isSearch
							isAdvancedFilter
							isCMSUsers
							isAdminUser={isAdminUser}
							placeholder={searchPlaceholder}
							advancedFilterUsersSearchData={
								advancedFilterUsersSearchData
							}
							isStatusMenu
							isRowSelectable
							isAction
							originalRows={
								typeof dataConverted !== "undefined"
									? dataConverted
									: [{}]
							}
							defaultColumn={defaultColumn}
							statusList={statusList}
							statusAllData={statusAllData}
							onExpandhandler={onExpandhandler}
							expandedRow={expandedRow}
							totelItemCount={totalItemsCount}
							page={page}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
							rowsPerPage={rowsPerPage}
							onhandeSeachSubmit={handleSearchBtn}
							searchKey={searchKey}
							onSearchClick={onSearchClick}
							showLoader={showLoader}
							onChangeAdvancedForm={onChangeAdvancedForm}
							advancedFormData={advancedFormData}
							onSubmitAdvancedFilter={onSubmitAdvancedFilter}
							exapndRowIntputData={expandedUserInfo}
							setExpandedUserInfo={setExpandedUserInfo}
							trackingInfoRef={trackingInfoRef}
							showAdvancedFilter={showAdvancedFilter}
							onClickAdvancedFilter={onClickAdvancedFilterBtn}
							userSelectedColumns={userSelectedColumns}
							handleColumnSubmit={handleColumnSubmit}
							modalLoader={modalLoader}
							setModalLoader={setModalLoader}
							type={value}
							userType="cms"
							setIsRefresh={setIsRefresh}
						/>
					</Stack>
				</TabPanel>
				<TabPanel value="2" sx={{ padding: "0 10px" }}>
					<Stack>
						<SmartTable
							columnsToAvoid={["_id"]}
							isSearch
							isAdvancedFilter
							isTenantMenu
							isUsersPage
							isTenantUsers
							isAdminUser={isAdminUser}
							placeholder={searchPlaceholder}
							advancedFilterUsersSearchData={
								advancedFilterUsersSearchData
							}
							isStatusMenu
							isRowSelectable
							isAction
							originalRows={
								typeof dataConverted !== "undefined"
									? dataConverted
									: [{}]
							}
							defaultColumn={defaultColumn}
							statusList={statusList}
							statusAllData={statusAllData}
							onExpandhandler={onExpandhandler}
							expandedRow={expandedRow}
							totelItemCount={totalItemsCount}
							page={page}
							handleChangePage={handleChangePage}
							handleChangeRowsPerPage={handleChangeRowsPerPage}
							rowsPerPage={rowsPerPage}
							onhandeSeachSubmit={handleSearchBtn}
							searchKey={searchKey}
							onSearchClick={onSearchClick}
							showLoader={showLoader}
							onChangeAdvancedForm={onChangeAdvancedForm}
							advancedFormData={advancedFormData}
							onSubmitAdvancedFilter={onSubmitAdvancedFilter}
							exapndRowIntputData={expandedUserInfo}
							setExpandedUserInfo={setExpandedUserInfo}
							trackingInfoRef={trackingInfoRef}
							showAdvancedFilter={showAdvancedFilter}
							onClickAdvancedFilter={onClickAdvancedFilterBtn}
							// exportButtonClicked={handleExportBtn}
							// trackingPage={true}
							userSelectedColumns={userSelectedColumns}
							handleColumnSubmit={handleColumnSubmit}
							modalLoader={modalLoader}
							setModalLoader={setModalLoader}
							type={value}
							userType="tenant"
							setIsRefresh={setIsRefresh}
						/>
					</Stack>
				</TabPanel>
			</TabContext>
		</>
	);
};

export default UsersPage;
