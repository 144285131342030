import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TextField, Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { setSigninData } from "src/features/signin/signin.action";
import { testConection } from "src/features/onborading/onboardingApi";
import { getUserInfo } from "src/features/user/userState";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import ModalComponent from "src/components/modal/ModalComponent";
import InfoLogo from "src/components/info-logo/info-logo";
import palette from "src/theme/palette";

import "./magentoForm.scss";
import {
	decrementAwaitingApiCounter,
	incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const styleInput = {
	input: { color: palette.common.black },
	width: { lg: 515, md: 446, sm: 446 },
	marginTop: "10px",
	"& .MuiOutlinedInput-root": {
		"&:hover fieldset": {
			borderColor: "primary.dark",
		},
		"&.Mui-focused fieldset": {
			borderColor: "primary.dark",
		},
	},
	"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "secondary.light",
	},
	"& .MuiInputLabel-formControl": {
		color: "#00000099",
	},
	"& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
		color: "#00000099",
	},
	"& .MuiFormHelperText-root": {
		textAlign: "left",
		fontSize: "14px",
	},
};

const MagentoForm = () => {
	const dispatch = useDispatch();
	const dispatchApp = useAppDispatch();
	const signinData = useSelector((state) => state.signin.signinData);

	const {
		managerSignMagentoUrl,
		managerSignMagentoUrlErrors,
		managerSignMagentoUrlNotValid,

		managerSignMagentoToken,
		managerSignMagentoTokenErrors,

		isMagentoValid,
		isMagentoTested,
		isFromConfig,
	} = signinData;

	const userInfo = useAppSelector(getUserInfo);
	const [openModal, setOpenModal] = useState(false);
	const [modalText, setModalText] = useState("");
	const [testCon, setTestCon] = useState(false);

	const form = [
		{
			text: "Log in to your Magento account admin portal with administrator credentials.",
			inp: false,
			extraOptions: false,
		},
		{
			text: "Navigate to System > Extensions (Integrations)",
			inp: false,
			extraOptions: false,
		},
		{ text: "Add New Integration Button", inp: false, extraOptions: false },
		{
			text: "Fill Mandatory Fields (Name, Email, Password)",
			inp: false,
			extraOptions: false,
		},
		{
			text: "Click the API tab and Select “Resource Access” dropdown values as “Custom” provide access for “orders “ --> View and “Shipments” under the Sales tab.",
			inp: false,
			extraOptions: false,
		},
		{
			text: "Click the Save button. You will be redirected to the list page.",
			inp: false,
			extraOptions: false,
		},
		{
			text: "Press the Activate link in the integration row.",
			inp: false,
			extraOptions: false,
		},
		{
			text: "On the confirmation screen click the “Allow” button",
			inp: false,
			extraOptions: false,
		},
		{
			text: "Once the above steps have been completed, you will be presented with the following:",
			inp: false,
			extraOptions: true,
			options: [
				"<Consumer Key>",
				"<Consumer Secrets>",
				"<Access Token>",
				"<Access Token Secret>",
			],
		},
		{ text: "Click Done.", inp: false, extraOptions: false },
		{
			text: "Copy the Magento Store URL and paste it here:",
			inp: true,
			placehold: "https://www.yourstore.com",
			infoTxt:
				"Enter the base URL of your Magento store. This should include the 'http://' or 'https://' prefix and any domain suffix (e.g., '.com'). Example: 'https://www.yourstore.com'. Ensure it's accurate to successfully connect your store.",
			extraOptions: false,
		},
		{
			text: "Copy the Access Token and paste it here:",
			inp: true,
			placehold: "Access Token",
			infoTxt:
				"Enter the Integration Access Token you received from Magento. This token is essential for securely connecting and granting access to your Magento store's API. You can generate or find this token in your Magento Admin under System > Integrations. Keep this token confidential to protect your store's data.",
			extraOptions: false,
		},
	];

	const checkUrl = (url) => {
		if (
			!/https:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(
				url
			)
		)
			return true;
		return false;
	};

	const handleTestConnection = async () => {
		if (checkUrl(signinData.managerSignMagentoUrl)) {
			dispatch(
				setSigninData({
					...signinData,
					managerSignMagentoUrlErrors: true,
					managerSignMagentoUrlNotValid: true,
				})
			);
			return;
		}

		if (!signinData.managerSignMagentoToken) {
			dispatch(
				setSigninData({
					...signinData,
					managerSignMagentoTokenErrors: true,
				})
			);
			return;
		}

		dispatchApp(incrementAwaitingApiCounter());

		testConection(
			{
				action: "magento",
				access_token: managerSignMagentoToken,
				store_url: managerSignMagentoUrl,
			},
			userInfo.accessToken
		)
			.then((r) => {
				const { statusCode } = r;

				if (statusCode === 200) {
					dispatch(
						setSigninData({
							...signinData,
							isMagentoValid: true,
							isMagentoTested: true,
						})
					);

					setTestCon("MAGENTO");
				} else {
					dispatch(
						setSigninData({
							...signinData,
							isMagentoValid: false,
							isMagentoTested: true,
						})
					);

					setTestCon("MAGENTO_NOT");
				}
				dispatchApp(decrementAwaitingApiCounter());
			})
			.catch(() => {
				dispatch(
					setSigninData({
						...signinData,
						isMagentoValid: false,
						isMagentoTested: true,
					})
				);

				setTestCon("NOT_MAGENTO");
				dispatchApp(decrementAwaitingApiCounter());
			});
	};

	const onValue = (index) => {
		if (index === 10) return managerSignMagentoUrl;
		if (index === 11) return managerSignMagentoToken;
	};

	const onInputChange = (value, index) => {
		if (index === 10)
			dispatch(
				setSigninData({
					...signinData,
					managerSignMagentoUrl: value,
					isMagentoValid: false,
					isMagentoTested: false,
					continueBtn: false,
				})
			);
		if (index === 11)
			dispatch(
				setSigninData({
					...signinData,
					managerSignMagentoToken: value,
					isMagentoValid: false,
					isMagentoTested: false,
					continueBtn: false,
				})
			);
	};

	const onInputError = (index) => {
		if (index === 10 && managerSignMagentoUrlErrors) return true;
		if (index === 11 && managerSignMagentoTokenErrors) return true;
		return false;
	};

	const onFocus = (index) => {
		if (index === 10 && managerSignMagentoUrlErrors)
			dispatch(
				setSigninData({
					...signinData,
					managerSignMagentoUrlErrors: false,
					managerSignMagentoUrlNotValid: false,
				})
			);
		if (index === 11 && managerSignMagentoTokenErrors)
			dispatch(
				setSigninData({
					...signinData,
					managerSignMagentoTokenErrors: false,
				})
			);
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		dispatch(setSigninData({ ...signinData, continueBtn: true }));
	};

	useEffect(() => {
		if (!testCon) return;

		if (isMagentoTested) {
			if (isMagentoValid) {
				dispatch(setSigninData({ ...signinData, continueBtn: true }));
			} else {
				setTestCon(true);
				setOpenModal(true);
				setModalText(
					"Your test connection did not succeed with Magento, affecting our ability to retrieve your order details at this moment."
				);
			}
		}
	}, [testCon]);

	useEffect(() => {
		if (isFromConfig) return;
		if (isMagentoTested)
			dispatch(setSigninData({ ...signinData, continueBtn: true }));
		else dispatch(setSigninData({ ...signinData, continueBtn: false }));
	}, []);

	return (
		<>
			<div className="magento-form-container">
				<img className="manage-img" src="/assets/magento.png"></img>

				<div className="magento-form-step-container">
					{form.map((f, index) => (
						<Fragment key={index}>
							<div className="magento-form-step">
								<p className="magento-form-index">
									{index + 1}
								</p>
								<p className="magento-form-txt">{f.text}</p>
							</div>

							{f.inp && (
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										fullWidth
										sx={styleInput}
										label={f.placehold + " *"}
										value={onValue(index)}
										error={onInputError(index)}
										helperText={
											managerSignMagentoUrlErrors &&
											managerSignMagentoUrlNotValid
												? onInputError(index) &&
												  "URL is not valid"
												: onInputError(index) &&
												  `${f.placehold} is required.`
										}
										onFocus={() => onFocus(index)}
										onChange={(e) => {
											const trimmedValue =
												e.target.value.trim();
											onInputChange(trimmedValue, index);
										}}
										inputProps={{
											maxLength: 50,
										}}
									></TextField>
									<InfoLogo infoTxt={f.infoTxt}></InfoLogo>
								</Box>
							)}

							{f.extraOptions && (
								<ul>
									{f.options.map((o) => (
										<li>{o}</li>
									))}
								</ul>
							)}
						</Fragment>
					))}

					<Typography
						sx={{
							color: palette.common.black,
							fontSize: 18,
							textAlign: "center",
							paddingTop: 1,
						}}
					>
						Kindly test connection to continue with the next step
					</Typography>

					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<LoadingButton
							variant="contained"
							type="submit"
							onClick={() => handleTestConnection()}
							sx={{
								fontSize: "16px !important",
								boxShadow: "none",
								width: "50%",
								margin: "20px auto",
								backgroundColor: isMagentoTested
									? isMagentoValid
										? palette.success.dark
										: palette.error.dark
									: palette.secondary.light,
							}}
						>
							{isMagentoTested
								? isMagentoValid
									? "Connection Successful"
									: "Unable to Connect"
								: "Test Magento Connection"}
						</LoadingButton>
					</Box>
				</div>
			</div>

			<ModalComponent
				openModal={openModal}
				handleCloseModal={handleCloseModal}
				handleBtnClick={handleCloseModal}
				title={`Connection status`}
				description={modalText}
				btnText={"Continue"}
			></ModalComponent>
		</>
	);
};

export default MagentoForm;
