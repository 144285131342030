import {
	Stack,
	Box,
	Table,
	TableHead,
	TableRow,
	TableCell,
	Typography,
	TableBody,
	Divider,
} from "@mui/material";
import {
	Timeline,
	TimelineDot,
	TimelineItem,
	TimelineContent,
	TimelineSeparator,
	TimelineConnector,
	TimelineOppositeContent,
	timelineOppositeContentClasses,
} from "@mui/lab";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { unixToDate, unixToTime, dateDiff } from "../../utils/core";
import SvgColor from "../svg-color/SvgColor";
import moment from "moment";
import ModalRowDetails from "../modal/ModalRowDetails";
import typography from "src/theme/typography";

function OrderItem({ item, isLast, isFirst }) {
	const { date, location, status, mapped_category } = item;
	return (
		<TimelineItem sx={{ minHeight: 80 }}>
			<TimelineOppositeContent>
				<Stack
					spacing={0.5}
					alignItems="center"
					sx={{ width: 82 }}
					divider={
						<Divider
							flexItem
							sx={{ borderWidth: 1, bgcolor: "#ACACAC" }}
						/>
					}
				>
					<Typography
						sx={{
							fontFamily: typography.fontFamilyRegular,
							fontSize: { lg: 14, md: 12, sm: 10 },
						}}
					>
						{unixToDate(date)}
					</Typography>
					<Typography
						sx={{
							fontFamily: typography.fontFamilyRegular,
							fontSize: { lg: 14, md: 12, sm: 10 },
						}}
					>
						{unixToTime(date)}
					</Typography>
				</Stack>
			</TimelineOppositeContent>
			<TimelineSeparator>
				{mapped_category === "Delivered" ? (
					<TimelineDot
						sx={{
							marginTop: 3.5,
							backgroundColor: "none",
							padding: 0,
							marginLeft: "-5px",
						}}
					>
						<img
							src="/assets/icons/timeline/delivered.svg"
							style={{ maxWidth: 20, zIndex: 2 }}
						/>
					</TimelineDot>
				) : (
					<TimelineDot
						sx={{
							backgroundColor: "primary.light",
							marginTop: 3.9,
						}}
					/>
				)}

				{isLast ? null : (
					<TimelineConnector
						sx={{
							height: 70,
							position: "absolute",
							marginTop: "43px",
							bgcolor: "#ACACAC",
							marginLeft: "-2px",
						}}
					/>
				)}
			</TimelineSeparator>

			<TimelineContent>
				<Typography
					sx={{
						fontFamily: typography.fontFamilyRegular,
						width: { sm: 100, lg: 210 },
						fontSize: { lg: 14, md: 12, sm: 10 },
					}}
				>
					{status} ({mapped_category})
				</Typography>
				{isLast ? null : (
					<Typography
						sx={{
							lineHeight: 22 / 14,
							fontFamily: typography.fontFamilyRegular,
							fontSize: { lg: 14, md: 12, sm: 10 },
						}}
					>
						{location.city ? `${location.city}, ` : null}
						{location.state ? `${location.state} ` : null}
						&nbsp;&nbsp;
						{location.postal_code ?? location.postal_code}
					</Typography>
				)}
			</TimelineContent>
		</TimelineItem>
	);
}

OrderItem.propTypes = {
	isLast: PropTypes.bool,
	isFirst: PropTypes.bool,
	item: PropTypes.shape({
		date: PropTypes.number,
		location: PropTypes.object,
		status: PropTypes.string,
	}),
};

function TrackingTableRowDetails({ inputData, onExpandhandler, showLoader }) {
	console.log("inputData", inputData);

	const StyledDetatilsRoot = styled(Box)(() => ({
		display: "flex",
		alignItems: "flex-start",
		flexDirection: "column",
		justifyContent: "left",
		minHeight: 300,
		padding: "0 !important",
		width: "max-content",
		gap: 13,
		fontSize: { sm: 14, lg: 16 },
		fontFamily: typography.fontFamilyRegular,
	}));
	const StyledTableRow = styled(TableRow)(() => ({}));
	const StyledTableCell = styled(TableCell)(() => ({
		color: "#000",
		border: "none",
		padding: 0,
		verticalAlign: "top",
		fontSize: { sm: 14, lg: 16 },
		fontFamily: typography.fontFamilyRegular,
	}));
	const StyledHeadTableCell = styled(TableCell)(() => ({
		color: "#000",
		background: "none",
		borderBottom: "1px solid #ACACAC",
		paddingLeft: 0,
		paddingBottom: 2,
		paddingTop: 3,
		paddingRight: 0,
		fontSize: 20,
		fontSize: { sm: 14, lg: 16 },
		fontFamily: typography.fontFamilyRegular,
	}));
	const StyledValueTableCell = styled(TableCell)(() => ({
		color: "#000",
		border: "none",
		paddingLeft: 0,
		paddingBottom: 3,
		paddingTop: 3,
		paddingRight: 0,
		fontWeight: 600,
		verticalAlign: "top",
		fontSize: { sm: 14, lg: 16 },
		// fontFamily: typography.fontFamilyRegular,
	}));
	TableRow;
	const currentDateUnix = moment(new Date()).format("X");

	return (
		<ModalRowDetails
			inputData={inputData}
			onExpandhandler={onExpandhandler}
			showLoader={showLoader}
		>
			<StyledDetatilsRoot>
				{/* Order details */}
				<Table
					sx={{ width: { lg: 500, md: 440, sm: 290 } }}
					aria-label="Order Details table"
				>
					<TableHead>
						<TableRow>
							<StyledHeadTableCell colSpan={3}>
								<Stack
									direction="row"
									spacing={1}
									alignItems="center"
								>
									<Typography variant="subtitle3">
										Order Details =
									</Typography>
									{/* <Iconify sx={{ pt: { sm: 0.4 } }} icon="ic:outline-calendar-month" /> */}
									<SvgColor
										src="/assets/icons/navbar/tracking.svg"
										color="common.black"
										sx={{ width: 20, height: 20, px: 3 }}
									/>
									<Typography variant="subtitle3">
										{inputData?.latest_status?.category}
									</Typography>
								</Stack>
							</StyledHeadTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<StyledTableRow>
							<StyledTableCell
								sx={{
									paddingTop: 1,
									width: { sm: 122, md: 165, lg: 180 },
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
								align="left"
							>
								Tracking Number
							</StyledTableCell>
							<StyledTableCell
								sx={{
									width: 15,
									paddingTop: 1,
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
								align="left"
							>
								:
							</StyledTableCell>
							<StyledValueTableCell
								sx={{
									color: "primary.light",
									paddingTop: 1,
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilySemiBold,
								}}
								align="left"
							>
								{inputData.tracking_number}
							</StyledValueTableCell>
						</StyledTableRow>
						<StyledTableRow>
							<StyledTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
							>
								Carrier
							</StyledTableCell>
							<StyledTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
							>
								:
							</StyledTableCell>
							<StyledValueTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilySemiBold,
								}}
							>
								{inputData?.carrier ? inputData?.carrier : "--"}
							</StyledValueTableCell>
						</StyledTableRow>
						<StyledTableRow>
							<StyledTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
							>
								Order Number
							</StyledTableCell>
							<StyledTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
							>
								:
							</StyledTableCell>
							<StyledValueTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilySemiBold,
								}}
							>
								{inputData?.order_info?.map((item, i) =>
									inputData.order_info.length === i + 1
										? item.order_details.increment_id
										: `${item.order_details.increment_id}, `
								)}
							</StyledValueTableCell>
						</StyledTableRow>
						<StyledTableRow>
							<StyledTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
							>
								Customers Name
							</StyledTableCell>
							<StyledTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
							>
								:
							</StyledTableCell>
							<StyledValueTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilySemiBold,
								}}
							>
								{inputData.customer_name}
							</StyledValueTableCell>
						</StyledTableRow>
						<StyledTableRow>
							<StyledTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
							>
								Address
							</StyledTableCell>
							<StyledTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
							>
								:
							</StyledTableCell>
							<StyledValueTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilySemiBold,
								}}
							>
								{inputData.shipping_city}&nbsp;&nbsp;&nbsp;
								{inputData.shipping_state}&nbsp;&nbsp;&nbsp;
								{inputData.shipping_postal_code}
							</StyledValueTableCell>
						</StyledTableRow>
					</TableBody>
				</Table>
				{/* Actual time */}
				<Table
					sx={{ width: { lg: 500, md: 440, sm: 290 } }}
					aria-label="Actual Time table"
				>
					<TableHead>
						<TableRow>
							<StyledHeadTableCell colSpan={3}>
								<Typography variant="subtitle3">
									Actual Time in Transit
								</Typography>
							</StyledHeadTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<StyledTableRow>
							<StyledTableCell
								colSpan={3}
								sx={{
									paddingTop: 1,
									paddingBottom: 1,
									fontSize: { sm: 14, md: 18, lg: 22 },
									color:
										currentDateUnix <
										inputData.delivered_date
											? "status.alert"
											: "common.black",
									fontFamily: typography.fontFamilySemiBold,
								}}
								align="left"
							>
								{inputData.delivered_date
									? `${dateDiff(
											unixToDate(
												inputData.label_created_date
											),
											unixToDate(inputData.delivered_date)
									  )} Days`
									: `${dateDiff(
											unixToDate(
												inputData.label_created_date
											),
											unixToDate(currentDateUnix)
									  )} Days`}
							</StyledTableCell>
						</StyledTableRow>
						<StyledTableRow>
							<StyledTableCell
								sx={{
									width: { lg: 180, sm: 122, md: 165 },
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
								align="left"
							>
								Shipped On
							</StyledTableCell>
							<StyledTableCell
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
								align="left"
							>
								:
							</StyledTableCell>
							<StyledValueTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilySemiBold,
								}}
							>
								{unixToDate(inputData.label_created_date)}
							</StyledValueTableCell>
						</StyledTableRow>
						<StyledTableRow>
							<StyledTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
							>
								Delivered On
							</StyledTableCell>
							<StyledTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
							>
								:
							</StyledTableCell>
							<StyledValueTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilySemiBold,
								}}
							>
								{inputData?.delivered_date
									? unixToDate(inputData?.delivered_date)
									: "--"}
							</StyledValueTableCell>
						</StyledTableRow>
					</TableBody>
				</Table>
				{/* Estimated Time in Transit */}
				<Table
					sx={{ width: { lg: 500, md: 440, sm: 290 } }}
					aria-label="Estimated Time table"
				>
					<TableHead>
						<TableRow>
							<StyledHeadTableCell colSpan={3}>
								<Typography variant="subtitle3">
									Time in Transit
								</Typography>
							</StyledHeadTableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<StyledTableRow>
							<StyledTableCell
								colSpan={3}
								sx={{
									paddingTop: 1,
									paddingBottom: 1,
									fontSize: { sm: 14, md: 18, lg: 22 },
									fontWeight: 600,
									color: "common.black",
									fontFamily: typography.fontFamilySemiBold,
								}}
								align="left"
							>
								{inputData.estimated_delivery_date &&
									`${dateDiff(
										unixToDate(
											inputData.label_created_date
										),
										unixToDate(
											inputData.estimated_delivery_date
										)
									)} Days`}
							</StyledTableCell>
						</StyledTableRow>
						<StyledTableRow>
							<StyledTableCell
								sx={{
									width: { lg: 180, sm: 122, md: 165 },
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
								align="left"
							>
								Shipped On
							</StyledTableCell>
							<StyledTableCell
								sx={{
									width: 15,
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
								align="left"
							>
								:
							</StyledTableCell>
							<StyledValueTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilySemiBold,
								}}
							>
								{unixToDate(inputData.label_created_date)}
							</StyledValueTableCell>
						</StyledTableRow>
						<StyledTableRow>
							<StyledTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
							>
								Estimated Delivery
							</StyledTableCell>
							<StyledTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilyRegular,
								}}
							>
								:
							</StyledTableCell>
							<StyledValueTableCell
								align="left"
								sx={{
									fontSize: { sm: 10, md: 14, lg: 18 },
									fontFamily: typography.fontFamilySemiBold,
								}}
							>
								{inputData.estimated_delivery_date_new
									? unixToDate(
											inputData.estimated_delivery_date_new
									  )
									: inputData.estimated_delivery_date
									? unixToDate(
											inputData.estimated_delivery_date
									  )
									: "--"}
							</StyledValueTableCell>
						</StyledTableRow>
						{inputData.estimated_delivery_date_new && (
							<StyledTableRow>
								<StyledTableCell
									align="left"
									sx={{
										fontSize: { sm: 10, md: 14, lg: 18 },
										fontFamily:
											typography.fontFamilyRegular,
									}}
								>
									Original Est. Delivery
								</StyledTableCell>
								<StyledTableCell
									align="left"
									sx={{
										fontSize: { sm: 10, md: 14, lg: 18 },
										fontFamily:
											typography.fontFamilyRegular,
									}}
								>
									:
								</StyledTableCell>
								<StyledValueTableCell
									align="left"
									sx={{
										fontSize: { sm: 10, md: 14, lg: 18 },
										fontFamily:
											typography.fontFamilySemiBold,
									}}
								>
									{inputData.estimated_delivery_date
										? unixToDate(
												inputData.estimated_delivery_date
										  )
										: "--"}
								</StyledValueTableCell>
							</StyledTableRow>
						)}
					</TableBody>
				</Table>
			</StyledDetatilsRoot>
			<StyledDetatilsRoot>
				<Box sx={{ paddingLeft: 2 }}>
					<Typography variant="subtitle3">
						Tracking Details
					</Typography>
					<Divider
						sx={{
							borderColor: "#ACACAC",
							marginTop: 1,
							width: 219,
						}}
					/>
				</Box>
				<Timeline
					sx={{
						paddingLeft: 0,
						[`& .${timelineOppositeContentClasses.root}`]: {
							flex: 0.2,
						},
					}}
				>
					{inputData?.events?.map((item, index) => (
						<OrderItem
							key={item.date}
							item={item}
							isLast={index === inputData.events.length - 1}
							isFirst={index === 0}
						/>
					))}
				</Timeline>
			</StyledDetatilsRoot>
		</ModalRowDetails>
	);
}

TrackingTableRowDetails.propTypes = {
	intputData: PropTypes.object,
	onExpandhandler: PropTypes.func,
};

export default TrackingTableRowDetails;
