import {
	Stack,
	Box,
	Modal,
	TextField,
	Button,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	IconButton,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { createAdminUser, createUser } from "src/features/usersPage/usersApi";
import { useAppSelector } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";

import typography from "src/theme/typography";
import palette from "src/theme/palette";
import { useSelector } from "react-redux";
import CircularLoader from "src/loader/circular/CircularLoader";

const styleInput = {
	input: { color: palette.common.black },
	"& .MuiOutlinedInput-root": {
		"&:hover fieldset": {
			borderColor: "primary.dark",
		},
		"&.Mui-focused fieldset": {
			borderColor: "primary.dark",
		},
	},
};

const popUpModalStyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "80%", sm: "auto", lg: "auto", md: "auto" },
	borderRadius: "20px",
	background: "#FFF",
	overflow: "auto",
	overflow: "auto-y",
	overflowX: "hidden",
	outline: "none",
};

const AddUserModal = ({
	openModal,
	setOpenModal,
	userType,
	setIsRefresh,
	isCMSUsers,
}) => {
	const [role, setRole] = useState("");
	const [statusMessage, setStatusMessage] = useState("");
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const userInfo = useAppSelector(getUserInfo);
	const { tenant_id, tenant_name } = useSelector((state) => state.tenants);
	const [successfulAddUser, setSuccessfulAddUser] = useState(false);
	const [invalidEmailError, setInvalidEmailError] = useState(false);

	const {
		register,
		control,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
		setIsLoading(true);

		if (isCMSUsers) data.role = "administrator";

		let response;
		try {
			if (userType === "cms") {
				const headers = {
					action: "createAdminUser",
					user_id: userInfo.user_id,
				};
				await createUser(headers, data, userInfo);
				setIsError(false);
				setSuccessfulAddUser(true);
				setIsLoading(false);
			} else {
				const headers = {
					tenant_id: tenant_id,
					user_id: userInfo.user_id,
					tenant_name: tenant_name,
					action: "createTenant",
				};
				response = await createUser(headers, data, userInfo);
				setIsError(false);
				setSuccessfulAddUser(true);
				setIsLoading(false);
			}
		} catch (err) {
			setIsError(true);
			setIsLoading(false);
			setStatusMessage(err.message);
		}
		// if (response)
	};

	const handleSelectChange = (event) => {
		setRole(event.target.value);
	};

	const handleCloseModal = () => {
		setIsRefresh(true);
		setOpenModal(false);
	};

	const validateEmail = (email) => {
		return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email);
	};

	useEffect(() => {
		if (!errors?.user_name?.message) setInvalidEmailError(true);
		else setInvalidEmailError(false);
	}, [validateEmail]);

	return isLoading ? (
		<CircularLoader />
	) : (
		<Modal open={openModal} onClose={handleCloseModal}>
			{successfulAddUser ? (
				<Box
					sx={{
						...popUpModalStyle,
						paddingY: 2,
						paddingX: 6,
					}}
				>
					<Stack spacing={3} alignItems="center">
						<IconButton
							aria-label="close"
							sx={{
								position: "fixed",
								top: { sm: 15, lg: 17 },
								right: { lg: 17, md: 30, sm: 10 },
								zIndex: 2,
							}}
							onClick={handleCloseModal}
						>
							<img
								width="34px"
								src="/assets/icons/table/close.svg"
								alt="X"
							/>
						</IconButton>
						<Box
							sx={{
								marginTop: "10px !important",
							}}
						>
							<Box
								style={{
									fontFamily: typography.fontFamilySemiBold,
								}}
								sx={{
									fontSize: {
										lg: 30,
										md: 27,
										xs: 27,
										sm: 27,
									},
									textAlign: "center",
									color: "#000",
								}}
							>
								User created successfully
							</Box>
							<Box
								sx={{
									fontSize: {
										lg: 16,
										md: 16,
										xs: 14,
										sm: 14,
									},
									textAlign: "center",
									color: "#000",
									marginTop: "10px !important",
								}}
							>
								An email has been sent to the user with their
								credentials.
							</Box>
						</Box>

						<Button
							size="large"
							type="submit"
							variant="contained"
							sx={{
								// marginTop: 0,
								boxShadow: "none",
								backgroundColor: palette.secondary.main,
								width: {
									lg: 300,
									md: 300,
									xs: "100%",
								},
							}}
							onClick={() => {
								setSuccessfulAddUser(false);
								handleCloseModal();
							}}
						>
							Continue
						</Button>
					</Stack>
				</Box>
			) : (
				<Box
					sx={{
						...popUpModalStyle,
					}}
				>
					<Stack
						gap={5}
						direction="column"
						sx={{
							color: "common.black",
							paddingY: 2,
							paddingX: 4,
							fontFamily: typography.fontFamilyRegular,
							backgroundColor: "white",
						}}
					>
						<IconButton
							aria-label="close"
							sx={{
								position: "fixed",
								top: { xs: 13, md: 20 },
								right: { md: 22, xs: 10 },
								zIndex: 2,
							}}
							onClick={handleCloseModal}
						>
							<img
								width="30px"
								src="/assets/icons/table/close.svg"
								alt="X"
							/>
						</IconButton>
						<FormControl
							component="form"
							className="form"
							onSubmit={handleSubmit(onSubmit)}
						>
							<Stack spacing={2} alignItems="center">
								<Box
									style={{
										fontFamily:
											typography.fontFamilySemiBold,
									}}
									sx={{
										fontSize: {
											lg: 30,
											md: 27,
											xs: 27,
											sm: 27,
										},
										textAlign: "center",
									}}
								>
									Add User
								</Box>
								<Box
									sx={{
										fontSize: {
											lg: 16,
											md: 16,
											xs: 14,
											sm: 14,
										},
										textAlign: "center",
									}}
								>
									To grant access to a new user,<br></br>{" "}
									please provide the required information
									below.
								</Box>
							</Stack>

							<Stack spacing={2}>
								<Stack direction={"row"} spacing={2}>
									<TextField
										// className="textField"
										label="First name"
										name="first_name"
										{...register("first_name", {
											required: "First Name is required.",
											validate: (value) =>
												!/[^A-Za-z0-9 _]+/.test(value),
										})}
										error={!!errors.first_name}
										helperText={
											errors.first_name &&
											"Valid first name is required."
										}
										sx={styleInput}
									/>
									<TextField
										label="Last name"
										name="last_name"
										{...register("last_name", {
											required: "Last Name is required.",
											validate: (value) =>
												!/[^A-Za-z0-9 _]+/.test(value),
										})}
										error={!!errors.last_name}
										helperText={
											errors.last_name &&
											"Valid last name is required."
										}
										sx={styleInput}
									/>
								</Stack>
								{!isCMSUsers && (
									<FormControl fullWidth sx={styleInput}>
										<InputLabel id="select-label">
											Role
										</InputLabel>
										<Select
											labelId="select-label"
											id="select"
											value={role}
											label="Role"
											name="role"
											{...register("role", {
												required: "Role is required.",
											})}
											error={!!errors.role}
											helperText={
												errors.role &&
												errors.role.message
											}
											sx={{
												color: "common.black",
												...styleInput,
											}}
											onChange={handleSelectChange}
										>
											<MenuItem
												value={"basic"}
												sx={{ color: "common.black" }}
											>
												Basic
											</MenuItem>
											<MenuItem
												value={"administrator"}
												sx={{ color: "common.black" }}
											>
												Administrator
											</MenuItem>
										</Select>
									</FormControl>
								)}

								<TextField
									label="Email"
									name="user_name"
									{...register("user_name", {
										required: "Email address is required.",
										validate: (email) =>
											validateEmail(email),
									})}
									error={!!errors.user_name || isError}
									helperText={
										errors?.user_name
											? invalidEmailError
												? "Invalid email address."
												: errors.user_name.message
											: statusMessage
									}
									sx={styleInput}
									inputProps={{ maxLength: 40 }}
								/>
							</Stack>

							<Stack
								spacing={2}
								direction={"row"}
								dis
								alignItems="center"
							>
								<Button
									fullWidth
									variant="contained"
									disableElevation
									sx={{
										boxShadow: "none",
										backgroundColor: "grey.600",
										paddingY: 1.3,
										fontSize: {
											lg: 15,
											md: 15,
											xs: 13,
											sm: 13,
										},
										"&:hover": {
											backgroundColor:
												"primary.lightIcon",
										},
									}}
									style={{
										fontFamily:
											typography.fontFamilySemiBold,
									}}
									onClick={() => {
										setOpenModal(false);
									}}
								>
									Cancel
								</Button>
								<Button
									fullWidth
									variant="contained"
									disableElevation
									type="submit"
									sx={{
										boxShadow: "none",
										backgroundColor: palette.secondary.main,
										paddingY: 1.3,
										fontSize: {
											lg: 15,
											md: 15,
											xs: 13,
											sm: 13,
										},
									}}
									style={{
										fontFamily:
											typography.fontFamilySemiBold,
									}}
								>
									Add User
								</Button>
							</Stack>
						</FormControl>
					</Stack>
				</Box>
			)}
		</Modal>
	);
};

export default AddUserModal;
