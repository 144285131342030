import axios from "axios";
import { encConfig } from "./config";
import { decryptData, encryptData } from "./chiper";

const extractData = (result) => {
	if (encConfig.encryption.decrypt) {
		if (result?.data) return decryptData(result?.data);
	}
	return result;
};

export default async function putData(url, headers, data, token = null) {
	const randomInt = Math.floor(1e7 + Math.random() * 9e7);
	const config = {
		headers: {
			...headers,
			"Content-Type": "application/json",
			Apikey: process.env.REACT_APP_BACKEND_API_KEY,
			request_id: randomInt,
			"Access-Control-Allow-Origin": "*",
		},
	};

	if (token !== null) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	let result = {};
	try {
		const info = JSON.stringify(
			encConfig.encryption.encrypt ? { data: encryptData(data) } : data
		);
		return (result = await axios.put(url, info, config));
	} catch (error) {
		let statusMsg = JSON.parse(extractData(error?.response));
		if ([401, 403].includes(parseInt(statusMsg.statusCode))) {
			deleteToken();
			return;
		}
		throw new Error(statusMsg.statusMessage || "Something went wrong!");
	}
	return result;
}
