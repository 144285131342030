import { Helmet } from "react-helmet-async";
import { Button, Typography, Container, Box, Grid } from "@mui/material";
import { useState } from "react";
import { decryptResponse, encryptResponse } from "src/features/user/userApi";
import AccountPopover from "src/layouts/dashboard/header/AccountPopover";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { materialOceanic } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
	decrementAwaitingApiCounter,
	incrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { useAppDispatch } from "src/hooks/hooks";

export default function Utility() {
	const [response, setResponse] = useState("");
	const [result, setResult] = useState("");
	const [error, setError] = useState(false);
	const [copy, setCopy] = useState(false);
	const dispatch = useAppDispatch();

	const handleSubmit = async () => {
		dispatch(incrementAwaitingApiCounter());
		await decryptResponse(response)
			.then((res) => {
				if (res.data) {
					if (typeof res.data === "string") setResult(res.data);
					if (typeof JSON.parse(res.data) === "object")
						setResult(JSON.stringify(JSON.parse(res.data)));
				}
				setResult(JSON.stringify(JSON.parse(res)));
			})
			.catch((e) => {
				dispatch(decrementAwaitingApiCounter());
			});
		dispatch(decrementAwaitingApiCounter());
	};
	const handleEncrypt = async () => {
		dispatch(incrementAwaitingApiCounter());
		setError(false);
		const res = await encryptResponse(response).catch(() => {
			setError(true);
			dispatch(decrementAwaitingApiCounter());
		});
		dispatch(decrementAwaitingApiCounter());
		setResult(JSON.stringify(res));
	};

	const handleCopy = () => {
		navigator.clipboard.writeText(result);
		setCopy(true);
		setTimeout(() => {
			setCopy(false);
		}, 2000);
	};

	return (
		<>
			<Helmet>
				<title> Utility | BeyondCarts CMS </title>
			</Helmet>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					borderBottom: "1px solid #c4c4c4",
					paddingBottom: 2,
					marginTop: "20px",
				}}
			>
				<Typography variant="h4" sx={{ color: "#000" }}>
					Encryption / Decryption
				</Typography>
				<AccountPopover />
			</Box>
			<Grid container spacing={2} marginTop={1}>
				<Grid item xs={12} md={6}>
					<Typography sx={{ color: "#000", marginBottom: "10px" }}>
						Input:
					</Typography>
					<textarea
						placeholder="CFTRD67YUCFTXYRD7568R7YCTRD756TYU..."
						required
						value={response}
						onChange={(e) => [
							setResponse(e.target.value),
							setError(false),
						]}
						style={{
							width: "100%",
							minHeight: "150px",
							resize: "none",
							padding: "10px",
							borderRadius: "6px",
						}}
					/>
					<Box sx={{ position: "relative" }}>
						{error && (
							<p
								style={{
									color: "red",
									position: "absolute",
									top: "-5px",
								}}
							>
								Invalid Input
							</p>
						)}
					</Box>
					<Box sx={{ margin: "20px 0" }}>
						<Button
							disabled={!(response.length > 0)}
							size="large"
							variant="contained"
							onClick={handleSubmit}
							sx={{ marginRight: "20px" }}
						>
							Decrypt
						</Button>
						<Button
							disabled={!(response.length > 0)}
							size="large"
							variant="contained"
							onClick={handleEncrypt}
						>
							Encrypt
						</Button>
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography sx={{ color: "#000", marginBottom: "10px" }}>
						Output:
					</Typography>
					<SyntaxHighlighter
						language="json"
						customStyle={{
							minHeight: "150px",
							borderRadius: "8px",
							margin: 0,
						}}
						wrapLongLines={true}
						style={materialOceanic}
					>
						{result}
					</SyntaxHighlighter>
					<Button
						disabled={!result}
						size="large"
						variant="contained"
						onClick={handleCopy}
						sx={{ margin: "20px 0" }}
					>
						{copy ? (
							<div style={{ display: "flex", gap: 3 }}>
								Copied{" "}
								<img
									src={"/assets/icons/accept.png"}
									style={{ height: "20px" }}
								/>
							</div>
						) : (
							<div style={{ display: "flex" }}>
								Copy&nbsp;
								<ContentCopyIcon />
							</div>
						)}
					</Button>
				</Grid>
			</Grid>
		</>
	);
}
