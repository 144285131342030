import PropTypes from "prop-types";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { titleCase, removeTitleCase } from "src/utils/core";
import { useState } from "react";
import typography from "src/theme/typography";
import { Button } from "@mui/material";

export default function MultipleSelect({
	columnsDetails,
	selectedColumns,
	handleColumnSubmit,
	isUsersPage = false,
	isCMSUsers = false,
}) {
	const filteredColumnDetails =
		isUsersPage || isCMSUsers
			? columnsDetails.filter((item) => item !== "_id")
			: columnsDetails;

	const [tempSelectedColumns, setTempSelectedColumns] = useState(
		selectedColumns.map((item) => {
			return titleCase(item);
		})
	);

	const handleColumnChange = (event) => {
		const {
			target: { value },
		} = event;

		const selectedColumnTitles =
			typeof value === "string"
				? value.split(",")
				: value.map((item) => titleCase(item));
		const sortedColumns = selectedColumnTitles.sort((a, b) => {
			return b.localeCompare(a);
		});
		setTempSelectedColumns(sortedColumns);
	};
	return (
		<div style={{ display: "flex", gap: "5px" }}>
			<FormControl
				sx={{
					// height: { lg: 40, sm: 33 },
					bgcolor: "common.white",
					borderRadius: 1,
					"& .MuiInputBase-formControl": {
						paddingLeft: 0,
					},
					"& .MuiOutlinedInput-root": {
						"&:hover fieldset": {
							borderColor: `${
								columnsDetails.length > 0 ? "primary.dark" : ""
							}`,
						},
						"&.Mui-focused fieldset": {
							borderColor: "primary.dark",
						},
					},
				}}
				size="small"
			>
				<InputLabel
					sx={{
						fontSize: { sm: 14, xs: 14, md: 16, lg: 16 },
						"&.MuiInputLabel-shrink": {
							display: "none",
						},
					}}
					id="demo-multiple-checkbox-label"
				>
					Columns
				</InputLabel>
				<Select
					labelId="demo-multiple-checkbox-label"
					id="demo-multiple-checkbox"
					multiple
					disabled={columnsDetails.length > 0 ? false : true}
					value={tempSelectedColumns.map((item) => titleCase(item))}
					onChange={handleColumnChange}
					renderValue={(selected) => selected.join(", ")}
					inputProps={{ "aria-label": "Without label" }}
					sx={{
						backgroundColor: `${
							columnsDetails.length > 0 ? "" : "#ddd"
						}`,
						color: "common.black",
						paddingLeft: 2,
						height: { xs: 36, lg: 40 },
						width: { xs: 100, md: 150 },
					}}
				>
					{filteredColumnDetails.map((item) => (
						<MenuItem
							sx={{
								color: "common.black",
								fontFamily: typography.fontFamilyRegular,
							}}
							key={item}
							value={titleCase(item)}
						>
							<Checkbox
								checked={
									tempSelectedColumns.indexOf(
										titleCase(item)
									) > -1
								}
							/>
							<ListItemText
								primary={titleCase(item).replace("Per", "%")}
							/>
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<Button
				type="button"
				variant="contained"
				onClick={() => handleColumnSubmit(tempSelectedColumns)}
				sx={{
					height: { xs: 36, lg: 40 },
					width: { xs: 90, md: 100 },
					backgroundColor: "secondary.main",
					boxShadow: "none",
				}}
			>
				Apply
			</Button>
		</div>
	);
}

MultipleSelect.propTypes = {
	columnsDetails: PropTypes.array,
	selectedColumns: PropTypes.array,
	handleColumnSubmit: PropTypes.func,
};
