import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
// @mui
import {
	Link,
	Stack,
	IconButton,
	InputAdornment,
	TextField,
	Checkbox,
	FormControlLabel,
	Box,
	Button,
	Typography,
	Modal,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
// components
import {
	getUserError,
	login,
	validateLogin,
} from "src/features/user/userState";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
	retrieveCookies,
	storeCredentialsInCookies,
} from "src/utils/cookiesTracker";
import Iconify from "src/components/iconify";
import typography from "src/theme/typography";
import palette from "src/theme/palette";
import CircularLoader from "src/loader/circular/CircularLoader";
// ----------------------------------------------------------------------
const styleLoginForm = {
	// color: "black",
	// width: { lg: 560, md: 517 },
	// height: { lg: 607, md: 656 },
	// marginRight: { md: 5 },
};

export default function LoginForm({ setSignUp }) {
	const StyledContent = styled("div")(({ theme }) => ({
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "flex-start",
		flexDirection: "column",
		color: theme.palette.common.black,
		// padding: theme.spacing(12, 0),
	}));

	const [showPassword, setShowPassword] = useState(false);
	const [displayError, setDisplayError] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const magentoURL = process.env.REACT_APP_MAGENTO_URL;

	const dispatch = useAppDispatch();
	const loginError = useAppSelector(getUserError);
	const navigate = useNavigate();

	const {
		handleSubmit,
		register,
		setError,
		formState: { errors },
		setValue,
		watch,
	} = useForm();

	const StyledTextField = styled(TextField)(({ theme }) => ({
		"& > div > input": {
			color: theme.palette.primary.main,
		},
	}));

	const rememberMe = watch("remember", false);

	useEffect(() => {
		retrieveCookies(setValue);
		// console.log(setValue);
	}, [setValue]);

	const onSubmit = async (data) => {
		setIsLoading(true);
		if (Object.keys(errors).length === 0) {
			const response = await validateLogin(data);

			if (response?.data?.password === "newPasswordRequired") {
				setIsLoading(false);
				// console.log("entra");

				navigate("/reset-password/logged", {
					state: {
						email: data?.email,
						generatedPassword: data?.password,
					},
				});
				return;
			}

			dispatch(login(data)).then((action) => {
				const { email, password } = action.meta.arg;
				setIsLoading(false);
				setDisplayError(true);
				const rememberMe = data.remember;
				if (rememberMe) {
					storeCredentialsInCookies(email, password);
				}
			});
		} else {
			setIsLoading(false);
		}
	};

	return (
		<>
			{isLoading && <CircularLoader />}
			<Box
				component="form"
				onSubmit={handleSubmit(onSubmit)}
				sx={{
					width: "100%",
				}}
			>
				<Stack spacing={3} style={styleLoginForm}>
					<TextField
						fullWidth
						sx={{
							"& > div > input": { color: "common.black" },
							// width: { lg: 515 },
							"& .MuiOutlinedInput-root": {
								"&:hover fieldset": {
									borderColor: "primary.dark",
								},
								"&.Mui-focused fieldset": {
									borderColor: "primary.dark",
								},
							},
						}}
						label="Email address"
						name="email"
						autoComplete="email"
						// autoFocus
						{...register("email", {
							required: "Email address is required.",
							pattern: {
								value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
								message: "Invalid email address.",
							},
						})}
						error={errors.email || errors.invalidUser}
						helperText={
							errors.email
								? errors.email.message
								: errors.invalidUser?.message
						}
						style={{ color: "black" }}
						onFocus={() => setDisplayError(false)}
					/>

					<TextField
						name="password"
						fullWidth
						sx={{
							"& > div > input": { color: "common.black" },
							// width: { lg: 515 },
							"& .MuiOutlinedInput-root": {
								"&:hover fieldset": {
									borderColor: "primary.dark",
								},
								"&.Mui-focused fieldset": {
									borderColor: "primary.dark",
								},
							},
						}}
						label="Password"
						type={showPassword ? "text" : "password"}
						{...register("password", {
							required: "Password is required.",
						})}
						error={errors.password}
						helperText={errors.password?.message}
						style={{ color: "black" }}
						onFocus={() => setDisplayError(false)}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() =>
											setShowPassword(!showPassword)
										}
										edge="end"
									>
										<Iconify
											icon={
												showPassword
													? "eva:eye-fill"
													: "eva:eye-off-fill"
											}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Stack>

				<Stack
					direction={{ xs: "row" }}
					alignItems="center"
					justifyContent="space-between"
					sx={{ my: 2 }}
				>
					<FormControlLabel
						control={
							<Checkbox
								{...register("remember")}
								sx={{ color: "#000000" }}
							/>
						}
						label={
							<Typography
								varient="subtitle1"
								sx={{
									color: "common.black",
									fontSize: "18px !important",
								}}
							>
								Remember Me
							</Typography>
						}
						sx={{
							color: "common.black",
							fontSize: "18px !important",
							fontWeight: 500,
							"& .Mui-checked": {
								bgColor: "black",
							},
						}}
					/>
					<Box
						sx={{
							borderBottom: 1,
							borderColor: "primary.main",
							alignItem: "center",
							height: 22.8,
						}}
					>
						<Link
							href="/reset-password/step-1"
							variant="h6"
							underline="none"
							sx={{ fontWeight: 500, cursor: "pointer" }}
							// onClick={() => setOpenModal(true)}
						>
							Forgot password?
						</Link>
					</Box>
				</Stack>

				<LoadingButton
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					sx={{
						fontSize: 18,
						boxShadow: "none",
						backgroundColor: palette.secondary.main,
						marginTop: { xs: 2, sm: 2, md: 0, lg: 0 },
					}}
				>
					Login
				</LoadingButton>
				{loginError?.loginError && (
					<StyledContent
						style={{
							alignItems: "center",
							marginTop: "20px",
							color: "red",
							minHeight: "25px",
						}}
					>
						<Typography variant="p">
							{loginError?.loginError &&
								displayError &&
								`${loginError.loginErrorMessage}`}
						</Typography>
					</StyledContent>
				)}
			</Box>
		</>
	);
}
