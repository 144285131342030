import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

const tenantSlice = createSlice({
    name: 'tenantSlice',
    initialState: {
        tenant_id: '',
        tenant_name: '',
        user_id: '',
        user_name: '',
        phone_number: '',
        is_demo_user: '',
    },
    reducers: {
        setTenant: (state, action) => {
            state.tenant_id = action.payload.tenantID;
            state.tenant_name = action.payload.tenantName;
            state.user_id = action.payload.userID;
            state.user_name = action.payload.email;
            state.phone_number = action.payload.phoneNo;
            state.is_demo_user = action.payload.is_demo_user;
        },
    },
});

export const { setTenant } = tenantSlice.actions;
export default tenantSlice.reducer;