import { Stack, TextField, InputAdornment, IconButton } from "@mui/material";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Iconify from "src/components/iconify";
import ResetPassword from "src/components/reset-password/ResetPassword";
import timer from "src/utils/timer";
import OtpForm from "./OtpForm";
import { resetPassword } from "src/features/user/userApi";
import { passwordValidator } from "src/utils/validators";
import CircularLoader from "src/loader/circular/CircularLoader";
import { ErrorMessagesSchema } from "src/utils/errorMessagesSchema";

const ResetPasswordStep2Page = () => {
	const {
		register,
		control,
		handleSubmit,
		clearErrors,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();
	const {
		state: { email },
	} = useLocation();

	const [isSubmited, setIsSubmited] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();
	const [errorMessage, setErrorMessage] = useState("");
	const [passwordError, setPasswordError] = useState("");

	const onSubmit = async (data) => {
		if (password !== confirmPassword) {
			setPasswordError(true);
			setIsSubmited(true);
			setErrorMessage(ErrorMessagesSchema.passwordValidation.dontMatch);
			return;
		}

		if (!passwordValidator(data?.new_password)) {
			setIsSubmited(true);
			setPasswordError(true);
			setErrorMessage(
				ErrorMessagesSchema.passwordValidation.requirements
			);
			return;
		}

		setIsLoading(true);

		const obj = {
			code: data?.otp,
			new_password: data?.new_password,
			email,
		};

		let response;

		try {
			response = await resetPassword(obj);
			setIsLoading(false);
			if (
				response?.data?.statusMessage?.startsWith(
					"Attempt limit exceeded"
				)
			) {
				setIsSubmited(true);
				setErrorMessage(
					ErrorMessagesSchema.passwordValidation.attemptLimit
				);
			}
			// password changed successfully
			else if (response?.data?.statusMessage === "Password Changed") {
				setErrorMessage("");
				setIsLoading(true);
				await timer(1000);
				navigate("/reset-password/step-3");
			}
			// password changed successfully
			else if (
				response.statusMessage === "Succesfully sent notification."
			) {
				setErrorMessage("");
				setIsLoading(true);
				await timer(1000);
				navigate("/reset-password/step-3");
			}
		} catch (error) {
			// TODO: it will be better if the backend sends different status code according to the error.
			setIsSubmited(true);
			setIsLoading(false);
			setErrorMessage(error.message);
		}
	};

	return (
		<>
			<Helmet>
				<title> Reset Password | BeyondCarts CMS </title>
			</Helmet>

			{isLoading && <CircularLoader />}

			<ResetPassword
				title={"Reset your password"}
				description={`Enter in the code that was sent to ${email}`}
				btnText={"Reset Password"}
				btnText2={"Back to log in"}
				handleSubmit={handleSubmit}
				setIsSubmited={setIsSubmited}
				onSubmit={onSubmit}
			>
				<OtpForm
					control={control}
					email={email}
					clearErrors={() => {}}
					isError={errorMessage.includes("Invalid")}
				/>
				<Stack spacing={2}>
					<TextField
						label="New Password"
						name="new_password"
						type={showPassword ? "text" : "password"}
						{...register("new_password", {
							required: "New Password is required.",
						})}
						error={!!errors.new_password}
						aria-invalid={!!errors.new_password}
						helperText={
							errors.new_password && errors.new_password.message
						}
						onChange={(e) => {
							clearErrors("new_password");
							setPassword(e.target.value);
						}}
						sx={{
							"& .MuiOutlinedInput-root": {
								"&:hover fieldset": {
									borderColor: "primary.dark",
								},
								"&.Mui-focused fieldset": {
									borderColor: "primary.dark",
								},
							},
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() =>
											setShowPassword(!showPassword)
										}
										edge="end"
									>
										<Iconify
											icon={
												showPassword
													? "eva:eye-fill"
													: "eva:eye-off-fill"
											}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>

					<TextField
						label="Confirm Password"
						name="confirm_password"
						type={showPassword ? "text" : "password"}
						{...register("confirm_password", {
							required:
								"To confirm your new password is required.",
						})}
						error={
							!!errors.confirm_password ||
							(passwordError && !errorMessage.includes("code"))
						}
						aria-invalid={!!errors.confirm_password}
						helperText={
							errors.confirm_password
								? errors.confirm_password.message
								: passwordError
								? !errorMessage.includes("code") && errorMessage
								: null
						}
						onChange={(e) => {
							setConfirmPassword(e.target.value);
							clearErrors("confirm_password");
						}}
						sx={{
							"& .MuiOutlinedInput-root": {
								"&:hover fieldset": {
									borderColor: "primary.dark",
								},
								"&.Mui-focused fieldset": {
									borderColor: "primary.dark",
								},
							},
						}}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() =>
											setShowPassword(!showPassword)
										}
										edge="end"
									>
										<Iconify
											icon={
												showPassword
													? "eva:eye-fill"
													: "eva:eye-off-fill"
											}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Stack>
			</ResetPassword>
		</>
	);
};

export default ResetPasswordStep2Page;
