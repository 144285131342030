import CryptoJS from 'crypto-js';
import { encConfig } from './config';

export function encryptData(data) {
  const { hash } = encConfig.encryption.cryptoJS;
  if (hash) {
    return CryptoJS.AES.encrypt(JSON.stringify(data), hash).toString();
  }
}

export function decryptData(data) {
  const { hash } = encConfig.encryption.cryptoJS;
  if (hash) {
    const bytes = CryptoJS.AES.decrypt(data.data, hash);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  }
}