import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@mui/material";
import { setSigninData } from "src/features/signin/signin.action";
import { getPlans } from "src/features/onborading/onboardingApi";
import palette from "src/theme/palette";
import "./plans.scss";
import { getUserInfo } from "src/features/user/userState";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
	decrementAwaitingApiCounter,
	incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const Plans = () => {
	const dispatch = useDispatch();
	const dispatchApp = useAppDispatch();
	const navigate = useNavigate();
	const userInfo = useAppSelector(getUserInfo);
	const signinData = useSelector((state) => state.signin.signinData);
	const { plansError, plan } = signinData || {};
	const selectedStyle = {
		backgroundColor: "#dee2e6",
		transform: "scale(1.05)",
	};
	const [plans, setPlans] = useState([]);
	const [planSelected, setPlanSelected] = useState();

	const handlePlanSelection = (plan) => {
		dispatch(
			setSigninData({
				...signinData,
				plan,
				plan_id: plan._id,
				plansError: false,
			})
		);
		setPlanSelected(plan);
	};

	useEffect(() => {
		dispatchApp(incrementAwaitingApiCounter());

		if (!signinData?.user_id) {
			navigate("/login");
			return;
		}

		getPlans(userInfo.accessToken)
			.then((r) => {
				const { data, statusCode } = r;

				if (statusCode === 200) setPlans(data);
				if (plan) setPlanSelected(plan);

				dispatchApp(decrementAwaitingApiCounter());
			})
			.catch(() => {
				dispatchApp(decrementAwaitingApiCounter());
			});
	}, []);

	return (
		<div className="plans-container">
			<Typography variant="h3" sx={{ textAlign: "center" }}>
				Pick the plan that best suits you!
			</Typography>
			<Typography
				sx={{
					color: palette.common.black,
					fontSize: 18,
					textAlign: "center",
				}}
			>
				You can change your plan at anytime if you aren’t satisfied.
			</Typography>

			<div className="plans-cards-container">
				{plans.map((p) => {
					return (
						p.display && (
							<div
								className="plans-card"
								style={
									p._id === planSelected?._id
										? selectedStyle
										: null
								}
								onClick={() => handlePlanSelection(p)}
							>
								<div>
									<h3 className="plans-card-title">
										{p.name}
									</h3>
									<h4 className="plans-card-title2">
										{p.sub_header}
									</h4>
								</div>

								<Typography
									sx={{
										color: palette.common.black,
										textAlign: "center",
									}}
								>
									{p.description}
								</Typography>

								<div
									style={{
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										columnGap: "5px",
									}}
								>
									<Typography
										variant="h3"
										sx={{
											color: palette.primary.main,
											fontSize: 18,
											textAlign: "center",
										}}
									>
										${p.price_per_order}
									</Typography>
									<Typography
										sx={{
											color: palette.common.black,
											fontSize: 18,
											textAlign: "center",
										}}
									>
										/order
									</Typography>
								</div>
							</div>
						)
					);
				})}
			</div>

			{plansError && (
				<Typography
					sx={{
						color: palette.error.main,
						textAlign: "center",
						fontSize: "18px",
					}}
				>
					You need to select 1 plan.
				</Typography>
			)}
		</div>
	);
};

export default Plans;
