import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "src/hooks/hooks";
import {
	Stack,
	Typography,
	Box,
	TextField,
	Button,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
	Switch,
	radioClasses,
} from "@mui/material";
import {
	getAllPayments,
	createPaymentMethod,
	updatePaymentById,
	deletePaymentById,
	updatePreferredCardById,
} from "src/features/billing/billingApi";
import {
	textStyle,
	styleInput,
	months,
	futureYears,
	capitalize,
	cc_format,
	showBrandLogo,
	creditCardType,
} from "./cardsUtils";
import { setSigninData } from "src/features/signin/signin.action";
import { getUserInfo } from "src/features/user/userState";

import CountrySelect from "src/sections/auth/signin/formsContainer/billing/countrySelect";
import typography from "src/theme/typography";
import palette from "src/theme/palette";

import "../billing.scss";
import {
	decrementAwaitingApiCounter,
	incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const CardsComponent = () => {
	const userInfo = useAppSelector(getUserInfo);
	const signinData = useSelector((state) => state.signin.signinData);
	const {
		cardNameError,
		creditCardError,
		creditCardMessage,
		cvvError,
		expirationError,
		billingAddrError,
		cityError,
		zipcodeError,
		stateError,
		cardName,
		creditCard,
		cvv,
		expirationMonth,
		isPreferred,
		expirationYear,
		billingAddr,
		city,
		zipcode,
		state,
		country,
	} = signinData || {};

	const modalRef = useRef();
	const deleteModalRef = useRef();
	const dispatch = useDispatch();

	const [errorMessage, setErrorMessage] = useState("");

	const [paymentData, setPaymentData] = useState([]);

	const [isModalActive, setIsModalActive] = useState(false);

	const [creditCardSelected, setCreditCardSelected] = useState();
	const [formatedCreditCard, setFormatedCreditCard] = useState("");
	const [creditLogo, setCreditLogo] = useState();
	const [cardTokenId, setCardTokenId] = useState("");

	const [isCreateSuccess, setIsCreateSuccess] = useState(false);
	const [isOnDelete, setIsOnDelete] = useState(false);
	const [isOnChangePref, setIsOnChangePref] = useState(false);
	const tenant = useSelector((state) => state.tenants);

	const handleCardChange = (e) => {
		const valueParsed = e.target.value.replaceAll(" ", "");
		const valueInt = parseInt(valueParsed) || "";
		const value = valueInt.toString();

		if (value.length > 16) return;

		setFormatedCreditCard(cc_format(value));
		creditCardType(value, setCreditLogo);
		dispatch(setSigninData({ ...signinData, creditCard: value }));
	};

	const handleCvvChange = (e) => {
		const value = e.target.value;
		if (value.length > 3) return;

		dispatch(setSigninData({ ...signinData, cvv: value }));
	};

	const handleCardEdit = (p) => {
		setIsModalActive(true);
		setCreditCardSelected(p);
		setFormatedCreditCard("**** **** **** " + p.card_no);
		dispatch(
			setSigninData({
				cardName: p.name,
				cvv: p.cvv,
				expirationMonth: p.exp_month,
				isPreferred: p.is_preferred,
				expirationYear: p.exp_year,
				billingAddr: p.billing.address,
				city: p.billing.city,
				zipcode: p.billing.zip_code,
				state: p.billing.state,
				country: p.billing.country,
			})
		);
		setCardTokenId(p.card_token_id);
	};

	const checkBillingData = () => {
		if (!creditCardSelected) {
			if (!cardName) {
				dispatch(setSigninData({ ...signinData, cardNameError: true }));
				return true;
			}

			if (
				!creditCard ||
				creditCard.length < 15 ||
				creditCard.length > 16
			) {
				dispatch(
					setSigninData({
						...signinData,
						creditCardError: true,
						creditCardMessage: "Credit Card is required.",
					})
				);
				return true;
			}

			if (!cvv || cvv.length < 3) {
				dispatch(setSigninData({ ...signinData, cvvError: true }));
				return true;
			}

			if (!expirationMonth) {
				dispatch(
					setSigninData({ ...signinData, expirationError: true })
				);
				return true;
			}

			if (!expirationYear) {
				dispatch(
					setSigninData({ ...signinData, expirationError: true })
				);
				return true;
			}

			if (!billingAddr) {
				dispatch(
					setSigninData({ ...signinData, billingAddrError: true })
				);
				return true;
			}

			if (!city) {
				dispatch(setSigninData({ ...signinData, cityError: true }));
				return true;
			}

			if (!zipcode) {
				dispatch(setSigninData({ ...signinData, zipcodeError: true }));
				return true;
			}

			if (!state) {
				dispatch(setSigninData({ ...signinData, stateError: true }));
				return true;
			}

			if (!country) {
				dispatch(setSigninData({ ...signinData, countryError: true }));
				return true;
			}

			const data = {
				name: cardName,
				card_no: creditCard,
				cvv: cvv,
				exp_month: expirationMonth,
				exp_year: expirationYear,
				is_preferred: false,
				billing: {
					address: billingAddr,
					city: city,
					zip_code: zipcode,
					state: state,
					country: country,
				},
			};

			dispatch(incrementAwaitingApiCounter());

			createPaymentMethod(
				data,
				userInfo,
				tenant.tenant_id,
				tenant.user_id,
				"create"
			)
				.then(() => {
					setIsCreateSuccess(true);
					dispatch(decrementAwaitingApiCounter());
				})
				.catch((e) => {
					if (e.message.includes("Your card number is incorrect")) {
						dispatch(
							setSigninData({
								...signinData,
								creditCardError: true,
								creditCardMessage:
									"Your card number is incorrect.",
							})
						);
					}
					if (e.message.includes("month is invalid")) {
						dispatch(
							setSigninData({
								...signinData,
								expirationMonthMessage:
									"Your card's expiration month is invalid.",
							})
						);
					}
					if (e.message.includes("declined")) {
						dispatch(
							setSigninData({
								...signinData,
								creditCardError: true,
								creditCardMessage: "Your card was declined.",
							})
						);
					}
					dispatch(decrementAwaitingApiCounter());
				});
		} else {
			if (!cardName) {
				dispatch(setSigninData({ ...signinData, cardNameError: true }));
				return true;
			}

			if (!expirationMonth) {
				dispatch(
					setSigninData({ ...signinData, expirationError: true })
				);
				return true;
			}

			if (!expirationYear) {
				dispatch(
					setSigninData({ ...signinData, expirationError: true })
				);
				return true;
			}

			if (!billingAddr) {
				dispatch(
					setSigninData({ ...signinData, billingAddrError: true })
				);
				return true;
			}

			if (!city) {
				dispatch(setSigninData({ ...signinData, cityError: true }));
				return true;
			}

			if (!zipcode) {
				dispatch(setSigninData({ ...signinData, zipcodeError: true }));
				return true;
			}

			if (!state) {
				dispatch(setSigninData({ ...signinData, stateError: true }));
				return true;
			}

			if (!country) {
				dispatch(setSigninData({ ...signinData, countryError: true }));
				return true;
			}

			const data = {
				name: cardName,
				cvv: cvv,
				exp_month: expirationMonth,
				is_preferred: creditCardSelected?.is_preferred || false,
				exp_year: expirationYear,
				billing: {
					address: billingAddr,
					city: city,
					zip_code: zipcode,
					state: state,
					country: country,
				},
				paymentId: cardTokenId,
			};

			dispatch(incrementAwaitingApiCounter());

			updatePaymentById(
				creditCardSelected._id,
				data,
				userInfo,
				tenant.tenant_id,
				tenant.user_id
			)
				.then(() => {
					setIsCreateSuccess(true);
					dispatch(decrementAwaitingApiCounter());
				})
				.catch((e) => {
					if (e.message === "Unauthorized access")
						setErrorMessage(
							"You are not authorized to change this payment information"
						);
					if (e.message.startsWith("Your card's expiration"))
						setErrorMessage(e.message);
					else {
						setErrorMessage(
							"There was an error updating this payment information"
						);
					}
					dispatch(decrementAwaitingApiCounter());
				});
		}
	};

	const onSuccess = () => {
		setIsCreateSuccess(false);
		handleCloseModal();
		getAllPayments(
			null,
			{},
			userInfo,
			tenant.tenant_id,
			tenant.user_id
		).then((r) => {
			const { data } = r;
			setPaymentData(data);
		});
	};

	const onDeleteCard = () => {
		if (paymentData.length === 1 || creditCardSelected?.is_preferred) {
			setIsOnDelete(false);
			return;
		}

		dispatch(incrementAwaitingApiCounter());

		deletePaymentById(
			creditCardSelected._id,
			userInfo,
			tenant.tenant_id,
			tenant.user_id
		)
			.then((r) => {
				const { statusCode } = r;

				if (statusCode === 200)
					getAllPayments(
						null,
						{},
						userInfo,
						tenant.tenant_id,
						tenant.user_id
					).then((r) => {
						const { data } = r;
						setPaymentData(data);
					});
				setIsOnDelete(false);
				dispatch(decrementAwaitingApiCounter());
			})
			.catch((e) => dispatch(decrementAwaitingApiCounter()));
	};

	const handleIsPreferredChange = () => {
		if (creditCardSelected?.is_preferred) {
			setIsOnChangePref(false);
			return;
		}

		dispatch(incrementAwaitingApiCounter());

		updatePreferredCardById(
			creditCardSelected._id,
			{ is_preferred: !creditCardSelected.is_preferred },
			userInfo,
			tenant.tenant_id,
			tenant.user_id
		)
			.then(() => {
				getAllPayments(
					null,
					{},
					userInfo,
					tenant.tenant_id,
					tenant.user_id
				).then((r) => {
					const { data } = r;
					setPaymentData(data);
				});
				setIsOnChangePref(false);
				dispatch(decrementAwaitingApiCounter());
				handleCloseModal();
			})
			.catch((e) => {
				dispatch(decrementAwaitingApiCounter());
			});
	};

	const handleCloseModal = () => {
		setIsModalActive(false);
		setCreditCardSelected(null);
		setFormatedCreditCard("");
		setCreditLogo(null);
		setErrorMessage("");
		resetValues();
	};

	const resetValues = () => {
		dispatch(
			setSigninData({
				cardName: "",
				creditCard: "",
				cvv: "",
				expiration: "",
				billingAddr: "",
				city: "",
				zipcode: "",
				country: "",
			})
		);
	};

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [isModalActive]);

	useEffect(() => {
		const handler = (e) => {
			if (isOnDelete) {
				if (!deleteModalRef.current) return;
				if (!deleteModalRef.current.contains(e.target)) {
					setIsOnDelete(false);
				}
			}
		};

		window.scrollTo(0, 0);

		getAllPayments(
			null,
			{},
			userInfo,
			tenant.tenant_id,
			tenant.user_id
		).then((r) => {
			const { data } = r;
			setPaymentData(data);
		});

		document.addEventListener("click", handler, true);

		return () => {
			document.removeEventListener("click", handler);
		};
	}, [isOnDelete]);

	useEffect(() => {
		resetValues();

		getAllPayments(
			null,
			{},
			userInfo,
			tenant.tenant_id,
			tenant.user_id
		).then((r) => {
			const { data } = r;
			setPaymentData(data);
		});
	}, []);

	return (
		<Box className="billing-invoices-payments-container">
			<h3 style={typography.h3}>Payment Options</h3>

			{paymentData.map((p, i) => {
				return (
					<Box className="billing-invoices-payment-container" key={i}>
						<Box className="billing-invoices-payment-card-name">
							<Typography sx={{ ...textStyle, paddingLeft: 0.7 }}>
								{p.name}
							</Typography>
							<Box>
								<Typography
									sx={{ cursor: "pointer", ...textStyle }}
									onClick={() => handleCardEdit(p)}
								>
									<img
										title="Edit Card"
										style={{
											cursor: "pointer",
											height: "26px",
											margin: "auto",
										}}
										src="/assets/edit.svg"
									/>
								</Typography>
							</Box>
						</Box>
						<Box className="billing-invoices-payment-card-info">
							<Stack
								flexDirection={"row"}
								width={"100%"}
								justifyContent={"space-between"}
							>
								<img
									style={{ height: "30px" }}
									src={showBrandLogo(p.card_type)}
								/>
								<img
									title="Delete Card"
									style={{
										cursor: "pointer",
										height: "26px",
									}}
									src="/assets/delete.svg"
									onClick={() => {
										setIsOnDelete(true);
										setCreditCardSelected(p);
									}}
								/>
							</Stack>
						</Box>
						<Box className="billing-invoices-payment-card-info">
							<Stack
								width={"100%"}
								flexDirection={"row"}
								justifyContent={"space-between"}
							>
								<Typography
									sx={{
										...textStyle,
										paddingLeft: 0.7,
									}}
								>
									{capitalize(p.card_type || "")} Card ending
									in {p.card_no}
								</Typography>
								{p.is_preferred && (
									<Stack
										flexDirection={"row"}
										alignItems={"center"}
										justifyContent={"flex-end"}
									>
										<Typography
											sx={{
												color: "#299D4A",
												fontFamily:
													typography.fontFamilySemiBold,
												paddingRight: 1,
											}}
										>
											Default Card
										</Typography>
										<img src="/assets/preferredLogo.svg" />
									</Stack>
								)}
							</Stack>
						</Box>
					</Box>
				);
			})}

			<Box className="billing-invoices-add-payments-container">
				<Box
					className="billing-invoices-add-payments"
					onClick={() => setIsModalActive(true)}
				>
					<Typography
						sx={{
							fontSize: "24px",
							fontWeight: "bold",
							textAlign: "center",
							color: "#000",
						}}
					>
						+
					</Typography>
				</Box>
			</Box>

			{isModalActive && (
				<Box
					className="billing-invoices-add-payments-modal"
					ref={modalRef}
				>
					<IconButton
						aria-label="close"
						sx={{
							position: "fixed",
							top: { xs: 8, md: 20 },
							right: { xs: 8, md: 22 },
							zIndex: 2,
						}}
						onClick={handleCloseModal}
					>
						<img
							width="34px"
							src="/assets/icons/table/close.svg"
							alt="X"
						/>
					</IconButton>
					<Typography variant="h3">Payment Information</Typography>
					<Typography sx={{ ...textStyle, textAlign: "center" }}>
						{creditCardSelected
							? "Change a payment method below."
							: "Add a payment method."}
					</Typography>

					{creditCardSelected && (
						<Box className="billing-invoices-add-payments-modal-card-container">
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-start",
										justifyContent: "center",
										gap: 1,
									}}
								>
									<img
										src={showBrandLogo(
											creditCardSelected.card_type
										)}
										style={{ height: "30px" }}
									/>
									<Typography
										sx={{
											color: palette.common.black,
											fontSize: 16,
											paddingLeft: 1.2,
											lineHeight: "normal",
										}}
									>
										{capitalize(
											creditCardSelected.card_type || ""
										)}{" "}
										Card ending with{" "}
										{creditCardSelected.card_no}
									</Typography>
								</Box>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
									}}
								>
									<Switch
										checked={
											creditCardSelected.is_preferred
										}
										onChange={() => setIsOnChangePref(true)}
									/>
									<Typography
										sx={{
											color: palette.common.black,
											fontSize: 16,
										}}
									>
										Default Card
									</Typography>
								</Box>
							</Box>
						</Box>
					)}

					<TextField
						sx={styleInput}
						label="Name of Card *"
						error={cardNameError}
						helperText={
							cardNameError && "Name of Card is required."
						}
						value={cardName}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									cardNameError: false,
								})
							)
						}
						onChange={(e) => {
							dispatch(
								setSigninData({
									...signinData,
									cardName: e.target.value,
									cardNameError: false,
								})
							);
						}}
						inputProps={{ maxLength: 40 }}
					></TextField>

					<TextField
						sx={styleInput}
						label="Credit Card *"
						disabled={creditCardSelected}
						value={formatedCreditCard}
						error={creditCardError}
						helperText={creditCardError && creditCardMessage}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									creditCardError: false,
								})
							)
						}
						onChange={handleCardChange}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton edge="end">
										<img
											style={{
												height: "25px",
												cursor: "default",
												marginRight: "20px",
											}}
											src={creditLogo}
										></img>
									</IconButton>
								</InputAdornment>
							),
						}}
					></TextField>

					<Box className="billing-invoices-add-payments-modal-inp-container">
						<TextField
							sx={styleInput}
							type="number"
							label="CVV *"
							disabled={creditCardSelected}
							value={cvv}
							error={cvvError}
							helperText={cvvError && "CVV is required."}
							onFocus={() =>
								dispatch(
									setSigninData({
										...signinData,
										cvvError: false,
									})
								)
							}
							onChange={handleCvvChange}
						></TextField>

						<FormControl
							fullWidth
							sx={{
								"& .MuiOutlinedInput-root": {
									"&:hover fieldset": {
										borderColor: "primary.dark",
									},
									"&.Mui-focused fieldset": {
										borderColor: "primary.dark",
									},
								},
							}}
						>
							<InputLabel
								id="credit-month-select-label"
								sx={{ marginTop: "10px" }}
							>
								Month *
							</InputLabel>
							<Select
								labelId="credit-month-select-label"
								defaultValue={""}
								value={expirationMonth}
								label="Month"
								onChange={(e) => {
									dispatch(
										setSigninData({
											...signinData,
											expirationMonth: e.target.value,
										})
									);
								}}
								sx={{
									...styleInput,
									color: palette.common.black,
								}}
								error={expirationError}
								onFocus={() =>
									dispatch(
										setSigninData({
											...signinData,
											expirationError: false,
										})
									)
								}
							>
								{months.map((m, index) => (
									<MenuItem
										value={index + 1}
										sx={{ color: palette.common.black }}
									>
										{m}
									</MenuItem>
								))}
							</Select>
							<FormHelperText error={expirationError}>
								{expirationError && "Month is required."}
							</FormHelperText>
							<FormHelperText error={true}>
								{signinData.expirationMonthMessage &&
									"Month is invalid."}
							</FormHelperText>
						</FormControl>

						<FormControl
							fullWidth
							sx={{
								"& .MuiOutlinedInput-root": {
									"&:hover fieldset": {
										borderColor: "primary.dark",
									},
									"&.Mui-focused fieldset": {
										borderColor: "primary.dark",
									},
								},
							}}
						>
							<InputLabel
								id="credit-year-select-label"
								sx={{ marginTop: "10px" }}
							>
								Year *
							</InputLabel>
							<Select
								labelId="credit-year-select-label"
								defaultValue={""}
								value={expirationYear}
								label="Year"
								onChange={(e) => {
									dispatch(
										setSigninData({
											...signinData,
											expirationYear: e.target.value,
										})
									);
								}}
								sx={{
									...styleInput,
									color: palette.common.black,
								}}
								error={expirationError}
								onFocus={() =>
									dispatch(
										setSigninData({
											...signinData,
											expirationError: false,
										})
									)
								}
							>
								{futureYears.map((m) => (
									<MenuItem
										value={m}
										sx={{ color: palette.common.black }}
									>
										{m}
									</MenuItem>
								))}
							</Select>
							<FormHelperText error={expirationError}>
								{expirationError && "Year is required."}
							</FormHelperText>
						</FormControl>
					</Box>

					<TextField
						sx={styleInput}
						label="Billing Address *"
						error={billingAddrError}
						helperText={
							billingAddrError && "Billing Address is required."
						}
						value={billingAddr}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									billingAddrError: false,
								})
							)
						}
						onChange={(e) => {
							dispatch(
								setSigninData({
									...signinData,
									billingAddr: e.target.value,
								})
							);
						}}
						inputProps={{ maxLength: 40 }}
					></TextField>

					<Box className="billing-invoices-add-payments-modal-inp-container">
						<TextField
							sx={styleInput}
							label="City *"
							error={cityError}
							helperText={cityError && "City is required."}
							value={city}
							onFocus={() =>
								dispatch(
									setSigninData({
										...signinData,
										cityError: false,
									})
								)
							}
							onChange={(e) => {
								dispatch(
									setSigninData({
										...signinData,
										city: e.target.value,
									})
								);
							}}
							inputProps={{ maxLength: 40 }}
						></TextField>

						<TextField
							sx={styleInput}
							label="Zipcode *"
							error={zipcodeError}
							helperText={zipcodeError && "Zipcode is required."}
							value={zipcode}
							onFocus={() =>
								dispatch(
									setSigninData({
										...signinData,
										zipcodeError: false,
									})
								)
							}
							onChange={(e) => {
								dispatch(
									setSigninData({
										...signinData,
										zipcode: e.target.value,
									})
								);
							}}
							inputProps={{ maxLength: 10 }}
						></TextField>
					</Box>

					<TextField
						sx={styleInput}
						label="State *"
						value={state}
						error={stateError}
						helperText={stateError && "State is required."}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									stateError: false,
								})
							)
						}
						onChange={(e) => {
							dispatch(
								setSigninData({
									...signinData,
									state: e.target.value,
								})
							);
						}}
						inputProps={{ maxLength: 40 }}
					></TextField>

					<CountrySelect sx={styleInput} />

					{errorMessage && (
						<FormHelperText
							error
							sx={{ fontSize: 14, textAlign: "center" }}
						>
							{errorMessage}
						</FormHelperText>
					)}

					<Box className="billing-invoices-add-payments-modal-btns-container">
						<Button
							size="large"
							type="button"
							variant="contained"
							sx={{
								boxShadow: "none",
								backgroundColor: palette.secondary.main,
								paddingY: 1.3,
								fontSize: {
									md: 15,
									xs: 13,
								},
							}}
							style={{
								fontFamily: typography.fontFamilySemiBold,
							}}
							onClick={checkBillingData}
						>
							Continue
						</Button>
					</Box>
				</Box>
			)}

			{isCreateSuccess && (
				<Box className="billing-invoices-success-payments-modal">
					<IconButton
						aria-label="close"
						sx={{
							position: "fixed",
							top: { xs: 8, md: 12 },
							right: { xs: 8, md: 10 },
							zIndex: 2,
						}}
						onClick={onSuccess}
					>
						<img
							width="34px"
							src="/assets/icons/table/close.svg"
							alt="X"
						/>
					</IconButton>
					{creditCardSelected ? (
						<Box>
							<Typography variant="h4">
								Payment Information
							</Typography>
							<Typography
								sx={{ ...textStyle, marginTop: "10px" }}
							>
								Card updated succesfully.
							</Typography>
						</Box>
					) : (
						<Box>
							<Typography variant="h4">
								Payment Information
							</Typography>
							<Typography
								sx={{ ...textStyle, marginTop: "10px" }}
							>
								Card created succesfully.
							</Typography>
						</Box>
					)}
					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							marginTop: "20px",
						}}
					>
						<Button
							size="large"
							type="button"
							variant="contained"
							sx={{
								boxShadow: "none",
								backgroundColor: palette.secondary.main,
								paddingY: 1.3,
								width: "200px",
								fontSize: {
									lg: 15,
									md: 15,
									xs: 13,
									sm: 13,
								},
							}}
							style={{
								fontFamily: typography.fontFamilySemiBold,
							}}
							onClick={onSuccess}
						>
							Continue
						</Button>
					</Box>
				</Box>
			)}

			{isOnDelete && (
				<Box
					className="billing-invoices-success-payments-modal"
					ref={deleteModalRef}
				>
					<IconButton
						aria-label="close"
						sx={{
							position: "fixed",
							top: { xs: 8, md: 10 },
							right: { xs: 8, md: 10 },
							zIndex: 2,
						}}
						onClick={() => setIsOnDelete(false)}
					>
						<img
							width="34px"
							src="/assets/icons/table/close.svg"
							alt="X"
						/>
					</IconButton>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 1,
						}}
					>
						<Typography variant="h4" sx={{ textAlign: "center" }}>
							Payment Information
						</Typography>
						<Typography sx={textStyle}>
							{creditCardSelected?.is_preferred
								? "At least one card must serve as your default payment method. Please designate an alternative card as your default payment method to proceed deleting this card."
								: paymentData.length === 1
								? "You can't delete this card since you have to have at least one payment method."
								: "Are you sure you want to delete this card?"}
						</Typography>
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							marginTop: "20px",
						}}
					>
						<Button
							size="large"
							type="button"
							variant="contained"
							sx={{
								boxShadow: "none",
								backgroundColor: palette.secondary.main,
								paddingY: 1,
								width: "200px",
								fontSize: {
									md: 15,
									xs: 13,
								},
							}}
							style={{
								fontFamily: typography.fontFamilySemiBold,
							}}
							onClick={onDeleteCard}
						>
							Continue
						</Button>
					</Box>
				</Box>
			)}

			{isOnChangePref && (
				<Box
					className="billing-invoices-success-payments-modal"
					ref={deleteModalRef}
				>
					<IconButton
						aria-label="close"
						sx={{
							position: "fixed",
							top: { xs: 8, md: 10 },
							right: { xs: 8, md: 10 },
							zIndex: 2,
						}}
						onClick={() => setIsOnChangePref(false)}
					>
						<img
							width="34px"
							src="/assets/icons/table/close.svg"
							alt="X"
						/>
					</IconButton>

					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 1,
						}}
					>
						<Typography variant="h4" sx={{ textAlign: "center" }}>
							Payment Information
						</Typography>
						<Typography sx={textStyle}>
							{creditCardSelected.is_preferred
								? "At least one card must serve as your default payment method. Please designate an alternative card as your default payment method to proceed removing default card."
								: "Are you sure you want to set this card as your default payment method?"}
						</Typography>
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "center",
							columnGap: "30px",
							marginTop: "20px",
						}}
					>
						<Button
							size="large"
							type="button"
							variant="contained"
							sx={{
								boxShadow: "none",
								width: "200px",
								backgroundColor: palette.secondary.main,
								paddingY: 1.3,
								fontSize: {
									lg: 15,
									md: 15,
									xs: 13,
									sm: 13,
								},
							}}
							style={{
								fontFamily: typography.fontFamilySemiBold,
							}}
							onClick={handleIsPreferredChange}
						>
							Continue
						</Button>
					</Box>
				</Box>
			)}
		</Box>
	);
};

export default CardsComponent;
