import { Stack } from "@mui/material";
import PropTypes from "prop-types";
import dayjs from "dayjs";
import { DatePicker as antDatePicker } from "antd";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import typography from "src/theme/typography";
import { CalendarIcon } from "./CalendarIcon";
import "./dataRangePicker.scss";

const { RangePicker } = antDatePicker;

export default function DateRangePicker({
	onDateChange,
	label,
	startDate,
	endDate,
}) {
	const location = useLocation();
	const currentPath = location.pathname;

	const disabledDates = (current) => {
		if (currentPath.includes("/reports/preshipment")) {
			const fiveDaysAgo = dayjs().subtract(5, "day");
			return (
				current.isBefore("2023-06-19") || current.isAfter(fiveDaysAgo)
			);
		} else if (currentPath.includes("/reports/intransit")) {
			const tenDaysAgo = dayjs().subtract(10, "day");
			return (
				current.isBefore("2023-06-19") || current.isAfter(tenDaysAgo)
			);
		} else {
			return current.isBefore("2023-06-19") || current.isAfter(dayjs());
		}
	};

	const handleDateChange = (dates) => {
		setSelectedDates(dates);
		onDateChange(dates);
	};

	const StyledDatePicker = styled(RangePicker)(({ theme }) => {
		return {
			paddingRight: 0,
			paddingTop: 0,
			paddingBottom: 0,
			borderTopLeftRadius: label ? 0 : 6,
			borderBottomLeftRadius: label ? 0 : 6,
			cursor: "pointer",
			"& .ant-picker-input > input": {
				fontSize: 14,
				fontFamily: typography.fontFamily,
			},
      "& .ant-picker-suffix:not(:last-child)": {
        opacity: "1!important",
      },
			"& .ant-picker .ant-picker-clear": {
				insetInlineEnd: "50px",
			},
			".ant-picker-clear": {
				display: "none",
			},
			"& .ant-picker-range-separator > .ant-picker-separator > .anticon-swap-right":
				{
					color: theme.palette.common.black,
				},
			"& .ant-picker-range-separator > .ant-picker-separator > .anticon-swap-right > svg":
				{
					width: 12,
				},
			//tild image of date picker
			"& .ant-picker-range-separator > .ant-picker-separator > .anticon-swap-right > svg > path":
				{
					d: "path('M872 474H152c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h720c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z')",
				},
			"& .ant-picker-input": {
				width: 86,
			},
			".ant-picker-suffix": {
				height: "100%",
			},
			height: 40,
			[theme.breakpoints.down("lg")]: {
				height: 36,
				"& .ant-picker-input": {
					width: 72,
				},
				"& .ant-picker-input > input": {
					fontSize: 12,
					fontWeight: 500,
					color: theme.palette.secondary.light,
				},
				"& .ant-picker-range-separator": {
					padding: 0,
				},
				".ant-picker-range .ant-picker-clear": {
					insetInlineEnd: "25px",
				},
				"& .ant-picker-range-separator > .ant-picker-separator > .anticon-swap-right > svg":
					{
						width: 6,
					},
			},
		};
	});

	return (
		<Stack
			direction="row"
			sx={{
				display: "flex",
				alignItems: "center",
			}}
		>
			{label && (
				<p
					style={typography.dataPickerLabel}
					className="dataPickerLabel"
				>
					{label}
				</p>
			)}
			<StyledDatePicker
				format="MM-DD-YYYY"
				allowEmpty
				onChange={(dates) => onDateChange(dates)}
				value={startDate && [dayjs(startDate), dayjs(endDate)]}
				disabledDate={disabledDates}
				suffixIcon={<CalendarIcon />}
			/>
		</Stack>
	);
}

DateRangePicker.propTypes = {
	onDateChange: PropTypes.func,
	label: PropTypes.string,
	sx: PropTypes.shape({}),
	startDate: PropTypes.string,
	endDate: PropTypes.string,
};
