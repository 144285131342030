import React, { useEffect, useState } from "react";
import {
	Box,
	Card,
	FormLabel,
	Grid,
	TextField,
	Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getTenantById } from "src/features/tenants/tenantsApi";
import palette from "src/theme/palette";
import { getPlans } from "src/features/onborading/onboardingApi";
import { getUserInfo } from "src/features/user/userState";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import {
	decrementAwaitingApiCounter,
	incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const Profile = () => {
	const tenant_id = useSelector((state) => state.tenants.tenant_id);
	const [tenant, setTenant] = useState({});
	const [planName, setPlanName] = useState("");
	const userInfo = useAppSelector(getUserInfo);
	const dispatch = useAppDispatch();

	const getDate = (billingDate) => {
		let date = new Date(billingDate * 1000);
		return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
	};

	useEffect(() => {
		const fetchData = async () => {
			try {
				dispatch(incrementAwaitingApiCounter());

				// Fetch tenant data
				const tenantResponse = await getTenantById(
					tenant_id,
					userInfo.accessToken
				);
				const { statusCode: tenantStatusCode, data: tenantData } =
					tenantResponse;

				if (tenantStatusCode === 200) {
					setTenant(tenantData);

					// Fetch plans data
					const plansResponse = await getPlans(userInfo.accessToken);
					const { data: plansData, statusCode: plansStatusCode } =
						plansResponse;

					if (plansStatusCode === 200) {
						plansData.forEach((plan) => {
							if (plan._id === tenantData.plan_id) {
								setPlanName(plan.name);
							}
						});
					}
				}

				dispatch(decrementAwaitingApiCounter());
			} catch (err) {
				console.error(err);
			} finally {
				dispatch(decrementAwaitingApiCounter());
			}
		};

		fetchData();
	}, [tenant_id]);

	const styleInput = {
		input: { color: palette.common.black },
		width: "100%",
		marginTop: { xs: "6px", md: "10px" },
		backgroundColor: "#fff",
		borderRadius: "10px",
		color: "#000",
		"& .MuiFormHelperText-root": {
			textAlign: "left",
			fontSize: "14px",
		},
		"& .MuiOutlinedInput-input": {
			"& .Mui-disabled": {
				"-webkit-text-fill-color": "#000",
			},
		},
	};

	return (
		<Box
			sx={{
				padding: { xs: "15px", md: "20px" },
				backgroundColor: "#F2F2F2",
				borderRadius: "10px",
			}}
		>
			<Grid container gap={{ xs: 2, md: 3 }}>
				<Grid item xs={12} lg={4}>
					<Typography
						variant="body1"
						sx={{ fontWeight: "600", color: "#000" }}
					>
						Name
					</Typography>
					<TextField
						disabled
						fullWidth
						value={tenant?.company_name}
						sx={styleInput}
					/>
				</Grid>
				<Grid item xs={12} lg={4}>
					<Typography
						variant="body1"
						sx={{ fontWeight: "600", color: "#000" }}
					>
						Contact Info
					</Typography>
					<TextField
						disabled
						fullWidth
						value={tenant.contact_info?.email}
						sx={styleInput}
					/>
				</Grid>
				<Grid item xs={12} lg={4}>
					<Typography
						variant="body1"
						sx={{ fontWeight: "600", color: "#000" }}
					>
						Phone Number
					</Typography>
					<TextField
						disabled
						fullWidth
						value={tenant.contact_info?.phone_no}
						sx={styleInput}
					/>
				</Grid>
				<Grid item xs={12} lg={4}>
					<Typography
						variant="body1"
						sx={{ fontWeight: "600", color: "#000" }}
					>
						Plan Name
					</Typography>
					<TextField
						disabled
						fullWidth
						value={planName}
						sx={styleInput}
					/>
				</Grid>
				<Grid item xs={12} lg={4}>
					<Typography
						variant="body1"
						sx={{ fontWeight: "600", color: "#000" }}
					>
						Enrolled At
					</Typography>
					<TextField
						disabled
						fullWidth
						value={getDate(tenant?.enrolled_at)}
						sx={styleInput}
					/>
				</Grid>
				<Grid item xs={12} lg={4}>
					<Typography
						variant="body1"
						sx={{ fontWeight: "600", color: "#000" }}
					>
						Status
					</Typography>
					<Box
						sx={{
							display: "flex",
							marginBottom: { xs: 1, md: 0 },
							alignItems: "center",
							gap: 2,
							height: { xs: "45px", md: "66px" },
						}}
					>
						<Typography
							variant="body2"
							sx={{
								color: `${
									tenant?.status !== "active"
										? palette.error.main
										: "green"
								}`,
							}}
						>
							{tenant?.status !== "active"
								? "Disbaled"
								: "Enabled"}
						</Typography>
						<Box
							sx={{
								padding: "8px",
								borderRadius: "50px",
								backgroundColor: `${
									tenant?.status !== "active"
										? palette.error.main
										: "green"
								}`,
							}}
						/>
					</Box>
				</Grid>
			</Grid>
			<Grid
				gap={{ xs: 2, md: 3 }}
				container
				direction={{ xs: "column", md: "row" }}
			>
				<Grid item xs={4}>
					<Typography
						variant="body1"
						sx={{
							fontWeight: "600",
							color: "#000",
							marginTop: { xs: 0, md: "25px" },
							marginBottom: "5px",
						}}
					>
						Opted In
					</Typography>
					<Box
						sx={{
							display: "flex",
							flexWrap: "wrap",
							justifyContent: "space-between",
							gap: 1,
						}}
					>
						{Object.keys(tenant?.carrier || {}).map((item, ind) => {
							return (
								<Box>
									<Card
										sx={{
											margin: {
												xs: "8px 0",
											},
											padding: "10px",
											width: "176px",
										}}
									>
										<Box
											sx={{
												display: "flex",
												alignItems: "center",
												height: "35px",
											}}
										>
											<Box
												sx={{
													width: "40px",
													height: "30px",
													objectFit: "contain",
													display: "block",
												}}
												component="img"
												alt="card img"
												src={`/assets/${item}.png`}
											/>
											<FormLabel
												sx={{
													textAlign: "center",
													marginLeft: "5px",
													color: "black",
													fontSize: "18px",
													textTransform: `${
														item === "ups" ||
														item === "usps" ||
														item === "dhl"
															? "uppercase"
															: "capitalize"
													}`,
												}}
											>
												{item}
											</FormLabel>
										</Box>
									</Card>
								</Box>
							);
						})}

						{tenant.order_management?.magento && (
							<Box>
								<Card
									sx={{
										margin: {
											sm: "8px 0",
											xs: "8px 0",
										},
										padding: "10px",
										width: "176px",
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											height: "35px",
										}}
									>
										<Box
											sx={{
												width: "80px",
												height: "30px",
												objectFit: "contain",
												display: "block",
											}}
											component="img"
											alt="card img"
											src="/assets/magento.png"
										/>
										<FormLabel
											sx={{
												textAlign: "center",
												marginLeft: "5px",
												color: "black",
												fontSize: "18px",
											}}
										>
											Magento
										</FormLabel>
									</Box>
								</Card>
							</Box>
						)}
						{tenant.order_management?.ship_station && (
							<Box>
								<Card
									sx={{
										margin: {
											sm: "8px 0",
											xs: "8px 0",
										},
										padding: "10px",
										width: "176px",
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											height: "35px",
										}}
									>
										<Box
											sx={{
												width: "30px",
												height: "30px",
												objectFit: "contain",
												display: "block",
											}}
											component="img"
											alt="card img"
											src="/assets/shipStation.png"
										/>
										<FormLabel
											sx={{
												textAlign: "center",
												marginLeft: "5px",
												color: "black",
												fontSize: "18px",
											}}
										>
											Ship Station
										</FormLabel>
									</Box>
								</Card>
							</Box>
						)}
						{tenant.order_management?.shopify && (
							<Box>
								<Card
									sx={{
										margin: {
											sm: "8px 0",
											xs: "8px 0",
										},
										padding: "10px",
										width: "176px",
									}}
								>
									<Box
										sx={{
											display: "flex",
											alignItems: "center",
											height: "35px",
										}}
									>
										<Box
											sx={{
												width: "80px",
												height: "30px",
												objectFit: "contain",
												display: "block",
											}}
											component="img"
											alt="card img"
											src="/assets/shopify.png"
										/>
										<FormLabel
											sx={{
												textAlign: "center",
												marginLeft: "5px",
												color: "black",
												fontSize: "18px",
											}}
										>
											Shopify
										</FormLabel>
									</Box>
								</Card>
							</Box>
						)}
					</Box>
				</Grid>
				<Grid item xs={4}>
					<Typography
						variant="body1"
						sx={{
							fontWeight: "600",
							color: "#000",
							marginTop: { xs: 0, md: "25px" },
						}}
					>
						Shipping Volume Per Month
					</Typography>
					<TextField
						disabled
						fullWidth
						value={tenant?.shipping_volume}
						sx={styleInput}
					/>
				</Grid>
			</Grid>
		</Box>
	);
};

export default Profile;
