import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { createTheme, styled } from "@mui/material/styles";
import {
	Box,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
} from "@mui/material";
import Logo from "src/components/logo";
import typography from "src/theme/typography";
import palette from "src/theme/palette";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
	getExistantTenant,
	signupUser,
} from "src/features/onborading/onboardingApi";
import { useDispatch, useSelector } from "react-redux";
import { setSigninData } from "src/features/signin/signin.action";
import { MuiTelInput } from "mui-tel-input";
import { ThemeProvider } from "@mui/material/styles";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import {
	decrementAwaitingApiCounter,
	incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const theme = createTheme({
	overrides: {
		MuiTelInput: {
			root: {
				color: "black",
			},
		},
	},
});

const StyledContent = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	flexDirection: "column",
	gap: theme.spacing(6),
	[theme.breakpoints.down("md")]: {
		gap: theme.spacing(5),
		padding: theme.spacing(0, 6),
	},
}));

const styleInput = {
	input: { color: "#000" },
	width: "100%",
	marginTop: "10px",
	"& .MuiFormHelperText-root": {
		textAlign: "left",
		fontSize: "14px",
	},
	"& .MuiOutlinedInput-root": {
		"&:hover fieldset": {
			borderColor: "primary.dark",
		},
		"&.Mui-focused fieldset": {
			borderColor: "primary.dark",
		},
	},
};

const phoneStyle = {
	input: { color: "#000 !important" },
	width: "100%",

	marginTop: "10px",
};

export default function OnboardTenants() {
	const dispatch = useDispatch();
	const userInfo = useAppSelector(getUserInfo);
	const [phoneValue, setPhoneValue] = useState("");
	const [shippingVol, setShippingVolume] = useState("");
	const [successfulOnBoard, setSuccessfulOnBoard] = useState(false);
	const signinData = useSelector((state) => state.signin.signinData);
	const navigate = useNavigate();
	const { phoneError, invalidPhoneError } = signinData || {};
	const {
		register,
		handleSubmit,
		setError,
		formState: { errors },
	} = useForm();

	const handlePhoneChange = (newValue) => {
		let newPhone = newValue.split(" ");
		let newPhoneStr = "";

		for (let index = 0; index < newPhone.length; index++) {
			if (index === 0) newPhoneStr += newPhone[index] + " ";
			else newPhoneStr += newPhone[index];
		}

		newPhone = newPhoneStr.split(" ");

		if (newPhone[1]?.length > 10) return;

		setPhoneValue(newValue);
		dispatch(setSigninData({ ...signinData, phone: newPhoneStr }));
	};

	const validatePhone = (phone) => {
		return /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/g.test(
			phone
		);
	};

	const onSubmit = async (data) => {
		if (!signinData.phone) {
			dispatch(setSigninData({ ...signinData, phoneError: true }));
			return true;
		}

		if (!validatePhone(signinData.phone)) {
			dispatch(setSigninData({ ...signinData, invalidPhoneError: true }));
			return true;
		}

		const reqBody = {
			first_name: data.first_name,
			last_name: data.last_name,
			email: data.email,
			shipping_volume: shippingVol,
			company_name: data.company_name,
			phone_number: signinData.phone,
		};

		dispatch(incrementAwaitingApiCounter());

		try {
			const response = await getExistantTenant(
				data.company_name,
				userInfo.accessToken
			);
			if (response.statusCode === 200) {
				try {
					const signupResponse = await signupUser(reqBody, userInfo);
					if (signupResponse.statusCode === 200) {
						const signupResponseData = signupResponse.data;
						console.log(signupResponseData);
						dispatch(
							setSigninData({
								shippingvolume: shippingVol,
								name: signupResponseData.first_name,
								lastName: signupResponseData.last_name,
								company: signupResponseData.company_name,
								email: signupResponseData.user_name,
								user_id:
									signupResponseData.user_id ||
									signupResponseData._id,
								phone: signinData.phone,

								isOnBoardingTenant: true,
							})
						);

						dispatch(decrementAwaitingApiCounter());
						setSuccessfulOnBoard(true);
					}
				} catch (error) {
					setError("email", {
						message: "Email already exist.",
					});
					dispatch(decrementAwaitingApiCounter());
				}
			}
		} catch (err) {
			setError("company_name", {
				message: "Company name already exist.",
			});
			dispatch(decrementAwaitingApiCounter());
		}
	};

	useEffect(() => {
		if (successfulOnBoard) navigate("/dashboard/on-board/carrier");
	}, [successfulOnBoard]);

	return (
		<>
			<Helmet>
				<title> Onboarding | BeyondCarts CMS </title>
			</Helmet>

			<Grid container sx={{ height: "100%", alignItems: "center" }}>
				<Grid
					item
					lg={7}
					sx={{
						display: { xs: "none", lg: "block" },
						height: "100%",
					}}
				>
					<img
						src="/assets/images/signup.svg"
						alt="signup"
						height="100%"
						style={{ marginTop: "20px" }}
					/>
				</Grid>
				<Grid
					item
					lg={5}
					xs={12}
					sx={{
						display: { xs: "flex", lg: "block" },
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<form
						className="form"
						onSubmit={handleSubmit(onSubmit)}
						style={{ maxWidth: "500px" }}
					>
						<StyledContent sx={{ marginBottom: "40px" }}>
							<h1
								style={typography.bigTitle}
								className="bigTitle"
							>
								Get Started
							</h1>
						</StyledContent>
						<Grid container spacing={{ xs: 0, md: 3 }}>
							<Grid item xs={12} md={6}>
								<TextField
									sx={styleInput}
									label="First Name"
									name="first_name"
									{...register("first_name", {
										required: "First Name is required.",
									})}
									error={!!errors.first_name}
									helperText={
										errors.first_name &&
										errors.first_name.message
									}
									inputProps={{
										maxLength: 50,
									}}
								/>
							</Grid>
							<Grid item xs={12} md={6}>
								<TextField
									sx={styleInput}
									label="Last Name"
									name="last_name"
									{...register("last_name", {
										required: "Last Name is required.",
									})}
									error={!!errors.last_name}
									helperText={
										errors.last_name &&
										errors.last_name.message
									}
									inputProps={{
										maxLength: 50,
									}}
								/>
							</Grid>
						</Grid>

						<TextField
							sx={styleInput}
							label="Email"
							name="email"
							{...register("email", {
								required: "Email is required.",
								validate: (value) => {
									if (
										!value.match(
											/^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/
										)
									) {
										return "Invalid email address.";
									}
									return true;
								},
							})}
							error={!!errors.email}
							helperText={errors.email && errors.email.message}
							inputProps={{
								maxLength: 50,
							}}
						></TextField>

						<TextField
							sx={styleInput}
							label="Company Name"
							name="company_name"
							{...register("company_name", {
								required: "Company Name is required.",
							})}
							error={!!errors.company_name}
							helperText={
								errors.company_name &&
								errors.company_name.message
							}
							inputProps={{
								maxLength: 50,
							}}
						></TextField>

						<ThemeProvider theme={theme}>
							<MuiTelInput
								sx={phoneStyle}
								defaultCountry="US"
								forceCallingCode={true}
								value={phoneValue}
								error={phoneError || invalidPhoneError}
								helperText={
									(phoneError || invalidPhoneError) &&
									"Invalid phone number."
								}
								onFocus={() =>
									dispatch(
										setSigninData({
											...signinData,
											phoneError: false,
											invalidPhoneError: false,
										})
									)
								}
								onChange={handlePhoneChange}
							/>
						</ThemeProvider>

						<FormControl>
							<FormLabel
								id="demo-radio-buttons-group-label"
								sx={{
									color: palette.common.black,
									marginTop: "10px",
								}}
							>
								Monthly Shipping Volume
							</FormLabel>
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								name="radio-buttons-group"
								value={shippingVol}
								onClick={(e) => {
									if (e.target.value === shippingVol) {
										setShippingVolume("");
									} else {
										setShippingVolume(e.target.value);
									}
								}}
							>
								<FormControlLabel
									value="1-2500"
									control={<Radio sx={{ paddingY: 0.5 }} />}
									label="1-2500"
									sx={{ color: "#000" }}
								/>
								<FormControlLabel
									value="2501-5000"
									control={<Radio sx={{ paddingY: 0.3 }} />}
									label="2501-5000"
									sx={{ color: "#000" }}
								/>
								<FormControlLabel
									value="5000&Above"
									control={<Radio sx={{ paddingY: 0.5 }} />}
									label="5000 & Above"
									sx={{ color: "#000" }}
								/>
							</RadioGroup>
						</FormControl>

						<LoadingButton
							fullWidth
							size="large"
							type="submit"
							variant="contained"
							sx={{
								marginTop: 3,

								boxShadow: "none",
								backgroundColor: palette.secondary.main,
							}}
						>
							Sign up
						</LoadingButton>
					</form>
				</Grid>
			</Grid>
		</>
	);
}
