import axios from "axios";
import { deleteToken } from "src/utils/extractUserToken";
import { extractData } from "./extractData";
import { encConfig } from "./config";

export default async function getData(
	url,
	parmas = null,
	headers,
	token = null
) {
	// random number of 9 digits, not starting by zero
	const randomInt = Math.floor(1e7 + Math.random() * 9e7);
	const apiKey = process.env.REACT_APP_BACKEND_API_KEY;
	const config = {
		headers: {
			...headers,
			"Content-Type": "application/json",
			Apikey: apiKey,
			request_id: randomInt,
		},
		params: parmas,
	};
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	try {
		const response = await axios.get(url, config);
		if (encConfig.encryption.decrypt) {
			return JSON.parse(extractData(response));
		} else {
			return response.data;
		}
	} catch (error) {
		extractData(error);
		if ([401, 403].includes(error.response?.status)) {
			deleteToken();
			return;
		}
		throw error; // Rethrow the error to allow the calling code to handle it as well, if needed.
	}
}
