import { styled } from "@mui/material/styles";
import { Typography, Box, Card, Grid } from "@mui/material";
import PropTypes from "prop-types";
import typography from "src/theme/typography";
// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
	item: PropTypes.object.isRequired,
	onClickRow: PropTypes.func,
};

function AppWidgetSummary({ item, onFirstScanHandleOnClick }) {
	const StyledIcon = styled("div")(() => ({
		display: "flex",
		borderRadius: "100%",
		alignItems: "center",
		justifyContent: "center",
	}));
	return (
		<Grid item sx={{}} md={4} xs={12} sm={4} lg={4} xl={2}>
			<Card
				sx={{
					py: { lg: 2, sm: 1, xs: 1 },
					px: { lg: 1, sm: 2, xs: 1.5 },
					boxShadow: 0,
					// textAlign: "center",
					display: "flex",
					alignItems: "center",
					height: { lg: 116, sm: 60, md: 85.53 },
					borderRadius: 1.5,
					m: 0,
				}}
				className="metrics-card"
				onClick={() => {
					onFirstScanHandleOnClick(item);
					// console.log(item);
				}}
			>
				<Box
					container
					sx={{
						width: { md: 30, xs: 50 },
					}}
				>
					<StyledIcon
						sx={{
							width: { lg: 60, md: 44.35, sm: 31.76, xs: 35 },
						}}
					>
						<img src={item.icon} alt="" />
					</StyledIcon>
				</Box>

				<Box
					container
					sx={{
						textAlign: "start",
						paddingLeft: { lg: "33px !important", sm: 1, md: 3 },
						color: "secondary.light",
						maxWidth: 300,
					}}
				>
					{/* crad value */}
					<Typography
						variant="shippingCardValue"
						sx={{
							fontSize: { lg: 22, sm: 14, md: 18 },
							opacity: 0.7,
						}}
					>
						{item.total}
						{typeof item.subtitle != "undefined" && (
							<Typography className="count-subtitle">
								({item.subtitle})
							</Typography>
						)}
					</Typography>

					<p style={typography.caption} className="caption">
						{item.title}
					</p>
				</Box>
			</Card>
		</Grid>
	);
}

EmailStatisticsDataCard.propTypes = {
	title: PropTypes.string,
	cardItems: PropTypes.array,
};

export default function EmailStatisticsDataCard({
	title,
	cardItems,
	onFirstScanHandleOnClick,
}) {
	const StyledRoot = styled("div")(() => ({
		maxWidth: "100%",
	}));

	return (
		<StyledRoot>
			<Box
				sx={{
					pl: 2,
					py: { xs: 1, md: 4 },
					height: 50,
					display: "flex",
					alignItems: "center",
					color: "common.black",
					bgcolor: "common.white",
					borderTopLeftRadius: 10,
					borderTopRightRadius: 10,
				}}
			>
				<h4 style={typography.h4}>{title}</h4>
			</Box>
			<Box
				sx={{
					display: "flex",
					direction: "row",
					justifyContent: "space-between",
					my: 3,
					gap: 1,
				}}
			>
				<Grid container rowSpacing={2} columnSpacing={3}>
					{cardItems.map((item, index) => (
						<AppWidgetSummary
							onFirstScanHandleOnClick={onFirstScanHandleOnClick}
							item={item}
							// eslint-disable-next-line react/no-array-index-key
							key={`${item.title}-${index}`}
						/>
					))}
				</Grid>
			</Box>
		</StyledRoot>
	);
}
