import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";
import ShopifyForm from "./shopifyForm/shopifyForm";
import MagentoForm from "./magentoForm/magentoForm";
import ShipstationForm from "./shipstationForm/shipstationForm";
import palette from "src/theme/palette";
import "./signinManage.scss";

const SigninManage = ({ navigateTo }) => {
	const navigate = useNavigate();
	const signinData = useSelector((state) => state.signin.signinData);
	const { manager, user_id } = signinData || {};

	useEffect(() => {
		if (!user_id) navigate("/login");

		console.log(navigateTo);

		if (
			navigateTo === "magento" ||
			navigateTo === "shipstation" ||
			navigateTo === "shopify"
		) {
			window.scrollTo(0, 0);
		}
	}, []);

	return (
		<div className="signin-manage-container">
			<Typography
				variant="h3"
				sx={{
					textAlign: "center",
				}}
			>
				Sign into your shipment Manager accounts.
			</Typography>

			<Typography
				sx={{
					color: palette.common.black,
					fontSize: 18,
					textAlign: "center",
				}}
			>
				This allows us to actually manage your data sources
			</Typography>
			{manager === "shopify" && <ShopifyForm />}
			{manager === "magento" && <MagentoForm />}
			{manager === "shipstation" && <ShipstationForm />}
		</div>
	);
};

export default SigninManage;
