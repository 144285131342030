import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import { setSigninData } from "src/features/signin/signin.action";
import {
	resendOtpCode,
	validateOtpCode,
} from "src/features/onborading/onboardingApi";

import OtpForm from "src/pages/ResetPassword/OtpForm";

import palette from "src/theme/palette";

import "./otpForm.scss";
import { red } from "@mui/material/colors";

const OtpForms = ({ setIsLoading }) => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const signinData = useSelector((state) => state.signin.signinData);

	const { email } = signinData;
	const { control, handleSubmit } = useForm();

	const secsToResendCode = 30;
	const [timerCount, setTimerCount] = useState(secsToResendCode);
	const [error, setError] = useState(false);

	const handleNextClick = (data) => {
		validateOtpCode({ email, code: data?.otp })
			.then((r) => {
				const {
					statusCode,
					data: { user_id },
				} = r;
				setIsLoading(false);
				if (statusCode === 200) {
					navigate("/onboarding/carrier");
					dispatch(
						setSigninData({
							...signinData,
							user_id,
							fullName: "",

							billingAddr: "",

							city: "",

							zipcode: "",

							state: "",
						})
					);
				}
			})
			.catch(({ message }) => {
				setIsLoading(false);
				if (message.includes("Invalid Code"))
					dispatch(
						setSigninData({ ...signinData, wrongOtpError: true })
					);
				setError(true);
			});
		setIsLoading(true);
	};

	useEffect(() => {
		if (timerCount === 0) setTimerCount(null);
		if (!timerCount) return;

		const intervalId = setInterval(() => {
			setTimerCount(timerCount - 1);
		}, 1000);

		return () => clearInterval(intervalId);
	}, [timerCount]);

	useEffect(() => {
		dispatch(setSigninData({ ...signinData, otp: "" }));
	}, []);

	return (
		<Box className="otpform-container">
			<Typography
				sx={{
					color: palette.common.black,
					fontSize: 18,
					textAlign: "center",
				}}
			>
				Enter in the code that was sent to {email}
			</Typography>

			<OtpForm
				control={control}
				email={email}
				clearErrors={() => setError("")}
				onBoarding={true}
			/>

			{error && (
				<Typography
					sx={{
						color: "#D32F2F",
						fontSize: 18,
					}}
				>
					Wrong OTP code entered, please check and try again.
				</Typography>
			)}

			<Box sx={{ display: "flex", justifyContent: "center" }}>
				<LoadingButton
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					onClick={handleSubmit(handleNextClick)}
					sx={{
						fontSize: "18px !important",
						margin: "auto",
						marginTop: 3,
						width: { lg: 240, sm: 444, md: 444, xs: 150 },
						boxShadow: "none",
						backgroundColor: palette.secondary.main,
					}}
				>
					Continue
				</LoadingButton>
			</Box>
		</Box>
	);
};

export default OtpForms;
