import { Outlet, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { Divider } from "@mui/material";
import Header from "./header";
import Nav from "./nav";

const StyledRoot = styled("div")({
	display: "flex",
	minHeight: "100%",
	overflow: "hidden",
});

const Main = styled("div")(({ theme }) => ({
	flexGrow: 1,
	overflow: "auto",
	minHeight: "100%",
	width: "min-content",
	backgroundColor: theme.palette.primary.lighter,
	paddingBottom: theme.spacing(3),
	[theme.breakpoints.down("md")]: {
		paddingLeft: theme.spacing(9),
		paddingRight: theme.spacing(3),
	},
	[theme.breakpoints.up("md")]: {
		paddingLeft: theme.spacing(5),
		paddingRight: theme.spacing(5),
	},
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
	borderStyle: "line",
	borderWidth: theme.spacing(0.1),
}));

function DashboardLayout() {
	const { pathname } = useLocation();
	const isConfig = pathname === "/dashboard/config";
	return (
		<StyledRoot>
			<Nav />
			<Main as="main" className="main">
				{/* <Header />
				{isConfig ? null : <StyledDivider />}	 */}
				<Outlet />
			</Main>
		</StyledRoot>
	);
}
export default DashboardLayout;
