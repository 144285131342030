import { deleteToken } from "src/utils/extractUserToken";

const { default: getData } = require("../../dataFetcher/getDataFetcher");

export const fetchScanData = async (startDate, endDate, token) => {
	var parmas = null;

	const BaseUrl = process.env.REACT_APP_BACKEND_URL;
	let url = `${BaseUrl}tracking/statistics`;

	if (startDate !== null && endDate !== null) {
		url += `?date_range=${startDate}-${endDate}`;
	}

	try {
		const data = await getData(url, (parmas = parmas), (token = token));

		return data;
	} catch (error) {
		if (
			error?.response?.status === 401 ||
			error?.response?.status === 403
		) {
			deleteToken();
		}
		throw error;
	}
};

export const fetchShippingStatisticData = async (startDate, endDate, token) => {
	var parmas = null;

	const BaseUrl = process.env.REACT_APP_BACKEND_URL;
	let url = `${BaseUrl}tracking/statistics_live`;

	if (startDate !== null && endDate !== null) {
		url += `?date_range=${startDate}-${endDate}`;
	}

	try {
		const data = await getData(url, (parmas = parmas), (token = token));

		return data;
	} catch (error) {
		if (
			error?.response?.status === 401 ||
			error?.response?.status === 403
		) {
			deleteToken();
		}
		throw error;
	}
};
