import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { removeTitleCase, unixToDate } from "src/utils/core";
import { Stack, Snackbar, Alert } from "@mui/material";
import SmartTable from "src/components/smart-table/SmartTable";
import { useSelector } from "react-redux";
import { logoutUser } from "src/features/user/userState";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";
import { getAllUsers, getSingleTenant } from "src/features/usersPage/usersApi";
import {
	incrementAwaitingApiCounter,
	decrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { updateTenantUser } from "src/features/user/userSelect";
import { capitalCase } from "capital-case";

const UsersListing = () => {
	const { pathname } = useLocation();
	const usersInfo = useSelector((state) => state.user.usersInfo);
	const filteredTenant = useSelector(
		(state) => state.selectedTenant.SelectedTenant
	);
	const userInfo = useAppSelector(getUserInfo);
	const dispatch = useAppDispatch();

	const [expandedRow, setExpandedRow] = useState(null);
	const [usersList, setUsersList] = useState([]);
	const [totalItemsCount, setTotalItemCount] = useState(0);

	// current page of the table
	const [page, setPage] = useState(0);

	// number of table rows per page
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const [searchKey, setSearchKey] = useState(null);
	const [showLoader, setShowLoader] = useState(false);
	const [advancedFormData, setAdvancedFormData] = useState({});
	const [expandedUserInfo, setExpandedUserInfo] = useState({});
	const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
	const [statusList, setStatusList] = useState([]);
	const [statusAllData, setStatusAllData] = useState("");
	const [showExportInfo, setShowExportInfo] = useState(false);
	const [userSelectedColumns, setUserSelectedColumns] = useState([]);
	const [order, setOrder] = useState("");
	const [product, setProduct] = useState("");
	const [modalLoader, setModalLoader] = useState(false);
	const sortDataString = `sort_data[${product}]`;
	// TODO: uncomment when the adminUser attribute is well setted.
	const isAdminUser = ["administrator", "admin"].includes(userInfo?.role);
	const [allTenantInfo, setAllTenantInfo] = useState([]);
	const { tenant_id, tenant_name } = useSelector((state) => state.tenants);
	const [isRefresh, setIsRefresh] = useState(false);

	const trackingInfoRef = useRef({});
	const dataConverted = [];
	const defaultColumn = "email";
	const searchPlaceholder = "Search email";
	const originalRowsFormatted = usersList?.map(
		({
			user_name,
			last_loggedin_at,
			role,
			updatedAt,
			login_failed_attempts,
			last_forgot_password_trigerred_at,
			status,
			company_name,
			createdAt,
			createdSource,
			createdBy,
			updatedBy,
			updatedSource,
			first_name,
			is_onBoarded,
			_id,
			...rest
		}) => ({
			email: user_name,
			first_name,
			created_at: createdAt,
			updated_source: updatedSource,
			updated_by: updatedBy,
			created_source: createdSource,
			created_by: createdBy,
			...rest,
			role: capitalCase(role),
			updated_at: updatedAt,
			last_logged_in: last_loggedin_at,
			failed_attempts: login_failed_attempts,
			_id,
		})
	);

	originalRowsFormatted?.forEach((item) => {
		dataConverted.push({
			...item,
		});
	});

	const advancedFilterUsersSearchData = {
		id: { name: "user_id", label: "User id" },
		first_name: { name: "first_name", label: "User First Name" },
		last_name: { name: "last_name", label: "User Last Name" },
		phone_number: { name: "phone_number", label: "User Phone Number" },
		email: { name: "user_name", label: "User Email" },
	};

	const getCurrentPageParams = (current_page = 1) => {
		let tempFormData = { ...advancedFormData };
		let queryParams = {
			page_size: rowsPerPage,
			current_page,
			[sortDataString]: order,
			...tempFormData,
		};

		return queryParams;
	};

	// fetches the users list
	const fetchData = async (isLoadAll = false, selected_page = null) => {
		try {
			let queryParams = getCurrentPageParams();

			if (selected_page != null) {
				queryParams["page_size"] = selected_page;
			}

			if (isLoadAll) {
				dispatch(incrementAwaitingApiCounter());
				setShowLoader(true);

				let params = null;
				const headers = {
					tenant_id,
					user_id: userInfo.user_id,
				};
				let data = null;
				data = await getAllUsers(
					params,
					headers,
					userInfo,
					tenant_name
				);
				setAllTenantInfo(data?.data);

				const dataFiltered = data?.data.map(
					({
						tenant_id,
						last_loggedin_at,
						updatedAt,
						createdAt,
						is_demo_user,
						isMarketingUser,
						last_loggedout_at,
						last_password_reset_at,
						shipping_volume,
						createdSource,
						...rest
					}) => {
						// console.log(rest);
						return {
							...rest,
							updatedAt: updatedAt ? unixToDate(updatedAt) : "--",
							last_loggedin_at: last_loggedin_at
								? unixToDate(last_loggedin_at)
								: "--",
							createdAt: createdAt ? unixToDate(createdAt) : "--",
							createdSource: createdSource,
						};
					}
				);
				// console.log('usersData', dataFiltered);
				setUsersList(dataFiltered || []);
				setTotalItemCount(dataFiltered.length || 0);
				dispatch(decrementAwaitingApiCounter());
				setShowLoader(false);
			}
		} catch (error) {
			dispatch(decrementAwaitingApiCounter());
			setShowLoader(false);
		}
	};

	// Function that gets the users list from the database
	const getUsersList = async (
		parms,
		appendRow = false,
		isOnlyTrackNo = false
	) => {
		dispatch(incrementAwaitingApiCounter());
		setShowLoader(true);
		let params = null;
		try {
			const headers = {
				tenant_id: tenant_id,
				user_id: userInfo.user_id,
			};
			const res = await getAllUsers(
				params,
				headers,
				userInfo,
				tenant_name
			);
			setUsersList(res.data);
			dispatch(decrementAwaitingApiCounter());
			setShowLoader(false);
		} catch (error) {
			dispatch(decrementAwaitingApiCounter());
			setShowLoader(false);
		}
	};

	// formate key names and column order

	const onExpandhandler = (event, userId, record) => {
		let expandedRecord = usersList[record];
		event.preventDefault();
		if (expandedRow !== userId) {
			getSingleUserInfo(userId, expandedRecord);
			setExpandedRow(userId);
			setSearchKey();
		} else {
			setExpandedRow(null);
			setSearchKey(null);
		}
	};

	// analogous to getSingleTrackNumberInfo on TrackingPage.jsx
	const getSingleUserInfo = async (userId, expandedRecord) => {
		setModalLoader(true);
		const params = null;
		let headers = null;
		if (!userId) return;
		let response = null;
		let tenant_name = expandedRecord.user_name;
		const chosenTenant = allTenantInfo.find((data) => {
			if (data._id === expandedRecord._id) {
				const payload = {
					TenantFullInfo: data,
					tenantName: tenant_name,
				};
				dispatch(updateTenantUser(payload));
				return data.tenant_id;
			}
		});
		headers = {
			tenant_id: chosenTenant.tenant_id,
			user_id: expandedRecord._id,
		};
		response = await getSingleTenant(
			params,
			headers,
			userId,
			userInfo,
			tenant_name
		);
		const data = response?.data;
		// console.log(data);

		if (data) {
			const filtered_data = {
				first_name: data?.first_name || "",
				last_name: data?.last_name || "",
				phone_number: data?.phone_number || "",
				email: data?.user_name || "",
				id: data?._id || "",
				role: data?.role || "",
			};
			setExpandedUserInfo(filtered_data);
			setModalLoader(false);
		}
	};

	// Search by default column
	const handleSearchBtn = (e) => {
		const searchedVal = e.target.trackingNumber.value.trim();
		setSearchKey(searchedVal);
		setPage(0);
		setRowsPerPage(10);
		setTotalItemCount(0);
		setAdvancedFormData({});
		setShowAdvancedFilter(false);

		if (searchedVal === "") {
			fetchData(true);
			return;
		}

		const filteredRows = usersList.filter((row) => {
			const name1 = row["user_name"].toLowerCase();
			const name2 = searchedVal.toLowerCase();
			return name1.includes(name2);
		});

		setUsersList(filteredRows);
		setTotalItemCount(filteredRows.length);
	};

	const onSearchClick = () => {
		setSearchKey(null);
	};

	// Function that manage the pagination. The event parameter is needed for TablePagination API of MUI
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		setExpandedRow(null);
		if (newPage <= page || usersList.length > rowsPerPage * (page + 1)) {
			return;
		}
		let paginationParams = {
			page_size: rowsPerPage,
			current_page: newPage + 1,
			[sortDataString]: order,
		};
		if (Object.keys(advancedFormData).length > 0) {
			Object.assign(paginationParams, advancedFormData);
		}
		getUsersList(paginationParams, true);
	};

	// Function that changes the number of rows per page
	const handleChangeRowsPerPage = (event) => {
		const newRowsPerPage = event.target.value;
		setRowsPerPage(newRowsPerPage);
		setPage(0);
		if (totalItemsCount > 1) {
			if (
				pathname === "/dashboard/tracking" ||
				advancedFormData.hasOwnProperty("status")
			) {
				fetchData(false, newRowsPerPage);
			}
		}
	};

	// Function to manage what happens when the advanced filter btn is clicked
	const onClickAdvancedFilterBtn = () => {
		setSearchKey(null);
		setAdvancedFormData({});
		if (showAdvancedFilter) {
			setIsRefresh(true);
		}
		setShowAdvancedFilter(!showAdvancedFilter && !showAdvancedFilter);
		setPage(0);
	};

	// TODO: review this
	const onChangeAdvancedForm = (e = null, type) => {
		const { name, value } = e.target;
		let tempData = { ...advancedFormData };
		tempData[name] = value;
		setAdvancedFormData({ ...tempData });
		// if (date == null) {

		// if ((value == null || value === '') && tempData.hasOwnProperty(name)) {
		//   delete tempData[name];
		// } else {
		// }

		// } else {
		// setAdvancedFormData({ ...advancedFormData});
		// }
	};

	// TODO: review this
	const onSubmitAdvancedFilter = async () => {
		dispatch(incrementAwaitingApiCounter());
		setShowLoader(true);
		let tempFormData = getCurrentPageParams(false);
		try {
			let params = null;
			const headers = {
				tenant_id: tenant_id,
				user_id: userInfo.user_id,
			};
			const res = await getAllUsers(
				params,
				headers,
				userInfo,
				tenant_name
			);
			const data = res.data;
			if (res.error) console.log(res.error?.message);

			const dataFiltered = data?.map(
				({
					tenant_id,
					updatedAt,
					createdAt,
					createdSource,
					last_loggedin_at,
					last_loggedout_at,
					last_password_reset_at,
					...rest
				}) => {
					return {
						...rest,
						updatedAt: updatedAt ? unixToDate(updatedAt) : "--",
						last_loggedin_at: last_loggedin_at
							? unixToDate(last_loggedin_at)
							: "--",
						createdAt: createdAt ? unixToDate(createdAt) : "--",
						last_loggedin_at: last_loggedin_at
							? unixToDate(last_loggedin_at)
							: "--",
						createdSource: createdSource,
					};
				}
			);

			const newUserData = dataFiltered.filter((df) => {
				return (
					(!tempFormData.first_name ||
						(df.first_name &&
							df.first_name
								.toLowerCase()
								.includes(
									tempFormData.first_name.toLowerCase()
								))) &&
					(!tempFormData.last_name ||
						(df.last_name &&
							df.last_name
								.toLowerCase()
								.includes(
									tempFormData.last_name.toLowerCase()
								))) &&
					(!tempFormData.phone_number ||
						(df.phone_number &&
							df.phone_number.includes(
								tempFormData.phone_number
							))) &&
					(!tempFormData.user_name ||
						(df.user_name &&
							df.user_name
								.toLowerCase()
								.includes(
									tempFormData.user_name.toLowerCase()
								)))
				);
			});

			setUsersList(newUserData || []);
			setTotalItemCount(newUserData?.length || 0);
			dispatch(decrementAwaitingApiCounter());
			setShowLoader(false);
		} catch (err) {
			dispatch(decrementAwaitingApiCounter());
			setShowLoader(false);
		}

		setExpandedRow(null);
		setPage(0);
	};

	// Function to handle the column display
	const handleColumnSubmit = (columnList) => {
		setUserSelectedColumns(columnList.map((item) => removeTitleCase(item)));
	};

	const onCloseExportInfo = () => {
		setShowExportInfo(false);
	};

	useEffect(() => {
		if (!showAdvancedFilter && (searchKey == "" || searchKey == null)) {
			fetchData(true);
		}
		return () => {
			setIsRefresh(false);
		};
	}, [filteredTenant, isRefresh]);

	useEffect(() => {
		// console.log('usersInfo', usersInfo);
		if (typeof usersInfo !== "undefined" && usersInfo.length > 0) {
			setUserSelectedColumns(usersInfo);
		}
	}, [usersInfo]);

	return (
		<>
			<Helmet>
				<title> Users | BeyondCarts CMS </title>
			</Helmet>
			<Snackbar
				open={showExportInfo}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				autoHideDuration={6000}
				onClose={onCloseExportInfo}
			>
				<Alert
					onClose={onCloseExportInfo}
					severity="success"
					sx={{ width: "100%" }}
				>
					Export in progress
				</Alert>
			</Snackbar>

			<Stack>
				<SmartTable
					columnsToAvoid={["_id"]}
					isSearch
					isAdvancedFilter
					isUsersPage
					isAdminUser={isAdminUser}
					placeholder={searchPlaceholder}
					advancedFilterUsersSearchData={
						advancedFilterUsersSearchData
					}
					isStatusMenu
					isRowSelectable
					isAction
					originalRows={
						typeof dataConverted !== "undefined"
							? dataConverted
							: [{}]
					}
					defaultColumn={defaultColumn}
					statusList={statusList}
					statusAllData={statusAllData}
					onExpandhandler={onExpandhandler}
					expandedRow={expandedRow}
					totelItemCount={totalItemsCount}
					page={page}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					rowsPerPage={rowsPerPage}
					onhandeSeachSubmit={handleSearchBtn}
					searchKey={searchKey}
					onSearchClick={onSearchClick}
					showLoader={showLoader}
					onChangeAdvancedForm={onChangeAdvancedForm}
					advancedFormData={advancedFormData}
					onSubmitAdvancedFilter={onSubmitAdvancedFilter}
					exapndRowIntputData={expandedUserInfo}
					setExpandedUserInfo={setExpandedUserInfo}
					trackingInfoRef={trackingInfoRef}
					showAdvancedFilter={showAdvancedFilter}
					onClickAdvancedFilter={onClickAdvancedFilterBtn}
					// exportButtonClicked={handleExportBtn}
					// trackingPage={true}
					userSelectedColumns={userSelectedColumns}
					handleColumnSubmit={handleColumnSubmit}
					modalLoader={modalLoader}
					setModalLoader={setModalLoader}
					setIsRefresh={setIsRefresh}
				/>
			</Stack>
		</>
	);
};

export default UsersListing;
