import axios from "axios";
import { deleteToken } from "src/utils/extractUserToken";

export default async function deleteData(url, headers, token = null) {
	const randomInt = Math.floor(1e7 + Math.random() * 9e7);
	const config = {
		headers: {
			...headers,
			"Content-Type": "application/json",
			Apikey: process.env.REACT_APP_BACKEND_API_KEY,
			request_id: randomInt,
			"Access-Control-Allow-Origin": "*",
		},
	};

	if (token !== null) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	let result = {};

	try {
		const response = await axios.delete(url, config);
		result = response?.data;
	} catch (error) {
		if ([401, 403].includes(error.response?.status)) {
			deleteToken();
			return;
		}
		throw new Error(
			error?.response?.data?.statusMessage || "Something went wrong! "
		);
	}
	return result;
}
