import { Grid, FormLabel, TextField } from "@mui/material";

function InputForm({
	label,
	id,
	name,
	advancedFormData,
	onChangeAdvancedForm,
}) {
	return (
		<Grid item lg={3} sm={4} width={{ xs: "48%", sm: "50%", md: "60%" }}>
			<FormLabel
				htmlFor={id}
				variant="subtitle2"
				sx={{
					color: "secondary.light",
					marginBottom: 1,
					fontSize: 14,
					fontWeight: 600,
				}}
			>
				{label}
			</FormLabel>
			<TextField
				id={id}
				value={advancedFormData[name] ? advancedFormData[name] : null}
				name={name}
				onChange={(e) => onChangeAdvancedForm(e)}
				sx={{
					width: { lg: "100%" },
					backgroundColor: "#FFF",
					marginTop: { lg: 1, sm: 0.5, xs: 0.5 },
					"& input": {
						height: { lg: 14, md: 8, sm: 2, xs: 2 },
						width: "100%",
						color: "#000",
					},
					"& .MuiOutlinedInput-root": {
						"&:hover fieldset": {
							borderColor: "primary.dark",
						},
						"&.Mui-focused fieldset": {
							borderColor: "primary.dark",
						},
					},
				}}
			/>
		</Grid>
	);
}

export default InputForm;
