import deleteData from "src/dataFetcher/deleteDataFetcher";
import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";


const backendUserUrl = process.env.REACT_APP_BACKEND_BASE_URL
export async function getSubscriptionPlan(token) {
	const get_subscriptionPlan_url = `${backendUserUrl}subscription/admin/subscription/plan`;
	return await getData(get_subscriptionPlan_url,{},{},token);
}

export async function getSubscriptionPlanById(id, token) {
	const get_subscriptionPlan_url = `${backendUserUrl}subscription/admin/subscription/plan/${id}`;
	return await getData(get_subscriptionPlan_url,{},{},token);
}

export async function createPlan(headers, body, userInfo) {
	const token = userInfo?.accessToken;
	return await postData(
		`${backendUserUrl}subscription/admin/subscription/plan`,
		headers,
		body,
		token
	);
}

export async function updatePlan(headers, data, userInfo, subscriptionPlanId) {
	const token = userInfo.accessToken;
	const result = await putData(
		`${backendUserUrl}subscription/admin/subscription/plan/${subscriptionPlanId}`,
		headers,
		data,
		token
	);
	return result;
}

export async function deletePlan(cardId, userInfo) {
	const token = userInfo?.accessToken;

	const header = {
		tenant_id: userInfo?.tenant_id,
	};

	return await deleteData(
		`${backendUserUrl}subscription/admin/subscription/plan/${cardId}`,
		header,
		token
	);
}

export async function disablePlan(cardId, userInfo, data) {
	const token = userInfo?.accessToken;
	const header = {
		tenant_id: userInfo?.tenant_id,
	};

	return await putData(
		`${backendUserUrl}subscription/admin/subscription/disable/${cardId}`,
		header,
		data,
		token
	);
}