import { Stepper, Step, StepLabel } from "@mui/material";

import "./stepper.scss";
import palette from "src/theme/palette";

const steps = [
	"Carriers",
	"Order Management",
	"Login to shipping",
	"Login to Order Management",
	"Plan selection",
	"Billing",
	"Summary",
];

const FormStepper = ({ step }) => {
	const useStyles = {
		"& .Mui-active": {
			"&.MuiStepIcon-root": {
				color: palette.secondary.main,
			},
		},
		"& .Mui-completed": {
			"&.MuiStepIcon-root": {
				color: palette.secondary.main,
			},
		},
	};

	return (
		<div className="stepper-container">
			<Stepper activeStep={step} alternativeLabel sx={useStyles}>
				{steps.map((label, index) => {
					const labelProps = {
						color: index === step ? "#212529" : "#868e96",
					};

					return (
						<Step key={label}>
							<StepLabel>
								<p style={labelProps}>{label}</p>
							</StepLabel>
						</Step>
					);
				})}
			</Stepper>
		</div>
	);
};

export default FormStepper;
