import {
	Stack,
	Box,
	TextField,
	Divider,
	Button,
	FormHelperText,
	FormControl,
	InputAdornment,
	IconButton,
} from "@mui/material";
import ModalUserRowDetails from "../modal/ModalUserRowDetails";
import { getUserInfo, login } from "src/features/user/userState";
import { useAppSelector } from "src/hooks/hooks";
import Iconify from "src/components/iconify";
import typography from "src/theme/typography";
import {
	promoteToAdmin,
	updateAdminPassword,
	updateAdminUser,
	updateTenantUser,
	updateTenantPassword,
	getAllTenantUsers,
} from "src/features/usersPage/usersApi";
import { useEffect, useMemo, useState } from "react";
import palette from "src/theme/palette";
import { useForm } from "react-hook-form";
import CircularLoader from "src/loader/circular/CircularLoader";
import { useSelector } from "react-redux";
import { MuiTelInput } from "mui-tel-input";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import timer from "src/utils/timer";
import isAdminUser from "src/utils/isAdminUser";

const styleInput = {
	input: { color: palette.common.black },
	"& .MuiOutlinedInput-root": {
		"&:hover fieldset": {
			borderColor: "primary.dark",
		},
		"&.Mui-focused fieldset": {
			borderColor: "primary.dark",
		},
	},
};

const theme = createTheme({
	overrides: {
		MuiTelInput: {
			root: {
				color: "black",
			},
		},
	},
});

const UsersTableRowDetails = ({
	inputData,
	setExpandedUserInfo,
	onExpandhandler,
	setIsRefresh,
	type,
	showLoader,
	setShowLoader = () => {},
	successfulUpdate,
	setSuccessfulUpdate = () => {},
	openChangePassword,
	setOpenChangePassword = () => {},
	openPasswordHasChanged,
	setOpenPasswordHasChanged = () => {},
}) => {
	const selectedUserId = inputData?.id;
	const userInfo = useAppSelector(getUserInfo);
	const [statusMessage, setStatusMessage] = useState("");
	const [isAdminMessage, setIsAdminMessage] = useState("");
	const [isError, setIsError] = useState(false);

	const [isSubmited, setIsSubmited] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState();
	const [phoneValue, setPhoneValue] = useState("");
	const [confirmPassword, setConfirmPassword] = useState();
	const { TenantFullInfo, tenantName } = useSelector(
		(state) => state.chosenTenantUser
	);

	const [roleChanged, setRoleChanged] = useState(false);
	const [isRoleChanged, setIsRoleChanged] = useState(false);
	const isAdmin = roleChanged
		? !isAdminUser(inputData)
		: isAdminUser(inputData);
	const [isBasicUser, setIsBasicUser] = useState(false);

	const {
		register,
		handleSubmit,
		reset,
		clearErrors,
		formState: { errors },
	} = useForm({
		defaultValues: async () => {
			return {
				first_name: inputData?.first_name,
				last_name: inputData?.last_name,
				user_name: inputData?.email,
				phone_number: setPhoneValue(inputData?.phone_number || ""),
			};
		},
	});

	const filteredTenant = useSelector(
		(state) => state.selectedTenant.SelectedTenant
	);

	const onSubmitUpdate = async (data) => {
		setShowLoader(true);

		const obj = {
			role: inputData?.role,
			...data,
			phone_number: phoneValue,
		};

		try {
			let response = null;
			if (type === "1") {
				response = await updateAdminUser(obj, inputData?.id, userInfo);
			}

			if (type === "2" && filteredTenant?._id) {
				let headers = {
					tenant_id: filteredTenant?._id,
					user_id: userInfo.user_id,
				};
				let tenant_name = filteredTenant?.tenant_name;
				response = await updateTenantUser(
					obj,
					inputData?.id,
					userInfo,
					tenant_name,
					headers
				);
			}

			if (type === "2" && !filteredTenant?._id) {
				let headers = {
					tenant_id: TenantFullInfo?.tenant_id,
					user_id: userInfo?.user_id,
				};
				response = await updateTenantUser(
					obj,
					inputData?.id,
					userInfo,
					tenantName,
					headers
				);
			}

			if (type === "" || type === undefined) {
				let headers = {
					tenant_id: TenantFullInfo?.tenant_id,
					user_id: userInfo?.user_id,
				};
				response = await updateTenantUser(
					obj,
					inputData?.id,
					userInfo,
					tenantName,
					headers
				);
			}

			if (userInfo.user_id === selectedUserId)
				localStorage.setItem("first_name", obj.first_name);

			setExpandedUserInfo({ ...inputData, ...obj });
			setShowLoader(false);
			setSuccessfulUpdate(true);
			setIsError(false);
		} catch (err) {
			// console.log(obj);
			setIsError(true);
			setShowLoader(false);
			setStatusMessage("There has been an unexpected error. Try again");
		}
	};

	const handlePromoteToAdmin = async () => {
		if (isAdmin) {
			// update tenant user role to basic when its adming
			const response = await onSubmitUpdate({
				first_name: inputData?.first_name,
				last_name: inputData?.last_name,
				user_name: inputData?.email,
				phone_number: inputData?.phone_number,
				role: "basic",
			});
			setRoleChanged(!roleChanged);
			setIsRoleChanged(true);
			setIsBasicUser(true);
			return;
		}

		let response;

		setShowLoader(true);

		try {
			if (type === "2" && !filteredTenant?._id) {
				let headers = {
					tenant_id: TenantFullInfo?.tenant_id,
					user_id: userInfo?.user_id,
				};
				response = await promoteToAdmin(
					headers,
					selectedUserId,
					userInfo,
					tenantName
				);
			}
			if (type === "2" && filteredTenant?._id) {
				let headers = {
					tenant_id: filteredTenant?._id,
					user_id: userInfo.user_id,
				};
				let tenant_name = filteredTenant?.tenant_name;
				response = await promoteToAdmin(
					headers,
					selectedUserId,
					userInfo,
					tenant_name
				);
			}
			if (type === "" || type === undefined) {
				let headers = {
					tenant_id: TenantFullInfo?.tenant_id,
					user_id: userInfo?.user_id,
				};
				response = await promoteToAdmin(
					headers,
					selectedUserId,
					userInfo,
					tenantName
				);
			}

			if (response?.statusCode === 200) {
				setRoleChanged(!roleChanged);
				setIsRoleChanged(true);
				setIsBasicUser(false);
				setSuccessfulUpdate(true);
				setExpandedUserInfo({ ...inputData, role: "admin" });
			} else {
				setIsError(true);
				setIsAdminMessage(
					"❌There was an error promoting this user to admin"
				);
			}
			setShowLoader(false);
		} catch (error) {
			setIsError(true);
			setIsAdminMessage(error.message);
			setShowLoader(false);
		}
	};

	const onChangePasswordSubmit = async (data) => {
		if (password !== confirmPassword) {
			setIsError(true);
			setStatusMessage("Passwords doesn't match");
			return;
		}

		const obj = {
			password: data?.new_password,
			confirm_password: data?.confirm_new_password,
		};

		let response;

		try {
			setShowLoader(true);

			if (type === "1") {
				response = await updateAdminPassword(
					obj,
					selectedUserId,
					userInfo
				);
			}
			if (type === "2" && filteredTenant?._id) {
				let headers = {
					tenant_id: filteredTenant?._id,
					user_id: userInfo.user_id,
				};
				let tenant_name = filteredTenant?.tenant_name;
				response = await updateTenantPassword(
					obj,
					selectedUserId,
					userInfo,
					tenant_name,
					headers
				);
			}
			if (type === "2" && !filteredTenant?._id) {
				let headers = {
					tenant_id: TenantFullInfo?.tenant_id,
					user_id: userInfo?.user_id,
				};
				response = await updateTenantPassword(
					obj,
					selectedUserId,
					userInfo,
					tenantName,
					headers
				);
			}
			if (type === "" || type === undefined) {
				let headers = {
					tenant_id: TenantFullInfo?.tenant_id,
					user_id: userInfo?.user_id,
				};
				response = await updateTenantPassword(
					obj,
					selectedUserId,
					userInfo,
					tenantName,
					headers
				);
			}

			setShowLoader(false);
			setIsError(false);
			setIsSubmited(true);
			setOpenPasswordHasChanged(true);
		} catch (error) {
			if (
				error.message?.startsWith("Password does not conform to policy")
			) {
				setIsSubmited(true);
				setIsError(true);
				setStatusMessage(error.message);
			} else if (error.message?.startsWith("Attempt limit exceeded")) {
				setIsSubmited(true);
				setIsError(true);
				setStatusMessage(error.message);
			} else {
				setIsSubmited(true);
				setIsError(true);
				setStatusMessage(
					"There has been an unexpected error. Try again"
				);
			}
			setShowLoader(false);
		}
	};

	const handlePhoneChange = (newValue) => {
		let newPhone = newValue.split(" ");
		let newPhoneStr = "";

		for (let index = 0; index < newPhone.length; index++) {
			if (index === 0) newPhoneStr += newPhone[index] + " ";
			else newPhoneStr += newPhone[index];
		}

		newPhone = newPhoneStr.split(" ");

		if (newPhone[1]?.length > 10) return;

		setPhoneValue(newPhoneStr);
	};

	return showLoader ? (
		<CircularLoader />
	) : (
		<ModalUserRowDetails
			inputData={inputData}
			onExpandhandler={onExpandhandler}
			showLoader={showLoader}
			setIsRefresh={setIsRefresh}
			setSuccessfulUpdate={setSuccessfulUpdate}
			setOpenChangePassword={setOpenChangePassword}
			setOpenPasswordHasChanged={setOpenPasswordHasChanged}
			extraLogicOnCloseCallback={() => {
				setShowLoader(true);
			}}
		>
			{openChangePassword ? (
				<>
					{/* modal: successful password update */}
					{openPasswordHasChanged ? (
						<Stack
							gap={6}
							direction={"column"}
							justifyContent={"center"}
							alignItems={"center"}
							sx={{
								width: "100%",
								color: "common.black",
								paddingY: 2,
								paddingX: 5,
								fontFamily: typography.fontFamilyRegular,
								backgroundColor: "white",
							}}
						>
							<Stack spacing={2}>
								<h2
									style={{
										...typography.h3,
										textAlign: "center",
									}}
								>
									User password updated successfully
								</h2>
							</Stack>
							<Button
								size="large"
								type="submit"
								variant="contained"
								sx={{
									marginTop: 3,
									boxShadow: "none",
									backgroundColor: palette.secondary.main,
									width: {
										lg: 300,
										md: 300,
										xs: "100%",
									},
								}}
								onClick={() => {
									setOpenPasswordHasChanged(false);
									setOpenChangePassword(false);
								}}
							>
								Go Back
							</Button>
						</Stack>
					) : (
						<Stack
							gap={6}
							direction={"column"}
							sx={{
								width: "100%",
								color: "common.black",
								fontFamily: typography.fontFamilyRegular,
								backgroundColor: "white",
							}}
						>
							<FormControl
								component="form"
								className="form"
								onSubmit={handleSubmit(onChangePasswordSubmit)}
								sx={{ width: { lg: 600, md: 500, xs: 300 } }}
							>
								<Stack paddingBottom={2}>
									<h2
										style={{
											...typography.h2,
											textAlign: "center",
										}}
									>
										Change user password
									</h2>
								</Stack>
								<Stack
									spacing={3}
									alignItems={"center"}
									sx={{
										width: "100%",
										color: "common.black",
										paddingY: 2,
										paddingX: 5,
										fontFamily:
											typography.fontFamilyRegular,
										backgroundColor: "white",
									}}
								>
									<TextField
										fullWidth
										sx={styleInput}
										label="New Password"
										name="new_password"
										type={
											showPassword ? "text" : "password"
										}
										autoFocus
										{...register("new_password", {
											required:
												"New Password is required.",
										})}
										error={!!errors.new_password}
										aria-invalid={!!errors.new_password}
										helperText={
											errors.new_password &&
											errors.new_password.message
										}
										onChange={(e) => {
											clearErrors("new_password");
											setPassword(e.target.value);
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() =>
															setShowPassword(
																!showPassword
															)
														}
														edge="end"
													>
														<Iconify
															icon={
																showPassword
																	? "eva:eye-fill"
																	: "eva:eye-off-fill"
															}
														/>
													</IconButton>
												</InputAdornment>
											),
										}}
									/>

									<TextField
										fullWidth
										label="Confirm Password"
										name="confirm_new_password"
										sx={styleInput}
										type={
											showPassword ? "text" : "password"
										}
										{...register("confirm_new_password", {
											required:
												"To confirm your new password is required.",
										})}
										error={
											!!errors.confirm_new_password ||
											isError
										}
										aria-invalid={
											!!errors.confirm_new_password
										}
										helperText={
											errors.confirm_new_password
												? errors.confirm_new_password
														.message
												: isError && statusMessage
										}
										onChange={(e) => {
											clearErrors("confirm_new_password");
											setIsError(false);
											setConfirmPassword(e.target.value);
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() =>
															setShowPassword(
																!showPassword
															)
														}
														edge="end"
													>
														<Iconify
															icon={
																showPassword
																	? "eva:eye-fill"
																	: "eva:eye-off-fill"
															}
														/>
													</IconButton>
												</InputAdornment>
											),
										}}
									/>

									<Button
										size="large"
										type="submit"
										variant="contained"
										sx={{
											marginTop: 3,
											boxShadow: "none",
											backgroundColor:
												palette.secondary.main,
											width: {
												lg: 350,
												md: 350,
												xs: "100%",
											},
										}}
									>
										Change Password
									</Button>
								</Stack>
							</FormControl>
						</Stack>
					)}
				</>
			) : (
				<>
					{/* modal: successful info update */}
					{successfulUpdate ? (
						<Stack
							gap={2}
							direction={"column"}
							justifyContent={"center"}
							alignItems={"center"}
							sx={{
								width: "100%",
								color: "common.black",
								paddingY: 1,
								paddingX: 3,
								fontFamily: typography.fontFamilyRegular,
								backgroundColor: "white",
							}}
						>
							<Stack spacing={2}>
								<h2
									style={{
										...typography.h3,
										textAlign: "center",
									}}
								>
									User information
								</h2>
							</Stack>
							<Box
								sx={{
									fontSize: { xs: 14, md: 16 },
								}}
							>
								User information updated successfully.
							</Box>
							<Button
								size="large"
								type="submit"
								variant="contained"
								sx={{
									boxShadow: "none",
									backgroundColor: palette.secondary.main,
									width: {
										lg: 300,
										md: 300,
										xs: "100%",
									},
								}}
								onClick={() => {
									setSuccessfulUpdate(false);
								}}
							>
								Back
							</Button>
						</Stack>
					) : (
						<Stack gap={4}>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: 2,
									paddingBottom: 1,
								}}
							>
								<h3
									style={{
										...typography.h2,
										textAlign: "center",
									}}
								>
									User Information
								</h3>
								<Box
									sx={{
										fontSize: { xs: 14, md: 16 },
									}}
								>
									Edit user information
								</Box>
							</Box>

							<FormControl
								component="form"
								onSubmit={handleSubmit(onSubmitUpdate)}
								sx={{
									display: "flex",
									alignItems: "center",
									gap: 3,
								}}
							>
								<Box display={"flex"} gap={4}>
									<Stack
										gap={2}
										sx={{
											width: {
												lg: 400,
												md: 300,
												sm: 200,
											},
										}}
									>
										<TextField
											className="textField"
											label={"First Name"}
											name={"first_name"}
											{...register("first_name")}
											onInput={(event) => {
												const inputValue =
													event.target.value.replace(
														/[^a-zA-Z0-9\s]/g,
														""
													);
												event.target.value = inputValue;
											}}
											sx={styleInput}
										/>
										{/* <TextField
											className="textField"
											label={"Phone Number"}
											name={"phone_number"}
											{...register("phone_number")}
											sx={styleInput}
										/> */}
										<ThemeProvider theme={theme}>
											<MuiTelInput
												sx={styleInput}
												defaultCountry="US"
												forceCallingCode={true}
												label="Phone Number"
												name="phone_number"
												value={phoneValue}
												{...register("phone_number", {
													required:
														"Phone number is required.",
												})}
												error={!!errors.phone_number}
												helperText={
													errors.phone_number &&
													errors.phone_number.message
												}
												onChange={handlePhoneChange}
											/>
										</ThemeProvider>
									</Stack>
									<Stack
										gap={2}
										sx={{
											width: {
												lg: 400,
												md: 300,
												sm: 200,
											},
										}}
									>
										<TextField
											className="textField"
											label={"Last Name"}
											name={"last_name"}
											onInput={(event) => {
												const inputValue =
													event.target.value.replace(
														/[^a-zA-Z0-9\s]/g,
														""
													);
												event.target.value = inputValue;
											}}
											{...register("last_name")}
											sx={styleInput}
										/>
										<TextField
											className="textField"
											disabled
											key={"Email Address"}
											label={"Email Address"}
											name={"email"}
											{...register("user_name")}
											sx={styleInput}
										/>
									</Stack>
								</Box>

								{statusMessage && (
									<FormHelperText
										error={isError}
										sx={{
											fontSize: { xs: 12, lg: 14 },
										}}
									>
										{statusMessage}
									</FormHelperText>
								)}

								<Button
									variant="contained"
									size="large"
									type="submit"
									disableElevation
									sx={{
										boxShadow: 0,
										backgroundColor: palette.secondary.main,
										width: {
											lg: 300,
											md: 300,
											xs: "100%",
										},
									}}
								>
									Update
								</Button>
							</FormControl>
							<Divider />

							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									gap: 2,
								}}
							>
								<h3
									style={{
										...typography.h2,
										textAlign: "center",
									}}
								>
									Security Information
								</h3>
								<Box
									sx={{
										fontSize: { xs: 14, md: 16 },
									}}
								>
									Edit user security information
								</Box>

								<Stack
									spacing={2}
									direction={"row"}
									justifyContent={"center"}
									paddingTop={2}
								>
									<Button
										variant="contained"
										size="large"
										disableElevation
										sx={{
											boxShadow: 0,
											backgroundColor:
												palette.secondary.main,
											width: {
												lg: 300,
												md: 300,
												xs: "100%",
											},
										}}
										onClick={() =>
											setOpenChangePassword(true)
										}
									>
										Change Password
									</Button>

									{type !== "1" && (
										<Button
											variant="contained"
											size="large"
											disableElevation
											sx={{
												boxShadow: 0,
												backgroundColor:
													palette.secondary.main,
												width: {
													lg: 300,
													md: 300,
													xs: "100%",
												},
											}}
											onClick={handlePromoteToAdmin}
										>
											{isAdmin
												? "Change Role to Basic"
												: "Promote to Administrator"}
										</Button>
									)}
								</Stack>
								{isAdminMessage && (
									<FormHelperText
										error={isError}
										sx={{
											fontSize: { xs: 12, lg: 14 },
										}}
									>
										{isAdminMessage}
									</FormHelperText>
								)}
							</Box>
						</Stack>
					)}
				</>
			)}
		</ModalUserRowDetails>
	);
};

export default UsersTableRowDetails;
