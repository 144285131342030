import { useState, Fragment } from "react";
import { Helmet } from "react-helmet-async";
import { styled } from "@mui/material/styles";
import { Container, Typography } from "@mui/material";
import Logo from "src/components/logo";
import { LoginForm } from "src/sections/auth/login";
import SigninForm from "src/sections/auth/signin/signinForm";
import typography from "src/theme/typography";

const StyledRoot = styled("div")(({ theme }) => ({
	height: "100%",

	[theme.breakpoints.up("md")]: {
		display: "flex",
		alignItems: "center",
		height: "100%",
		margin: "auto",
		justifyContent: "center",
	},
}));

const StyledSection = styled("div")(({ theme }) => ({
	flexDirection: "column",
	[theme.breakpoints.down("md")]: {
		display: "none",
	},
}));

const StyledContent = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	flexDirection: "column",
	padding: theme.spacing(0, 5),
	gap: 30,
	[theme.breakpoints.down("md")]: {
		gap: theme.spacing(5),
		padding: theme.spacing(0, 6),
	},
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
	// const { pathname } = useLocation();
	const [signUp, setSignUp] = useState(false);
	const [isOtpView, setIsOtpView] = useState(false);

	return (
		<Fragment>
			<Helmet>
				<title> Login | BeyondCarts CMS </title>
			</Helmet>

			<StyledRoot>
				<Container
					sx={{
						maxWidth: { lg: 700 },
						alignItems: "center",
						marginTop: "80px",
						justifyContent: "center",
						display: "flex",
					}}
				>
					<Logo
						isLight={false}
						sx={{
							position: "fixed",
							top: { xs: 20, sm: 24, md: 30, lg: 30 },
							left: { xs: 20, sm: 24, md: 30, lg: 30 },
						}}
					/>
					<StyledSection
						sx={{
							width: "100%",
						}}
					>
						<img
							src="/assets/images/signup.svg"
							alt="login"
							style={{}}
						/>
					</StyledSection>
				</Container>

				<Container
					sx={{
						maxWidth: { lg: 700 },
						position: { xs: "absolute", md: "unset", lg: "unset" },
						top: "50%",
						left: "50%",
						transform: {
							xs: "translate(-50%, -50%)",
							md: "unset",
							lg: "none",
						},
					}}
				>
					<StyledContent>
						<h1 style={typography.bigTitle} className="bigTitle">
							{signUp
								? isOtpView
									? "Confirmation Code"
									: "Get Started"
								: "Welcome!"}
						</h1>
						{signUp ? (
							<SigninForm
								setSignUp={setSignUp}
								isOtpView={isOtpView}
								setIsOtpView={setIsOtpView}
							/>
						) : (
							<LoginForm setSignUp={setSignUp} />
						)}
					</StyledContent>
				</Container>
			</StyledRoot>
		</Fragment>
	);
}
