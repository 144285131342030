import { Stack, Box, Modal, IconButton, CircularProgress } from "@mui/material";

import { useState } from "react";
import typography from "src/theme/typography";

const popUpModalStyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	// width: { lg: "70%", md: "80%", sm: "72%", xs: "72%" },
	width: "auto",
	height: "auto",
	borderRadius: "20px",
	background: "#FFF",
	// padding: '15px 0',
	overflow: "auto",
	overflow: "auto-y",
	overflowX: "hidden",
	outline: "none",
};

const ModalUserRowDetails = ({
	children,
	inputData,
	onExpandhandler,
	showLoader,
	setSuccessfulUpdate,
	setOpenChangePassword,
	setOpenPasswordHasChanged,
	setIsRefresh,
	extraLogicOnCloseCallback = () => {},
}) => {
	// TODO: make this id conditionally according to the url
	const id = inputData?.tracking_number || inputData?.id;

	const [openModal, setOpenModal] = useState(true);

	const handleCloseModal = (e) => {
		setOpenModal(false);
		onExpandhandler(e, id, inputData);
		setIsRefresh(true);
		extraLogicOnCloseCallback();
		setSuccessfulUpdate(false);
		setOpenChangePassword(false);
		setOpenPasswordHasChanged(false);
	};

	return (
		<>
			{/* {showLoader ||
				(Object.keys(inputData).length == 0 && (
					<CircularProgress sx={{ color: "primary.main" }} />
				))} */}
			<Modal open={openModal} onClose={handleCloseModal}>
				<Box
					sx={{
						...popUpModalStyle,
					}}
				>
					{/* {showLoader || Object.keys(inputData).length == 0 && (
					<CircularProgress sx={{ color: "primary.main" }} />
				) : ( */}
					<Stack
						direction="row"
						// spacing={{ lg: 8, sm: 2, md: 2 }}
						sx={{
							color: "#000",
							padding: 4,
							fontSize: { sm: 14, lg: 20 },
							fontFamily: typography.fontFamilyRegular,
							backgroundColor: "white",
							// margin: 'auto',
						}}
					>
						<IconButton
							aria-label="close"
							sx={{
								position: "fixed",
								top: { sm: 15, lg: 34 },
								right: { lg: 29, md: 30, sm: 10 },
								zIndex: 2,
							}}
							onClick={handleCloseModal}
						>
							<img
								width="34px"
								src="/assets/icons/table/close.svg"
								alt="X"
							/>
						</IconButton>

						{children}
					</Stack>
					{/* )} */}
				</Box>
			</Modal>
		</>
	);
};

export default ModalUserRowDetails;
