import PropTypes from "prop-types";
import { forwardRef } from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Box, Link } from "@mui/material";
import useResponsive from "src/hooks/useResponsive";
import LogoSvg from "./LogoSvg";

// ----------------------------------------------------------------------

const Logo = forwardRef(
	({ disabledLink = false, sx, isLight, ...other }, ref) => {
		const theme = useTheme();

		const isDesktop = useResponsive("up", "md");

		const PRIMARY_LIGHT = theme.palette.primary.light;
		const PRIMARY_MAIN = theme.palette.primary.main;
		const PRIMARY_DARK = theme.palette.primary.dark;

		const darkLogo = (
			<Box
				ref={ref}
				component="div"
				sx={{
					height: "auto",
					display: "inline-flex",
					...sx,
				}}
				{...other}
			>
				{isDesktop ? (
					<LogoSvg
						width={180}
						fillC1={"#0765A9"}
						fillC2={"#143F67"}
					/>
				) : (
					<LogoSvg
						width={150}
						fillC1={"#0765A9"}
						fillC2={"#143F67"}
					/>
				)}
			</Box>
		);

		const lightLogo = (
			<Box
				ref={ref}
				component="div"
				sx={{
					height: "auto",
					display: "inline-flex",
					...sx,
				}}
				{...other}
			>
				{isDesktop ? (
					<LogoSvg width={150} fillC1={"#fff"} fillC2={"#fff"} />
				) : (
					<LogoSvg width={40} fillC1={"#fff"} fillC2={"#fff"} />
				)}
			</Box>
		);

		if (disabledLink) {
			// eslint-disable-next-line react/jsx-no-useless-fragment
			return <>{darkLogo}</>;
		}

		return (
			<Link to="/" component={RouterLink} sx={{ display: "contents" }}>
				{isLight ? lightLogo : darkLogo}
			</Link>
		);
	}
);

Logo.propTypes = {
	sx: PropTypes.object,
	disabledLink: PropTypes.bool,
	isLight: PropTypes.bool,
};

export default Logo;
