import {
	Backdrop,
	CircularProgress,
	Stack,
	TextField,
	InputAdornment,
	IconButton,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import Iconify from "src/components/iconify";
import ResetPassword from "src/components/reset-password/ResetPassword";
import timer from "src/utils/timer";
import { forceUpdatePassword } from "src/features/user/userApi";
import { useAppSelector } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";
import Typography from "src/theme/overrides/Typography";
import { passwordValidator } from "src/utils/validators";
import CircularLoader from "src/loader/circular/CircularLoader";
import { ErrorMessagesSchema } from "src/utils/errorMessagesSchema";

const inputStyles = {
	"& .MuiOutlinedInput-root": {
		"&:hover fieldset": {
			borderColor: "primary.dark",
		},
		"&.Mui-focused fieldset": {
			borderColor: "primary.dark",
		},
	},
};
const ResetPasswordLogged = () => {
	const {
		register,
		handleSubmit,
		// clearErrors,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();
	const {
		state: { email, generatedPassword },
	} = useLocation();

	const userInfo = useAppSelector(getUserInfo);

	const [isSubmited, setIsSubmited] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const [passwordError, setPasswordError] = useState(false);

	const onSubmit = async (data) => {
		if (data?.new_password !== data?.confirm_new_password) {
			setPasswordError(true);
			setIsSubmited(true);
			setErrorMessage(
				`${ErrorMessagesSchema.passwordValidation.dontMatch}`
			);
			return;
		}

		if (!passwordValidator(data?.new_password)) {
			setPasswordError(true);
			setIsSubmited(true);
			setErrorMessage(
				`${ErrorMessagesSchema.passwordValidation.requirements}`
			);
			return;
		}

		setIsLoading(true);
		await timer(1000);

		const obj = {
			...data,
			email,
			password: generatedPassword,
		};

		try {
			const response = await forceUpdatePassword(obj, userInfo);
			setIsLoading(false);

			if (response.status === 200) {
				setErrorMessage("");
				setIsLoading(true);
				await timer(1000);
				navigate("/reset-password/done", { state: { obj } });
			}

			if (
				response?.data?.statusMessage?.startsWith(
					"Password does not conform to policy"
				)
			) {
				setIsSubmited(true);
				setErrorMessage(
					`${ErrorMessagesSchema.passwordValidation.requirements}`
				);
			} else if (
				response?.data?.statusMessage?.startsWith(
					"Attempt limit exceeded"
				)
			) {
				setIsSubmited(true);
				setErrorMessage(
					`${ErrorMessagesSchema.passwordValidation.attemptLimit}`
				);
			}
		} catch (error) {
			if (error.message.includes("Invalid"))
				setErrorMessage(
					"Invalid verification code provided, please try again."
				);
			else {
				setErrorMessage(
					"There has been an unexpected error. Try again"
				);
			}
			setIsSubmited(true);
			setIsLoading(false);
		}
	};

	return (
		<Fragment>
			<Helmet>
				<title> Reset Password | BeyondCarts CMS </title>
			</Helmet>

			{isLoading && <CircularLoader />}

			<ResetPassword
				title={"Change your password"}
				btnText={"Reset Password"}
				btnText2={"Back to log in"}
				handleSubmit={handleSubmit}
				setIsSubmited={setIsSubmited}
				onSubmit={onSubmit}
			>
				<Stack spacing={2}>
					<TextField
						label="New Password"
						name="new_password"
						type={showPassword ? "text" : "password"}
						autoFocus
						{...register("new_password", {
							required: `${ErrorMessagesSchema.newPassword.required}`,
						})}
						error={!!errors.new_password}
						aria-invalid={!!errors.new_password}
						helperText={
							errors.new_password && errors.new_password.message
						}
						sx={inputStyles}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() =>
											setShowPassword(!showPassword)
										}
										edge="end"
									>
										<Iconify
											icon={
												showPassword
													? "eva:eye-fill"
													: "eva:eye-off-fill"
											}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>

					<TextField
						label="Confirm Password"
						name="confirm_new_password"
						type={showPassword ? "text" : "password"}
						{...register("confirm_new_password", {
							required: `${ErrorMessagesSchema.confirmPassword.required}`,
						})}
						error={!!errors.confirm_new_password || passwordError}
						aria-invalid={!!errors.confirm_new_password}
						helperText={
							errors.confirm_new_password
								? errors.confirm_new_password.message
								: passwordError
								? errorMessage
								: null
						}
						onFocus={() => setPasswordError(false)}
						sx={inputStyles}
						InputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton
										onClick={() =>
											setShowPassword(!showPassword)
										}
										edge="end"
									>
										<Iconify
											icon={
												showPassword
													? "eva:eye-fill"
													: "eva:eye-off-fill"
											}
										/>
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
				</Stack>
			</ResetPassword>
		</Fragment>
	);
};

export default ResetPasswordLogged;
