import palette from "src/theme/palette";

const textStyle = {
	color: palette.common.black,
	fontSize: { xs: 16, md: 18 },
};

const styleInput = {
	input: { color: palette.common.black },
	width: "100%",
	marginTop: "10px",
	"& .MuiOutlinedInput-root": {
		"&:hover fieldset": {
			borderColor: "primary.dark",
		},
		"&.Mui-focused fieldset": {
			borderColor: "primary.dark",
		},
	},
	"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "secondary.light",
	},
	"& .MuiInputLabel-formControl": {
		color: "#00000099",
	},
	"& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
		color: "#00000099",
	},
	"& .MuiFormHelperText-root": {
		textAlign: "left",
		fontSize: "14px",
	},
};

const months = [
	"01-January",
	"02-February",
	"03-March",
	"04-April",
	"05-May",
	"06-June",
	"07-July",
	"08-August",
	"09-September",
	"10-October",
	"11-November",
	"12-December",
];

const years = [
	"2023",
	"2024",
	"2025",
	"2026",
	"2027",
	"2028",
	"2029",
	"2030",
	"2031",
	"2032",
	"2033",
	"2034",
];

const currentYear = new Date().getFullYear();
const yearsArray = Array.from(
	{ length: 11 },
	(_, index) => currentYear + index
);

const futureYears = yearsArray.filter((year) => year >= currentYear);

const capitalize = (string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const cc_format = (value) => {
	let newValue = "";

	for (let i = 0; i < value.length; i++) {
		if (i === 4 || i === 8 || i === 12) newValue += " ";
		newValue += value[i];
	}
	return newValue;
};

const showBrandLogo = (brand) => {
	if (brand === "mastercard") return "/assets/master.svg";
	if (brand === "visa") return "/assets/visa.svg";
	if (brand === "amex") return "/assets/amex.svg";
};

const creditCardType = (cc, setCreditLogo) => {
	let amex = new RegExp("^3[47][0-9]{13}$");
	let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
	let cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
	let cup2 = new RegExp("^81[0-9]{14}[0-9]*$");

	let mastercard = new RegExp("^5[1-5][0-9]{14}$");
	let mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

	let disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
	let disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
	let disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");

	let diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
	let jcb = new RegExp("^35[0-9]{14}[0-9]*$");

	if (visa.test(cc)) {
		setCreditLogo("/assets/visa.svg");
		return;
	}
	if (amex.test(cc)) {
		setCreditLogo("/assets/amex.svg");
		return;
	}
	if (mastercard.test(cc) || mastercard2.test(cc)) {
		setCreditLogo("/assets/master.svg");
		return;
	}
	if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
		return "DISCOVER";
	}
	if (diners.test(cc)) {
		return "DINERS";
	}
	if (jcb.test(cc)) {
		return "JCB";
	}
	if (cup1.test(cc) || cup2.test(cc)) {
		return "CHINA_UNION_PAY";
	}
	if (cc.length < 12) setCreditLogo(null);
};

export {
	textStyle,
	styleInput,
	months,
	futureYears,
	capitalize,
	cc_format,
	showBrandLogo,
	creditCardType,
};
