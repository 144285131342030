import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
// @mui
import { styled } from "@mui/material/styles";
import { Button, Typography, Stack, Box, Grid } from "@mui/material";
import DateRangePicker from "../../components/date-picker/DateRangePicker";
import Iconify from "../../components/iconify/Iconify";
import TransitDataCard from "../../components/data-card/TransitDataCard";
import TransitTotalTableDataCard from "../../components/data-card/TransitTotalTableDataCard";
import AverageDeliveryChart from "../../components/chart-bar/AverageDeliveryChart";
import SelectInput from "../../components/select/SelectInput";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { cleanObject } from "src/utils/core";
import { CSVLink } from "react-csv";
import {
	incrementAwaitingApiCounter,
	decrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import { getAverageTrackingList } from "src/features/tracking/trackingApi";
import { getUserInfo } from "src/features/user/userState";
import typography from "src/theme/typography";
// ----------------------------------------------------------------------

export default function AverageTimeTracker() {
	const [reportData, setReportData] = useState([]);
	const [startDate, setStartDate] = useState(
		dayjs().subtract(30, "day").format("MM/DD/YYYY")
	);
	const [endDate, setEndDate] = useState(dayjs().format("MM/DD/YYYY"));
	const [selectedCarrier, setSelectedCarrier] = useState("Carrier");
	const [selectedClass, setSelectedClass] = useState("Provider");
	const [dayTransit, setDayTransit] = useState({
		TimeInTransit: 0,
		PlaceCount: 0,
		Percentage: 0,
		Runnig: 0,
	});
	const [showLoader, setShowLoader] = useState(false);
	const userInfo = useAppSelector(getUserInfo);
	const dispatch = useAppDispatch();
	useEffect(() => {
		const queryParams = getCurrentPageParams();
		let data = getTrackingNumberList({ ...queryParams }, false);
		setReportData(data);
	}, []);

	useEffect(() => {
		setSelectedClass("Provider");
	}, [selectedCarrier]);

	const getTrackingNumberList = (parms, appendRow = false) => {
		dispatch(incrementAwaitingApiCounter());
		setShowLoader(true);
		const res = getAverageTrackingList(
			cleanObject(parms),
			userInfo?.accessToken
		);
		res.then((result) => {
			setReportData(result);
			dispatch(decrementAwaitingApiCounter());
			setShowLoader(false);
		}).catch((error) => {
			if (
				error?.response?.status === 401 ||
				error?.response?.status === 403
			) {
				dispatch(logoutUser());
			}
			dispatch(decrementAwaitingApiCounter());
			setShowLoader(false);
		});
	};

	const getCurrentPageParams = () => {
		let queryParams = {
			status: "Delivered",
			averageTimeReport: true,
		};
		if (startDate != false && endDate != false) {
			queryParams["date_range"] = `${startDate}-${endDate}`;
		} else {
			if (queryParams.hasOwnProperty("date_range")) {
				delete queryParams["date_range"];
			}
		}

		if (selectedCarrier != false && selectedCarrier != "Carrier") {
			queryParams["carrier"] = `${selectedCarrier}`;
		} else {
			if (queryParams.hasOwnProperty("carrier")) {
				delete queryParams["carrier"];
			}
		}

		if (selectedClass != false && selectedClass != "Provider") {
			queryParams["provider"] = `${selectedClass}`;
		} else {
			if (queryParams.hasOwnProperty("provider")) {
				delete queryParams["provider"];
			}
		}
		return queryParams;
	};

	const onDateChange = (dates) => {
		if (dates != null) {
			const formattedStartDate = dayjs(dates[0]).format("MM/DD/YYYY");
			const formattedEndDate = dayjs(dates[1]).format("MM/DD/YYYY");
			setStartDate(formattedStartDate);
			setEndDate(formattedEndDate);
		}
	};

	const onCarrierSelect = (e) => {
		setSelectedCarrier(e.target.value);
	};
	const onClassSelect = (e) => {
		setSelectedClass(e.target.value);
	};

	const carrierSelect = [
		{
			label: "Carrier",
			value: "Carrier",
		},
		{
			label: "UPS",
			value: "UPS",
		},
		{
			label: "USPS",
			value: "USPS",
		},
	];
	const ClassSelect = [
		{
			label: "Provider",
			value: "Provider",
		},
	];

	if (selectedCarrier === "Carrier") {
		ClassSelect.push(
			{
				label: "UPS Mail Innovations® Expedited",
				value: "UPS Mail Innovations® Expedited",
			},
			{
				label: "Priority Mail",
				value: "Priority Mail",
			},
			{
				label: "USPS Ground Advantage",
				value: "USPS Ground Advantage",
			}
		);
	} else if (selectedCarrier === "UPS") {
		ClassSelect.push({
			label: "UPS Mail Innovations® Expedited",
			value: "UPS Mail Innovations® Expedited",
		});
	} else if (selectedCarrier === "USPS") {
		ClassSelect.push(
			{
				label: "Priority Mail",
				value: "Priority Mail",
			},
			{
				label: "USPS Ground Advantage",
				value: "USPS Ground Advantage",
			}
		);
	}

	const headers = [
		{ label: "Time in Transit" },
		{ label: "Piece Count", key: "Piece Count" },
		{ label: "Percentage", key: "Percentage" },
		{ label: "Running %", key: "Running Percentage" },
	];
	const formatPieceCount = (pieceCount) => {
		return pieceCount === 0 || pieceCount === "" || pieceCount === undefined
			? "-"
			: pieceCount;
	};
	const formatPercentage = (percentage) => {
		return percentage !== "" && percentage !== undefined
			? `${percentage}%`
			: "-";
	};

	const formatRunningPercentage = (runningPercentage) => {
		return runningPercentage !== "" && runningPercentage !== undefined
			? `${runningPercentage}%`
			: "-";
	};

	const csvData = [
		{
			"Time in Transit": "0d",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.with0day?.piece
			),
			Percentage: formatPercentage(
				reportData?.data?.reportData?.with0day?.percentage
			),
			"Running Percentage": formatRunningPercentage(
				reportData?.data?.reportData?.with0day?.runningPercentage
			),
		},
		{
			"Time in Transit": "1d",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.with1day?.piece
			),
			Percentage: formatPercentage(
				reportData?.data?.reportData?.with1day?.percentage
			),
			"Running Percentage": formatRunningPercentage(
				reportData?.data?.reportData?.with1day?.runningPercentage
			),
		},
		{
			"Time in Transit": "2d",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.with2day?.piece
			),
			Percentage: formatPercentage(
				reportData?.data?.reportData?.with2day?.percentage
			),
			"Running Percentage": formatRunningPercentage(
				reportData?.data?.reportData?.with2day?.runningPercentage
			),
		},
		{
			"Time in Transit": "3d",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.with3day?.piece
			),
			Percentage: formatPercentage(
				reportData?.data?.reportData?.with3day?.percentage
			),
			"Running Percentage": formatRunningPercentage(
				reportData?.data?.reportData?.with3day?.runningPercentage
			),
		},
		{
			"Time in Transit": "4d",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.with4day?.piece
			),
			Percentage: formatPercentage(
				reportData?.data?.reportData?.with4day?.percentage
			),
			"Running Percentage": formatRunningPercentage(
				reportData?.data?.reportData?.with4day?.runningPercentage
			),
		},
		{
			"Time in Transit": "5d",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.with5day?.piece
			),
			Percentage: formatPercentage(
				reportData?.data?.reportData?.with5day?.percentage
			),
			"Running Percentage": formatRunningPercentage(
				reportData?.data?.reportData?.with5day?.runningPercentage
			),
		},
		{
			"Time in Transit": "6d",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.with6day?.piece
			),
			Percentage: formatPercentage(
				reportData?.data?.reportData?.with6day?.percentage
			),
			"Running Percentage": formatRunningPercentage(
				reportData?.data?.reportData?.with6day?.runningPercentage
			),
		},
		{
			"Time in Transit": "7d",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.with7day?.piece
			),
			Percentage: formatPercentage(
				reportData?.data?.reportData?.with7day?.percentage
			),
			"Running Percentage": formatRunningPercentage(
				reportData?.data?.reportData?.with7day?.runningPercentage
			),
		},
		{
			"Time in Transit": "8d",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.with8day?.piece
			),
			Percentage: formatPercentage(
				reportData?.data?.reportData?.with8day?.percentage
			),
			"Running Percentage": formatRunningPercentage(
				reportData?.data?.reportData?.with8day?.runningPercentage
			),
		},
		{
			"Time in Transit": "9d",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.with9day?.piece
			),
			Percentage: formatPercentage(
				reportData?.data?.reportData?.with9day?.percentage
			),
			"Running Percentage": formatRunningPercentage(
				reportData?.data?.reportData?.with9day?.runningPercentage
			),
		},
		{
			"Time in Transit": "10d+",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.with10Plusday?.piece
			),
			Percentage: formatPercentage(
				reportData?.data?.reportData?.with10Plusday?.percentage
			),
			"Running Percentage": formatRunningPercentage(
				reportData?.data?.reportData?.with10Plusday?.runningPercentage
			),
		},
		{
			"Time in Transit": "Sum",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.totalDeliveredOrders
			),
		},
		{
			"Time in Transit": "Min (days)",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.minDayOfDelivery
			),
		},
		{
			"Time in Transit": "Max (days)",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.maxDayOfDelivery
			),
		},
		{
			"Time in Transit": "Avg Day Of Delivery",
			"Shipment Count": formatPieceCount(
				reportData?.data?.reportData?.avgDayOfDelivery
			),
		},
	];

	const onClickToBarChart = (e) => {
		setDayTransit({
			TimeInTransit: e?.TimeinTransit ?? 0,
			PlaceCount: e?.pieceCount ?? 0,
			Percentage: e?.percentage ?? 0,
			Runnig: e?.RunningPer ?? 0,
		});
	};

	//cards
	const mainIcon = (name) => `/assets/icons/transitIcons/${name}.svg`;
	const TransitCards = [
		{
			icon: mainIcon("TimeInTransit"),
			title: "Time in Transit",
			total:
				dayTransit?.TimeInTransit +
					(dayTransit?.TimeInTransit === 1 ? " Day" : " Days") || 0,
			status: "Time in Transit",
		},
		{
			title: "Shipment Count",
			total: dayTransit?.PlaceCount || 0,
			icon: mainIcon("PlaceCount"),
			status: "Shipment Count",
		},
		{
			title: "Percentage",
			total: dayTransit?.Percentage + "%" || 0,
			icon: mainIcon("Percentage"),
			status: "Percentage",
		},
		{
			title: "Runnig %",
			total: dayTransit?.Runnig + " %" || 0,
			icon: mainIcon("Runnig"),
			status: "Runnig %",
		},
	];
	//table
	const TransitCardTotal = [
		{
			PlaceCount: reportData?.data?.reportData?.totalDeliveredOrders ?? 0,
			Average: reportData?.data?.reportData?.avgDayOfDelivery ?? 0,
			Min: reportData?.data?.reportData?.minDayOfDelivery ?? 0,
			Max: reportData?.data?.reportData?.maxDayOfDelivery ?? 0,
		},
	];

	function generateFileName() {
		const currentTime = new Date();
		const year = currentTime.getFullYear();
		const month = String(currentTime.getMonth() + 1).padStart(2, "0"); // Add 1 to the month since it's zero-based
		const day = String(currentTime.getDate()).padStart(2, "0");
		const hours = String(currentTime.getHours()).padStart(2, "0");
		const minutes = String(currentTime.getMinutes()).padStart(2, "0");
		const seconds = String(currentTime.getSeconds()).padStart(2, "0");

		return `Average_time_reports_${year}-${month}-${day}_${hours}_${minutes}_${seconds}.csv`;
	}
	const filename = generateFileName();
	const handleApplyClick = () => {
		const queryParams = getCurrentPageParams();
		getTrackingNumberList(queryParams, false);
	};

	return (
		<>
			<Helmet>
				<title> Average Time Tracker | BeyondCarts CMS </title>
			</Helmet>
			<Stack maxWidth="100%" marginTop={3}>
				{/* <Box sx={{ display: 'flex', justifyContent: 'space-between', paddingY: 4 }}> */}
				<Grid
					container
					columnSpacing={{ xs: 1, sm: 1, md: 2 }}
					rowSpacing={{ lg: 2, md: 2, sm: 1, xs: 1 }}
					justifyContent={{ lg: "space-between" }}
					marginBottom={3}
				>
					<Grid item xs="auto">
						<SelectInput
							sx={{
								height: { lg: 40, md: 40, sm: 35 },
								"&  .MuiFormControl-root": {
									width: { lg: 174, md: 150, sm: 100 },
								},
								"&  .MuiFormControl-root > .MuiInputBase-root":
									{ height: { lg: 40, md: 33, sm: 33 } },
							}}
							name="report_type"
							onChangeAdvancedForm={onCarrierSelect}
							advancedFormData={carrierSelect}
							listItem={carrierSelect}
							value={selectedCarrier}
							defaultValue={selectedCarrier}
						/>
					</Grid>
					<Grid item xs="auto">
						<SelectInput
							sx={{
								marginTop: 0,
								height: { lg: 40, md: 40, sm: 35 },
								"&  .MuiFormControl-root": {
									width: { lg: 174, md: 150, sm: 120 },
								},
								"&  .MuiFormControl-root > .MuiInputBase-root":
									{ height: { lg: 40, md: 33, sm: 33 } },
							}}
							name="report_type"
							onChangeAdvancedForm={onClassSelect}
							advancedFormData={ClassSelect}
							listItem={ClassSelect}
							value={selectedClass}
							defaultValue={selectedClass}
						/>
					</Grid>
					<Grid item xs="auto">
						<DateRangePicker
							onDateChange={onDateChange}
							startDate={startDate}
							endDate={endDate}
						/>
					</Grid>
					<Grid item xs="auto">
						<Button
							type="button"
							variant="contained"
							onClick={handleApplyClick}
							sx={{
								height: { xs: 36, lg: 40 },
								width: { md: 110, sm: 90, xs: 90, md: 100 },
								backgroundColor: "secondary.main",
								boxShadow: "none",
							}}
						>
							Apply
						</Button>
					</Grid>

					<Grid item xs="auto">
						<CSVLink
							data={csvData}
							filename={filename}
							style={{
								color: "#FFF",
								textDecoration: "none",
								display: "flex",
							}}
						>
							<Button
								variant="contained"
								disabled={
									!reportData?.data?.reportData
										?.totalDeliveredOrders
								}
								sx={{
									boxShadow: "none",
									backgroundColor: "secondary.main",
									width: {
										lg: 130,
										sm: 110,
										xs: 110,
										md: 120,
									},
									height: { xs: 36, sm: 36, md: 36, lg: 40 },
									fontSize: {
										md: 14,
										lg: 16,
										sm: 14,
										xs: 14,
									},
									letterSpacing: "0.1px",
								}}
								startIcon={
									<Iconify icon="ic:outline-sim-card-download" />
								}
							>
								Export
							</Button>
						</CSVLink>
					</Grid>
				</Grid>
				{/* </Box> */}

				<AverageDeliveryChart
					onClickToBarChart={onClickToBarChart}
					reportData={reportData}
					showLoader={showLoader}
				/>

				<TransitDataCard title="Day Transit" cardItems={TransitCards} />

				<TransitTotalTableDataCard
					title="Total"
					cardItems={TransitCardTotal}
				/>
			</Stack>
		</>
	);
}
