import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	Box,
	TextField,
	Typography,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	FormHelperText,
	IconButton,
	InputAdornment,
} from "@mui/material";
import { setSigninData } from "src/features/signin/signin.action";
import {
	styleInput,
	months,
	futureYears,
} from "src/pages/billing/cardsComponent/cardsUtils";
import palette from "src/theme/palette";
import CountrySelect from "./countrySelect";
import "./billing.scss";
import CircularLoader from "src/loader/circular/CircularLoader";
import { useAppDispatch } from "src/hooks/hooks";

const Billing = () => {
	const dispatch = useDispatch();
	const dispatchApp = useAppDispatch();
	const navigate = useNavigate();
	const signinData = useSelector((state) => state.signin.signinData);
	const {
		creditCard,
		creditCardError,
		cvv,
		cvvError,
		expirationMonth,
		expirationYear,
		expirationError,
		fullName,
		fullNameError,
		billingAddr,
		billingAddrError,
		city,
		cityError,
		zipcode,
		zipcodeError,
		state,
		stateError,
	} = signinData || {};

	const [card, setCard] = useState("");
	const [creditLogo, setCreditLogo] = useState();

	function cc_format(value) {
		if (!value) return;
		let newValue = "";

		for (let i = 0; i < value.length; i++) {
			if (i === 4 || i === 8 || i === 12) newValue += " ";
			newValue += value[i];
		}
		return newValue;
	}

	const handleCardChange = (e) => {
		handleNumChars(e);
		const valueParsed = e.target.value.replaceAll(" ", "");
		const valueInt = parseInt(valueParsed) || "";
		const value = valueInt.toString();
		if (value.length > 16) return;

		setCard(cc_format(value));
		creditCardType(value);
		dispatch(
			setSigninData({ ...signinData, creditCard: value, cardError: "" })
		);
	};

	const handleCvvChange = (e) => {
		const value = e.target.value;
		if (value.length > 3) return;
		dispatch(setSigninData({ ...signinData, cvv: value }));
	};

	const creditCardType = (cc) => {
		if (!cc) return;
		let amex = new RegExp("^3[47][0-9]{13}$");
		let visa = new RegExp("^4[0-9]{12}(?:[0-9]{3})?$");
		let cup1 = new RegExp("^62[0-9]{14}[0-9]*$");
		let cup2 = new RegExp("^81[0-9]{14}[0-9]*$");

		let mastercard = new RegExp("^5[1-5][0-9]{14}$");
		let mastercard2 = new RegExp("^2[2-7][0-9]{14}$");

		let disco1 = new RegExp("^6011[0-9]{12}[0-9]*$");
		let disco2 = new RegExp("^62[24568][0-9]{13}[0-9]*$");
		let disco3 = new RegExp("^6[45][0-9]{14}[0-9]*$");

		let diners = new RegExp("^3[0689][0-9]{12}[0-9]*$");
		let jcb = new RegExp("^35[0-9]{14}[0-9]*$");

		if (visa.test(cc)) {
			setCreditLogo("/assets/visa.svg");
			return;
		}
		if (amex.test(cc)) {
			setCreditLogo("/assets/amex.svg");
			return;
		}
		if (mastercard.test(cc) || mastercard2.test(cc)) {
			setCreditLogo("/assets/master.svg");
			return;
		}
		if (disco1.test(cc) || disco2.test(cc) || disco3.test(cc)) {
			return "DISCOVER";
		}
		if (diners.test(cc)) {
			return "DINERS";
		}
		if (jcb.test(cc)) {
			return "JCB";
		}
		if (cup1.test(cc) || cup2.test(cc)) {
			return "CHINA_UNION_PAY";
		}
		if (cc.length < 12) setCreditLogo(null);
	};

	const handleNumChars = (event) => {
		const regex = /^[0-9]*$/;

		if (!regex.test(event.target.value)) {
			event.target.value = event.target.value.replace(/[^0-9]/g, "");
		}
	};

	useEffect(() => {
		if (!signinData?.user_id) {
			navigate("/login");
			return;
		}

		setCard(cc_format(creditCard));
		creditCardType(creditCard);
	}, []);

	return (
		<Box className="billing-container">
			{signinData.isOnboardingPaymentCardLoad && <CircularLoader />}
			<Typography
				variant="h3"
				sx={{
					textAlign: "center",
				}}
			>
				Billing Information
			</Typography>

			<Typography
				sx={{
					color: palette.common.black,
					fontSize: 18,
					textAlign: "center",
				}}
			>
				Choose a payment method below
			</Typography>

			<Box className="billing-card-container">
				<img src="/assets/card.svg" alt="main-logo" />
				<Typography
					sx={{
						color: palette.common.black,
						fontSize: 18,
						textAlign: "center",
					}}
				>
					Credit Card
				</Typography>
			</Box>

			<Box className="billing-inps-container">
				<TextField
					sx={styleInput}
					label="Credit Card *"
					value={card}
					error={creditCardError}
					helperText={creditCardError && "Credit Card is required."}
					onFocus={() =>
						dispatch(
							setSigninData({
								...signinData,
								creditCardError: false,
							})
						)
					}
					onChange={handleCardChange}
					InputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton edge="end">
									<img
										style={{
											height: "25px",
											cursor: "default",
											marginRight: "20px",
										}}
										src={creditLogo}
									/>
								</IconButton>
							</InputAdornment>
						),
					}}
				></TextField>

				<Box className="billing-inp-container">
					<TextField
						sx={styleInput}
						type="number"
						label="CVV *"
						value={cvv}
						error={cvvError}
						helperText={cvvError && "Valid CVV is required."}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									cvvError: false,
								})
							)
						}
						onChange={handleCvvChange}
					></TextField>

					<FormControl fullWidth>
						<InputLabel
							id="credit-month-select-label"
							sx={{ marginTop: "10px" }}
						>
							Month *
						</InputLabel>
						<Select
							labelId="credit-month-select-label"
							label="Month"
							defaultValue=""
							value={expirationMonth}
							onChange={(e) =>
								dispatch(
									setSigninData({
										...signinData,
										expirationMonth: e.target.value,
									})
								)
							}
							sx={{ ...styleInput, color: palette.common.black }}
							error={expirationError}
							onFocus={() =>
								dispatch(
									setSigninData({
										...signinData,
										expirationError: false,
									})
								)
							}
						>
							{months.map((m, index) => (
								<MenuItem
									value={index + 1}
									sx={{ color: palette.common.black }}
								>
									{m}
								</MenuItem>
							))}
						</Select>
						<FormHelperText error={expirationError}>
							{expirationError && "Month is required."}
						</FormHelperText>
					</FormControl>

					<FormControl fullWidth>
						<InputLabel
							id="credit-year-select-label"
							sx={{ marginTop: "10px" }}
						>
							Year *
						</InputLabel>
						<Select
							labelId="credit-year-select-label"
							label="Year"
							defaultValue=""
							value={expirationYear}
							onChange={(e) =>
								dispatch(
									setSigninData({
										...signinData,
										expirationYear: e.target.value,
									})
								)
							}
							sx={{ ...styleInput, color: palette.common.black }}
							error={expirationError}
							onFocus={() =>
								dispatch(
									setSigninData({
										...signinData,
										expirationError: false,
									})
								)
							}
						>
							{futureYears.map((m) => (
								<MenuItem
									value={m}
									sx={{ color: palette.common.black }}
								>
									{m}
								</MenuItem>
							))}
						</Select>
						<FormHelperText error={expirationError}>
							{expirationError && "Year is required."}
						</FormHelperText>
					</FormControl>
				</Box>

				<TextField
					sx={styleInput}
					label="Full Name *"
					error={fullNameError}
					helperText={fullNameError && "Full Name is required."}
					onFocus={() =>
						dispatch(
							setSigninData({
								...signinData,
								fullNameError: false,
							})
						)
					}
					value={fullName}
					onChange={(e) => {
						dispatch(
							setSigninData({
								...signinData,
								fullName: e.target.value,
							})
						);
					}}
				></TextField>

				<TextField
					sx={styleInput}
					label="Billing Address *"
					error={billingAddrError}
					helperText={
						billingAddrError && "Billing Address is required."
					}
					onFocus={() =>
						dispatch(
							setSigninData({
								...signinData,
								billingAddrError: false,
							})
						)
					}
					value={billingAddr}
					onChange={(e) => {
						dispatch(
							setSigninData({
								...signinData,
								billingAddr: e.target.value,
							})
						);
					}}
				></TextField>

				<Box className="billing-inp-container">
					<TextField
						sx={styleInput}
						label="City *"
						error={cityError}
						helperText={cityError && "City is required."}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									cityError: false,
								})
							)
						}
						value={city}
						onChange={(e) => {
							dispatch(
								setSigninData({
									...signinData,
									city: e.target.value,
								})
							);
						}}
					></TextField>

					<TextField
						sx={styleInput}
						label="Zipcode *"
						error={zipcodeError}
						helperText={zipcodeError && "Zipcode is required."}
						onFocus={() =>
							dispatch(
								setSigninData({
									...signinData,
									zipcodeError: false,
								})
							)
						}
						value={zipcode}
						onChange={(e) => {
							dispatch(
								setSigninData({
									...signinData,
									zipcode: e.target.value,
								})
							);
						}}
					></TextField>
				</Box>

				<TextField
					sx={styleInput}
					label="State *"
					error={stateError}
					helperText={stateError && "State is required."}
					onFocus={() =>
						dispatch(
							setSigninData({
								...signinData,
								stateError: false,
							})
						)
					}
					value={state}
					onChange={(e) => {
						dispatch(
							setSigninData({
								...signinData,
								state: e.target.value,
							})
						);
					}}
				></TextField>

				<CountrySelect sx={styleInput} />

				{signinData.cardError && (
					<p
						style={{
							color: "red",
							position: "absolute",
							width: "inherit",
							textAlign: "center",
						}}
					>
						{signinData.cardError.includes("declined")
							? "Your card was declined."
							: signinData.cardError}
					</p>
				)}
			</Box>
		</Box>
	);
};

export default Billing;
