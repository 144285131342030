import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginCall } from "./userApi";
import { deleteToken, setToken } from "src/utils/extractUserToken";
import { getPlansThunk } from "../plans/plansState";
import { getTenant } from "../usersPage/usersApi";

const initialState = {
	isAuthenticated: false,
	userInfo: {},
	userError: {
		loginError: false,
		loginErrorMessage: "",
	},
};

export const validateLogin = async (data) => {
	let response;
	try {
		response = await loginCall(data);
		return response;
	} catch (error) {
		return error.message;
	}
};

export const login = createAsyncThunk(
	"user/login",
	async (data, { dispatch }) => {
		let tenant_logo = null;
		let createdBy = null;
		let carrier = null;

		const response = await loginCall(data);
		await dispatch(getPlansThunk());

		// if (response?.statusCode === 200) {
		// 	try {
		// 		const tenant = await getTenant(
		// 			response?.data,
		// 			response?.data?.user_id
		// 		);
		// 		const { statusCode, data } = tenant;
		// 		if (statusCode === 200) {
		// 			tenant_logo = data?.logo_path;
		// 			createdBy = data?.createdBy;
		// 			carrier = data?.carrier;
		// 		}
		// 	} catch (error) {
		// 		console.log(error);
		// 	}
		// }

		return { ...response, tenant_logo, createdBy, carrier };
	}
);

export const logoutUser = createAsyncThunk("user/fetchUserLogout", async () => {
	const response = initialState;
	return response;
});

export const updateUserInfo = createAsyncThunk(
	"user/updateUserInfo",
	async (newUserInfo, { getState }) => {
		const currentState = getState();
		const currentUserInfo = currentState.user.userInfo;
		const updatedUserInfo = {
			...currentUserInfo,
			...newUserInfo,
		};

		try {
			const response = await updateProfileInfo(
				updatedUserInfo,
				currentUserInfo
			);
			return updatedUserInfo;
		} catch (err) {
			return currentUserInfo;
		}
	}
);

export const updateUserPreferences = createAsyncThunk(
	"user/updateUserPreferences",
	async ({ trackingInfo, mailScan }, { getState }) => {
		const currentState = getState();
		return {
			...currentState.user,
		};
	}
);

const userSlice = createSlice({
	name: "user",
	initialState,
	reducers: {
		selectProduct: (state, action) => {
			state.selectedProduct = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder
			.addCase(login.pending, (state) => {
				state.isLoading = true;
				state.error = null;
			})
			.addCase(login.fulfilled, (state, action) => {
				state.userInfo = {
					...action.payload.data,
					tenant_logo: action.payload.tenant_logo,
					createdBy: action.payload.createdBy,
					carrier: action.payload.carrier,
					email: action.meta.arg.email,
				};
				state.isAuthenticated = true;
				state.userError = { ...state.userError, loginError: false };
				setToken(action?.payload?.data?.accessToken);
			})
			.addCase(login.rejected, (state, action) => {
				state.userError = {
					...state.userError,
					loginError: true,
					loginErrorMessage:
						action?.error?.message === "Invalid credentials"
							? "Invalid Email or Password."
							: action?.error?.message ||
							  "Invalid Email or Password.",
				};
			})
			.addCase(logoutUser.fulfilled, (state, action) => {
				state.isAuthenticated = initialState.isAuthenticated;
				state.userInfo = initialState.userInfo;
				deleteToken();
			})
			.addCase(updateUserPreferences.fulfilled, (state, action) => {});
	},
});

export const { selectProduct } = userSlice.actions;

export const getUserInfo = (state) => state?.user?.userInfo;
export const getUserError = (state) => state?.user?.userError;

export default userSlice.reducer;
