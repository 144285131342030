import {useEffect, useState} from "react";
import {Stack, Box, FormHelperText} from "@mui/material";
import {Controller} from "react-hook-form";
import {MuiOtpInput} from "mui-one-time-password-input";
import {forgotPassword, resendCode} from "src/features/user/userApi";
import palette from "src/theme/palette";
import {ErrorMessagesSchema} from "src/utils/errorMessagesSchema";

const OtpForm = ({
                   control,
                   email,
                   clearErrors,
                   isError,
                   onBoarding = false,
                   deleteTenant = false,
                   cbDeleteTenantResend
                 }) => {
  const otpLength = 6;
  const secsToResendCode = 30;
  const [timerCount, setTimerCount] = useState(secsToResendCode);

  const handleResendCode = async () => {
    setTimerCount(secsToResendCode);
    try {
      if (deleteTenant) {
        cbDeleteTenantResend()
      } else {
        onBoarding
          ? await resendCode({email: email})
          : await forgotPassword({email: email});
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    if (!timerCount) return;

    const intervalId = setInterval(() => {
      setTimerCount(timerCount - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timerCount]);

  return (
    <>
      {timerCount === 0 ? (
        <Box
          sx={{
            color: "primary.main",
            cursor: "pointer",
            textAlign: "center",
          }}
          onClick={handleResendCode}
        >
          Click here to resend code
        </Box>
      ) : (
        <>
          <Box sx={{color: "primary.main", textAlign: "center"}}>
            Resend code in {timerCount} secs
          </Box>
        </>
      )}
      <Stack
        direction="row"
        spacing={{xs: 2, md: 3}}
        className="flexContainer"
      >
        <Controller
          control={control}
          rules={{validate: (value) => value?.length === otpLength}}
          render={({field, fieldState}) => (
            <Box>
              <MuiOtpInput
                sx={{
                  gap: {xs: 1, md: 2},
                  input: {
                    backgroundColor: "#eaeaea",
                    color: palette.common.black,
                    borderRadius: "inherit",
                  },

                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "primary.dark",
                    },
                    "&.Mui-focused fieldset": {
                      borderColor: "primary.dark",
                    },
                  },
                }}
                {...field}
                length={otpLength}
                onFocus={() => clearErrors()}
              />
              {fieldState.invalid && (
                <FormHelperText error>
                  {ErrorMessagesSchema.otpCode.invalid}
                </FormHelperText>
              )}
              {isError && (
                <FormHelperText error>
                  {ErrorMessagesSchema.otpCode.wrong}
                </FormHelperText>
              )}
            </Box>
          )}
          name="otp"
        ></Controller>
      </Stack>
    </>
  );
};

export default OtpForm;
