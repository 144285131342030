import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Typography, Box } from "@mui/material";
import palette from "src/theme/palette";
import "./checkout.scss";

const pStyle = { color: palette.common.black, fontSize: 18 };

const Checkout = () => {
	const navigate = useNavigate();
	const signinData = useSelector((state) => state.signin.signinData);

	const {
		billingAddr,
		carrier,
		city,
		country,
		creditCard,
		email,
		fullName,
		lastName,
		manager,
		name,
		phone,
		company,
		zipcode,
		isUpsValid,
		isUspsValid,
		isFedexValid,
		isDHLValid,
		isMagentoValid,
		isShipStationValid,
		isShopifyValid,
	} = signinData || {};

	const formatPhone = (phone) => {
		return phone;
	};

	useEffect(() => {
		if (!signinData?.user_id) {
			navigate("/login");
			return;
		}
	}, []);

	return (
		<Box className="checkout-container">
			<Typography variant="h3" sx={{ textAlign: "center" }}>
				Confirmation
			</Typography>

			<Box className="checkout-cards-container">
				<Box className="checkout-card">
					<Box className="section-container">
						<Typography variant="h4">Tenant Information</Typography>
						<Box className="section-info-container">
							<img
								src="/assets/user.svg"
								alt="logo"
								style={{ height: "30px", display: "block" }}
							></img>
							<Typography sx={pStyle}>
								{name} {lastName}
							</Typography>
						</Box>

						<Box className="section-info-container">
							<img
								src="/assets/phone.svg"
								alt="logo"
								style={{ height: "30px", display: "block" }}
							></img>
							<Typography sx={pStyle}>
								{formatPhone(phone)}
							</Typography>
						</Box>
						<Box className="section-info-container">
							<img
								src="/assets/email.svg"
								alt="logo"
								style={{ height: "30px", display: "block" }}
							></img>
							<Typography sx={pStyle}>{email}</Typography>
						</Box>
						<Box className="section-info-container">
							<img
								src="/assets/company.svg"
								alt="logo"
								style={{ height: "30px", display: "block" }}
							></img>
							<Typography sx={pStyle}>{company}</Typography>
						</Box>
					</Box>
					<Box className="section-container">
						<Typography variant="h4">
							Shipping Fulfiller and Order Management
						</Typography>
						<Box className="checkout-section-img-container">
							{/* TODO: refactor this code */}
							{/* carriers */}
							{carrier?.usps && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
										position: "relative",
									}}
								>
									{isUspsValid ? (
										<img
											src={"/assets/icons/accept.png"}
											style={{
												position: "absolute",
												right: -18,
												top: -18,
											}}
										/>
									) : (
										<img
											src={"/assets/icons/cross.png"}
											style={{
												position: "absolute",
												right: -18,
												top: -18,
											}}
										/>
									)}
									<img src={"/assets/usps.png"} />
								</div>
							)}
							{carrier?.ups && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
										position: "relative",
									}}
								>
									{isUpsValid ? (
										<img
											src={"/assets/icons/accept.png"}
											style={{
												position: "absolute",
												right: -10,
												top: -15,
											}}
										/>
									) : (
										<img
											src={"/assets/icons/cross.png"}
											style={{
												position: "absolute",
												right: -10,
												top: -15,
											}}
										/>
									)}
									<img src={"/assets/ups.png"} />
								</div>
							)}
							{carrier?.fedex && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
										position: "relative",
									}}
								>
									{isFedexValid ? (
										<img
											src={"/assets/icons/accept.png"}
											style={{
												position: "absolute",
												right: -15,
												top: -25,
											}}
										/>
									) : (
										<img
											src={"/assets/icons/cross.png"}
											style={{
												position: "absolute",
												right: -15,
												top: -25,
											}}
										/>
									)}
									<img
										src={"/assets/fedex.png"}
										width={100}
									/>
								</div>
							)}
							{carrier?.dhl && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
										position: "relative",
									}}
								>
									{isDHLValid ? (
										<img
											src={"/assets/icons/accept.png"}
											style={{
												position: "absolute",
												right: -18,
												top: -18,
											}}
										/>
									) : (
										<img
											src={"/assets/icons/cross.png"}
											style={{
												position: "absolute",
												right: -18,
												top: -18,
											}}
										/>
									)}
									<img src={"/assets/dhl.png"} width={110} />
								</div>
							)}

							{/* managers */}
							{manager === "shipstation" && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
										position: "relative",
									}}
								>
									{isShipStationValid ? (
										<img
											src={"/assets/icons/accept.png"}
											style={{
												position: "absolute",
												right: -10,
												top: -15,
											}}
										/>
									) : (
										<img
											src={"/assets/icons/cross.png"}
											style={{
												position: "absolute",
												right: -10,
												top: -15,
											}}
										/>
									)}
									<img
										src={"/assets/shipStation.png"}
										height={72}
									/>
								</div>
							)}
							{manager === "shopify" && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
										position: "relative",
									}}
								>
									{isShopifyValid ? (
										<img
											src={"/assets/icons/accept.png"}
											style={{
												position: "absolute",
												right: -10,
												top: -25,
											}}
										/>
									) : (
										<img
											src={"/assets/icons/cross.png"}
											style={{
												position: "absolute",
												right: -10,
												top: -25,
											}}
										/>
									)}
									<img src={"/assets/shopify.png"} />
								</div>
							)}
							{manager === "magento" && (
								<div
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "flex-end",
										position: "relative",
									}}
								>
									{isMagentoValid ? (
										<img
											src={"/assets/icons/accept.png"}
											style={{
												position: "absolute",
												right: -10,
												top: -25,
											}}
										/>
									) : (
										<img
											src={"/assets/icons/cross.png"}
											style={{
												position: "absolute",
												right: -10,
												top: -25,
											}}
										/>
									)}
									<img src={"/assets/magento.png"} />
								</div>
							)}
						</Box>
					</Box>
				</Box>

				<Box className="checkout-card">
					<Box className="section-container">
						<Typography variant="h4">Billing Address</Typography>
						<Typography sx={pStyle}>{fullName}</Typography>
						<Typography sx={pStyle}>{billingAddr}</Typography>
						<Typography sx={pStyle}>
							{city}, {zipcode}
						</Typography>
						<Typography sx={pStyle}>{country}</Typography>
						<Typography sx={pStyle}>
							{formatPhone(phone)}
						</Typography>
					</Box>
					<Box className="section-container">
						<Typography variant="h4">Payment</Typography>
						<Typography sx={pStyle}>
							Card ending in {creditCard?.slice(12, 16)}
						</Typography>
					</Box>
				</Box>
			</Box>
		</Box>
	);
};

export default Checkout;
