import { Backdrop, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import ResetPassword from "src/components/reset-password/ResetPassword";
import timer from "src/utils/timer";

const ResetPasswordStep3Page = () => {
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const navigate = useNavigate();
	const [isSubmited, setIsSubmited] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const onSubmit = async (data) => {
		// connect to the api that sends the email with the code to reset the password
		console.log(data);
		setIsLoading(true);
		await timer(1000);
		setIsSubmited(true);
		navigate("/");
	};

	return (
		<>
			<Helmet>
				<title> Reset Password | BeyondCarts CMS </title>
			</Helmet>

			{isLoading && (
				<Backdrop
					sx={{
						color: "#fff",
						zIndex: (theme) => theme.zIndex.drawer + 1,
					}}
					open
				>
					<CircularProgress color="inherit" />
				</Backdrop>
			)}

			<ResetPassword
				title={"Your Password has been reset!"}
				style={{
					title: {
						color: "$primary-color",
						textAlign: "center",
						paddingBottom: "3vh",
					},
				}}
				btnText={"Return to login"}
				handleSubmit={handleSubmit}
				setIsSubmited={setIsSubmited}
				onSubmit={onSubmit}
			/>
		</>
	);
};

export default ResetPasswordStep3Page;
