import {useEffect, useState, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import {styled} from "@mui/material/styles";
import {useForm} from "react-hook-form";
import {Helmet} from "react-helmet-async";
import {
  TextField,
  Box,
  Button,
  Typography,
  Snackbar,
  Alert,
  Modal,
  IconButton,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  Switch,
} from "@mui/material";

import {getUserInfo, updateUserInfo} from "../features/user/userState";
import {
  getConfigData,
  getConfigUpdated,
} from "../features/config/configState";
import {useAppDispatch, useAppSelector} from "../hooks/hooks";
import typography from "src/theme/typography";
import palette from "src/theme/palette";
import SigninCarrier from "src/sections/auth/signin/formsContainer/carrier/signinCarrier/signinCarrier";
import SigninManage from "src/sections/auth/signin/formsContainer/manage/signinManage/signinManage";

import {setSigninData} from "src/features/signin/signin.action";
import {
  getTenant,
  getAllTenantUsers,
  getTenantUser,
  getSingleTenant,
  deleteTenant,
} from "src/features/usersPage/usersApi";
import {getPlans} from "src/features/onborading/onboardingApi";
import {
  updateLogo,
  updateTenantInfo,
  retrieveLogoUrl,
  updateCarrierOrder,
  updateEmailPreferences,
} from "src/features/config/configApi";
import {getPlansInfo} from "src/features/plans/plansState";
import planNames from "src/utils/planNames";
import InfoLogo from "src/components/info-logo/info-logo";
import ManageForm from "src/sections/auth/signin/formsContainer/manage/manage";
import Carrier from "src/sections/auth/signin/formsContainer/carrier/carrier";
import {LoadingButton} from "@mui/lab";
import {unixToDate} from "src/utils/core";
import ModalComponent from "src/components/modal/ModalComponent";
import OtpForm from "./ResetPassword/OtpForm";
import {useNavigate} from "react-router-dom";
import {
  decrementAwaitingApiCounter,
  incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const styleInput = {
  input: {color: palette.common.black},
  width: {lg: "100%"},
  marginTop: "10px",
  "& .MuiOutlinedInput-root": {
    "&:hover fieldset": {
      borderColor: "primary.dark",
    },
    "&.Mui-focused fieldset": {
      borderColor: "primary.dark",
    },
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "secondary.light",
  },
  "& .MuiInputLabel-formControl": {
    color: "#00000099",
  },
  "& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
    color: "#00000099",
  },
  "& .MuiFormHelperText-root": {
    textAlign: "left",
    fontSize: "14px",
  },
};

const btnStyle = {
  width: 188,
  height: 48,
  boxShadow: "none",
  fontSize: 18,
  fontFamily: typography.fontFamilySemiBold,
};

const stylePassModal = {
  position: "absolute",
  top: {lg: "46%", md: "42.5%", sm: "30%"},
  left: {lg: "47.5%", md: "52%", sm: "50%"},
  transform: "translate(-50%, -50%)",
  width: "550px",
  borderRadius: "10px",
  background: "#FAFAFA",
  padding: "20px 0",
};

const textStyle = {
  color: palette.common.black,
  fontSize: {xs: 16, md: 18},
};

export default function ConfigPage() {
  const signinData = useSelector((state) => state.signin.signinData);
  const {tenant_id, tenant_name} = useSelector((state) => state.tenants);
  const {control, handleSubmit, reset} = useForm();
  const dispatchRedux = useDispatch();
  const inputFile = useRef(null);

  const [showConfigUpdated, setShowConfigUpdated] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [saveEnable, setsaveEnable] = useState(true);

  const [isConfig, setIsConfig] = useState(false);
  const [isConfigCarrierOrderManager, setIsConfigCarrierOrderManager] =
    useState(false);
  const [userConfigInfo, setUserConfigInfo] = useState({});
  const [tenantConfigInfo, setTenantConfigInfo] = useState({});
  const [shippingVolume, setShippingVolume] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [contactEmail, setContactEmail] = useState("");
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState("");
  const [logoPath, setLogoPath] = useState();
  const [file, setFile] = useState();
  const [fileError, setFileError] = useState(false);
  const [isLogoUpdate, setIsLogoUpdate] = useState(false);
  const [isActivate, setIsActivate] = useState("active");
  const [isSwitched, setIsSwitched] = useState(false);
  const [planSwitch, setPlanSwitch] = useState("");
  const [adminUsers, setAdminUsers] = useState([]);
  const [openDeleteTenantConfirmation, setOpenDeleteTenantConfirmation] =
    useState(false);
  const [openOTPDeleteTenant, setOpenOTPDeleteTenant] = useState(false);
  const [openDeleteTenantSuccessful, setOpenDeleteTenantSuccessful] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(getUserInfo);
  const configData = useAppSelector(getConfigData);
  const isConfigUpdated = useAppSelector(getConfigUpdated);

  const [orderType, setOrderType] = useState("");
  const defaultValue = {
    REFRESH_STEP_FUNC_ARN: configData?.aws?.refresh_step_func_arn || "",
    BUCKET_NAME: configData?.aws?.bucket_name || "",
    BUCKET_FOLDER_NAME: configData?.aws?.bucket_folder_name || "",
    ITERABLE_URL: configData?.iterable?.url || "",
    ITERABLE_CAMPAIGN_ID: configData?.iterable?.campaign_id || "",
    SERVICE_UPS_USERNAME: configData?.ups?.ups_username || "",
    ACCESS_TOKEN: configData?.magento?.access_token || "",
    BASE_URL: configData?.website?.base_url || "",
    MAGENTO_BASE_URL: configData?.magento?.magento_base_url || "",
    EMAIL_FROM: configData?.report?.email_from || "",
    EMAIL_TO: configData?.report?.email_to || "",
    IS_PROD: configData?.website?.is_prod || "",
    ITERABLE_API_KEY: configData?.iterable?.api_key || "",
    SERVICE_UPS_LICENCE_NUMBER: configData?.ups?.ups_license_no || "",
    SERVICE_UPS_PASSWORD: configData?.ups?.ups_password || "",
    SERVICE_USPS_USER_ID: configData?.usps?.usps_user_id || "",
    // TODO: make sure this is working
    SERVICE_FEDEX_USERNAME: configData?.fedex?.fedex_username || "",
    SERVICE_FEDEX_LICENCE_NUMBER: configData?.fedex?.fedex_license_no || "",
    SERVICE_FEDEX_PASSWORD: configData?.fedex?.fedex_password || "",
  };

  const [emailPreferences, setEmailPreferences] = useState({
    first_scan_email: true,
    delivered_email: true,
    out_for_delivery_email: true,
  });

  const [isEmailPreferencesUpdate, setIsEmailPreferencesUpdate] =
    useState(false);

  const plansInfo = useAppSelector(getPlansInfo);
  const isDemoPlan = userInfo?.plan_id === plansInfo[planNames.demo]?._id;

  const onCloseExportInfo = () => {
    setShowConfigUpdated(false);
  };

  const onChangeClick = (name) => {
    setOrderType(name);
    dispatchRedux(
      setSigninData({
        ...signinData,
        user_id: userInfo?.user_id,
      })
    );
    const carriers = ["ups", "usps", "fedex", "dhl"];
    const orders = ["magento", "shopify", "shipstation"];

    if (carriers.includes(name)) {
      setIsConfig("carrier");
    }
    if (orders.includes(name)) {
      setIsConfig("manager");
    }
  };

  const handleUpdateCarrierAndOrderManager = (name) => {
    dispatchRedux(
      setSigninData({
        ...signinData,
        user_id: userInfo?.user_id,
      })
    );
    name === "carrier"
      ? setIsConfigCarrierOrderManager("carrier")
      : name === "manager" && setIsConfigCarrierOrderManager("manager");
  };

  const onSaveClick = () => {
    if (checkSignCarrier()) return;
    if (checkSignManager()) return;

    dispatch(incrementAwaitingApiCounter());

    const data = {};

    if (signinData.carrier.usps) {
      data.carrier = {
        ...data.carrier,
        usps: {
          user_name: signinData.carrierSignUSPSUsername,
          password: signinData.carrierSignUSPSPassword,
          user_id: signinData.carrierSignUSPSUserId,
        },
      };
    }

    if (signinData.carrier.ups) {
      data.carrier = {
        ...data.carrier,
        ups: {
          user_name: signinData.carrierSignUPSUsername,
          password: signinData.carrierSignUPSPassword,
          client_key: signinData.carrierSignUPSClientId,
          client_secret: signinData.carrierSignUPSClientSecret,
        },
      };
    }

    if (signinData.carrier.fedex) {
      data.carrier = {
        ...data.carrier,
        fedex: {
          user_name: signinData.carrierSignFedexUsername,
          password: signinData.carrierSignFedexPassword,
          client_key: signinData.carrierSignFedexClientId,
          client_secret: signinData.carrierSignFedexClientSecret,
        },
      };
    }

    if (signinData.carrier.dhl) {
      data.carrier = {
        ...data.carrier,
        dhl: {
          user_name: signinData.carrierSignDHLUsername,
          password: signinData.carrierSignDHLPassword,
          api_key: signinData.carrierSignDHLApiKey,
          api_secret: signinData.carrierSignDHLApiSecret,
        },
      };
    }

    if (signinData.manager === "magento") {
      data.order_management = {
        magento: {
          store_url: signinData.managerSignMagentoUrl,
          access_token: signinData.managerSignMagentoToken,
        },
      };
    } else if (signinData.manager === "shopify") {
      data.order_management = {
        shopify: {
          access_token: signinData.managerSignShopifyApiKey,
          store_url: signinData.managerSignShopifyStoreUrl,
        },
      };
    } else if (signinData.manager === "shipstation") {
      data.order_management = {
        ship_station: {
          api_key: signinData.managerSignShipStationApiKey,
          api_secret: signinData.managerSignShipStationApiSecret,
        },
      };
    }

    updateCarrierOrder(data, userInfo, tenant_name, tenant_id)
      .then(() => {
        setShowConfirmation(true);
        setIsConfig(false);
        dispatch(decrementAwaitingApiCounter());
      })
      .catch(() => {
        setIsConfig(false);
        dispatch(decrementAwaitingApiCounter());
      });
  };

  const handleCloseModal = () => {
    setShowConfirmation(false);
    setIsEmailPreferencesUpdate(false);
    setIsLogoUpdate(false);
    location.reload();
  };

  const checkSignCarrier = () => {
    if (signinData.carrier.usps) {
      if (!signinData.carrierSignUSPSUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSUsernameError: true,
          })
        );
        return true;
      }

      if (!signinData.carrierSignUSPSPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUSPSPasswordError: true,
          })
        );
        return true;
      }
    }

    if (signinData.carrier.ups) {
      if (!signinData.carrierSignUPSUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUPSUsernameError: true,
          })
        );
        return true;
      }
      if (!signinData.carrierSignUPSPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignUPSPasswordError: true,
          })
        );
        return true;
      }
    }

    if (signinData.carrier.fedex) {
      if (!signinData.carrierSignFedexUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignFedexUsernameError: true,
          })
        );
        return true;
      }
      if (!signinData.carrierSignFedexPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignFedexPasswordError: true,
          })
        );
        return true;
      }
    }

    if (signinData.carrier.dhl) {
      if (!signinData.carrierSignDHLUsername) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignDHLUsernameError: true,
          })
        );
        return true;
      }

      if (!signinData.carrierSignDHLPassword) {
        dispatch(
          setSigninData({
            ...signinData,
            carrierSignDHLPasswordError: true,
          })
        );
        return true;
      }
    }
  };

  const checkSignManager = () => {
    if (
      !signinData.managerSignMagentoUrl &&
      signinData.manager === "magento"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignMagentoUrlErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignMagentoToken &&
      signinData.manager === "magento"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignMagentoTokenErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignShopifyApiKey &&
      signinData.manager === "shopify"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShopifyApiKeyErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignShipStationApiKey &&
      signinData.manager === "shipstation"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShipStationApiKeyErrors: true,
        })
      );
      return true;
    }

    if (
      !signinData.managerSignShipStationApiSecret &&
      signinData.manager === "shipstation"
    ) {
      dispatch(
        setSigninData({
          ...signinData,
          managerSignShipStationApiSecretErrors: true,
        })
      );
      return true;
    }
  };

  const onUpdateLogoClick = () => {
    setFileError(false);
    const {current} = inputFile;
    (
      current || {
        click: () => {
        },
      }
    ).click();
  };

  const handleTenantInfoUpdate = () => {
    if (!contactEmail || !shippingVolume || emailError) return;
    dispatch(incrementAwaitingApiCounter());
    const data = {
      shipping_volume: shippingVolume,
      contact_info: {
        email: contactEmail,
      },
      plan_id: planSwitch,
      switched_to_premium:
        planSwitch === plansInfo[planNames.premium]?._id && isSwitched
          ? true
          : false,
      status: isActivate,
    };

    updateTenantInfo(data, userInfo, tenant_name, tenant_id)
      .then(({data}) => {
        setIsSwitched(false);
        dispatch(decrementAwaitingApiCounter());
        setIsLogoUpdate(false);

        setShowConfirmation(true);
        // getTenantUser(null, headers, userInfo?.user_id, userInfo)
        // 	.then((r) => {
        // 		const { data, statusCode } = r;
        // 		if (statusCode === 200) {
        // 			setUserConfigInfo(data);
        // 		}
        // 	})
        // 	.catch((e) => console.log(e));

        // getSingleTenant(null, null, tenant_id, userInfo, tenant_name)
        getTenant(userInfo, tenant_id)
          .then((r) => {
            const {statusCode, data} = r;

            if (statusCode === 200) {
              setTenantConfigInfo(data);
              setShippingVolume(data.shipping_volume);
              setContactEmail(data.contact_info.email);
              setSelectedPlan(data.plan_id);
              setIsActivate(data.status);
              setUserConfigInfo(data);
              setLogoPath(
                data.logo_path
                  ? retrieveLogoUrl + "/" + data.logo_path
                  : null
              );
            }
          })
          .catch((e) => console.log(e));
      })
      .catch((e) => {
        dispatch(decrementAwaitingApiCounter());
        console.log(e);
      });
  };

  const handleUpdateLogo = () => {
    if (!file) {
      setFileError(true);
      return;
    }

    dispatch(incrementAwaitingApiCounter());

    fetch(file)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "company_logo", {
          type: "image/png",
        });
        const headers = {
          tenant_id,
          user_id: userInfo.user_id,
        };
        updateLogo(file, userInfo, tenant_name, headers)
          .then((r) => {
            dispatch(decrementAwaitingApiCounter());
            setIsLogoUpdate(true);
            const {statusCode, data} = r;

            if (statusCode === 200) {
              setShowConfirmation(true);
              const {file_path} = data;
              dispatch(
                updateUserInfo({tenant_logo: file_path})
              );
            }
          })
          .catch((e) => {
            dispatch(decrementAwaitingApiCounter());
            console.log(e);
          });
      });
  };

  const handleCarrierManagerLoad = ({carrier, order_management}) => {
    const data = {};

    if (carrier.ups) {
      data.carrierSignUPSUsername = carrier.ups.user_name;
      data.carrierSignUPSPassword = carrier.ups.password;
      data.carrierSignUPSClientId = carrier.ups.client_key;
      data.carrierSignUPSClientSecret = carrier.ups.client_secret;
      data.carrier = {...data.carrier, ups: true};
    }

    if (carrier.usps) {
      data.carrierSignUSPSUsername = carrier.usps.user_name;
      data.carrierSignUSPSPassword = carrier.usps.password;
      data.carrierSignUSPSUserId = carrier.usps.user_id;
      data.carrier = {...data.carrier, usps: true};
    }

    if (carrier.fedex) {
      data.carrierSignFedexUsername = carrier.fedex.user_name;
      data.carrierSignFedexPassword = carrier.fedex.password;
      data.carrierSignFedexClientId = carrier.fedex.client_key;
      data.carrierSignFedexClientSecret = carrier.fedex.client_secret;
      data.carrier = {...data.carrier, fedex: true};
    }

    if (carrier.dhl) {
      data.carrierSignDHLUsername = carrier.dhl.user_name;
      data.carrierSignDHLPassword = carrier.dhl.password;
      data.carrierSignDHLApiKey = carrier.dhl.api_key;
      data.carrierSignDHLApiSecret = carrier.dhl.api_secret;
      data.carrier = {...data.carrier, dhl: true};
    }

    if (order_management.magento) {
      data.managerSignMagentoUrl = order_management.magento.store_url;
      data.managerSignMagentoToken =
        order_management.magento.access_token;
      data.manager = "magento";
    }

    if (order_management.shopify) {
      data.managerSignShopifyStoreUrl =
        order_management.shopify.store_url;
      data.managerSignShopifyApiKey =
        order_management.shopify.access_token;
      data.manager = "shopify";
    }

    if (order_management.ship_station) {
      data.managerSignShipStationApiKey =
        order_management.ship_station.api_key;
      data.managerSignShipStationApiSecret =
        order_management.ship_station.api_secret;
      data.manager = "shipstation";
    }

    data.isFromConfig = true;

    dispatchRedux(setSigninData(data));
  };

  const handleUpdateEmailPreferences = async () => {
    try {
      dispatch(incrementAwaitingApiCounter());
      const response = await updateEmailPreferences(
        emailPreferences,
        userInfo,
        tenant_name,
        tenant_id
      );
      if (response?.status === 200) {
        setIsEmailPreferencesUpdate(true);
        setShowConfirmation(true);

        getTenant(userInfo, tenant_id)
          .then((r) => {
            const {statusCode, data} = r;

            if (statusCode === 200) {
              setEmailPreferences({
                delivered_email: data.delivered_email,
                first_scan_email: data.first_scan_email,
                out_for_delivery_email:
                data.out_for_delivery_email,
              });
            }
          })
          .catch((e) => console.log(e));
      }
      dispatch(decrementAwaitingApiCounter());
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteTenantConfirmation = async () => {
    dispatch(incrementAwaitingApiCounter());
    try {
      const response = await deleteTenant(
        userInfo,
        {tenant_id: tenant_id},
        {
          action: "sendOTP",
        }
      );
      if (response.statusCode === 200) {
        setOpenOTPDeleteTenant(true);
        dispatch(decrementAwaitingApiCounter());
      }
      if (response.statusMessage === "Invalid OTP Code")
        setErrorMessage(
          "Invalid verification code provided, please try again."
        );
    } catch (error) {
      dispatch(decrementAwaitingApiCounter());
      setErrorMessage("There was an unexpected error.");
    }
  };
  const resendDandleDeleteTenant = async () => {
    dispatch(incrementAwaitingApiCounter());
    try {
      const response = await deleteTenant(
        userInfo,
        {tenant_id: tenant_id},
        {
          action: "sendOTP",
        }
      );
      if (response.statusCode === 200) {

      }
      dispatch(decrementAwaitingApiCounter());

    } catch (error) {
      dispatch(decrementAwaitingApiCounter());
    }
  };
  const handleDeleteTenant = async (data) => {
    dispatch(incrementAwaitingApiCounter());
    try {
      const response = await deleteTenant(
        userInfo,
        {tenant_id, otp_code: parseInt(data.otp)},
        {
          action: "executeDeleteAction",
        }
      );

      if (response.statusCode === 200) {
        setOpenDeleteTenantSuccessful(true);
        dispatch(decrementAwaitingApiCounter());
      }
    } catch (error) {
      dispatch(decrementAwaitingApiCounter());
      setErrorMessage(error.message);
    }
  };

  const handleDeleteTenantSuccesful = () => {
    setOpenDeleteTenantSuccessful(false);
    navigate("/dashboard/tenants");
  };

  useEffect(() => {
    dispatch(incrementAwaitingApiCounter());

    const headers = {
      tenant_id: tenant_id,
      user_id: userInfo.user_id,
    };

    getTenant(userInfo, tenant_id)
      .then((r) => {
        const {statusCode, data} = r;
        if (statusCode === 200) {
          setTenantConfigInfo(data);
          setShippingVolume(data.shipping_volume);
          setContactEmail(data.contact_info.email);
          setSelectedPlan(data.plan_id);
          setPlanSwitch(data.plan_id);
          setIsActivate(data?.status);
          setUserConfigInfo(data);
          setLogoPath(
            data.logo_path
              ? retrieveLogoUrl + "/" + data.logo_path
              : null
          );
          setEmailPreferences({
            delivered_email: data.delivered_email,
            first_scan_email: data.first_scan_email,
            out_for_delivery_email: data.out_for_delivery_email,
          });
          handleCarrierManagerLoad(data);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => dispatch(decrementAwaitingApiCounter()));

    getPlans(userInfo.accessToken).then((r) => {
      const {data, statusCode} = r;
      if (statusCode === 200) setPlans(data.filter((d) => d.display));
    });

    getAllTenantUsers(null, headers, userInfo, tenant_name)
      .then((r) => {
        const {data, statusCode} = r;
        if (statusCode === 200) {
          const filteredArray = data.filter(
            (r) => r.role === "administrator"
          );
          setAdminUsers(filteredArray);
        }
      })
      .catch((e) => console.log(e));
  }, []);

  useEffect(() => {
    setShowConfigUpdated(isConfigUpdated);
  }, [isConfigUpdated]);

  useEffect(() => {
    reset(defaultValue);
  }, [reset, configData]);

  return (
    <Box>
      <Helmet>
        <title> Configuration | BeyondCarts CMS </title>
      </Helmet>
      {!isConfig && !isConfigCarrierOrderManager && (
        <Box
          sx={{
            marginTop: 0,
            paddingX: {xs: 1, md: 3},
            paddingY: {xs: 2, md: 3},
            border: {xs: "none", md: "1px solid #c4c4c4"},
            borderRadius: "10px",
            display: "flex",
            flexDirection: "column",
            gap: 4,
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: {
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
                xl: "repeat(3, 1fr)",
              },
              gap: {xs: 4, sm: 2, lg: 4},
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: {xs: 1.5, md: 2},
                flexDirection: "column",
                alignItems: "center",
                maxWidth: {xl: "500px"},
                paddingX: {xs: 2, lg: 4},
                paddingY: {xs: 3, lg: 4},
                paddingBottom: "0",
                border: "1px solid #c4c4c4",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: {xs: 1.5, md: 2},
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <h3
                  style={{
                    ...typography.h3,
                    textAlign: "center",
                    width: "100%",
                  }}
                >
                  Company and Contact Information
                </h3>

                <Typography
                  sx={{...textStyle, width: "100%"}}
                >
                  Company name:{" "}
                  <span
                    style={{
                      fontSize: {xs: 16, md: 18},
                      fontWeight: "bold",
                    }}
                  >
										{tenantConfigInfo?.company_name}
									</span>
                </Typography>

                <Typography
                  sx={{...textStyle, width: "100%"}}
                >
                  Enrolled at:{" "}
                  {unixToDate(tenantConfigInfo?.enrolled_at)}
                </Typography>

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "20px",
                    width: "100%",
                  }}
                >
                  <Typography
                    sx={{
                      ...textStyle,
                      marginTop: 0,
                    }}
                  >
                    Status:
                  </Typography>
                  <Switch
                    checked={
                      isActivate === "active"
                        ? true
                        : false
                    }
                    size="medium"
                    onChange={(event) =>
                      setIsActivate(
                        event.target.checked
                          ? "active"
                          : "Inactive"
                      )
                    }
                  ></Switch>
                </Box>
              </Box>

              {selectedPlan ===
              plansInfo[planNames.premium]?._id ? (
                <Box sx={{width: "100%"}}>
                  <Typography
                    sx={{...textStyle, marginTop: 0}}
                  >
                    Plan name:{" "}
                    {
                      plans.filter(
                        (el) =>
                          el._id ===
                          plansInfo[planNames.premium]
                            ?._id
                      )[0]?.name
                    }
                  </Typography>
                </Box>
              ) : selectedPlan ===
              plansInfo[planNames.demo]?._id ? (
                <Box sx={{width: "100%"}}>
                  <Typography
                    sx={{...textStyle, marginTop: 0}}
                  >
                    Plan name:{" "}
                    {
                      plans.filter(
                        (el) =>
                          el._id ===
                          plansInfo[planNames.demo]
                            ?._id
                      )[0]?.name
                    }
                  </Typography>{" "}
                </Box>
              ) : (
                <FormControl
                  fullWidth
                  sx={{...styleInput, marginTop: "10px"}}
                >
                  <InputLabel
                    id="credit-month-select-label"
                    sx={{marginTop: "10px"}}
                  >
                    Plan name
                  </InputLabel>
                  <Select
                    labelId="credit-month-select-label"
                    value={planSwitch}
                    label="Plan name"
                    onChange={(e) => {
                      setIsSwitched(true);
                      setPlanSwitch(e.target.value);
                      // setSelectedPlan(e.target.value);
                    }}
                    sx={{
                      ...styleInput,
                      color: palette.common.black,
                      marginTop: 0,
                    }}
                  >
                    <MenuItem
                      value={
                        plansInfo[planNames.free]?._id
                      }
                      disabled
                      sx={{color: palette.common.black}}
                    >
                      {
                        plans.find(
                          (p) =>
                            p._id ===
                            plansInfo[
                              planNames.free
                              ]?._id
                        )?.name
                      }
                    </MenuItem>
                    {plans.map(
                      (p) =>
                        p._id !==
                        plansInfo[planNames.free]
                          ?._id &&
                        p._id !==
                        plansInfo[planNames.demo]
                          ?._id && (
                          <MenuItem
                            key={p._id}
                            value={p._id}
                            sx={{
                              color: palette
                                .common.black,
                            }}
                          >
                            {p.name}
                          </MenuItem>
                        )
                    )}
                  </Select>
                </FormControl>
              )}

              {adminUsers.length > 1 ? (
                <FormControl fullWidth sx={styleInput}>
                  <InputLabel id="credit-month-select-email">
                    Primary contact
                  </InputLabel>
                  <Select
                    labelId="credit-month-select-email"
                    value={contactEmail}
                    label="Primary contact"
                    onChange={(e) =>
                      setContactEmail(e.target.value)
                    }
                    sx={{
                      ...styleInput,
                      color: palette.common.black,
                      marginTop: 0,
                    }}
                  >
                    {adminUsers.map((a, index) => (
                      <MenuItem
                        key={index}
                        value={a.user_name}
                        sx={{
                          color: palette.common.black,
                        }}
                      >
                        {`${a.first_name} ${a.last_name}`}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <TextField
                  sx={styleInput}
                  label="Primary contact"
                  value={contactEmail}
                  onChange={(e) =>
                    setContactEmail(e.target.value)
                  }
                  inputProps={{maxLength: 40}}
                  disabled
                />
              )}

              {(!contactEmail || emailError) && (
                <p style={{fontSize: "12px", color: "red"}}>
                  {!contactEmail
                    ? "Email is required"
                    : "Invalid email format"}
                </p>
              )}

              <FormControl
                as="fieldset"
                error={signinData?.shippingVolError}
                fullWidth
              >
                <FormLabel
                  id="demo-radio-buttons-group-label"
                  sx={{
                    color: "black !important",
                    paddingBottom: 0.5,
                    fontSize: {xs: 16, md: 18},
                  }}
                >
                  Monthly Shipping Volume
                </FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                  value={shippingVolume}
                  onClick={(e) => {
                    if (e.target.value === shippingVolume) {
                      setShippingVolume("");
                    } else {
                      setShippingVolume(e.target.value);
                    }
                  }}
                >
                  <FormControlLabel
                    value="1-2500"
                    sx={{color: "#000"}}
                    control={
                      <Radio sx={{paddingY: 0.5}}/>
                    }
                    label="1-2500"
                  />
                  <FormControlLabel
                    value="2501-5000"
                    sx={{color: "#000"}}
                    control={
                      <Radio sx={{paddingY: 0.3}}/>
                    }
                    label="2501-5000"
                  />
                  <FormControlLabel
                    value="5000&Above"
                    sx={{color: "#000"}}
                    control={
                      <Radio sx={{paddingY: 0.5}}/>
                    }
                    label="5000 & Above"
                  />
                </RadioGroup>
              </FormControl>

              {/* <Box
								sx={{
									height: 150,
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							> */}
              <Button
                size="large"
                type="button"
                variant="contained"
                sx={{
                  bgcolor: saveEnable
                    ? "primary.light"
                    : "info.button",
                  color: saveEnable
                    ? "common.white"
                    : "common.black",
                  ...btnStyle,
                  backgroundColor: palette.secondary.main,
                }}
                onClick={handleTenantInfoUpdate}
              >
                Update
              </Button>
              {/* </Box> */}
            </Box>

            <Box
              sx={{
                display: "flex",
                gap: 2,
                justifyContent: "center",
                maxWidth: {xl: "500px"},
                paddingX: {xs: 2, lg: 4},
                paddingY: {xs: 3, lg: 4},
                paddingBottom: "0",
                border: "1px solid #c4c4c4",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                  // width: "300px",
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    // width: "300px",
                    gap: {xs: 2, md: 3},
                  }}
                >
                  <h3
                    style={{
                      ...typography.h3,
                      textAlign: "center",
                      width: "100%",
                    }}
                  >
                    Logo
                  </h3>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: {xs: 2, md: 4},
                    }}
                  >
                    <Typography
                      sx={{
                        textAlign: "center",
                        // marginTop: "10px",
                        color: "#000",
                      }}
                    >
                      (Click on the image below to browse
                      for new logo file)
                    </Typography>

                    <Box
                      sx={{
                        maxWidth: {
                          xs: "200px",
                          md: "300px",
                        },
                        margin: "auto",
                      }}
                    >
                      <img
                        style={{
                          display: "block",
                          // height: "auto",
                          // width: 'auto',
                          maxHeight: "200px",
                          cursor: "pointer",
                        }}
                        src={
                          file
                            ? file
                            : logoPath
                              ? logoPath
                              : "/assets/no-image.png"
                        }
                        onClick={onUpdateLogoClick}
                      />
                    </Box>

                    {fileError && (
                      <Typography
                        sx={{
                          color: fileError
                            ? "red"
                            : "transparent",
                          textAlign: "center",
                        }}
                      >
                        You must select one file.
                      </Typography>
                    )}
                  </Box>

                  <input
                    type="file"
                    id="file"
                    accept="image/*"
                    onChange={(e) => {
                      let reader = new FileReader();
                      try {
                        reader.readAsDataURL(
                          e.target.files[0]
                        );
                        reader.onloadend = function () {
                          setFile(reader.result);
                        };
                      } catch (error) {
                      }
                    }}
                    ref={inputFile}
                    hidden
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="large"
                    type="button"
                    variant="contained"
                    sx={{
                      bgcolor: saveEnable
                        ? "primary.light"
                        : "info.button",
                      color: saveEnable
                        ? "common.white"
                        : "common.black",
                      ...btnStyle,
                      backgroundColor:
                      palette.secondary.main,
                    }}
                    onClick={handleUpdateLogo}
                  >
                    Update Logo
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                paddingX: {xs: 2, lg: 4},
                paddingY: {xs: 3, lg: 4},
                paddingBottom: "0",
                maxWidth: {xl: "500px"},
                gridColumn: {sm: "span 2", xl: "unset"},
                border: "1px solid #c4c4c4",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 3,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: {xs: 1, md: 3},
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h3
                      style={{
                        ...typography.h3,
                        textAlign: "center",
                        width: "100%",
                      }}
                    >
                      Email Preferences
                    </h3>
                    <InfoLogo
                      infoTxt="Select the type of emails end customers want to receive."
                      contentPositionStyle={{
                        top: "140%",
                        left: " -480%",
                        backgroundColor: "white",
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: {xs: 0, md: 1},
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          ...textStyle,
                          marginTop: 0,
                        }}
                      >
                        Carrier Origin Scan
                      </Typography>
                      <Switch
                        checked={
                          emailPreferences.first_scan_email
                        }
                        onChange={(event) =>
                          setEmailPreferences(
                            (prev) => {
                              return {
                                ...prev,
                                first_scan_email:
                                event.target
                                  .checked,
                              };
                            }
                          )
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{...textStyle, margin: 0}}
                      >
                        Out For Delivery
                      </Typography>
                      <Switch
                        checked={
                          emailPreferences.out_for_delivery_email
                        }
                        onChange={(event) =>
                          setEmailPreferences(
                            (prev) => {
                              return {
                                ...prev,
                                out_for_delivery_email:
                                event.target
                                  .checked,
                              };
                            }
                          )
                        }
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        sx={{
                          ...textStyle,
                          margin: 0,
                        }}
                      >
                        Delivered Email
                      </Typography>
                      <Switch
                        checked={
                          emailPreferences.delivered_email
                        }
                        onChange={(event) =>
                          setEmailPreferences(
                            (prev) => {
                              return {
                                ...prev,
                                delivered_email:
                                event.target
                                  .checked,
                              };
                            }
                          )
                        }
                      />
                    </Box>
                  </Box>
                </Box>

                <Box
                  sx={{
                    // height: 150,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="large"
                    type="button"
                    variant="contained"
                    disabled={isDemoPlan}
                    sx={{
                      bgcolor: saveEnable
                        ? "primary.light"
                        : "info.button",
                      color: saveEnable
                        ? "common.white"
                        : "common.black",
                      ...btnStyle,
                      backgroundColor:
                      palette.secondary.main,
                    }}
                    onClick={handleUpdateEmailPreferences}
                  >
                    Update
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Snackbar
            open={showConfigUpdated}
            anchorOrigin={{vertical: "top", horizontal: "right"}}
            autoHideDuration={6000}
            onClose={onCloseExportInfo}
          >
            <Alert
              onClose={onCloseExportInfo}
              severity="success"
              sx={{width: "100%"}}
            >
              Configuration updated.
            </Alert>
          </Snackbar>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              columnGap: "100px",
              paddingBottom: "0",
              borderTop: "1px solid #c4c4c4",
            }}
          >
            <Box
              sx={{
                padding: {xs: 3, md: 4},
                paddingBottom: {xs: 4, md: 5},
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: {xs: 2, md: 4},
              }}
            >
              <h3
                style={{
                  ...typography.h3,
                  textAlign: "center",
                  width: "100%",
                }}
              >
                Carriers and Order Managers
              </h3>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: {xs: 3, md: 6},
                  flexDirection: {xs: "column", md: "row"},
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: {
                      xs: "column",
                      md: "row",
                    },
                    gap: {xs: 3, md: 6},
                  }}
                >
                  {tenantConfigInfo?.carrier?.usps && (
                    <img
                      className="carrier-img"
                      src="/assets/usps.png"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        onChangeClick("usps")
                      }
                    />
                  )}

                  {tenantConfigInfo?.carrier?.ups && (
                    <img
                      className="carrier-img"
                      src="/assets/ups.png"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => onChangeClick("ups")}
                    />
                  )}

                  {tenantConfigInfo?.carrier?.fedex && (
                    <img
                      className="carrier-img"
                      src="/assets/fedex.png"
                      style={{
                        cursor: "pointer",
                        width: "150px",
                      }}
                      onClick={() =>
                        onChangeClick("fedex")
                      }
                    />
                  )}

                  {tenantConfigInfo?.carrier?.dhl && (
                    <img
                      className="carrier-img"
                      src="/assets/dhl.png"
                      style={{
                        cursor: "pointer",
                        width: "160px",
                      }}
                      onClick={() => onChangeClick("dhl")}
                    />
                  )}
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 6,
                  }}
                >
                  {tenantConfigInfo?.order_management
                    ?.shopify && (
                    <img
                      className="manage-img"
                      src="/assets/shopify.png"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        onChangeClick("shopify")
                      }
                    />
                  )}

                  {tenantConfigInfo?.order_management
                    ?.magento && (
                    <img
                      className="manage-img"
                      src="/assets/magento.png"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        onChangeClick("magento")
                      }
                    />
                  )}

                  {tenantConfigInfo?.order_management
                    ?.ship_station && (
                    <img
                      className="manage-img manage-img2"
                      src="/assets/shipStation.png"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        onChangeClick("shipstation")
                      }
                    />
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: {xs: "column", md: "row"},
                  gap: 2,
                  marginTop: {xs: 2, md: 0},
                }}
              >
                <Button
                  size="large"
                  type="button"
                  variant="contained"
                  sx={{
                    color: "common.white",
                    backgroundColor: palette.secondary.main,
                    ...btnStyle,
                    width: 240,
                  }}
                  onClick={() =>
                    handleUpdateCarrierAndOrderManager(
                      "carrier"
                    )
                  }
                >
                  Update Carriers
                </Button>
                <Button
                  size="large"
                  type="button"
                  variant="contained"
                  sx={{
                    color: "common.white",
                    backgroundColor: palette.secondary.main,
                    ...btnStyle,
                    width: 240,
                  }}
                  onClick={() =>
                    handleUpdateCarrierAndOrderManager(
                      "manager"
                    )
                  }
                >
                  Update Order Managers
                </Button>
              </Box>
            </Box>

            {/* Delete tenant */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                borderTop: "1px solid #c4c4c4",
                paddingX: {xs: 3, md: 4},
                paddingTop: {xs: 4, md: 5},
                paddingBottom: {xs: 2, md: 3},
                paddingBottom: 2,
              }}
            >
              <Button
                size="large"
                type="button"
                variant="contained"
                sx={{
                  color: "common.white",
                  backgroundColor: palette.secondary.main,
                  ...btnStyle,
                  width: 240,
                }}
                onClick={() =>
                  setOpenDeleteTenantConfirmation(true)
                }
              >
                Delete Tenant
              </Button>
            </Box>
          </Box>
        </Box>
      )}

      {isConfig && (
        <Box
          sx={{
            border: "1px solid #c4c4c4",
            borderRadius: "10px",
            padding: {xs: "24px 0px", md: "50px 100px"},
            marginTop: {xs: "20px", md: "30px"},
          }}
        >
          {isConfig === "carrier" ? (
            <SigninCarrier navigateTo={orderType}/>
          ) : (
            <SigninManage navigateTo={orderType}/>
          )}
          <Box
            sx={{
              paddingX: 2,
              paddingTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              gap: {xs: 1, md: 2},
            }}
          >
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                setIsConfig(false);
                window.location.reload();
                // dispatchRedux(
                // 	setSigninData({
                // 		...originalSigninData,
                // 	})
                // );
              }}
              sx={{
                fontSize: "16px !important",
                width: {xs: 180, md: 260},
                boxShadow: "none",
                backgroundColor: "grey.600",
                "&:hover": {
                  backgroundColor: "primary.lightIcon",
                },
              }}
            >
              Cancel
            </Button>

            <Button
              size="large"
              type="button"
              variant="contained"
              onClick={onSaveClick}
              sx={{
                fontSize: "16px !important",
                width: {xs: 180, md: 260},
                boxShadow: "none",
                backgroundColor: saveEnable
                  ? palette.secondary.main
                  : "info.button",
                color: saveEnable
                  ? "common.white"
                  : "common.black",
              }}
            >
              Save
            </Button>
          </Box>
        </Box>
      )}

      {isConfigCarrierOrderManager && (
        <Box
          sx={{
            border: "1px solid #c4c4c4",
            borderRadius: "10px",
            padding: {xs: "24px 0px", md: "50px 100px"},
            marginTop: {xs: "20px", md: "30px"},
          }}
        >
          {isConfigCarrierOrderManager === "carrier" ? (
            <Carrier/>
          ) : (
            <ManageForm/>
          )}

          <Box
            sx={{
              paddingX: 2,
              paddingTop: "30px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
              gap: {xs: 1, md: 2},
            }}
          >
            <LoadingButton
              // fullWidth
              size="large"
              type="submit"
              variant="contained"
              onClick={() => {
                setIsConfigCarrierOrderManager(false);
                window.location.reload();
              }}
              sx={{
                fontSize: "16px !important",
                width: {xs: 180, md: 260},
                boxShadow: "none",
                backgroundColor: "grey.600",
                "&:hover": {
                  backgroundColor: "primary.lightIcon",
                },
              }}
            >
              Cancel
            </LoadingButton>

            <LoadingButton
              // fullWidth
              size="large"
              type="submit"
              variant="contained"
              disabled={
                isDemoPlan ||
                signinData?.carrierError ||
                signinData?.manageError
              }
              onClick={() => {
                setIsConfig(isConfigCarrierOrderManager);
                setIsConfigCarrierOrderManager(false);
              }}
              sx={{
                fontSize: "16px !important",
                width: {xs: 180, md: 260},
                boxShadow: "none",
                backgroundColor: saveEnable
                  ? palette.secondary.main
                  : "info.button",
                color: saveEnable
                  ? "common.white"
                  : "common.black",
              }}
            >
              Continue
            </LoadingButton>
          </Box>
        </Box>
      )}

      {/* TODO: refactor this 3 to use ModalComponent!*/}
      <Modal
        open={showConfirmation}
        onClose={handleCloseModal}
        sx={{
          width: "550px",
          margin: "auto",
          textAlign: "center",
        }}
      >
        <Box sx={stylePassModal}>
          <Box>
            <IconButton
              aria-label="close"
              sx={{
                position: "fixed",
                top: {sm: 15, lg: 10},
                right: {lg: 29, md: 30, sm: 10},
                zIndex: 2,
              }}
              onClick={handleCloseModal}
            >
              <img
                width="30px"
                src="/assets/icons/table/close.svg"
                alt="X"
              />
            </IconButton>
            <Typography variant="h3">Configuration</Typography>
            <Typography sx={textStyle}>
              {isLogoUpdate
                ? "Logo"
                : isEmailPreferencesUpdate
                  ? "Email preferences"
                  : "Configuration"}{" "}
              updated succesfully.
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "20px 0",
            }}
          >
            <Button
              size="large"
              type="button"
              variant="contained"
              sx={{
                boxShadow: "none",
                backgroundColor: palette.secondary.main,
                paddingY: 1.3,
                width: "200px",
                fontSize: {
                  lg: 15,
                  md: 15,
                  xs: 13,
                  sm: 13,
                },
              }}
              style={{
                fontFamily: typography.fontFamilySemiBold,
              }}
              onClick={handleCloseModal}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Modal>

      {openDeleteTenantConfirmation && (
        <ModalComponent
          openModal={openDeleteTenantConfirmation}
          handleCloseModal={() =>
            setOpenDeleteTenantConfirmation(false)
          }
          handleBtnClick={handleDeleteTenantConfirmation}
          title={"Delete Tenant"}
          description={"Are you sure you want to delete this tenant?"}
          btnText={"Continue"}
        />
      )}

      {openOTPDeleteTenant && (
        <ModalComponent
          openModal={openOTPDeleteTenant}
          handleCloseModal={() => {
            reset();
            setErrorMessage("");
            setOpenOTPDeleteTenant(false);
            setOpenDeleteTenantConfirmation(false);
          }}
          handleBtnClick={handleSubmit(handleDeleteTenant)}
          title={"OTP Confirmation"}
          description={`Enter in the code that was sent to ${process.env.REACT_APP_ADMIN_EMAIL_ADDRESS}`}
          btnText={"Continue"}
        >
          <OtpForm
            control={control}
            cbDeleteTenantResend={resendDandleDeleteTenant}
            email={process.env.REACT_APP_ADMIN_EMAIL_ADDRESS}
            clearErrors={() => setErrorMessage("")}
            isError={errorMessage.includes("Invalid")}
            deleteTenant={true}
            onBoarding={true}
          />
        </ModalComponent>
      )}

      {openDeleteTenantSuccessful && (
        <ModalComponent
          openModal={openDeleteTenantSuccessful}
          handleCloseModal={handleDeleteTenantSuccesful}
          handleBtnClick={handleDeleteTenantSuccesful}
          title={"Delete Tenant"}
          description={"Tenant deleted succesfully."}
          btnText={"Continue"}
        />
      )}
    </Box>
  );
}
