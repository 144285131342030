import postData from "src/dataFetcher/postDataFetcher";
import postFormData from "src/dataFetcher/postFormDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";

import { backendTenantUrl } from "src/utils/backendUrls";

const logoUrl = process.env.REACT_APP_BACKEND_LOGO_URL;

export const retrieveLogoUrl = process.env.REACT_APP_BACKEND_RETRIEVE_LOGO_URL;

export async function updateTenantInfo(data, userInfo, tenant_name, tenant_id) {
	const token = userInfo?.accessToken;
	const header = {
		tenant_id,
		action: "upgradePlan",
		user_id: userInfo.user_id,
	};

	return await postData(`${backendTenantUrl}order`, header, data, token);
}

export async function updateCarrierOrder(
	data,
	userInfo,
	tenant_name,
	tenant_id
) {
	const token = userInfo?.accessToken;
	const header = {
		tenant_id,
	};

	return await putData(
		`${backendTenantUrl}tenant/${tenant_name}/tenant/company_config/${tenant_id}`,
		header,
		data,
		token
	);
}

export async function updateLogo(file, userInfo, tenant_name, headers) {
	const token = userInfo?.accessToken;
	const tenant = userInfo?.tenant_name;

	return await postFormData(
		`${logoUrl}${tenant_name}/tenant/logo_update`,
		headers,
		file,
		token
	);
}

export async function updateEmailPreferences(
	data,
	userInfo,
	tenant_name,
	tenant_id
) {
	const token = userInfo?.accessToken;

	const headers = {
		tenant_id,
		// user_id: userInfo.user_id,
	};

	return await putData(
		`${backendTenantUrl}tenant/${tenant_name}/tenant/config/email_preference/${tenant_id}`,
		headers,
		data,
		token
	);
}
