import Cookies from "js-cookie";
import { AES, enc } from "crypto-js";

export function retrieveCookies(setValue) {
	const secretVariableName = "d4521t";
	const storedCredentials = Cookies.get(secretVariableName);
	if (storedCredentials) {
		const decryptedCredentials = AES.decrypt(
			storedCredentials,
			"secret-key"
		).toString(enc.Utf8);
		const { email, password } = JSON.parse(decryptedCredentials);
		setValue("email", email);
		setValue("password", password);
	}
}
export const storeCredentialsInCookies = (email, password) => {
	const secretVariableName = "d4521t";
	const credentials = { email, password };
	const encryptedCredentials = AES.encrypt(
		JSON.stringify(credentials),
		"secret-key"
	).toString();
	Cookies.set(secretVariableName, encryptedCredentials);
};
