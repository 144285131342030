import { styled } from '@mui/material/styles';
import { Typography, Box, Card } from '@mui/material';
import PropTypes from 'prop-types';
import typography from 'src/theme/typography';
// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  item: PropTypes.object.isRequired,
  onClickRow: PropTypes.func,
};

function AppWidgetSummary({ item, handleOnClick }) {
  const StyledIcon = styled('div')(() => ({
    display: 'flex',
    borderRadius: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  }));
  return (
    <Card
      sx={{
        py: { lg: 2, sm: 1 },
        px: { lg: 3, sm: 1 },
        boxShadow: 0,
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        width: { lg: '23%', sm: '23%', md: '23%' },
        height: { lg: 116, sm: 60, md: 85.53 },
        borderRadius: 1.5,
      }}
      className="metrics-card"
      //   onClick={() =>{ handleOnClick(item)
      // console.log(item)
      //   }}
    >
      <Box
        container
        sx={{
          width: 30,
        }}
      >
        <StyledIcon
          sx={{
            width: { lg: 60, sm: 31.76, md: 44.35 },
            height: { lg: 60, sm: 31.52, md: 44.23 },
          }}
        >
          <img src={item.icon} sx={{ outline: 'none' }} alt="" />
        </StyledIcon>
      </Box>

      <Box
        container
        sx={{
          textAlign: 'start',
          paddingLeft: { lg: 6, sm: 1, md: 3 },
          color: 'secondary.light',
          // maxWidth: 150,
        }}
      >
        {/* crad value */}
        <Typography variant="shippingCardValue" sx={{ opacity: 0.7, fontSize: { md: 23, lg: 25, sm: 20, xs: 20 } }}>
          {item.total}
        </Typography>
        {/* card */}
        <Typography
          variant="body2"
          sx={{
            fontSize: { md: 15, lg: 17, sm: 15, xs: 15 },
            fontFamily: typography.fontFamilyRegular,
            lineHeight: 1.2,
            opacity: 0.8,
          }}
        >
          {item.title}
        </Typography>
      </Box>
    </Card>
  );
}

TransitDataCard.propTypes = {
  title: PropTypes.string,
  cardItems: PropTypes.array,
};

export default function TransitDataCard({ title, cardItems, handleOnClick }) {
  const StyledRoot = styled('div')(() => ({
    maxWidth: '100%',
  }));

  return (
    <StyledRoot>
      <Box
        sx={{
          pl: 2,
          py: 1,
          // height: 50,
          display: 'flex',
          alignItems: 'center',
          color: 'common.black',
          //   bgcolor: 'common.white',
          paddingLeft: '0px',
          marginTop: '32px',
          marginBottom: '23px',
          borderTopLeftRadius: 10,
          borderTopRightRadius: 10,
        }}
      >
        <Typography
          variant="ReportsCardValue"
          sx={{
            fontSize: { md: 24, lg: 28, sm: 20, xs: 20 },
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          direction: 'row',
          justifyContent: 'space-between',
          my: 3,
          gap: 1,
        }}
      >
        {cardItems.map((item, index) => (
          <AppWidgetSummary handleOnClick={handleOnClick} item={item} key={`${item.title}-${index}`} />
        ))}
      </Box>
    </StyledRoot>
  );
}
