import { styled } from "@mui/material/styles";
import { Box, Stack, AppBar, Toolbar } from "@mui/material";
import { useLocation } from "react-router-dom";

import AccountPopover from "./AccountPopover";
import Searchbar from "./Searchbar";
import PageTitle from "./PageTitle";

const HEADER_MOBILE = 64;
const HEADER_DESKTOP = 70;

const StyledRoot = styled(AppBar)(({ theme }) => ({
	boxShadow: "none",
	background: "none",
	position: "relative",
	zIndex: 1,
	paddingTop: theme.spacing(3),
	paddingBottom: theme.spacing(3),
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
	"&.MuiToolbar-root": {
		paddingLeft: 0,
		paddingRight: 0,
	},
	minHeight: HEADER_MOBILE,
	[theme.breakpoints.down("md")]: {
		paddingTop: theme.spacing(3),
	},
	[theme.breakpoints.down("sm")]: {
		alignItems: "flex-start",
	},

	[theme.breakpoints.up("md")]: {
		minHeight: HEADER_DESKTOP,
		paddingTop: theme.spacing(7),
	},
}));

export default function Header() {
	const { pathname } = useLocation();
	const isDashboard = pathname === "/dashboard/tenants";

	return (
		<StyledRoot>
			<StyledToolbar>
				{/* {isDashboard && <Searchbar />} */}
				{/* {!isDashboard && <PageTitle />} */}
				<Box sx={{ flexGrow: 1 }} />

				<Stack
					direction="row"
					alignItems="center"
					spacing={{
						xs: 0.5,
						sm: 1,
					}}
				>
					<AccountPopover />
				</Stack>
			</StyledToolbar>
		</StyledRoot>
	);
}
