import {
	Box,
	Button,
	FormLabel,
	Grid,
	IconButton,
	Modal,
	Switch,
	TextField,
	Typography,
} from "@mui/material";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import {
	getAppConfig,
	updateAppConfig,
} from "src/features/appConfig/appConfigApi";
import AccountPopover from "src/layouts/dashboard/header/AccountPopover";
import CircularLoader from "src/loader/circular/CircularLoader";
import palette from "src/theme/palette";
import { getUserInfo } from "src/features/user/userState";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { useNavigate } from "react-router-dom";
import {
	decrementAwaitingApiCounter,
	incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const Configuration = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const [base_url, setBaseUrl] = useState("");
	const [baseUrlError, setBaseUrlError] = useState("");
	const [alert_to_address, setAlertAddress] = useState("");
	const [emailError, setEmailError] = useState("");
	const [refresh_arn, setRefreshArn] = useState("");
	const [refreshError, setRefreshError] = useState("");
	const [data_fetching_days_onboarding, setDataFetchOnboarding] =
		useState(null);
	const [dataFetchOnboardingError, setDataFetchOnboardingError] =
		useState("");
	const [order_sync_arn, setOrderSyncArn] = useState("");
	const [orderSyncError, setOrderSyncError] = useState("");
	const [config_reload_arn, setConfigReloadArn] = useState("");
	const [configReloadErr, setConfigReloadErr] = useState("");
	const [email_notification_arn, setEmailNotificationArn] = useState("");
	const [emailNotificationErr, setEmailNotificationErr] = useState("");
	const [is_prod, setWebProdStatus] = useState(false);
	const [alert_report, setAlertReportStatus] = useState(false);
	const [showUpdateModel, setShowUpdateModel] = useState(false);
	const userInfo = useAppSelector(getUserInfo);
	const [isCancel, setCancel] = useState(false);

	const popUpModalStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "80%", sm: "auto", lg: "auto", md: "auto" },
		borderRadius: "20px",
		background: "#FFF",
		overflow: "auto",
		overflow: "auto-y",
		overflowX: "hidden",
		outline: "none",
	};

	const gridSpace = {
		paddingTop: "8px",
		marginBottom: "15px",
	};

	const styleInput = {
		input: { color: palette.common.black },
		width: "100%",
		marginTop: "10px",
		"& .MuiFormHelperText-root": {
			textAlign: "left",
			fontSize: "14px",
		},
		"& .MuiOutlinedInput-root": {
			"&:hover fieldset": {
				borderColor: "primary.dark",
			},
			"&.Mui-focused fieldset": {
				borderColor: "primary.dark",
			},
		},
	};

	const handleSubmit = () => {
		if (!base_url) {
			setBaseUrlError("URL is required");
			return;
		} else {
			setBaseUrlError("");
		}

		if (!alert_to_address) {
			setEmailError("Email is required");
			return;
		} else {
			setEmailError("");
		}

		if (!refresh_arn) {
			setRefreshError("Refresh arn is required");
			return;
		} else {
			setRefreshError("");
		}

		if (!order_sync_arn) {
			setOrderSyncError("Order sync arn is required");
			return;
		} else {
			setOrderSyncError("");
		}

		if (!config_reload_arn) {
			setConfigReloadErr("Config reload arn is required");
			return;
		} else {
			setConfigReloadErr("");
		}

		if (!email_notification_arn) {
			setEmailNotificationErr("Email notification arn is required");
			return;
		} else {
			setEmailNotificationErr("");
		}

		if (!data_fetching_days_onboarding) {
			setDataFetchOnboardingError("Days is required");
			return;
		} else {
			setDataFetchOnboardingError("");
		}

		const obj = {
			website: {
				base_url,
				is_prod,
			},
			billing: {
				alert_report,
				alert_to_address,
			},
			step_function: {
				refresh_arn,
				order_sync_arn,
				config_reload_arn,
				email_notification_arn,
			},
			tenant: {
				data_fetching_days_onboarding: Number(
					data_fetching_days_onboarding
				),
			},
		};
		dispatch(incrementAwaitingApiCounter());

		updateAppConfig(obj, userInfo)
			.then(() => {
				setShowUpdateModel(true);
				dispatch(decrementAwaitingApiCounter());
			})
			.catch((e) => console.log(e));
	};

	useEffect(() => {
		dispatch(incrementAwaitingApiCounter());

		getAppConfig(userInfo.accessToken).then((r) => {
			const { data } = r;
			setBaseUrl(data.website.base_url);
			setAlertAddress(data.billing.alert_to_address);
			setRefreshArn(data.step_function.refresh_arn);
			setOrderSyncArn(data.step_function.order_sync_arn);
			setConfigReloadArn(data.step_function.config_reload_arn);
			setEmailNotificationArn(data.step_function.email_notification_arn);
			setWebProdStatus(data.website.is_prod);
			setAlertReportStatus(data.billing.alert_report);
			setDataFetchOnboarding(data.tenant.data_fetching_days_onboarding);
			dispatch(decrementAwaitingApiCounter());
			setBaseUrlError("");
			setRefreshError("");
			setDataFetchOnboardingError("");
			setEmailError("");
			setOrderSyncError("");
			setConfigReloadErr("");
			setEmailNotificationErr("");
		});
	}, [isCancel]);

	return (
		<div>
			<Helmet>
				<title> Configuration | BeyondCarts CMS </title>
			</Helmet>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					borderBottom: "1px solid #c4c4c4",
					paddingBottom: 2,
					marginTop: "20px",
				}}
			>
				<Typography variant="h4" sx={{ color: "#000" }}>
					Configuration
				</Typography>
				<AccountPopover />
			</Box>

			<Box
				sx={{
					color: "#000",
					padding: "30px 0",
					display: "flex",
					flexDirection: "column",
					gap: 2,
				}}
			>
				<Box>
					<Typography
						variant="caption"
						sx={{
							textTransform: "uppercase",
							fontSize: "20px",
							fontWeight: "bold",
						}}
					>
						Website
					</Typography>
					<Grid container spacing={3} sx={gridSpace}>
						<Grid item xs={12} md={4}>
							<Typography
								variant="caption"
								sx={{
									textTransform: "uppercase",
								}}
							>
								Website Base URL
							</Typography>
							<Typography
								variant="body1"
								sx={{
									display: "inline-flex",
								}}
								style={{ color: "#FF0000" }}
							>
								*
							</Typography>
							<TextField
								value={base_url}
								sx={styleInput}
								onChange={(e) => setBaseUrl(e.target.value)}
							/>
							{baseUrlError && (
								<p
									style={{
										color: "red",
										fontSize: "14px",
										marginTop: "3px",
									}}
								>
									{baseUrlError}
								</p>
							)}
						</Grid>
						<Grid item xs={12} md={4}>
							<Typography
								variant="caption"
								sx={{
									textTransform: "uppercase",
								}}
							>
								Send Shipment Notification to Real Customers <b style={{color: 'red'}}>(Important Setting)</b>
							</Typography>
							<Box sx={{ marginTop: "18px" }}>
								<Switch
									checked={is_prod}
									onChange={() => setWebProdStatus(!is_prod)}
									inputProps={{
										"aria-label": "controlled",
									}}
								/>
							</Box>
						</Grid>
					</Grid>
				</Box>
				<Box>
					<Typography
						variant="caption"
						sx={{
							textTransform: "uppercase",
							fontSize: "20px",
							fontWeight: "bold",
						}}
					>
						AWS
					</Typography>
					<Grid container spacing={3} sx={gridSpace}>
						<Grid item xs={12} md={4}>
							<Typography
								variant="caption"
								sx={{
									textTransform: "uppercase",
								}}
							>
								Step Function Refresh ARN
							</Typography>
							<Typography
								variant="body1"
								sx={{
									display: "inline-flex",
								}}
								style={{ color: "#FF0000" }}
							>
								*
							</Typography>
							<TextField
								value={refresh_arn}
								sx={styleInput}
								onChange={(e) => setRefreshArn(e.target.value)}
							/>
							{refreshError && (
								<p
									style={{
										color: "red",
										fontSize: "14px",
										marginTop: "3px",
									}}
								>
									{refreshError}
								</p>
							)}
						</Grid>
						<Grid item xs={12} md={4}>
							<Typography
								variant="caption"
								sx={{
									textTransform: "uppercase",
								}}
							>
								Step Function Order Sync ARN
							</Typography>
							<Typography
								variant="body1"
								sx={{
									display: "inline-flex",
								}}
								style={{ color: "#FF0000" }}
							>
								*
							</Typography>
							<TextField
								value={order_sync_arn}
								sx={styleInput}
								onChange={(e) =>
									setOrderSyncArn(e.target.value)
								}
							/>
							{orderSyncError && (
								<p
									style={{
										color: "red",
										fontSize: "14px",
										marginTop: "3px",
									}}
								>
									{orderSyncError}
								</p>
							)}
						</Grid>
						<Grid item xs={12} md={4}>
							<Typography
								variant="caption"
								sx={{
									textTransform: "uppercase",
								}}
							>
								Step Function Config Reload ARN
							</Typography>
							<Typography
								variant="body1"
								sx={{
									display: "inline-flex",
								}}
								style={{ color: "#FF0000" }}
							>
								*
							</Typography>
							<TextField
								value={config_reload_arn}
								sx={styleInput}
								onChange={(e) =>
									setConfigReloadArn(e.target.value)
								}
							/>
							{configReloadErr && (
								<p
									style={{
										color: "red",
										fontSize: "14px",
										marginTop: "3px",
									}}
								>
									{configReloadErr}
								</p>
							)}
						</Grid>
						<Grid item xs={12} md={4}>
							<Typography
								variant="caption"
								sx={{
									textTransform: "uppercase",
								}}
							>
								Step Function Email Notification ARN
							</Typography>
							<Typography
								variant="body1"
								sx={{
									display: "inline-flex",
								}}
								style={{ color: "#FF0000" }}
							>
								*
							</Typography>
							<TextField
								value={email_notification_arn}
								sx={styleInput}
								onChange={(e) =>
									setEmailNotificationArn(e.target.value)
								}
							/>
							{emailNotificationErr && (
								<p
									style={{
										color: "red",
										fontSize: "14px",
										marginTop: "3px",
									}}
								>
									{emailNotificationErr}
								</p>
							)}
						</Grid>
					</Grid>
				</Box>
				<Box>
					<Typography
						variant="caption"
						sx={{
							textTransform: "uppercase",
							fontSize: "20px",
							fontWeight: "bold",
						}}
					>
						Billing Alerts
					</Typography>
					<Grid container spacing={3} sx={gridSpace}>
						<Grid item xs={12} md={4}>
							<Typography
								variant="caption"
								sx={{
									textTransform: "uppercase",
								}}
							>
								Alert To Address
							</Typography>
							<Typography
								variant="body1"
								sx={{
									display: "inline-flex",
								}}
								style={{ color: "#FF0000" }}
							>
								*
							</Typography>
							<TextField
								value={alert_to_address}
								sx={styleInput}
								onChange={(e) =>
									setAlertAddress(e.target.value)
								}
							/>
							{emailError && (
								<p
									style={{
										color: "red",
										fontSize: "14px",
										marginTop: "3px",
									}}
								>
									{emailError}
								</p>
							)}
						</Grid>
						<Grid item xs={12} md={4}>
							<Typography
								variant="caption"
								sx={{
									textTransform: "uppercase",
								}}
							>
								Alert Report
							</Typography>
							<Box sx={{ marginTop: "18px" }}>
								<Switch
									checked={alert_report}
									onChange={() =>
										setAlertReportStatus(!alert_report)
									}
									inputProps={{
										"aria-label": "controlled",
									}}
								/>
							</Box>
						</Grid>
					</Grid>
				</Box>

				<Box>
					<Typography
						variant="caption"
						sx={{
							textTransform: "uppercase",
							fontSize: "20px",
							fontWeight: "bold",
						}}
					>
						Tenant
					</Typography>
					<Grid container spacing={3} sx={gridSpace}>
						<Grid item xs={12} md={4}>
							<Typography
								variant="caption"
								sx={{
									textTransform: "uppercase",
								}}
							>
								Data Fetching Days (Onboarding)
							</Typography>
							<Typography
								variant="body1"
								sx={{
									display: "inline-flex",
								}}
								style={{ color: "#FF0000" }}
							>
								*
							</Typography>
							<TextField
								value={data_fetching_days_onboarding}
								sx={styleInput}
								onChange={(e) =>
									setDataFetchOnboarding(e.target.value)
								}
							/>
							{dataFetchOnboardingError && (
								<p
									style={{
										color: "red",
										fontSize: "14px",
										marginTop: "3px",
									}}
								>
									{dataFetchOnboardingError}
								</p>
							)}
						</Grid>
					</Grid>
				</Box>

				<Box
					sx={{
						display: "flex",
						flexDirection: { xs: "column", sm: "row" },
						alignItems: "center",
						gap: 2,
						paddingTop: "20px",
					}}
				>
					<Button
						variant="contained"
						disableElevation
						sx={{
							width: { sm: "150px", xs: "100%" },
							boxShadow: "none",
							backgroundColor: "grey.600",
							paddingY: 1.3,
							fontSize: {
								lg: 15,
								md: 15,
								xs: 13,
								sm: 13,
							},
							"&:hover": {
								backgroundColor: "primary.lightIcon",
							},
						}}
						onClick={() => setCancel(!isCancel)}
					>
						Cancel
					</Button>
					<Button
						size="large"
						type="submit"
						variant="contained"
						disableElevation
						sx={{ width: { sm: "150px", xs: "100%" } }}
						onClick={handleSubmit}
					>
						Save
					</Button>
				</Box>
			</Box>

			{showUpdateModel && (
				<Modal
					open={showUpdateModel}
					onClose={() => setShowUpdateModel(false)}
				>
					<Box
						sx={{
							...popUpModalStyle,
							width: {
								xs: "85%",
								sm: "60%",
								md: "50%",
								lg: "40%",
							},
							height: "180px",
							color: "black",
							display: "flex",
							flexDirection: "column",
						}}
					>
						<IconButton
							aria-label="close"
							sx={{
								position: "fixed",
								top: { xs: 15, lg: 20 },
								right: {
									xs: 15,
									md: 20,
								},
								zIndex: 2,
							}}
							onClick={() => setShowUpdateModel(false)}
						>
							<img
								width="30px"
								src="/assets/icons/table/close.svg"
								alt="X"
							/>
						</IconButton>
						<FormLabel
							sx={{
								color: "black",
								fontWeight: "500",
								fontSize: "20px",
								marginBottom: "20px",
							}}
						>
							Configuration Updated Successfully
						</FormLabel>
						<Button
							variant="contained"
							type="submit"
							sx={{
								boxShadow: "none",
								backgroundColor: palette.secondary.main,
								paddingY: 1.3,
								fontSize: {
									md: 15,
									xs: 13,
								},
								width: { xs: "150px", md: "200px" },
							}}
							onClick={() => setShowUpdateModel(false)}
						>
							Done
						</Button>
					</Box>
				</Modal>
			)}
		</div>
	);
};

export default Configuration;
