import { Helmet } from "react-helmet-async";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useState, useEffect, useRef, useCallback, useContext } from "react";
import dayjs from "dayjs";
import {
	setSessionData,
	unixToDate,
	unixToTime,
	datePstToUtc,
	cleanObject,
	removeTitleCase,
} from "src/utils/core";
// @mui
import {
	Stack,
	Snackbar,
	Alert,
	Box,
	TextField,
	Typography,
} from "@mui/material";
import getTrackingList, {
	getTrackingInfo,
	sendTrackInformationToRefresh,
} from "src/features/tracking/trackingApi";
import SmartTable from "../components/smart-table/SmartTable";
import { SocketContext } from "../components/socket-handler/sockethandler";
import { useDispatch, useSelector } from "react-redux";
import { updateColumnPreferences } from "src/features/tableActions/userColPreferenceApi";
import { logoutUser, updateUserPreferences } from "src/features/user/userState";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";
import { titleCase } from "src/utils/core";
import { deleteToken } from "src/utils/extractUserToken";
import {
	incrementAwaitingApiCounter,
	decrementAwaitingApiCounter,
} from "src/features/ui/uiState";
import AccountPopover from "src/layouts/dashboard/header/AccountPopover";

function TrackingPage(callback, deps) {
	const socket = useContext(SocketContext);
	const {
		trackingNumber,
		status,
		first_scan,
		dashboardStartDate,
		dashboardEndDate,
	} = useParams();
	// console.log(status);
	const { pathname } = useLocation();
	const [expandedRow, setExpandedRow] = useState(null);
	const [displayedTrackingList, setDisplayedTrackingList] = useState([]);
	const [initAllOrders, setInitAllOrders] = useState([]);
	setInitAllOrders;
	const [totalItemsCount, setTotalItemCount] = useState(0);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [searchKey, setSearchKey] = useState(null);
	const [showLoader, setShowLoader] = useState(false);
	const [startDate, setStartDate] = useState(
		dayjs().subtract(7, "day").format("MM/DD/YYYY")
	);
	const [endDate, setEndDate] = useState(dayjs().format("MM/DD/YYYY"));
	const [advancedFormData, setAdvacnedFromData] = useState({});
	const [rawSingleTrackingInfo, setRawSingleTrackingInfo] = useState({});
	const [refreshTrackingInfo, setRefreshTrackingInfo] = useState({});
	const [showAdvancedFilter, setShowAdvancedFilter] = useState(false);
	const [statusList, setStatusList] = useState([]);
	const statusAllData = "All Orders";
	const [statusToShow, setStatusToShow] = useState(statusAllData);
	const [showExportInfo, setShowExportInfo] = useState(false);
	const [userSelectedColumns, setUserSelectedColumns] = useState([]);
	const userInfo = useAppSelector(getUserInfo);
	const dispatch = useAppDispatch();
	const [initDelivered, setInitDelivered] = useState({});
	const [initInTransit, setInitInTransit] = useState({});
	const [initPreShipment, setInitPreShipment] = useState({});
	const [initAlert, setAlert] = useState({});
	const [order, setOrder] = useState("");
	const [product, setProduct] = useState("");
	const sortDataString = `sort_data[${product}]`;
	const [noDateRange, setnoDateRange] = useState(false);
	const filteredTenant = useSelector(
		(state) => state.selectedTenant.SelectedTenant
	);
	const [currentTab, setCurrentTab] = useState("");
	const headers = {
		apiKey: process.env.REACT_APP_BACKEND_API_KEY,
		tenant_id: filteredTenant?._id,
		is_demo_user: filteredTenant?.isMarketingUser?.toString(),
		user_id: userInfo?.user_id,
	};

	const [firstFetch, setFirstFetch] = useState(false);

	useEffect(() => {
		const queryParams = getCurrentPageParams();
		const asyncFn = async () => {
			dispatch(incrementAwaitingApiCounter());
			setShowLoader(true);

			if (statusToShow === statusAllData && filteredTenant?._id) {
				const trackingData = await getTrackingList(
					queryParams,
					userInfo,
					headers
				);
				setDisplayedTrackingList(trackingData.data?.items || []);
				setInitAllOrders(trackingData);
				setTotalItemCount(trackingData.data?.total_count || 0);
			} else {
				const trackingData = await getTrackingList(
					{
						...queryParams,
						status: currentTab,
						[sortDataString]: order,
					},
					userInfo,
					headers
				);
				if (searchKey == null || searchKey === "") {
					setDisplayedTrackingList(trackingData.data?.items || []);
					setTotalItemCount(trackingData.data?.total_count || 0);
					if (status === "Delivered") {
						setInitDelivered(displayedTrackingList);
					} else if (status === "In Transit") {
						setInitInTransit(displayedTrackingList);
					} else if (status === "Pre-Shipment") {
						setInitPreShipment(displayedTrackingList);
					} else if (status === "Alert") {
						setAlert(displayedTrackingList);
					}
				}
			}
			dispatch(decrementAwaitingApiCounter());
			setShowLoader(false);
		};
		if (firstFetch) asyncFn();
	}, [currentTab]);

	useEffect(() => {
		fetchData(true);
	}, [startDate, endDate, filteredTenant]);

	useEffect(() => {
		const queryParams = getCurrentPageParams();
		if (first_scan) {
			getTrackingNumerList(
				{
					...queryParams,
					page_size: rowsPerPage,
					current_page: 1,
					first_scan: first_scan,
					[sortDataString]: order,
				},
				false
			);
		} else if (statusToShow) {
			if (
				statusToShow === "All Orders" ||
				statusToShow.includes([
					"Delivered",
					"In Transit",
					"Pre-Shipment",
					"Alert",
				])
			) {
				if (firstFetch)
					getTrackingNumerList(
						{
							...queryParams,
							page_size: rowsPerPage,
							current_page: 1,
							[sortDataString]: order,
						},
						false
					);
			} else {
				getTrackingNumerList(
					{
						...queryParams,
						page_size: rowsPerPage,
						current_page: 1,
						status: statusToShow,
						[sortDataString]: order,
					},
					false
				);
			}
		}
		setPage(0);
	}, [order, statusToShow]);

	const getCurrentPageParams = (isStatusStrict = true, current_page = 1) => {
		let tempFormData = { ...advancedFormData };
		let queryParams = {
			page_size: rowsPerPage,
			current_page,
			// [sortDataString]: order,
			...tempFormData,
		};
		if (noDateRange == true) {
			if (queryParams.hasOwnProperty("date_range")) {
				delete queryParams["date_range"];
			}
		}
		if (startDate != false && endDate != false) {
			queryParams["date_range"] = `${startDate}-${endDate}`;
		} else {
			if (queryParams.hasOwnProperty("date_range")) {
				delete queryParams["date_range"];
			}
		}
		// console.log(queryParams);
		return queryParams;
	};

	const sortOrder = (columnName, sortOrderVal) => {
		let correctColName = columnName;
		if (columnName === "tracking_number") correctColName = "tracking_no";
		if (columnName === "shipped_on") correctColName = "shipping_date";
		if (columnName === "destination") correctColName = "shipping_city";
		if (columnName === "estimated") correctColName = "estimated_date";
		if (columnName === "order_number") correctColName = "order_id";
		if (columnName === "email") correctColName = "customer_email";

		setOrder(sortOrderVal);
		setProduct(correctColName);
	};

	const fetchData = async (isLoadAll = false, selected_page = null) => {
		try {
			let queryParams = getCurrentPageParams();
			if (noDateRange == true) {
				if (queryParams.hasOwnProperty("date_range")) {
					delete queryParams["date_range"];
				}
			}
			if (selected_page != null) {
				queryParams["page_size"] = selected_page;
			}
			const statusArray = [
				"Delivered",
				"In Transit",
				"Pre-Shipment",
				"Alert",
			];
			if (pathname === "/dashboard/tracking" || isLoadAll) {
				if (
					!statusArray.includes(statusToShow) ||
					advancedFormData.hasOwnProperty("status")
				) {
					getTrackingNumerList({ ...queryParams });
				}
				if (
					!advancedFormData.hasOwnProperty("status") ||
					!advancedFormData.status
				) {
					asyncFn();
				}
			} else {
				if (selected_page != null) {
					getTrackingNumerList({ ...queryParams });
				}
			}
			setFirstFetch(true);
		} catch (error) {}
	};

	const [modalLoader, setModalLoader] = useState(false);

	useEffect(() => {
		if (pathname == "/dashboard/tracking") {
			if (statusToShow && statusToShow !== statusAllData) {
				setStatusList[[statusToShow]];
			} else {
				setStatusList([
					"Delivered",
					"In Transit",
					"Pre-Shipment",
					"Alert",
				]);
				setStatusToShow(statusAllData);
			}
		}
		if (advancedFormData.hasOwnProperty("status")) {
			const Status = " ";
			setStatusToShow(Status);
			setStatusList(Status);
			// setStatusToShow(advancedFormData.status);
			// setStatusAllData("");
		} else if (status) {
			setStatusToShow(status);
			// setStatusAllData(statusAllData);
		}
		// else {
		// 	setStatusAllData(statusAllData);
		// }
	}, [pathname]);

	const navigate = useNavigate();
	const trackingInfoRef = useRef({});

	useEffect(() => {
		if (typeof refreshTrackingInfo.trackNo !== "undefined") {
			const { trackNo } = refreshTrackingInfo;
			const index = displayedTrackingList.findIndex(
				(object) => object.tracking_number === trackNo
			);
			if (index > -1) {
				const tableList = [...displayedTrackingList];
				tableList[index] = refreshTrackingInfo.tableResponseData;
				setDisplayedTrackingList(tableList);
				if (
					rawSingleTrackingInfo.hasOwnProperty("tracking_number") &&
					rawSingleTrackingInfo.tracking_number === trackNo
				) {
					setRawSingleTrackingInfo({
						...refreshTrackingInfo.trackNoInfo,
					});
				}
			}
		}
	}, [refreshTrackingInfo]);

	useEffect(() => {
		function cbHandleRefreshTrackingDetails(data) {
			setRefreshTrackingInfo(data);
		}
		socket.on(
			filteredTenant?._id + "_refresh_tracking_details",
			cbHandleRefreshTrackingDetails
		);
		return () => {
			socket.off(
				filteredTenant?._id + "_refresh_tracking_details",
				cbHandleRefreshTrackingDetails
			);
		};
	}, [filteredTenant?._id]);

	const exportButtonClicked = () => {
		setShowExportInfo(true);
		let parmas = {
			export_data: true,
			export_data_to: userInfo?.user?.user_mail,
			...advancedFormData,
		};
		if (startDate !== false && endDate !== false) {
			parmas.date_range = `${startDate}-${endDate}`;
		}
		if (searchKey) {
			parmas.tracking_no = searchKey;
		}
		if (statusToShow !== statusAllData) {
			parmas.status = statusToShow;
		}
		exportTrackingNumerList(parmas);
	};

	const exportTrackingNumerList = (parms, appendRow = false) => {
		const res = getTrackingList(
			cleanObject(parms),
			userInfo?.accessToken,
			headers
		);
		res.then((result) => {}).catch((error) => {
			if (
				error?.response?.status === 401 ||
				error?.response?.status === 403
			) {
				dispatch(logoutUser());
			}
		});
	};

	// get Tracking number list
	const getTrackingNumerList = (
		params,
		appendRow = false,
		isOnlyTrackNo = false
	) => {
		dispatch(incrementAwaitingApiCounter());
		setShowLoader(true);
		const res = getTrackingList(params, userInfo, headers);
		res.then((result) => {
			const responseData = result.data?.items;
			if (
				displayedTrackingList.length > 0 &&
				appendRow &&
				!isOnlyTrackNo
			) {
				setDisplayedTrackingList([
					...new Set(displayedTrackingList),
					...result.data.items,
				]);
			} else {
				setDisplayedTrackingList(result.data?.items);
			}
			if (isOnlyTrackNo && responseData.length === 1) {
				setStatusList([responseData[0].status]);
				setStatusToShow(responseData[0].status);
			}
			if (result.data.current_page == 1) {
				setTotalItemCount(result.data?.total_count);
			}

			dispatch(decrementAwaitingApiCounter());
			setShowLoader(false);
		}).catch((error) => {
			dispatch(decrementAwaitingApiCounter());
			setShowLoader(false);
		});
	};

	const getSingleTrackNumberInfo = async (trackNumber) => {
		setModalLoader(true);
		var params = null;
		const response = await getTrackingInfo(
			trackNumber,
			params,
			userInfo,
			headers
		);
		// console.log(response);
		try {
			setRawSingleTrackingInfo(response?.data);
			setModalLoader(false);
		} catch (error) {
			// if (error?.response?.status == "401") {
			// 	dispatch(logoutUser());
			// }
			setModalLoader(false);
		}
	};

	const checkTrackingNumber = (arr, trackNumber) => {
		const found = arr.some((el) => el.tracking_number === trackNumber);
		return found;
	};

	//from dashboard track num search
	useEffect(() => {
		if (trackingNumber) {
			if (!checkTrackingNumber(displayedTrackingList, trackingNumber)) {
				getTrackingNumerList(
					{
						page_size: rowsPerPage,
						current_page: 1,
						tracking_no: trackingNumber,
					},
					false,
					true
				);
				getSingleTrackNumberInfo(trackingNumber);
			} else {
				getSingleTrackNumberInfo(trackingNumber);
			}
			setExpandedRow(trackingNumber);
			setSearchKey(trackingNumber);
			setStartDate(false);
			setEndDate(false);

			// setStatusList([status]);
			// setStatusToShow(status);
		} else if (first_scan) {
			// Check if first_scan exists then call the api
			setStartDate(dashboardStartDate);
			setEndDate(dashboardEndDate);
			setStatusList([first_scan]);
			setStatusToShow(first_scan);
			getTrackingNumerList({
				page_size: rowsPerPage,
				current_page: 1,
				date_range: `${dashboardStartDate}-${dashboardEndDate}`,
				first_scan,
			});
		} else if (status) {
			setStartDate(dashboardStartDate);
			setEndDate(dashboardEndDate);
			setStatusList([status]);
			setStatusToShow(status);
			getTrackingNumerList({
				page_size: rowsPerPage,
				current_page: 1,
				date_range: `${dashboardStartDate}-${dashboardEndDate}`,
				status,
			});
		} else if (!showAdvancedFilter) {
			if (startDate && endDate) {
				if (first_scan) {
					setStatusList([status]);
					setStatusToShow(status);
					getTrackingNumerList({
						page_size: rowsPerPage,
						current_page: 1,
						date_range: `${startDate}-${endDate}`,
						first_scan,
					});
				} else if (status) {
					setStatusList([status]);
					setStatusToShow(status);
					getTrackingNumerList({
						page_size: rowsPerPage,
						current_page: 1,
						date_range: `${startDate}-${endDate}`,
						status,
					});
				} else {
					// getTrackingNumerList(
					//   {page_size: rowsPerPage, current_page: 1, date_range: `${startDate}-${endDate}`},
					//   false
					// );
				}
			}
		}
	}, [trackingNumber, endDate]);

	// formate key names
	const originalRows1 = displayedTrackingList?.map(
		({
			carrier,
			shipping_date,
			shipping_city,
			tracking_number,
			status,
			order_id: order_number,
			estimated_delivery_date,
			scanned_status,
			customer_email,
		}) => ({
			carrier,
			shipped_on: shipping_date,
			destination: shipping_city,
			tracking_number,
			status,
			estimated: estimated_delivery_date,
			scanned_status,
			order_number,
			customer_email,
		})
	);

	// change scan status
	const scanStatus = {
		1: "Scanned",
		2: "Un Scanned",
		3: "Delivered without Scan",
		4: "Invalid Email",
		5: "Delivered",
		6: "Un Scanned Exit met",
	};

	const dataConverted = [];

	const allFormatetted = originalRows1?.forEach((item) => {
		dataConverted.push({
			...item,
			shipped_on: item.shipped_on
				? `${unixToDate(item.shipped_on)}`
				: "--",
			estimated: item.estimated ? `${unixToDate(item.estimated)}` : "--",
			status: item.status,
			scanned_status: scanStatus[item.scanned_status],
			order_number: Object.values(item.order_number).toString(),
		});
	});

	const defaultColumn = "tracking_number";
	const statusColumn = "status";

	const carrierSelect = [
		{
			label: "Select Carrier",
			value: "",
		},
		{
			label: "UPS",
			value: "UPS",
		},
		{
			label: "USPS",
			value: "USPS",
		},
		{
			label: "FEDEX",
			value: "FEDEX",
		},
		{
			label: "DHL",
			value: "DHL",
		},
	];

	const statusSelect = [
		// {
		//   label: 'Select Status',
		//   value: '',
		// },
		{
			label: "Alert",
			value: "Alert",
		},
		{
			label: "Available for Agent Pickup",
			value: "Available for Agent Pickup",
		},
		{
			label: "Available for Pickup",
			value: "Available for Pickup",
		},
		{
			label: "Delivered",
			value: "Delivered",
		},
		{
			label: "Delivered to Agent",
			value: "Delivered to Agent",
		},
		{
			label: "Delivery Attempt",
			value: "Delivery Attempt",
		},
		{
			label: "Forwarded",
			value: "Forwarded",
		},
		{
			label: "In Transit",
			value: "In Transit",
		},
		{
			label: "Out for Delivery",
			value: "Out for Delivery",
		},
		{
			label: "Pre-Shipment",
			value: "Pre-Shipment",
		},
		{
			label: "Returned",
			value: "Returned",
		},
		{
			label: "Unknown",
			value: "Unknown",
		},
	];

	const scannedStatus = [
		{
			label: "Select Status",
			value: "",
		},
		{
			label: "Scanned",
			value: 1,
		},
		{
			label: "Un Scanned",
			value: 2,
		},
		{
			label: "Delivered Without Scan",
			value: 3,
		},
		{
			label: "Invalid Email",
			value: 4,
		},
		{
			label: "Delivered",
			value: 5,
		},
		{
			label: "Un Scanned Exit Met",
			value: 6,
		},
	];

	const firstScanEmailStatistics = [
		{
			label: "Select Status",
			value: "",
		},
		{
			label: "Scanned Successfully",
			value: "Scanned Successfully",
		},
		{
			label: "No Scan",
			value: "No Scan",
		},
		{
			label: "Scanned with Estimated Delivery Date",
			value: "Scanned with Estimated Delivery Date",
		},
		{
			label: "Scanned without Estimated Delivery Date",
			value: "Scanned without Estimated Delivery Date",
		},
		{
			label: "Delivered Without Scan",
			value: "Delivered Without Scan",
		},
	];

	const getDateRange = (dates) => {
		if (dates == null) {
			setnoDateRange(true);
		}
		if (dates != null && dates.length > 0) {
			const formattedStartDate = dayjs(dates[0]).format("MM/DD/YYYY");
			const formattedEndDate = dayjs(dates[1]).format("MM/DD/YYYY");
			const date_range = `${formattedStartDate}-${formattedEndDate}`;
			setSearchKey(null);
			if (!showAdvancedFilter) {
				if (first_scan) {
					getTrackingNumerList(
						{
							page_size: rowsPerPage,
							current_page: 1,
							date_range: date_range,
							first_scan: first_scan,
						},
						false
					);
					setStatusToShow(first_scan);
					setStartDate(formattedStartDate);
					setEndDate(formattedEndDate);
				} else if (status) {
					getTrackingNumerList(
						{
							page_size: rowsPerPage,
							current_page: 1,
							date_range: date_range,
							status: status,
						},
						false
					);
					setStartDate(formattedStartDate);
					setEndDate(formattedEndDate);
					setPage(0);
				} else {
					getTrackingNumerList(
						{
							page_size: rowsPerPage,
							current_page: 1,
							date_range: date_range,
						},
						false
					);
					setStartDate(formattedStartDate);
					setEndDate(formattedEndDate);
					setPage(0);
				}
			} else {
				setAdvacnedFromData({
					...advancedFormData,
					date_range: date_range,
				});
				setStartDate(formattedStartDate);
				setEndDate(formattedEndDate);
			}
			if (pathname === "/dashboard/tracking") {
				setStatusToShow(statusAllData);
				setStatusList([
					"Delivered",
					"In Transit",
					"Pre-Shipment",
					"Alert",
				]);
			}
		} else {
			setStartDate(false);
			setEndDate(false);
			if (!showAdvancedFilter) {
				setStatusToShow(statusAllData);
				if (statusToShow === statusAllData) {
					if (noDateRange == true) {
						fetchData();
						setnoDateRange(false);
					}
				}
			} else {
				if (advancedFormData.hasOwnProperty("date_range")) {
					delete advancedFormData["date_range"];
					setAdvacnedFromData({ ...advancedFormData });
				}
			}
		}
	};
	//conditional refresh
	const onRefreshClicked = async (event, trackNumbers, isAll) => {
		// console.log(trackNumbers);
		if (!isAll) {
			const response = await sendTrackInformationToRefresh(
				{ tracking_no: trackNumbers },
				userInfo,
				headers
			);
			// console.log(response);
		} else {
			trackNumbers.forEach((number) => {
				sendTrackInformationToRefresh(
					{ tracking_no: number },
					userInfo,
					headers
				);
			});
		}
	};

	const onExpandhandler = (event, trackNumber, index) => {
		event.preventDefault();
		// console.log(trackingNumber);
		// console.log(trackNumber);
		if (expandedRow !== trackNumber) {
			getSingleTrackNumberInfo(trackNumber);
			// navigate(`/dashboard/tracking/${trackNumber}`);
			setExpandedRow(trackNumber);
			setSearchKey(trackNumber);
		} else {
			// navigate('/dashboard/tracking');
			setExpandedRow(null);
			setSearchKey(null);
			// if (statusToShow) {
			//   setStatusToShow(statusToShow);
			// }
			// setRawSingleTrackingInfo({});
		}
	};

	// Search
	const handleSearchBtn = (e) => {
		const searchedVal = e.target.trackingNumber.value.trim();
		setSearchKey(searchedVal);
		setRowsPerPage(10);
		setStartDate(false);
		setEndDate(false);
		setTotalItemCount(0);
		setAdvacnedFromData({});
		setShowAdvancedFilter(false);

		if (pathname !== "/dashboard/tracking") {
			navigate("/dashboard/tracking");
		}

		setStatusList([]);
		setStatusToShow(statusAllData);

		if (searchedVal === "") {
			fetchData(true);
			return;
		}

		// internal search
		const filteredRows = displayedTrackingList.filter((row) =>
			row[defaultColumn].toString().includes(searchedVal.toLowerCase())
		);

		setDisplayedTrackingList(filteredRows);
		setTotalItemCount(filteredRows.length);
	};

	const onSearchClick = () => {
		setSearchKey(null);
		setStartDate(false);
		setEndDate(false);
	};

	// pagination
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
		setExpandedRow(null);
		setSearchKey(null);
		if (
			newPage <= page ||
			displayedTrackingList.length > rowsPerPage * (page + 1)
		) {
			return;
		}
		let paginationParams = {
			page_size: rowsPerPage,
			current_page: newPage + 1,
			// [sortDataString]: order,
		};
		if (startDate && endDate) {
			paginationParams.date_range = `${startDate}-${endDate}`;
		}
		if (first_scan) {
			paginationParams.first_scan = first_scan;
		} else if (statusToShow !== statusAllData) {
			paginationParams.status = statusToShow;
		}
		if (Object.keys(advancedFormData).length > 0) {
			Object.assign(paginationParams, advancedFormData);
		}
		// console.log(paginationParams);
		getTrackingNumerList(paginationParams, true);
	};

	const handleChangeRowsPerPage = (event) => {
		const selected_page = event.target.value;
		setRowsPerPage(selected_page);
		setPage(0);
		if (totalItemsCount > 1) {
			if (
				pathname === "/dashboard/tracking" ||
				advancedFormData.hasOwnProperty("status")
			) {
				fetchData(false, selected_page);
				//setCurrentStatusData(statusToShow);
			} else {
				const queryParams = getCurrentPageParams();
				if (first_scan) {
					getTrackingNumerList(
						{
							...queryParams,
							page_size: selected_page,
							current_page: 1,
							first_scan: first_scan,
							[sortDataString]: order,
						},
						false
					);
				} else if (statusToShow) {
					getTrackingNumerList(
						{
							...queryParams,
							page_size: selected_page,
							current_page: 1,
							status: statusToShow,
							[sortDataString]: order,
						},
						false
					);
					setPage(0);
				}
			}
		}
	};

	const onClickAdvancedFilter = () => {
		if (pathname === "/dashboard/tracking") {
			if (!showAdvancedFilter) {
				setStartDate(false);
				setEndDate(false);
			} else {
				setStartDate(dayjs().subtract(7, "day").format("MM/DD/YYYY"));
				setEndDate(dayjs().format("MM/DD/YYYY"));
				setStatusToShow(statusAllData);
				// setStatusAllData(statusAllData);
				setStatusList([
					"Delivered",
					"In Transit",
					"Pre-Shipment",
					"Alert",
				]);
			}
		}
		setSearchKey(null);
		setAdvacnedFromData({});
		setShowAdvancedFilter(!showAdvancedFilter && !showAdvancedFilter);
		setPage(0);
	};

	const onChangeAdvancedForm = (e = null, date = null) => {
		if (date == null) {
			const { name, value } = e.target;
			let tempData = { ...advancedFormData };
			if (
				(value == null || value === "") &&
				tempData.hasOwnProperty(name)
			) {
				delete tempData[name];
			} else {
				tempData[name] = value;
			}
			setAdvacnedFromData({ ...tempData });
		} else {
			setAdvacnedFromData({
				...advancedFormData,
				[e]: date !== "Invalid Date" ? date : null,
			});
		}
	};

	const onSubmitAdvancedFilter = () => {
		setDisplayedTrackingList([]);
		const tabStatus = ["Delivered", "In Transit", "Pre-Shipment", "Alert"];
		let tempFormData = getCurrentPageParams(false);
		if (
			tempFormData.hasOwnProperty("status") &&
			tempFormData.status != ""
		) {
			if (tempFormData.status.length == 1) {
				setStatusList([tempFormData.status]);
				setStatusToShow(tempFormData.status);
				// setStatusAllData(tempFormData.status);
			} else {
				setStatusList([statusAllData]);
				setStatusToShow(statusAllData);
				// setStatusAllData(statusAllData);
			}
		} else {
			setStatusList(tabStatus);
			// setStatusAllData(statusAllData);
			setStatusToShow(statusAllData);
			getTrackingNumerList({ ...tempFormData });
			if (
				!tabStatus.includes(statusToShow) &&
				statusToShow != statusAllData
			) {
				setStatusToShow(statusAllData);
			}
		}
		if (pathname !== "/dashboard/tracking") {
			navigate("/dashboard/tracking");
			getTrackingNumerList({ ...tempFormData });
			setExpandedRow(null);
			setPage(0);
		} else {
			if (
				tempFormData.hasOwnProperty("status") &&
				tempFormData.status != ""
			) {
				getTrackingNumerList({ ...tempFormData });
			} else {
				setStatusToShow(statusAllData);
				fetchData();
			}
			setExpandedRow(null);
			setPage(0);
		}
	};

	// Status filter
	const onStatusChange = (e) => {
		const mainStatus = e.target.name;
		setCurrentTab(mainStatus);
		setSearchKey(null);
		// console.log(mainStatus);
		// console.log(statusAllData);

		// If we are on All Orders
		if (mainStatus === statusAllData) {
			// console.log(statusToShow);

			// navigate("/dashboard/tracking");
			// fetchData(true);

			if (pathname === "/dashboard/tracking") {
				let queryParams = getCurrentPageParams();
				getTrackingNumerList({ ...queryParams });
			} else {
				fetchData(true);
			}
			setExpandedRow(null);
			setStatusList(["Delivered", "In Transit", "Pre-Shipment", "Alert"]);
		} else {
			//for these state stored api response
			if (
				["Delivered", "In Transit", "Pre-Shipment", "Alert"].includes(
					mainStatus
				)
			) {
				switch (mainStatus) {
					case "Delivered":
						setDisplayedTrackingList(
							initDelivered.data?.items || []
						);
						setTotalItemCount(initDelivered.data?.total_count || 0);
						break;
					case "In Transit":
						setDisplayedTrackingList(
							initInTransit.data?.items || []
						);
						setTotalItemCount(initInTransit.data?.total_count || 0);
						break;
					case "Pre-Shipment":
						setDisplayedTrackingList(
							initPreShipment.data?.items || []
						);
						setTotalItemCount(
							initPreShipment.data?.total_count || 0
						);
						break;
					case "Alert":
						setDisplayedTrackingList(initAlert.data?.items || []);
						setTotalItemCount(initAlert.data?.total_count || 0);
						break;
					default:
						setDisplayedTrackingList([]);
						setTotalItemCount(0);
						break;
				}
				if (trackingNumber) {
					getTrackingNumerList({
						page_size: rowsPerPage,
						current_page: 1,
						status: mainStatus,
					});
					navigate("/dashboard/tracking");
				}
			} else if (first_scan) {
				//call the API if we have not stored data
				getTrackingNumerList({
					page_size: rowsPerPage,
					current_page: 1,
					first_scan: mainStatus,
				});
			} else {
				setDisplayedTrackingList(initAllOrders.data?.items || []);
				setTotalItemCount(initAllOrders.data?.total_count || 0);

				// getTrackingNumerList({
				// 	page_size: rowsPerPage,
				// 	// current_page: 1,
				// 	// status: mainStatus,
				// });
			}
		}
		setStatusToShow(mainStatus);
		setPage(0);
	};

	const onCloseExportInfo = () => {
		setShowExportInfo(false);
	};

	const handleColumnSubmit = (columnList) => {
		setUserSelectedColumns(columnList.map((item) => removeTitleCase(item)));
		// dispatch(
		// 	updateUserPreferences({
		// 		trackingInfo: columnList.map((item) => removeTitleCase(item)),
		// 		mailScan: mailScan,
		// 	})
		// );
		// updateColumnPreferences(
		// 	{
		// 		table_name: "tracking_info",
		// 		table_columns: columnList.map((item) => removeTitleCase(item)),
		// 	},
		// 	userInfo?.accessToken
		// );
		// useAppDispatch(updateColumnPreferences({table_name:'tracking_info',table_columns:columnList.map(item => removeTitleCase(item))}))
	};

	return (
		<>
			<Helmet>
				<title> Tracking | BeyondCarts CMS </title>
			</Helmet>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					borderBottom: "1px solid #c4c4c4",
					paddingBottom: 2,
					marginTop: "20px",
				}}
			>
				<Typography variant="h4" sx={{ color: "#000" }}>
					Tracking
				</Typography>
				<AccountPopover />
			</Box>
			<Snackbar
				open={showExportInfo}
				anchorOrigin={{ vertical: "top", horizontal: "right" }}
				autoHideDuration={6000}
				onClose={onCloseExportInfo}
			>
				<Alert
					onClose={onCloseExportInfo}
					severity="success"
					sx={{ width: "100%" }}
				>
					Export in progress
				</Alert>
			</Snackbar>

			<Stack>
				<SmartTable
					isSearch
					isAdvancedFilter
					isStatusMenu
					isTenantMenu
					isRowSelectable
					isAction
					originalRows={
						typeof dataConverted !== "undefined"
							? dataConverted
							: [{}]
					}
					defaultColumn={defaultColumn}
					statusColumn={statusColumn}
					statusList={statusList}
					statusAllData={statusAllData}
					getDateRange={getDateRange}
					onRefreshClicked={onRefreshClicked}
					onExpandhandler={onExpandhandler}
					expandedRow={expandedRow}
					totelItemCount={totalItemsCount}
					page={page}
					handleChangePage={handleChangePage}
					handleChangeRowsPerPage={handleChangeRowsPerPage}
					rowsPerPage={rowsPerPage}
					onhandeSeachSubmit={handleSearchBtn}
					searchKey={searchKey}
					onSearchClick={onSearchClick}
					showLoader={showLoader}
					startDate={startDate}
					endDate={endDate}
					onChangeAdvancedForm={onChangeAdvancedForm}
					advancedFormData={advancedFormData}
					carrierSelect={carrierSelect}
					statusSelect={statusSelect}
					scannedStatus={scannedStatus}
					firstScanEmailStatistics={firstScanEmailStatistics}
					onSubmitAdvancedFilter={onSubmitAdvancedFilter}
					exapndRowIntputData={rawSingleTrackingInfo}
					trackingInfoRef={trackingInfoRef}
					showAdvancedFilter={showAdvancedFilter}
					onClickAdvancedFilter={onClickAdvancedFilter}
					onStatusChange={onStatusChange}
					statusToShow={statusToShow}
					// exportButtonClicked={exportButtonClicked}
					trackingPage={true}
					userSelectedColumns={userSelectedColumns}
					handleColumnSubmit={handleColumnSubmit}
					modalLoader={modalLoader}
					sortOrder={sortOrder}
				/>
			</Stack>
		</>
	);
}

export default TrackingPage;
