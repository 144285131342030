import PropTypes from "prop-types";
import { StyledNavItem, StyledNavItemIcon } from "./styles";
import { NavLink as RouterLink } from "react-router-dom";
import { Box, ListItemText, Stack } from "@mui/material";
import palette from "src/theme/palette";

const NavItem = ({ onClick, item }) => {
	const { title, path, icon, info } = item;
	const isActive =
		title === "Reports" &&
		path &&
		location.pathname.includes("/dashboard/reports");

	let linkComponent = {};
	if (path != "/#report_menu") {
		linkComponent = {
			component: RouterLink,
			to: path,
		};
	}

	return icon ? (
		<StyledNavItem
			{...linkComponent}
			onClick={onClick}
			pathname={path}
			sx={{
				"&.active": {
					color: "text.active",
					// bgcolor: "action.selected",
					// width: 45,
					"&>.childIcon": {
						bgcolor: "action.selected",
						width: 45,
						paddingLeft: "11px",
						marginRight: "11px",
						borderTopRightRadius: 6,
						borderBottomRightRadius: 6,
					},
				},
				marginLeft: { sm: -0.6, md: -1, lg: -1 },
			}}
			className={isActive ? "active" : ""}
		>
			<Stack
				height={48}
				alignItems={"center"}
				justifyContent={"center"}
				className="childIcon"
			>
				<StyledNavItemIcon
				// sx={{
				// 	"&.active": {
				// 		// color: "text.active",
				// 		bgcolor: "action.selected",
				// 		width: 45,
				// 		// marginLeft: { sm: -0.9, lg: -1 },
				// 	},
				// }}
				// className={isActive ? "active" : ""}
				>
					{icon && icon}
				</StyledNavItemIcon>
			</Stack>

			<ListItemText disableTypography primary={title} />

			{info && info}
		</StyledNavItem>
	) : (
		<StyledNavItem
			{...linkComponent}
			onClick={onClick}
			sx={{
				color: palette.text.alternative2,
				marginTop: "10px",
				padding: "0 0 0 35px",
			}}
		>
			<ListItemText disableTypography primary={title} />
			{info && info}
		</StyledNavItem>
	);
};

NavItem.propTypes = {
	item: PropTypes.object,
};

export default NavItem;
