import {
	Box,
	Button,
	Drawer,
	FormLabel,
	IconButton,
	Modal,
	TextField,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import palette from "src/theme/palette";
import AccountPopover from "src/layouts/dashboard/header/AccountPopover";
import { useAppDispatch, useAppSelector } from "src/hooks/hooks";
import { getUserInfo } from "src/features/user/userState";
import { Helmet } from "react-helmet-async";
import {
	getAppConfig,
	updateStripeConfig,
} from "src/features/appConfig/appConfigApi";
import {
	decrementAwaitingApiCounter,
	incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const PaymentProcessors = () => {
	const [isOpen, setOpen] = useState(false);
	const [access_token, setAccessToken] = useState("");
	const [accessTokenError, setTokenError] = useState("");
	const [showUpdateModel, setShowUpdateModel] = useState(false);
	const userInfo = useAppSelector(getUserInfo);
	const dispatch = useAppDispatch();

	const onSubmit = () => {
		dispatch(incrementAwaitingApiCounter());
		if (!access_token) {
			setTokenError("Access token is required");
			dispatch(decrementAwaitingApiCounter());
			return;
		} else {
			setTokenError("");
		}

		const obj = {
			stripe: {
				access_token,
			},
		};
		updateStripeConfig(obj, userInfo)
			.then(() => {
				setShowUpdateModel(true);
				dispatch(decrementAwaitingApiCounter());
			})
			.catch((e) => {
				dispatch(decrementAwaitingApiCounter());
				console.log(e);
			});
	};
	const handleClose = () => {
		setOpen(false);
		setShowUpdateModel(false);
		setTokenError("");
	};

	const popUpModalStyle = {
		display: "flex",
		alignItems: "center",
		justifyContent: "center",
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: { xs: "80%", sm: "auto", lg: "auto", md: "auto" },
		borderRadius: "20px",
		background: "#FFF",
		overflow: "auto",
		overflow: "auto-y",
		overflowX: "hidden",
		outline: "none",
	};

	const styleInput = {
		input: { color: palette.common.black },
		marginTop: "10px",
		width: "100%",
		"& .MuiFormHelperText-root": {
			textAlign: "left",
			fontSize: "14px",
		},
		"& .MuiOutlinedInput-root": {
			"&:hover fieldset": {
				borderColor: "primary.dark",
			},
			"&.Mui-focused fieldset": {
				borderColor: "primary.dark",
			},
		},
	};

	useEffect(() => {
		dispatch(incrementAwaitingApiCounter());

		getAppConfig(userInfo.accessToken)
			.then((res) => {
				setAccessToken(res.data.stripe.access_token);
				dispatch(decrementAwaitingApiCounter());
			})
			.catch((e) => {
				console.log(e);
				dispatch(decrementAwaitingApiCounter());
			});
	}, []);

	return (
		<Box>
			<Helmet>
				<title> Payment Processors | BeyondCarts CMS </title>
			</Helmet>
			<Box
				sx={{
					display: "flex",
					justifyContent: "space-between",
					alignItems: "center",
					borderBottom: "1px solid #c4c4c4",
					paddingBottom: 2,
					marginTop: "20px",
				}}
			>
				<Typography variant="h4" sx={{ color: "#000" }}>
					Payment Processors
				</Typography>
				<AccountPopover />
			</Box>
			<Typography
				variant="h5"
				sx={{ color: "black", padding: "10px 0", marginTop: 1 }}
			>
				Payment Processing
			</Typography>
			<Typography variant="body2" sx={{ color: "#000", fontSize: 16 }}>
				Add and remove payment plans, adjust values and manage payment
				settings
			</Typography>
			<Box sx={{ display: "flex", flexWrap: "wrap" }}>
				<Box
					sx={{
						width: {
							xs: "100%",
							md: "45%",
							lg: "30%",
						},
					}}
				>
					<Box
						onClick={() => setOpen(true)}
						sx={{
							border: "1px solid #c4c4c4",
							cursor: "pointer",
							borderRadius: "12px",
							padding: "22px 18px",
							marginTop: "15px",
							boxShadow:
								"0 0 2px 0 rgba(128, 128, 128, 0.2),0 12px 24px -4px rgba(128, 128, 128, 0.12)",
						}}
					>
						<Box
							sx={{
								display: { xs: "block", sm: "flex" },
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<img width="85px" src="/assets/stripe.png" />
							<Box
								sx={{
									display: "flex",
									marginTop: { xs: "10px", sm: 0 },
									alignItems: "center",
									gap: 2,
								}}
							>
								<Typography
									variant="body2"
									sx={{ color: "green" }}
								>
									Enabled
								</Typography>
								<Box
									sx={{
										padding: "8px",
										borderRadius: "50px",
										backgroundColor: "green",
									}}
								/>
							</Box>
						</Box>
						<Typography
							variant="body1"
							sx={{
								fontWeight: "bold",
								color: "#000",
								marginTop: "10px",
								textTransform: "capitalize",
							}}
						>
							Stripe Payment Processor
						</Typography>
					</Box>
				</Box>
			</Box>

			<Drawer anchor="right" open={isOpen} onClose={handleClose}>
				<Box sx={{ width: "350px", color: "#000", padding: "40px" }}>
					<IconButton
						aria-label="close"
						sx={{
							position: "fixed",
							top: { xs: 15, lg: 20 },
							right: {
								xs: 15,
								md: 20,
							},
							zIndex: 2,
						}}
						onClick={handleClose}
					>
						<img
							width="30px"
							src="/assets/icons/table/close.svg"
							alt="X"
						/>
					</IconButton>
					{!showUpdateModel && (
						<>
							<Box
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "space-between",
									borderBottom: "1px solid #c4c4c4",
									padding: "20px 0",
								}}
							>
								<img
									width="100px"
									src="https://upload.wikimedia.org/wikipedia/commons/thumb/b/ba/Stripe_Logo%2C_revised_2016.svg/2560px-Stripe_Logo%2C_revised_2016.svg.png"
								/>
								<Box
									sx={{
										display: "flex",
										marginTop: { xs: "10px", sm: 0 },
										alignItems: "center",
										gap: 2,
									}}
								>
									<Typography
										variant="body2"
										sx={{ color: "green" }}
									>
										Enabled
									</Typography>
									<Box
										sx={{
											padding: "8px",
											borderRadius: "50px",
											backgroundColor: "green",
										}}
									/>
								</Box>
							</Box>
							<Box
								sx={{ display: "flex", gap: 1, width: "100%" }}
							>
								<div
									style={{ width: "100%", marginTop: "20px" }}
								>
									<Typography
										variant="body2"
										sx={{
											fontWeight: "bold",
											textWrap: "nowrap",
										}}
									>
										Access Token
									</Typography>
									<Box>
										<TextField
											value={access_token}
											sx={styleInput}
											onChange={(e) =>
												setAccessToken(e.target.value)
											}
										/>
										{accessTokenError && (
											<p
												style={{
													color: "red",
													fontSize: "14px",
													marginTop: "3px",
												}}
											>
												{accessTokenError}
											</p>
										)}
									</Box>
									<Button
										size="large"
										type="submit"
										variant="contained"
										onClick={onSubmit}
										sx={{
											marginTop: 3,
											width: "100%",
											marginBottom: 3,
											boxShadow: "none",
											backgroundColor:
												palette.secondary.main,
										}}
									>
										Update
									</Button>
								</div>
							</Box>{" "}
						</>
					)}
					{showUpdateModel && (
						<Box
							sx={{
								display: "grid",
								alignItems: "center",
								marginTop: "20px",
							}}
						>
							<FormLabel
								sx={{
									color: "black",
									fontWeight: "500",
									fontSize: "20px",
									marginBottom: "20px",
									textAlign: "center",
								}}
							>
								Token Updated Successfully
							</FormLabel>
							<Button
								variant="contained"
								type="submit"
								sx={{
									boxShadow: "none",
									backgroundColor: palette.secondary.main,
									paddingY: 1.3,
									fontSize: {
										lg: 15,
										md: 15,
										xs: 13,
										sm: 13,
									},
								}}
								onClick={() => [setShowUpdateModel(false)]}
							>
								Go back
							</Button>
						</Box>
					)}
				</Box>
			</Drawer>
		</Box>
	);
};

export default PaymentProcessors;
