import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { Box, Drawer } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import Logo from 'src/components/logo';
import Scrollbar from 'src/components/scrollbar';
import NavSection from 'src/components/nav-section';
import navDetails from './navDetails';
import { useAppSelector } from 'src/hooks/hooks';
import { getUserInfo } from 'src/features/user/userState';

const NAV_WIDTH = 218;
const MINI_DRAWER_WIDTH = 50;

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();
  const user = useAppSelector(getUserInfo);

  const isDesktop = useResponsive('up', 'md');
  const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
  const drawerRef = useRef(null);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const reportsChildren = navDetails(user).find((item) => item.path === '/#report_menu')?.children || [];
  const onNavMenuClick = (e) => {
    if (e.target.innerText == 'Reports') {
      setIsSideMenuOpen(!isSideMenuOpen);
    } else {
      setIsSideMenuOpen(false);
    }
  };
  const onNavSecondMenuClick = (e) => {
    setIsSideMenuOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target) && isSideMenuOpen) {
        setIsSideMenuOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isSideMenuOpen]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <Box
        sx={{
          px: { sm: 1, md: 3, lg: 2.5 },
          py: 3,
          display: 'inline-flex',
        }}
      >
        <Logo isLight />
      </Box>

      <NavSection onCbNavMenuClick={onNavMenuClick} data={navDetails(user)} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  const reportOptions = (
    <Scrollbar
      sx={{
        height: 1,
        '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
      }}
    >
      <NavSection
        data={reportsChildren}
        onCbNavMenuClick={onNavSecondMenuClick}
        sx={{ color: '! white', fontSize: '!26px' }}
      />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {/* First Sidebar */}
      <Box
        component="nav"
        sx={{
          flexShrink: { md: 0 },
          width: { md: NAV_WIDTH },
        }}
        ref={drawerRef}
      >
        {isDesktop ? (
          <Drawer
            open
            variant="permanent"
            PaperProps={{
              sx: {
                width: NAV_WIDTH,
                bgcolor: 'primary.main',
                borderRightStyle: 'dashed',
              },
            }}
          >
            {renderContent}
          </Drawer>
        ) : (
          <Drawer
            open
            variant="permanent"
            ModalProps={{
              keepMounted: true,
            }}
            PaperProps={{
              sx: {
                width: MINI_DRAWER_WIDTH,
                backgroundColor: 'primary.main',
              },
            }}
          >
            {renderContent}
          </Drawer>
        )}
      </Box>

      {/* Second Sidebar */}
      {isSideMenuOpen && (
        <Drawer
          open={true}
          variant="permanent"
          PaperProps={{
            sx: {
              width: 215,
              backgroundColor: 'primary.darker',
              marginLeft: isDesktop ? 27 : 6.3,
            },
          }}
        >
          {reportOptions}
        </Drawer>
      )}
    </Box>
  );
}
