import axios from "axios";
import { deleteToken } from "src/utils/extractUserToken";
import { encConfig } from "./config";
import { encryptData } from "./chiper";
import { extractData } from "./extractData";

export default async function postData(url, headers, data, token = null) {
	// random number of 9 digits, not starting by zero
	const randomInt = Math.floor(1e7 + Math.random() * 9e7);
	const config = {
		headers: {
			...headers,
			"Content-Type": "application/json",
			Apikey: process.env.REACT_APP_BACKEND_API_KEY,
			request_id: randomInt,
			"Access-Control-Allow-Origin": "*",
			"Access-Control-Allow-Methods": "*",
			"Access-Control-Allow-Headers": "*",
		},
	};

	if (token !== null) {
		config.headers.Authorization = `Bearer ${token}`;
	}
	try {
		const info = JSON.stringify(
			encConfig.encryption.encrypt ? { data: encryptData(data) } : data
		);
		const res = await axios.post(url, info, config);
		return res.data;
	} catch (error) {
		if (parseInt(error.response.status) === 401)
			throw new Error("Invalid credentials");

		let statusMsg = JSON.parse(extractData(error?.response));
		if ([403].includes(parseInt(statusMsg.statusCode))) {
			deleteToken();
			return;
		}
		throw new Error("Something went wrong!");
	}
}
