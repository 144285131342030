import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import deleteData from "src/dataFetcher/deleteDataFetcher";
import { useSelector } from "react-redux";

import { backendUserUrl as backendUrl } from "src/utils/backendUrls";
import { extractData } from "src/dataFetcher/extractData";

export async function getAllPayments(
	params = null,
	headers = {},
	userInfo,
	tenant_id
) {
	const token = userInfo?.accessToken;
	const tenant = userInfo?.tenant_name;
	headers.tenant_id = tenant_id;
	headers.user_id = userInfo?.user_id;
	// console.log(userInfo, "userInfo")

	const result = await getData(
		`${backendUrl}user/admin/payment_method`,
		params,
		headers,
		token
	);

	return result;
} // gets a single user info

export async function createPaymentMethod(data, userInfo, tenant_id, user_id, type) {
	const token = userInfo?.accessToken;
	const tenant = userInfo?.tenant_name;
	const header = {
		tenant_id: tenant_id,
		user_id: user_id,
		action: type,
		tenant_name: "admin",
	};
	const res = await postData(`${backendUrl}tenant-cards`, header, data, token);
	return JSON.parse(extractData({data: res}));
}

export async function updatePaymentById(
	cardId,
	data,
	userInfo,
	tenant_id,
	user_id
) {
	const token = userInfo?.accessToken;
	const tenant = userInfo?.tenant_name;
	const header = {
		payment_method_id: cardId,
		action: "update",
		tenant_name: "admin",
		tenant_id: tenant_id,
		user_id: user_id,
	};

	return await postData(`${backendUrl}tenant-cards`, header, data, token);
}

export async function deletePaymentById(cardId, userInfo, tenant_id, user_id) {
	const token = userInfo?.accessToken;
	const tenant = userInfo?.tenant_name;

	const header = {
		tenant_id: tenant_id,
		user_id: user_id,
	};

	return await deleteData(
		`${backendUrl}user/admin/payment_method/${cardId}`,
		header,
		token
	);
}

export async function updatePreferredCardById(
	cardId,
	data,
	userInfo,
	tenant_id,
	user_id
) {
	const token = userInfo?.accessToken;
	const tenant = userInfo?.tenant_name;

	const header = {
		tenant_id: tenant_id,
		user_id: user_id,
	};

	return await putData(
		`${backendUrl}user/admin/payment_method/preferred/${cardId}`,
		header,
		data,
		token
	);
}
