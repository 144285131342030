import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPlans } from "src/features/onborading/onboardingApi";

const initialState = {
	plansInfo: {},
};

export const getPlansThunk = createAsyncThunk("plans", async () => {
	return await getPlans(null);
});

export const plansSlice = createSlice({
	name: "plans",
	initialState,
	extraReducers: (builder) => {
		builder.addCase(getPlansThunk.fulfilled, (state, action) => {
			const data = action.payload.data;
			const obj = {};
			for (let i = 0; i < data.length; i++) {
				const el = data[i];
				obj[el?.name] = el;
			}
			state.plansInfo = obj;
		});
	},
});

export const getPlansInfo = (state) => state?.plans?.plansInfo;

export default plansSlice.reducer;
