import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import palette from "src/theme/palette";

import "./welcome.scss";

const pStyle = {
	width: "800px",
	color: palette.common.black,
	fontSize: 18,
	textAlign: "center",
	margin: "auto",
	marginTop: "10px",
};

const WelcomeView = () => {
	const navigate = useNavigate();

	return (
		<div className="welcome-view-container">
			<img
				src="/assets/beyond-carts-logo.svg"
				alt="logo"
				style={{
					height: "65px",
					display: "block",
					margin: "30px 0 0 30px",
				}}
			/>

			<Typography
				variant="h3"
				sx={{ marginTop: "100px", textAlign: "center" }}
			>
				Welcome to Beyond Carts!
			</Typography>

			<Typography sx={pStyle}>
				A big welcome to you from the Beyond Carts family. At Beyond
				Carts, we empower you with hassle-free tracking of your
				shipments. Dive into a world where logistics is simplified, and
				every delivery is just a click away. We're here to support you
				every step of the way in your shipment tracking needs. Let's get
				those packages moving!
			</Typography>

			<Box textAlign="center">
				<LoadingButton
					fullWidth
					size="large"
					type="submit"
					variant="contained"
					onClick={() => navigate("/login")}
					sx={{
						fontSize: "18px !important",
						margin: "30px auto",
						width: { lg: 513, sm: 444, md: 444 },
						boxShadow: "none",
						backgroundColor: palette.secondary.main,
					}}
				>
					Get Started
				</LoadingButton>
			</Box>
		</div>
	);
};

export default WelcomeView;
