import {
	Stack,
	Box,
	Modal,
	TextField,
	Button,
	FormControl,
	CircularProgress,
	Backdrop,
	InputAdornment,
	IconButton,
} from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import {
	getTenantUser,
	updateProfileInfo,
} from "src/features/usersPage/usersApi";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import { getUserInfo, logoutUser } from "src/features/user/userState";
import { useNavigate } from "react-router-dom";
import Iconify from "src/components/iconify";

import typography from "src/theme/typography";
import palette from "src/theme/palette";
import formatPhone from "src/utils/formatPhone";
import { updatePassword } from "src/features/user/userApi";
import { MuiTelInput } from "mui-tel-input";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { passwordValidator } from "src/utils/validators";
import {
	decrementAwaitingApiCounter,
	incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const popUpModalStyle = {
	display: "flex",
	position: "absolute",
	top: 0,
	right: 0,
	bottom: 0,
	width: { xs: "70%", sm: "70%", lg: "40%", md: "50%" },
	maxWidth: "800px",
	background: "#FFF",
	overflow: "auto",
	overflow: "auto-y",
	overflowX: "hidden",
	outline: "none",
};

const styleInput = {
	input: { color: palette.common.black },
	width: { lg: "100%", md: 446, sm: 446 },
	marginTop: "10px",
	"& .MuiFormHelperText-root": {
		textAlign: "left",
		fontSize: "14px",
	},
	"& .MuiOutlinedInput-root": {
		"&:hover fieldset": {
			borderColor: "primary.dark",
		},
		"&.Mui-focused fieldset": {
			borderColor: "primary.dark",
		},
	},
};
const theme = createTheme({
	overrides: {
		MuiTelInput: {
			root: {
				color: "black",
			},
		},
	},
});

const ProfileModal = ({ openModal, setOpenModal }) => {
	const [statusMessage, setStatusMessage] = useState("");
	const [isError, setIsError] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const userInfo = useAppSelector(getUserInfo);
	const [isUserUpdate, setIsUserUpdate] = useState(false);
	const [isSubmited, setIsSubmited] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState();
	const [confirmPassword, setConfirmPassword] = useState();
	// const [errorMessage, setErrorMessage] = useState("");
	const [openChangePassword, setOpenChangePassword] = useState(false);
	const [openPasswordHasChanged, setOpenPasswordHasChanged] = useState(false);
	const [phoneValue, setPhoneValue] = useState("");
	const [successfulUpdate, setSuccessfulUpdate] = useState(false);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const headers = {
		tenant_id: userInfo.tenant_id,
		user_id: userInfo.user_id,
	};

	const {
		register,
		handleSubmit,
		clearErrors,
		formState: { errors },
	} = useForm({
		defaultValues: async () => {
			dispatch(incrementAwaitingApiCounter());
			const data = await getTenantUser(
				null,
				headers,
				userInfo.user_id,
				userInfo
			);
			dispatch(decrementAwaitingApiCounter());
			setIsLoading(false);
			dispatch(decrementAwaitingApiCounter());

			return {
				first_name: data?.data?.first_name,
				last_name: data?.data?.last_name,
				user_name: data?.data?.user_name,
				phone_number: setPhoneValue(data?.data?.phone_number || ""),
			};
		},
	});

	// updates the users info calling the corresponding api
	const onSubmit = async (data) => {
		dispatch(incrementAwaitingApiCounter());
		const reqBody = {
			...data,
			phone_number: phoneValue,
		};
		localStorage.setItem("first_name", reqBody.first_name);
		let response;
		try {
			response = await updateProfileInfo(reqBody, userInfo);
			setIsError(false);
			dispatch(decrementAwaitingApiCounter());
			// setStatusMessage(`✅The user was successfully updated`);
			setIsUserUpdate(true);
			setSuccessfulUpdate(true);
		} catch (err) {
			dispatch(decrementAwaitingApiCounter());
			setIsError(true);
			setStatusMessage("There has been an unexpected error. Try again");
		}
	};

	const handlePhoneChange = (newValue) => {
		let newPhone = newValue.split(" ");
		let newPhoneStr = "";

		for (let index = 0; index < newPhone.length; index++) {
			if (index === 0) newPhoneStr += newPhone[index] + " ";
			else newPhoneStr += newPhone[index];
		}

		newPhone = newPhoneStr.split(" ");

		if (newPhone[1]?.length > 10) return;

		setPhoneValue(newPhoneStr);
	};

	const onChangePasswordSubmit = async (data) => {
		if (data?.new_password !== data?.confirm_new_password) {
			setIsError(true);
			setIsSubmited(true);
			setStatusMessage("Passwords doesn't match");
			return;
		}
		if (!passwordValidator(data?.new_password)) {
			setIsError(true);
			setIsSubmited(true);
			setStatusMessage(
				"Password should meet these requirements: minimum length of 8 characters, 1 capital letter, 1 lower-case letter, 1 number and 1 special character"
			);
			return;
		}
		dispatch(incrementAwaitingApiCounter());
		const obj = {
			password: data?.new_password,
			confirm_password: data?.confirm_new_password,
		};
		let response;
		try {
			response = await updatePassword(headers, obj, userInfo);
			setIsError(false);
			setStatusMessage(response?.data?.statusMessage);
			setOpenPasswordHasChanged(true);
			dispatch(decrementAwaitingApiCounter());
		} catch (error) {
			if (
				error.message?.startsWith("Password does not conform to policy")
			) {
				setIsSubmited(true);
				setIsError(true);
				setStatusMessage(error.message);
			} else if (error.message?.startsWith("Attempt limit exceeded")) {
				setIsSubmited(true);
				setIsError(true);
				setStatusMessage(error.message);
			} else {
				setIsSubmited(true);
				setIsError(true);
				setStatusMessage(
					"There has been an unexpected error. Try again"
				);
			}
			dispatch(decrementAwaitingApiCounter());
		}
	};

	const handleCloseModal = () => {
		setOpenModal(false);
	};

	return (
		<>
			<Modal open={openModal} onClose={handleCloseModal}>
				<Box sx={popUpModalStyle}>
					{!isLoading &&
						!openChangePassword &&
						!openPasswordHasChanged &&
						!successfulUpdate && (
							<Stack
								gap={6}
								direction="column"
								sx={{
									width: "100%",
									color: "common.black",
									paddingY: { xs: 5, md: 7 },
									paddingX: { xs: 3, md: 8 },
									fontFamily: typography.fontFamilyRegular,
									backgroundColor: "white",
								}}
							>
								<IconButton
									aria-label="close"
									sx={{
										position: "fixed",
										top: { xs: 15, lg: 30 },
										right: { xs: 15, lg: 30 },
										zIndex: 2,
									}}
									onClick={handleCloseModal}
								>
									<img
										width="30px"
										src="/assets/icons/table/close.svg"
										alt="X"
									/>
								</IconButton>
								<FormControl
									component="form"
									className="form"
									onSubmit={handleSubmit(onSubmit)}
								>
									<Stack spacing={2}>
										<h2 style={typography.h3}>
											My Account
										</h2>
										<h4 style={typography.h4}>
											Basic Information
										</h4>
									</Stack>

									<Stack spacing={2} direction={"column"}>
										<TextField
											sx={styleInput}
											label="First Name"
											name="first_name"
											{...register("first_name", {
												required:
													"First Name is required.",
											})}
											error={!!errors.first_name}
											helperText={
												errors.first_name &&
												errors.first_name.message
											}
										/>

										<TextField
											sx={styleInput}
											label="Last Name"
											name="last_name"
											{...register("last_name", {
												required:
													"Last Name is required.",
											})}
											error={!!errors.last_name}
											helperText={
												errors.last_name &&
												errors.last_name.message
											}
										/>
										<ThemeProvider theme={theme}>
											<MuiTelInput
												sx={styleInput}
												defaultCountry="US"
												forceCallingCode={true}
												label="Phone Number"
												name="phone_number"
												value={phoneValue}
												{...register("phone_number", {
													required:
														"Phone number is required.",
												})}
												error={!!errors.phone_number}
												helperText={
													errors.phone_number &&
													errors.phone_number.message
												}
												onChange={handlePhoneChange}
											/>
										</ThemeProvider>

										<TextField
											sx={styleInput}
											label="Email Address"
											disabled
											name="user_name"
											{...register("user_name")}
											error={!!errors.user_name}
											helperText={
												(errors.user_name &&
													errors.user_name.message) ||
												statusMessage
											}
										/>

										<Button
											size="large"
											type="submit"
											variant="contained"
											sx={{
												marginTop: 3,
												marginBottom: 3,
												boxShadow: "none",
												backgroundColor:
													palette.secondary.main,
											}}
										>
											Update
										</Button>
									</Stack>
								</FormControl>

								<Stack>
									<h4 style={typography.h4}>
										Security Information
									</h4>
									<Button
										size="large"
										type="submit"
										variant="contained"
										sx={{
											marginTop: 3,
											boxShadow: "none",
											backgroundColor:
												palette.secondary.main,
										}}
										onClick={() =>
											setOpenChangePassword(true)
										}
									>
										Change Password
									</Button>
								</Stack>
							</Stack>
						)}

					{successfulUpdate && (
						<Stack
							gap={6}
							direction="column"
							sx={{
								width: "100%",
								color: "common.black",
								paddingY: { xs: 5, md: 7 },
								paddingX: { xs: 3, md: 8 },
								fontFamily: typography.fontFamilyRegular,
								backgroundColor: "white",
							}}
						>
							<IconButton
								aria-label="close"
								sx={{
									position: "fixed",
									top: { xs: 15, lg: 30 },
									right: { xs: 15, lg: 30 },
									zIndex: 2,
								}}
								onClick={handleCloseModal}
							>
								<img
									width="34px"
									src="/assets/icons/table/close.svg"
									alt="X"
								/>
							</IconButton>
							<Stack alignItems={"center"} spacing={5}>
								<h2 style={typography.h3}>
									Profile updated successfully
								</h2>
								<Button
									size="large"
									type="submit"
									variant="contained"
									sx={{
										marginTop: 3,
										boxShadow: "none",
										backgroundColor: palette.secondary.main,
										width: {
											lg: 330,
											md: 330,
											xs: "100%",
										},
									}}
									onClick={() => setSuccessfulUpdate(false)}
								>
									Back to Profile
								</Button>
							</Stack>
						</Stack>
					)}

					{openChangePassword && !openPasswordHasChanged && (
						<Stack
							gap={6}
							direction={"column"}
							sx={{
								width: "100%",
								color: "common.black",
								paddingY: { xs: 9, md: 7 },
								paddingX: { xs: 3, md: 8 },
								fontFamily: typography.fontFamilyRegular,
								backgroundColor: "white",
							}}
						>
							<IconButton
								aria-label="close"
								sx={{
									position: "fixed",
									top: { xs: 15, lg: 30 },
									right: { xs: 15, lg: 30 },
									zIndex: 2,
								}}
								onClick={handleCloseModal}
							>
								<img
									width="30px"
									src="/assets/icons/table/close.svg"
									alt="X"
								/>
							</IconButton>
							<FormControl
								component="form"
								className="form"
								onSubmit={handleSubmit(onChangePasswordSubmit)}
							>
								<Stack spacing={2}>
									<h2 style={typography.h3}>
										Change your password
									</h2>
								</Stack>
								<Stack spacing={2}>
									<TextField
										sx={styleInput}
										label="New Password"
										name="new_password"
										type={
											showPassword ? "text" : "password"
										}
										autoFocus
										{...register("new_password", {
											required:
												"New Password is required.",
										})}
										error={!!errors.new_password}
										aria-invalid={!!errors.new_password}
										helperText={
											errors.new_password &&
											errors.new_password.message
										}
										onChange={(e) => {
											clearErrors("new_password");
											setPassword(e.target.value);
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() =>
															setShowPassword(
																!showPassword
															)
														}
														edge="end"
													>
														<Iconify
															icon={
																showPassword
																	? "eva:eye-fill"
																	: "eva:eye-off-fill"
															}
														/>
													</IconButton>
												</InputAdornment>
											),
										}}
									/>

									<TextField
										label="Confirm Password"
										name="confirm_new_password"
										sx={styleInput}
										type={
											showPassword ? "text" : "password"
										}
										{...register("confirm_new_password", {
											required:
												"To confirm your new password is required.",
										})}
										error={
											!!errors.confirm_new_password ||
											isError
										}
										aria-invalid={
											!!errors.confirm_new_password
										}
										onFocus={() => setIsError(false)}
										helperText={
											errors.confirm_new_password
												? errors.confirm_new_password
														.message
												: isError
												? statusMessage
												: null
										}
										onChange={(e) => {
											clearErrors("confirm_new_password");
											setConfirmPassword(e.target.value);
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() =>
															setShowPassword(
																!showPassword
															)
														}
														edge="end"
													>
														<Iconify
															icon={
																showPassword
																	? "eva:eye-fill"
																	: "eva:eye-off-fill"
															}
														/>
													</IconButton>
												</InputAdornment>
											),
										}}
									/>

									<Button
										size="large"
										type="submit"
										variant="contained"
										sx={{
											marginTop: 3,
											boxShadow: "none",
											backgroundColor:
												palette.secondary.main,
										}}
										// onClick={() =>
										// 	setOpenChangePassword(true)
										// }
									>
										Change Password
									</Button>
								</Stack>
							</FormControl>
						</Stack>
					)}

					{openPasswordHasChanged && (
						<Stack
							gap={6}
							direction={"column"}
							sx={{
								width: "100%",
								color: "common.black",
								paddingY: { xs: 9, md: 7 },
								paddingX: { xs: 3, md: 8 },
								fontFamily: typography.fontFamilyRegular,
								backgroundColor: "white",
							}}
						>
							<IconButton
								aria-label="close"
								sx={{
									position: "fixed",
									top: { xs: 15, lg: 30 },
									right: { xs: 15, lg: 30 },
									zIndex: 2,
								}}
								onClick={handleCloseModal}
							>
								<img
									width="30px"
									src="/assets/icons/table/close.svg"
									alt="X"
								/>
							</IconButton>
							<Stack spacing={2}>
								<h2
									style={{
										...typography.h3,
										textAlign: "center",
									}}
								>
									Your Password has been changed!
								</h2>
							</Stack>
							<Button
								size="large"
								type="submit"
								variant="contained"
								sx={{
									marginTop: { md: 3 },
									boxShadow: "none",
									backgroundColor: palette.secondary.main,
								}}
								onClick={() => {
									dispatch(logoutUser());
									navigate("/login");
								}}
							>
								Back to login screen
							</Button>
						</Stack>
					)}
				</Box>
			</Modal>
		</>
	);
};

export default ProfileModal;
