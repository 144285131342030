import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	Stack,
	TextField,
	Typography,
	IconButton,
	InputAdornment,
	Box,
} from "@mui/material";
import Iconify from "src/components/iconify/Iconify";
import { testConection } from "src/features/onborading/onboardingApi";
import CircularLoader from "src/loader/circular/CircularLoader";
import "./signinCarrier.scss";
import InfoLogo from "src/components/info-logo/info-logo";
import { setSigninData } from "src/features/signin/signin.action";
import palette from "src/theme/palette";
import { LoadingButton } from "@mui/lab";
import { getUserInfo } from "src/features/user/userState";
import { useAppSelector, useAppDispatch } from "src/hooks/hooks";
import ModalComponent from "src/components/modal/ModalComponent";
import {
	decrementAwaitingApiCounter,
	incrementAwaitingApiCounter,
} from "src/features/ui/uiState";

const styleLoginForm = {
	color: "black",
	width: { lg: 560, md: 517 },
	height: { lg: 607, md: 656 },
};

const popUpModalStyle = {
	display: "flex",
	alignItems: "center",
	justifyContent: "center",
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: { xs: "80%", sm: "auto", lg: "580px", md: "auto" },
	borderRadius: "20px",
	background: "#FFF",
	overflow: "auto",
	overflow: "auto-y",
	overflowX: "hidden",
	outline: "none",
};

const styleInput = {
	input: { color: palette.common.black },
	width: { lg: 515, md: 446, sm: 446 },
	"& .MuiOutlinedInput-root": {
		"&:hover fieldset": {
			borderColor: "primary.dark",
		},
		"&.Mui-focused fieldset": {
			borderColor: "primary.dark",
		},
	},
	"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
		borderColor: "secondary.light",
	},
	"& .MuiInputLabel-formControl": {
		color: "#00000099",
	},
	"& .MuiFormLabel-root-MuiInputLabel-root.Mui-focused": {
		color: "#00000099",
	},
};

const SigninCarrier = ({ navigateTo }) => {
	const dispatch = useDispatch();
	const dispatchApp = useAppDispatch();
	const navigate = useNavigate();
	const signinData = useSelector((state) => state.signin.signinData);

	const userInfo = useAppSelector(getUserInfo);

	const {
		carrier,
		carrierSignUSPSUsername,
		carrierSignUPSUsername,
		carrierSignFedexUsername,
		carrierSignDHLUsername,

		carrierSignUSPSUsernameError,
		carrierSignUPSUsernameError,
		carrierSignFedexUsernameError,
		carrierSignDHLUsernameError,

		carrierSignUSPSPassword,
		carrierSignUPSPassword,
		carrierSignFedexPassword,
		carrierSignDHLPassword,

		carrierSignUSPSPasswordError,
		carrierSignUPSPasswordError,
		carrierSignFedexPasswordError,
		carrierSignDHLPasswordError,

		carrierSignUSPSUserId,
		carrierSignDHLApiKey,
		carrierSignDHLApiSecret,

		carrierSignUPSClientId,
		carrierSignUPSClientSecret,
		carrierSignFedexClientId,
		carrierSignFedexClientSecret,

		isUpsValid,
		isUpsTested,
		isUspsValid,
		isUspsTested,
		isFedexValid,
		isFedexTested,
		isDHLValid,
		isDHLTested,

		isFromConfig,
	} = signinData || {};

	const [showPassword, setShowPassword] = useState(false);
	const [openModal, setOpenModal] = useState(false);
	const [modalText, setModalText] = useState("");
	const [testCon, setTestCon] = useState(false);

	const [testedCarrier, setTestedCarrier] = useState("");

	const handleTestCarrierConnection = async (carrierName) => {
		dispatchApp(incrementAwaitingApiCounter());
		setTestedCarrier(carrierName);

		if (carrierName === "UPS") {
			testConection(
				{
					action: "ups",
					user_name: carrierSignUSPSUsername,
					password: carrierSignUSPSPassword,
					client_key: carrierSignUPSClientId,
					client_secret: carrierSignUPSClientSecret,
				},
				userInfo.accessToken
			)
				.then((r) => {
					const { statusCode } = r;
					if (statusCode === 200) {
						dispatch(
							setSigninData({
								...signinData,
								isUpsValid: true,
								isUpsTested: true,
								continueBtn: true,
							})
						);

						setTestCon("UPS");
					} else {
						dispatch(
							setSigninData({
								...signinData,
								isUpsValid: false,
								isUpsTested: true,
							})
						);

						setTestCon("UPS_NOT");
					}

					dispatchApp(decrementAwaitingApiCounter());
				})
				.catch(() => {
					dispatch(
						setSigninData({
							...signinData,
							isUpsValid: false,
							isUpsTested: true,
						})
					);

					setTestCon("NOT_UPS");
					dispatchApp(decrementAwaitingApiCounter());
				});
		} else if (carrierName === "USPS") {
			testConection(
				{
					action: "usps",
					user_id: carrierSignUSPSUserId,
					user_name: carrierSignUSPSUsername,
					password: carrierSignUSPSPassword,
				},
				userInfo.accessToken
			)
				.then((r) => {
					const { statusCode } = r;

					if (statusCode === 200) {
						dispatch(
							setSigninData({
								...signinData,
								isUspsValid: true,
								isUspsTested: true,
								continueBtn: true,
							})
						);

						setTestCon("USPS");
					} else {
						dispatch(
							setSigninData({
								...signinData,
								isUspsValid: false,
								isUspsTested: true,
							})
						);

						setTestCon("USPS_NOT");
					}
					dispatchApp(decrementAwaitingApiCounter());
				})
				.catch(() => {
					dispatch(
						setSigninData({
							...signinData,
							isUspsValid: false,
							isUspsTested: true,
						})
					);

					setTestCon("NOT_USPS");
					dispatchApp(decrementAwaitingApiCounter());
				});
		} else if (carrierName === "Fedex") {
			testConection({
				action: "fedex",
				user_name: carrierSignFedexUsername,
				password: carrierSignFedexPassword,
				client_key: carrierSignFedexClientId,
				client_secret: carrierSignFedexClientSecret,
			})
				.then((r) => {
					const { statusCode } = r;

					if (statusCode === 200) {
						dispatch(
							setSigninData({
								...signinData,
								isFedexValid: true,
								isFedexTested: true,
								continueBtn: true,
							})
						);

						setTestCon("Fedex");
					} else {
						dispatch(
							setSigninData({
								...signinData,
								isFedexValid: false,
								isFedexTested: true,
							})
						);

						setTestCon("Fedex_NOT");
					}

					dispatchApp(decrementAwaitingApiCounter());
				})
				.catch(() => {
					dispatch(
						setSigninData({
							...signinData,
							isFedexValid: false,
							isFedexTested: true,
						})
					);

					setTestCon("NOT_Fedex");
					dispatchApp(decrementAwaitingApiCounter());
				});
		} else if (carrierName === "DHL") {
			testConection({
				action: "dhl",
				api_key: carrierSignDHLApiKey,
				api_secret: carrierSignDHLApiSecret,
				user_name: carrierSignDHLUsername,
				password: carrierSignDHLPassword,
			})
				.then((r) => {
					const { statusCode } = r;

					if (statusCode === 200) {
						dispatch(
							setSigninData({
								...signinData,
								isDHLValid: true,
								isDHLTested: true,
							})
						);

						setTestCon("DHL");
					} else {
						dispatch(
							setSigninData({
								...signinData,
								isDHLValid: false,
								isDHLTested: true,
							})
						);

						setTestCon("DHL_NOT");
					}

					dispatchApp(decrementAwaitingApiCounter());
				})
				.catch(() => {
					dispatch(
						setSigninData({
							...signinData,
							isDHLValid: false,
							isDHLTested: true,
						})
					);

					setTestCon("NOT_DHL");
					dispatchApp(decrementAwaitingApiCounter());
				});
		}
	};

	const handleCloseModal = () => {
		setOpenModal(false);
		dispatch(setSigninData({ ...signinData, continueBtn: true }));
	};

	useEffect(() => {
		if (!testCon) return;

		const handleInvalidTestConnection = (failedCarriers) => {
			setOpenModal(true);
			const failedCarriersText = failedCarriers.join(", ");
			setModalText(
				`Your test connection did not succeed for ${failedCarriersText}. This would affect order tracking and may not work as expected. Please fix the connection and try again.`
			);
			setTestCon(false);
		};

		const failedCarriers = [];
		if (carrier?.usps && isUspsTested && !isUspsValid) {
			failedCarriers.push("USPS");
		}
		if (carrier?.ups && isUpsTested && !isUpsValid) {
			failedCarriers.push("UPS");
		}
		if (carrier?.fedex && isFedexTested && !isFedexValid) {
			failedCarriers.push("Fedex");
		}
		if (carrier?.dhl && isDHLTested && !isDHLValid) {
			failedCarriers.push("DHL");
		}
		if (failedCarriers.length > 0) {
			handleInvalidTestConnection(failedCarriers);
		} else {
			dispatch(setSigninData({ ...signinData, continueBtn: true }));
		}
	}, [testCon]);

	useEffect(() => {
		if (navigateTo === "usps") {
			window.scrollTo(0, 0);
		}
		if (navigateTo === "ups") {
			if (carrier.usps) window.scrollTo(0, 670);
			else window.scrollTo(0, 0);
		}
		if (navigateTo === "fedex") {
			if (carrier.ups && carrier.usps) window.scrollTo(0, 1200);
			else if (carrier.ups || carrier.usps) window.scrollTo(0, 750);
			else window.scrollTo(0, 0);
		}
		if (navigateTo === "dhl") {
			if (!carrier.ups && !carrier.usps && !carrier.fedex)
				window.scrollTo(0, 0);
			else window.scrollTo(0, 1350);
		}

		if (isFromConfig) return;

		if (!signinData.user_id) navigate("/login");
		if (carrier?.usps && carrier?.ups && carrier?.fedex && carrier?.dhl) {
			if (isUpsTested && isUspsTested && isFedexTested && isDHLTested)
				dispatch(setSigninData({ ...signinData, continueBtn: true }));
			else dispatch(setSigninData({ ...signinData, continueBtn: false }));
		} else if (carrier?.usps) {
			if (isUspsTested)
				dispatch(setSigninData({ ...signinData, continueBtn: true }));
			else dispatch(setSigninData({ ...signinData, continueBtn: false }));
		} else if (carrier?.ups) {
			if (isUpsTested)
				dispatch(setSigninData({ ...signinData, continueBtn: true }));
			else dispatch(setSigninData({ ...signinData, continueBtn: false }));
		} else if (carrier?.fedex) {
			if (isFedexTested)
				dispatch(setSigninData({ ...signinData, continueBtn: true }));
			else dispatch(setSigninData({ ...signinData, continueBtn: false }));
		} else if (carrier?.dhl) {
			if (isDHLTested)
				dispatch(setSigninData({ ...signinData, continueBtn: true }));
			else dispatch(setSigninData({ ...signinData, continueBtn: false }));
		}
	}, []);

	return (
		<>
			<div className="signin-carrier-container">
				<Typography
					variant="h3"
					sx={{
						textAlign: "center",
					}}
				>
					Sign into your shipment accounts
				</Typography>

				<Typography
					sx={{
						color: palette.common.black,
						fontSize: 18,
						textAlign: "center",
					}}
				>
					This allows us to actually manage your data sources
				</Typography>

				<div style={{ display: "flex", justifyContent: "center" }}>
					<Stack spacing={3} style={styleLoginForm}>
						{carrier.usps && (
							<>
								<img
									className="carrier-img"
									src="/assets/usps.png"
								></img>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										fullWidth
										sx={styleInput}
										label="User Name *"
										value={carrierSignUSPSUsername}
										error={carrierSignUSPSUsernameError}
										helperText={
											carrierSignUSPSUsernameError &&
											"User Name is required."
										}
										onFocus={() =>
											dispatch(
												setSigninData({
													...signinData,
													carrierSignUSPSUsernameError: false,
												})
											)
										}
										onChange={(e) => {
											dispatch(
												setSigninData({
													...signinData,
													carrierSignUSPSUsername:
														e.target.value,
												})
											);
										}}
										inputProps={{
											maxLength: 50,
										}}
									></TextField>
									<InfoLogo infoTxt="Enter your USPS account username. This is the email address associated with your USPS account."></InfoLogo>
								</Box>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										fullWidth
										sx={styleInput}
										label="Password *"
										value={carrierSignUSPSPassword}
										type={
											showPassword ? "text" : "password"
										}
										error={carrierSignUSPSPasswordError}
										helperText={
											carrierSignUSPSPasswordError &&
											"Password is required."
										}
										onFocus={() =>
											dispatch(
												setSigninData({
													...signinData,
													carrierSignUSPSPasswordError: false,
												})
											)
										}
										onChange={(e) =>
											dispatch(
												setSigninData({
													...signinData,
													carrierSignUSPSPassword:
														e.target.value,
												})
											)
										}
										inputProps={{
											maxLength: 50,
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() =>
															setShowPassword(
																!showPassword
															)
														}
														edge="end"
													>
														<Iconify
															icon={
																showPassword
																	? "eva:eye-fill"
																	: "eva:eye-off-fill"
															}
														/>
													</IconButton>
												</InputAdornment>
											),
										}}
									></TextField>
									<InfoLogo infoTxt="Enter your USPS account password. This is the password for the email address associated with your USPS account."></InfoLogo>
								</Box>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										fullWidth
										sx={styleInput}
										label="User Id"
										value={carrierSignUSPSUserId}
										onChange={(e) => {
											const trimmedValue =
												e.target.value.trim();
											dispatch(
												setSigninData({
													...signinData,
													isUspsValid: false,
													isUspsTested: false,
													carrierSignUSPSUserId:
														trimmedValue,
												})
											);
										}}
										inputProps={{
											maxLength: 50,
										}}
									></TextField>
									<InfoLogo infoTxt="The User ID provided by USPS upon registration or through their Web Tools API portal. It's essential for accessing USPS services and differs from your Username. If you're unsure about your UserID, you may find it in your USPS confirmation email or by logging into your USPS Web Tools account."></InfoLogo>
								</Box>

								<Typography
									sx={{
										color: palette.common.black,
										fontSize: 18,
										textAlign: "center",
										paddingTop: 1,
									}}
								>
									Kindly test connection to continue with the
									next step
								</Typography>

								<LoadingButton
									type="submit"
									variant="contained"
									onClick={() =>
										handleTestCarrierConnection("USPS")
									}
									sx={{
										fontSize: "16px !important",
										boxShadow: "none",
										width: "50%",
										marginX: "auto !important",
										backgroundColor: isUspsTested
											? isUspsValid
												? palette.success.dark
												: palette.error.dark
											: palette.secondary.light,
									}}
								>
									{isUspsTested
										? isUspsValid
											? "Connection Successful"
											: "Unable to Connect"
										: "Test USPS Connection"}
								</LoadingButton>
							</>
						)}

						{carrier.ups && (
							<>
								<img
									className="carrier-img"
									src="/assets/ups.png"
								></img>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										sx={styleInput}
										label="User Name *"
										value={carrierSignUPSUsername}
										error={carrierSignUPSUsernameError}
										helperText={
											carrierSignUPSUsernameError &&
											"User Name is required."
										}
										onFocus={() =>
											dispatch(
												setSigninData({
													...signinData,
													carrierSignUPSUsernameError: false,
												})
											)
										}
										onChange={(e) => {
											dispatch(
												setSigninData({
													...signinData,
													carrierSignUPSUsername:
														e.target.value,
												})
											);
										}}
										inputProps={{
											maxLength: 50,
										}}
									></TextField>
									<InfoLogo infoTxt="Enter your UPS account username. This is the email address associated with your UPS account."></InfoLogo>
								</Box>
								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										sx={styleInput}
										label="Password *"
										value={carrierSignUPSPassword}
										type={
											showPassword ? "text" : "password"
										}
										error={carrierSignUPSPasswordError}
										helperText={
											carrierSignUPSPasswordError &&
											"Password is required."
										}
										onFocus={() =>
											dispatch(
												setSigninData({
													...signinData,
													carrierSignUPSPasswordError: false,
												})
											)
										}
										onChange={(e) => {
											dispatch(
												setSigninData({
													...signinData,
													carrierSignUPSPassword:
														e.target.value,
												})
											);
										}}
										inputProps={{
											maxLength: 50,
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() =>
															setShowPassword(
																!showPassword
															)
														}
														edge="end"
													>
														<Iconify
															icon={
																showPassword
																	? "eva:eye-fill"
																	: "eva:eye-off-fill"
															}
														/>
													</IconButton>
												</InputAdornment>
											),
										}}
									></TextField>
									<InfoLogo infoTxt="Enter your UPS account password. This is the password for the email address associated with your UPS account."></InfoLogo>
								</Box>

								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										sx={styleInput}
										label="Client Id"
										value={carrierSignUPSClientId}
										onChange={(e) => {
											const trimmedValue =
												e.target.value.trim();
											dispatch(
												setSigninData({
													...signinData,
													isUpsValid: false,
													isUpsTested: false,
													carrierSignUPSClientId:
														trimmedValue,
												})
											);
										}}
										inputProps={{
											maxLength: 50,
										}}
									></TextField>
									<InfoLogo infoTxt="Your unique Client ID (or API Key) from UPS. This is used to authenticate UPS requests and must be obtained through your UPS developer account."></InfoLogo>
								</Box>

								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										sx={styleInput}
										label="Client Secret"
										value={carrierSignUPSClientSecret}
										onChange={(e) => {
											const trimmedValue =
												e.target.value.trim();
											dispatch(
												setSigninData({
													...signinData,
													isUpsValid: false,
													isUpsTested: false,
													carrierSignUPSClientSecret:
														trimmedValue,
												})
											);
										}}
										inputProps={{
											maxLength: 70,
										}}
									></TextField>
									<InfoLogo infoTxt="A secret key paired with your Client ID, used to authenticate your application with UPS securely."></InfoLogo>
								</Box>

								<Typography
									sx={{
										color: palette.common.black,
										fontSize: 18,
										textAlign: "center",
										paddingTop: 1,
									}}
								>
									Kindly test connection to continue with the
									next step
								</Typography>

								<LoadingButton
									type="submit"
									variant="contained"
									onClick={() =>
										handleTestCarrierConnection("UPS")
									}
									sx={{
										fontSize: "16px !important",
										boxShadow: "none",
										width: "50%",
										marginX: "auto !important",
										backgroundColor: isUpsTested
											? isUpsValid
												? palette.success.dark
												: palette.error.dark
											: palette.secondary.light,
									}}
								>
									{isUpsTested
										? isUpsValid
											? "Connection Successful"
											: "Unable to Connect"
										: "Test UPS Connection"}
								</LoadingButton>
							</>
						)}
						{carrier?.fedex && (
							<>
								<img
									className="carrier-img"
									src="/assets/fedex.png"
								/>

								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										sx={styleInput}
										label="User Name *"
										value={carrierSignFedexUsername}
										error={carrierSignFedexUsernameError}
										helperText={
											carrierSignFedexUsernameError &&
											"User Name is required."
										}
										onFocus={() =>
											dispatch(
												setSigninData({
													...signinData,
													carrierSignFedexUsernameError: false,
												})
											)
										}
										onChange={(e) => {
											dispatch(
												setSigninData({
													...signinData,
													carrierSignFedexUsername:
														e.target.value,
												})
											);
										}}
										inputProps={{
											maxLength: 50,
										}}
									></TextField>
									<InfoLogo infoTxt="Enter your Fedex account username. This is the email address associated with your Fedex account."></InfoLogo>
								</Box>

								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										sx={styleInput}
										label="Password *"
										value={carrierSignFedexPassword}
										type={
											showPassword ? "text" : "password"
										}
										error={carrierSignFedexPasswordError}
										helperText={
											carrierSignFedexPasswordError &&
											"Password is required."
										}
										onFocus={() =>
											dispatch(
												setSigninData({
													...signinData,
													carrierSignFedexPasswordError: false,
												})
											)
										}
										onChange={(e) => {
											dispatch(
												setSigninData({
													...signinData,
													carrierSignFedexPassword:
														e.target.value,
												})
											);
										}}
										inputProps={{
											maxLength: 50,
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() =>
															setShowPassword(
																!showPassword
															)
														}
														edge="end"
													>
														<Iconify
															icon={
																showPassword
																	? "eva:eye-fill"
																	: "eva:eye-off-fill"
															}
														/>
													</IconButton>
												</InputAdornment>
											),
										}}
									></TextField>
									<InfoLogo infoTxt="Enter your Fedex account password. This is the password for the email address associated with your Fedex account."></InfoLogo>
								</Box>

								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										sx={styleInput}
										label="Client Id"
										value={carrierSignFedexClientId}
										onChange={(e) => {
											const trimmedValue =
												e.target.value.trim();
											dispatch(
												setSigninData({
													...signinData,
													carrierSignFedexClientId:
														trimmedValue,
													isFedexValid: false,
													isFedexTested: false,
													continueBtn: false,
												})
											);
										}}
										inputProps={{
											maxLength: 50,
										}}
									></TextField>
									<InfoLogo infoTxt="Your unique Client ID (or API Key) from Fedex. This is used to authenticate Fedex requests and must be obtained through your Fedex developer account."></InfoLogo>
								</Box>

								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										sx={styleInput}
										label="Client Secret"
										value={carrierSignFedexClientSecret}
										onChange={(e) => {
											const trimmedValue =
												e.target.value.trim();
											dispatch(
												setSigninData({
													...signinData,
													carrierSignFedexClientSecret:
														trimmedValue,
													isFedexValid: false,
													isFedexTested: false,
													continueBtn: false,
												})
											);
										}}
										inputProps={{
											maxLength: 70,
										}}
									></TextField>

									<InfoLogo infoTxt="A secret key paired with your Client ID, used to authenticate your application with Fedex securely."></InfoLogo>
								</Box>

								<Typography
									sx={{
										color: palette.common.black,
										fontSize: 18,
										textAlign: "center",
										paddingTop: 1,
									}}
								>
									Kindly test connection to continue with the
									next step
								</Typography>

								<LoadingButton
									type="submit"
									variant="contained"
									onClick={() =>
										handleTestCarrierConnection("Fedex")
									}
									sx={{
										fontSize: "16px !important",
										boxShadow: "none",
										width: "50%",
										marginX: "auto !important",
										backgroundColor: isFedexTested
											? isFedexValid
												? palette.success.dark
												: palette.error.dark
											: palette.secondary.light,
									}}
								>
									{isFedexTested
										? isFedexValid
											? "Connection Successful"
											: "Unable to Connect"
										: "Test Fedex Connection"}
								</LoadingButton>
							</>
						)}
						{carrier?.dhl && (
							<>
								<img
									className="carrier-img"
									src="/assets/dhl.png"
								/>

								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										sx={styleInput}
										label="User Name *"
										value={carrierSignDHLUsername}
										error={carrierSignDHLUsernameError}
										helperText={
											carrierSignDHLUsernameError &&
											"User Name is required."
										}
										onFocus={() =>
											dispatch(
												setSigninData({
													...signinData,
													carrierSignDHLUsernameError: false,
												})
											)
										}
										onChange={(e) => {
											dispatch(
												setSigninData({
													...signinData,
													carrierSignDHLUsername:
														e.target.value,
												})
											);
										}}
										inputProps={{
											maxLength: 50,
										}}
									/>

									<InfoLogo infoTxt="Enter your DHL account username. This is the email address associated with your DHL account."></InfoLogo>
								</Box>

								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										sx={styleInput}
										label="Password *"
										value={carrierSignDHLPassword}
										type={
											showPassword ? "text" : "password"
										}
										error={carrierSignDHLPasswordError}
										helperText={
											carrierSignDHLPasswordError &&
											"Password is required."
										}
										onFocus={() =>
											dispatch(
												setSigninData({
													...signinData,
													carrierSignDHLPasswordError: false,
												})
											)
										}
										onChange={(e) =>
											dispatch(
												setSigninData({
													...signinData,
													carrierSignDHLPassword:
														e.target.value,
												})
											)
										}
										inputProps={{
											maxLength: 50,
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														onClick={() =>
															setShowPassword(
																!showPassword
															)
														}
														edge="end"
													>
														<Iconify
															icon={
																showPassword
																	? "eva:eye-fill"
																	: "eva:eye-off-fill"
															}
														/>
													</IconButton>
												</InputAdornment>
											),
										}}
									/>

									<InfoLogo infoTxt="Enter your DHL account password. This is the password for the email address associated with your DHL account."></InfoLogo>
								</Box>

								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										sx={styleInput}
										label="Api Key"
										value={carrierSignDHLApiKey}
										onChange={(e) => {
											const trimmedValue =
												e.target.value.trim();
											dispatch(
												setSigninData({
													...signinData,
													carrierSignDHLApiKey:
														trimmedValue,
													isDHLValid: false,
													isDHLTested: false,
													continueBtn: false,
												})
											);
										}}
										inputProps={{
											maxLength: 50,
										}}
									/>

									<InfoLogo infoTxt="The Api Key provided by DHL upon registration or through their Web Tools API portal. It's essential for accessing DHL services and differs from your Username. If you're unsure about your Api Key, you may find it in your DHL confirmation email or by logging into your DHL Web Tools account."></InfoLogo>
								</Box>

								<Box
									sx={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
									}}
								>
									<TextField
										sx={styleInput}
										label="Api Secret"
										value={carrierSignDHLApiSecret}
										onChange={(e) => {
											const trimmedValue =
												e.target.value.trim();
											dispatch(
												setSigninData({
													...signinData,
													carrierSignDHLApiSecret:
														trimmedValue,
													isDHLValid: false,
													isDHLTested: false,
													continueBtn: false,
												})
											);
										}}
										inputProps={{
											maxLength: 50,
										}}
									/>

									<InfoLogo infoTxt="The Api Secret provided by DHL upon registration or through their Web Tools API portal. It's essential for accessing DHL services and differs from your Username. If you're unsure about your Api Secret, you may find it in your DHL confirmation email or by logging into your DHL Web Tools account."></InfoLogo>
								</Box>

								<Typography
									sx={{
										color: palette.common.black,
										fontSize: 18,
										textAlign: "center",
										paddingTop: 1,
									}}
								>
									Kindly test connection to continue with the
									next step
								</Typography>

								<LoadingButton
									type="submit"
									variant="contained"
									onClick={() =>
										handleTestCarrierConnection("DHL")
									}
									sx={{
										fontSize: "16px !important",
										boxShadow: "none",
										width: "50%",
										marginX: "auto !important",
										backgroundColor: isDHLTested
											? isDHLValid
												? palette.success.dark
												: palette.error.dark
											: palette.secondary.light,
									}}
								>
									{isDHLTested
										? isDHLValid
											? "Connection Successful"
											: "Unable to Connect"
										: "Test DHL Connection"}
								</LoadingButton>
							</>
						)}
					</Stack>
				</div>
			</div>
			<ModalComponent
				openModal={openModal}
				handleCloseModal={handleCloseModal}
				handleBtnClick={handleCloseModal}
				title={`Connection status`}
				description={modalText}
				btnText={"Continue"}
			></ModalComponent>
		</>
	);
};

export default SigninCarrier;
