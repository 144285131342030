import { SIGNIN_ACTION_TYPES } from './signin.types';

// INITIAL STATE DEF
const INITIAL_STATE = {
  signinData: null,
};

// REDUCER DEF
export const signinReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case SIGNIN_ACTION_TYPES.SET_SIGNIN_DATA:
      return { ...state, signinData: payload };
    default:
      return state;
  }
};
