import getData from "src/dataFetcher/getDataFetcher";
import postData from "src/dataFetcher/postDataFetcher";
import putData from "src/dataFetcher/putDataFetcher";
import { backendUserUrl } from "src/utils/backendUrls";
import { extractData } from "../user/userApi";

// get a tenant by its id
export async function getTenantUser(params = null, headers, userId, userInfo) {
  const tenant = userInfo?.tenant_name;
  const token = userInfo?.accessToken;

  const result = await getData(
    `${backendUserUrl}user/admin/user/${userId}`,
    params,
    headers,
    token
  );
  return result;
}

export async function getSingleTenant(
  params = null,
  headers,
  userId,
  userInfo,
  tenant_name
) {
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendUserUrl}user/${tenant_name}/user/${userId}`,
    params,
    headers,
    token
  );
  return result;
}

export async function getAllTenantUsers(
  params = null,
  headers,
  userInfo,
  tenant_name
) {
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendUserUrl}user/${tenant_name}/user`,
    params,
    headers,
    token
  );
  return result;
}

export async function getAllTenantUsersList(params = null, headers, userInfo) {
  const tenant = userInfo?.tenant_name;
  const token = userInfo?.accessToken;

  const result = await getData(
    `${backendUserUrl}user/admin/tenant/users`,
    params,
    headers,
    token
  );
  return result;
}

// updates the password of a user
export async function updateAdminPassword(body = null, userId, userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;
  const header = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.id,
  };

  return await putData(
    `${backendUserUrl}user/admin/user/password/${userId}`,
    header,
    body,
    token
  );
}

// updates the password of a user
export async function updateTenantPassword(
  body = null,
  userId,
  userInfo,
  tenant_name,
  headers
) {
  const token = userInfo?.accessToken;

  return await putData(
    `${backendUserUrl}user/${tenant_name}/user/password/${userId}`,
    headers,
    body,
    token
  );
}

// gets the list of tenant users
export async function getAllAdminUsers(params = null, headers, userInfo) {
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendUserUrl}user/admin/user`,
    params,
    headers,
    token
  );
  return result;
}

export async function getTenant(userInfo, tenant_id) {
  const token = userInfo?.accessToken;

  return await getData(
    `${backendUserUrl}tenant/frontend/tenant/${tenant_id}`,
    null,
    null,
    token
  );
}

export async function deleteTenant(userInfo, data, action) {
  const token = userInfo?.accessToken;
  const res = await postData(
    `${backendUserUrl}delete-tenant`,
    action,
    data,
    token
  );
  return JSON.parse(extractData(res));
}

export async function getAllUsers(
  params = null,
  headers,
  userInfo,
  tenant_name
) {
  const token = userInfo?.accessToken;
  const result = await getData(
    `${backendUserUrl}user/${tenant_name}/user`,
    params,
    headers,
    token
  );
  return result;
}

export async function createUser(headers, body, userInfo) {
  const token = userInfo?.accessToken;
  return await postData(`${backendUserUrl}account`, headers, body, token);
}

export async function updateProfileInfo(data, userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;
  const header = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };
  return await putData(
    `${backendUserUrl}user/admin/profile/info`,
    header,
    data,
    token
  );
}

export async function updateAdminUser(body = null, userId, userInfo) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;
  const header = {
    tenant_id: userInfo?.tenant_id,
    user_id: userInfo?.user_id,
  };

  return await putData(
    `${backendUserUrl}user/admin/user/${userId}`,
    header,
    body,
    token
  );
}

export async function updateTenantUser(
  body = null,
  userId,
  userInfo,
  tenant_name,
  headers
) {
  const token = userInfo?.accessToken;
  const tenant = userInfo?.tenant_name;

  return await putData(
    `${backendUserUrl}user/${tenant_name}/user/${userId}`,
    headers,
    body,
    token
  );
}

export async function promoteToAdmin(
  headers,
  selectedUserId,
  userInfo,
  tenant_name
) {
	const token = userInfo?.accessToken;
	const userId = {
		userId: selectedUserId,
	};
	const res = await postData(
		`${backendUserUrl}user/${tenant_name}/user/promote`,
		headers,
		userId,
		token
	);
	return JSON.parse(extractData(res))
}
